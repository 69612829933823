import { forgotPasswordRequest } from 'api/User/api';

import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const useResetPassword = () => {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('reset_password.enter_valid_email'))
      .required(t('reset_password.enter_email')),
  });

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  const formikSubmit = (values: { email: string }) => {
    setSubmitting(true);

    forgotPasswordRequest(values.email).then(() => {
      setSubmitting(false);
      setIsSubmitted(true);
    }).catch((error) => {
      setSubmitting(false);
      setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
    }).finally(() => setSubmitting(false));
  };

  const formik = useFormik<{ email: string }>({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: formikSubmit,
  });

  return { formik, isSubmitting, submitError, isSubmitted };
};

export default useResetPassword;
