import { AuthorizationContainer, FormTextField, Label } from 'common/components';
import { resendActivateLinkRequest } from 'api/User/api';
import useVerify from 'components/Dashboard/components/Authorization/hooks/useVerify';

import React, { ReactElement } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { Trans, useTranslation } from 'react-i18next';

const VerifyPage = () => {
  const { t } = useTranslation();

  const { key } = useParams();
  const { isLoading } = useVerify(key);
  const [ email, setEmail ] = React.useState<string>('');
  const [ submitError, setSubmitError ] = React.useState<string | ReactElement>('');
  const [ success, setSuccess ] = React.useState<boolean>(false);
  const [ isSubmitting, setIsSubmitting ] = React.useState<boolean>(false);

  const resendActivationLink = React.useCallback(() => {
    setSubmitError('');
    setSuccess(false);
    setIsSubmitting(true);
    resendActivateLinkRequest(email)
      .then(() => {
        setSuccess(true);
        setIsSubmitting(false);
      })
      .catch((error) => {
        if (error?.response.data.message === 'User already activated') {
          const errorMsg = (
            <span>
              <Trans t={t} i18nKey="verify_page.already_activated">
                This account is already activated. Go to <Link to="/dashboard">Sign in</Link>
              </Trans>
            </span>
          );
          setSubmitError(errorMsg);
        } else {
          setSubmitError(error?.response.data.message || t('errors.server_error_try_again'));
        }
        setIsSubmitting(false);
      });
  }, [ email, t ]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <AuthorizationContainer data-testid="verify-page">
      <Label>{t('verify_page.activation_link_invalid')}</Label>
      <Typography marginTop="0px" marginBottom={{ xs: 8, sm: 5 }}>
        {t('dashboard.verify_account.error_something_went_wrong')}
      </Typography>
      <FormTextField
        id="email"
        name="email"
        type="email"
        label={t('general.email')}
        value={email}
        onChange={e => setEmail(e.target.value)}
        disabled={false}
      />
      {
        submitError && (
          <Box alignSelf="flex-start">
            <Typography color="error" variant="subtitle2">
              {submitError}
            </Typography>
          </Box>
        )
      }
      {
        success && (
          <Box alignSelf="flex-start" marginTop={2}>
            <Typography variant="subtitle2" color="success">
              {t('verify_page.activation_link_sent')}
            </Typography>
          </Box>
        )
      }
      <Box display="flex" justifyContent="flex-start" width="100%" marginTop={{ xs: 3, sm: 5 }}>
        <LoadingButton sx={{ width: { xs: '100%', sm: 'unset' } }} loading={isSubmitting} disabled={!email || success} onClick={resendActivationLink} variant="contained">
          {t('verify_page.button_request_link')}
        </LoadingButton>
      </Box>
    </AuthorizationContainer>
  );
};

export default VerifyPage;
