import { getPartnerInfoRequest } from 'api/User/api';
import { PartnerInfo } from 'api/User/types';

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const usePartnerInvite = () => {
  const [ partnerValues, setPartnerValues ] = React.useState<PartnerInfo>();
  const [ isPartnerInfoLoading, setIsPartnerInfoLoading ] = React.useState<boolean>(true);
  const [ searchParams ] = useSearchParams();
  const partnerId = searchParams.get('partnerId');

  useEffect(() => {
    if (partnerId) {
      getPartnerInfoRequest(partnerId).then((response) => {
        setPartnerValues(response.data);
        setIsPartnerInfoLoading(false);
      }).catch(() => {
        setIsPartnerInfoLoading(false);
      });
    } else {
      setIsPartnerInfoLoading(false);
    }
  }, [ partnerId ]);

  return { partnerValues, isPartnerInfoLoading };
};

export default usePartnerInvite;
