import { WithKit } from 'api/UserKits/types';
import { axiosInstance } from 'api/instance';
import PaginatedData from 'common/types/PaginatedData';

import { AxiosResponse } from 'axios';

export const getUserKits = (page: number, pageSize: number, username: string): Promise<AxiosResponse<PaginatedData<WithKit>>> => {
  return axiosInstance.post<PaginatedData<WithKit>>(`users/with-kit?page=${page}&pageSize=${pageSize}`, { username });
};

export const updateUserKitRequest = (userId: number, newKitId: string): Promise<AxiosResponse> => {
  return axiosInstance.put(`users/update-kit/${userId}`, { kitId: newKitId });
};
