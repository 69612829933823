import { ivory } from 'common/constants/colors';
import NewsCard
  from 'components/Landing/components/NewsView/components/NewsCard/NewsCard';
import { getResourceUrl } from 'common/utils/getResourceUrl';
import { useNewsList } from 'common/hooks/useNewsList';

import { Box, Button, CircularProgress, Paper, Stack, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import SwiperClass from 'swiper/types/swiper-class';
import { useTranslation } from 'react-i18next';

const NewsView: React.FC = () => {
  const { t } = useTranslation();
  const [ swiper, setSwiper ] = useState<SwiperClass>();
  const navigate = useNavigate();

  const [ isPrevDisabled, setPrevDisabled ] = useState(false);
  const [ isNextDisabled, setNextDisabled ] = useState(false);

  const theme = useTheme();
  const {
    requestNews,
    newsList,
    isLoading,
  } = useNewsList();

  useEffect(() => {
    requestNews();
  }, [ requestNews ]);

  const renderNews = useCallback(() => {
    return newsList.map((news) => {
      return (
        <SwiperSlide key={news.id}>
          <Box onClick={() => navigate(`/news/${news.readableId}`)}>
            <NewsCard date={news.newsDate} preview={getResourceUrl(news.title.imageUrl)} title={news.title.title} />
          </Box>
        </SwiperSlide>
      );
    });
  }, [ newsList, navigate ]);

  const updateScrollButtons = (swiper: SwiperClass) => {
    setPrevDisabled(swiper.isBeginning);
    setNextDisabled(swiper.isEnd);
  };
  
  return (
    <Paper
      id="news"
      sx={{
        borderRadius: '30px',
        position: 'relative',
        overflow: 'hidden',
        minHeight: 400,
      }}
    >
      <Box
        position="absolute"
        bottom={0}
        width="100%"
        height="55%"
        sx={{
          backgroundColor: ivory,
        }}
      />
      <Box sx={{
        padding: { xs: '32px', lg: '64px' },
      }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            color="midnight.main"
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: { xs: '28px', lg: '40px' },
              lineHeight: '125%',
              letterSpacing: '-0.05em',
            }}
          >
            {t('landing.publications.publications_title')}
          </Typography>
          <Stack direction="row" spacing={3}>
            <Button
              variant="round"
              size="small"
              color="primary"
              disabled={isPrevDisabled}
              onClick={() => { swiper?.slidePrev(); }}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              variant="round"
              size="small"
              color="primary"
              disabled={isNextDisabled}
              onClick={() => { swiper?.slideNext(); swiper?.update(); }}
            >
              <ArrowForwardIcon />
            </Button>
          </Stack>
        </Box>
        {isLoading ? (
          <Box display="flex" flexDirection="column" height="100%" justifyContent="space-around" minHeight="200px">
            <CircularProgress />
          </Box> 
        ) : (
          <Box marginTop="30px" width="100%" position="relative">
            <Swiper
              slidesPerView={1}
              spaceBetween={50}
              onSlideChange={swiper => updateScrollButtons(swiper)}
              onImagesReady={swiper => updateScrollButtons(swiper)}
              onResize={swiper => updateScrollButtons(swiper)}
              onBeforeInit={(swiper) => {
                setSwiper(swiper);
              }}
              style={{ height: '100%' }}
              breakpoints={{
                750: {
                  slidesPerView: 2,
                },
                [theme.breakpoints.values.lg]: {
                  slidesPerView: 3,
                },
              }}
            >
              {renderNews()}
            </Swiper>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default NewsView;
