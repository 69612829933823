import {
  AncestryData,
} from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/types';
import {
  getChartOptions,
} from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/components/AncestryChart/helpers';

import React from 'react';
import ReactECharts from 'echarts-for-react';

interface Props {
  ancestryData: AncestryData[];
}

const AncestryChart: React.FC<Props> = ({ ancestryData }) => {
  const option = getChartOptions(ancestryData);

  return (
    <ReactECharts
      option={option}
      notMerge
      lazyUpdate
      style={{ width: '100%', height: '100%', minHeight: '184px' }}
      data-testid="ancestry-chart"
    />
  );
};

export default AncestryChart;
