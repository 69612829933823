import SampleMaskedInput from 'components/AdminDashboard/components/SamplesPage/components/SamplesListModal/components/SampleMaskedInput/SampleMaskedInput';
import { useFindUser } from 'components/AdminDashboard/components/SamplesPage/components/SamplesControlPanel/components/SampleSearchInput/hooks';
import { SAMPLE_ID_LENGTH } from 'api/Samples/constants';
import { red } from 'common/constants/colors';
import useDebounce from 'common/hooks/useDebounce';

import { Grid, OutlinedInput, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SampleSearchInput: React.FC = () => {
  const theme = useTheme();

  const [ inputValue, setInputValue ] = useState('');
  const debouncedSampleId = useDebounce(inputValue, 300);
  const [ lastDebouncedSampleId, setLastDebouncedSampleId ] = useState<string>('');

  const {
    findUser,
    isLoading,
    error,
    setError,
  } = useFindUser();

  useEffect(() => {
    if (debouncedSampleId.length !== 0 && debouncedSampleId.length < SAMPLE_ID_LENGTH) {
      setError(`Sample id must contain ${SAMPLE_ID_LENGTH} characters`);
      setLastDebouncedSampleId(debouncedSampleId);
      return;
    }

    if (lastDebouncedSampleId !== debouncedSampleId) {
      setError('');
      findUser(debouncedSampleId);
    }

    setLastDebouncedSampleId(debouncedSampleId);
  }, [ debouncedSampleId, lastDebouncedSampleId, setError, findUser, setLastDebouncedSampleId ]);
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setInputValue(event.target.value);
  };

  return (
    <Grid item>
      <OutlinedInput
        name="search-sample"
        value={inputValue}
        onChange={handleChange}
        inputComponent={SampleMaskedInput as any}
        error={Boolean(error)}
        size="small"
        disabled={isLoading}
        sx={{
          minWidth: '430px',
          fontFamily: 'Red Hat Mono',
          backgroundColor: 'white',
          '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.neutral[60],
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': { borderColor: red },
        }}
      />
    </Grid>
  );
};

export default SampleSearchInput;
