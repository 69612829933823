import { createSampleRequest } from 'api/Samples/api';
import { CreateSampleParams } from 'api/Samples/types';
import { CREATE_SAMPLE } from 'store/users/types';

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useCreateSample = (onCreateSample: () => void) => {
  const dispatch = useDispatch();
  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  useEffect(() => {
    return () => {
      setError('');
      setLoading(false);
    };
  }, []);

  const createSample = (userId: number, sampleId: string) => {
    setError('');
    setLoading(true);

    const sample: CreateSampleParams = { userId, sampleId };

    createSampleRequest(sample)
      .then(({ data }) => {
        dispatch({ type: CREATE_SAMPLE, data });
        onCreateSample();
      })
      .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
      .finally(() => setLoading(false));
  };

  return {
    createSample,
    isLoading,
    error,
    setError,
  };
};
