import useAuth from 'common/hooks/useAuth';
import Footer from 'components/Landing/components/Footer/Footer';
import { RootState } from 'store';
import { getMyUser } from 'store/user/actions';
import ApplicationBar from 'components/Landing/components/MainContent/components/ApplicationBar/ApplicationBar';
import MainContent from 'components/Landing/components/MainContent/MainContent';
import NewsPage from 'components/Landing/components/NewsPage/NewsPage';
import PartnersContent from 'components/Landing/components/PartnersContent/PartnersContent';
import { AmplitudeEvent } from 'common/constants/amplitude';
import UserRemovedModal from 'components/Landing/components/UserRemovedModal/UserRemovedModal';

import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { LogOnMount } from 'react-amplitude-hooks';

const Landing = () => {
  const { isAuthenticated } = useAuth();
  
  const location = useLocation();
  const locationState = location?.state as { userRemoved?: boolean };

  const { myUser } = useSelector((store: RootState) => store.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && !myUser.id) {
      dispatch(getMyUser());
    }
  }, [ dispatch, isAuthenticated, myUser ]);

  return (
    <LogOnMount eventType={AmplitudeEvent.LANDING_PAGE_VISITED}>
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <ApplicationBar />
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/partners" element={<PartnersContent />} />
          <Route path="/news/:newsId" element={<NewsPage />} />
        </Routes>
        {locationState?.userRemoved &&
          <UserRemovedModal />}
        <Footer />
      </Box>
    </LogOnMount>
  );
};

export default Landing;
