import React from 'react';
import { Grid, Link, List, ListItem, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

const AncestrySevenLabels: React.FC = () => {
  const { t } = useTranslation();

  const bioLink = 'https://www.biorxiv.org/content/10.1101/2021.09.19.460980v1';

  return (
    <Grid container spacing={2} marginTop={1} color="neutral.90" data-testid="ancestry-seven-labels">
      <Grid xs={12} item>
        <Typography variant="textXLBold">
          {t('dashboard.ancestry.7_labels.title')}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography variant="textLMedium">
          {t('dashboard.ancestry.7_labels.how_does_it_work')}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography variant="textMRegular">
          {t('dashboard.ancestry.7_labels.ancestry_estimation')}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <List sx={{ listStyle: 'disc', marginLeft: 'calc(1rem - 2px)' }}>
          <ListItem sx={{ display: 'list-item' }}>{t('dashboard.ancestry.7_labels.list.1')}</ListItem>
          <ListItem sx={{ display: 'list-item' }}>{t('dashboard.ancestry.7_labels.list.2')}</ListItem>
          <ListItem sx={{ display: 'list-item' }}>{t('dashboard.ancestry.7_labels.list.3')}</ListItem>
          <ListItem sx={{ display: 'list-item' }}>{t('dashboard.ancestry.7_labels.list.4')}</ListItem>
          <ListItem sx={{ display: 'list-item' }}>{t('dashboard.ancestry.7_labels.list.5')}</ListItem>
          <ListItem sx={{ display: 'list-item' }}>{t('dashboard.ancestry.7_labels.list.6')}</ListItem>
          <ListItem sx={{ display: 'list-item' }}>{t('dashboard.ancestry.7_labels.list.7')}</ListItem>
        </List>
      </Grid>
      <Grid xs={12} item>
        <Typography variant="textMRegular">
          {t('dashboard.ancestry.7_labels.depending')}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography variant="textLMedium">
          {t('dashboard.ancestry.7_labels.which_technology')}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography variant="textMRegular">
          {t('dashboard.ancestry.7_labels.we_have_used_an_ancestry_method')}
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography sx={{ whiteSpace: 'pre-line' }} variant="textMRegular">
          <Trans t={t} i18nKey="dashboard.ancestry.7_labels.you_can_read">
            You can read the authors’ paper here:
            <Link sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} href={bioLink} target="_blank" rel="noreferrer" />
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AncestrySevenLabels;
