import LogoSimpleIcon from 'common/icons/LogoSimpleIcon';
import { purple200, purple400 } from 'common/constants/colors';
import { RootState } from 'store';
import { DASHBOARD_ROUTE, PARTNERS_CONTACTUS_ROUTE, PARTNERS_ROUTE, SIGNUP_ROUTE } from 'common/constants/urls';

import React from 'react';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { myUser } = useSelector((store: RootState) => store.user);

  return (
    <Box position="relative" bottom={0} data-testid="footer">
      <footer>
        <Box width="100%" sx={{ backgroundColor: 'black' }}>
          <Container maxWidth="xl">
            <Grid container sx={{ padding: { xs: 4, md: 10 } }} spacing={4}>
              <Grid item xs={12} md>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <LogoSimpleIcon color="orange" sx={{ width: 130, height: 130 }} />
                  <Typography variant="body2" color={purple200} maxWidth={220}>
                    {`${t('landing.footer.copyright')}`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid
                  container
                  spacing={4}
                  flexGrow={1}
                  justifyContent={{ xs: 'space-between', md: 'space-around' }}
                  maxWidth="900px"
                >
                  <Grid item xs>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      spacing={4}
                    >
                      <Typography
                        color={purple400}
                        sx={{
                          fontFamily: 'Montserrat Alternates',
                          fontWeight: 700,
                          fontSize: '15px',
                          lineHeight: '125%',
                          letterSpacing: '-0.1em',
                        }}
                      >
                        {t('landing.navigation.about')}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '13px',
                          lineHeight: '100%',
                          cursor: 'pointer',
                        }}
                        color="secondary"
                        onClick={() => navigate(PARTNERS_ROUTE)}
                      >
                        {t('landing.navigation.partners')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      spacing={4}
                    >
                      <Typography
                        color={purple400}
                        sx={{
                          fontFamily: 'Montserrat Alternates',
                          fontWeight: 700,
                          fontSize: '15px',
                          lineHeight: '125%',
                          letterSpacing: '-0.1em',
                        }}
                      >
                        {t('landing.goals.goal_1_body')}
                      </Typography>
                      <Stack spacing={2} direction="column">
                        {!myUser.id ? (
                          <>
                            <Typography
                              color="secondary"
                              onClick={() => navigate(DASHBOARD_ROUTE)}
                              sx={{
                                fontFamily: 'Satoshi',
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '100%',
                                cursor: 'pointer',
                              }}
                            >
                              {t('auth.signin')}
                            </Typography>
                            <Typography
                              color="secondary"
                              onClick={() => navigate(SIGNUP_ROUTE)}
                              sx={{
                                fontFamily: 'Satoshi',
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '100%',
                                cursor: 'pointer',
                              }}
                            >
                              {t('auth.registration')}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            color="secondary"
                            onClick={() => navigate(DASHBOARD_ROUTE)}
                            sx={{
                              fontFamily: 'Satoshi',
                              fontWeight: 500,
                              fontSize: '13px',
                              lineHeight: '100%',
                              cursor: 'pointer',
                            }}
                          >
                            {t('landing.navigation.dashboard')}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      spacing={4}
                      minWidth={{ xs: '100%', md: '' }}
                    >
                      <Typography
                        color={purple400}
                        sx={{
                          fontFamily: 'Montserrat Alternates',
                          fontWeight: 700,
                          fontSize: '15px',
                          lineHeight: '125%',
                          letterSpacing: '-0.1em',
                        }}
                      >
                        {t('landing.footer.contact')}
                      </Typography>
                      <Typography
                        color="secondary"
                        sx={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '13px',
                          lineHeight: '100%',
                          cursor: 'pointer',
                        }}
                        onClick={() => navigate(PARTNERS_CONTACTUS_ROUTE)}
                      >
                        {t('landing.footer.contact_form')}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </footer>
    </Box>
  );
};

export default Footer;
