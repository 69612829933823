import { SurveyV1 } from 'api/User/types';
import AgeQuestion from 'components/Dashboard/components/SurveyPage/components/V1/AgeQuestion/AgeQuestion';
import HeightQuestion from 'components/Dashboard/components/SurveyPage/components/Common/HeightQuestion/HeightQuestion';
import WeightQuestion from 'components/Dashboard/components/SurveyPage/components/Common/WeightQuestion/WeightQuestion';
import LatinoOriginQuestion from 'components/Dashboard/components/SurveyPage/components/V1/LatinoOriginQuestion/LatinoOriginQuestion';
import RaceQuestion from 'components/Dashboard/components/SurveyPage/components/V1/RaceQuestion/RaceQuestion';
import OriginQuestion from 'components/Dashboard/components/SurveyPage/components/V1/OriginQuestion/OriginQuestion';
import { SurveyQuestionV1 } from 'components/Dashboard/components/SurveyPage/types';
import {
  getGrandparentsBornPlaceFilled,
  getOriginFilled,
  getSpecifiedRaces,
} from 'components/Dashboard/components/SurveyPage/helpers';
import BornPlaceQuestion from 'components/Dashboard/components/SurveyPage/components/Common/BornPlaceQuestion/BornPlaceQuestion';
import GrandparentsBornPlaceQuestion
  from 'components/Dashboard/components/SurveyPage/components/Common/GrandparentsBornPlaceQuestion/GrandparentsBornPlaceQuestion';
import HighBloodPressureQuestion from 'components/Dashboard/components/SurveyPage/components/V1/HighBloodPressureQuestion/HighBloodPressureQuestion';
import LastBloodPressureQuestion from 'components/Dashboard/components/SurveyPage/components/V1/LastBloodPressureQuestion/LastBloodPressureQuestion';
import BloodPressureMedicationsQuestion
  from 'components/Dashboard/components/SurveyPage/components/V1/BloodPressureMedications/BloodPressureMedicationsQuestion';
import FamilyHealthConditionsQuestion
  from 'components/Dashboard/components/SurveyPage/components/V1/FamilyHealthConditionsQuestion/FamilyHealthConditionsQuestion';
import HealthConditionsQuestion from 'components/Dashboard/components/SurveyPage/components/V1/HealthConditionsQuestion/HealthConditionsQuestion';
import CovidTestedQuestion from 'components/Dashboard/components/SurveyPage/components/V1/CovidTestedQuestion/CovidTestedQuestion';
import CovidVaccinatedQuestion from 'components/Dashboard/components/SurveyPage/components/V1/CovidVaccinatedQuestion/CovidVaccinatedQuestion';
import CovidVaccineQuestion from 'components/Dashboard/components/SurveyPage/components/V1/CovidVaccineQuestion/CovidVaccineQuestion';
import SymptomsQuestion from 'components/Dashboard/components/SurveyPage/components/V1/SymptomsQuestion/SymptomsQuestion';

export const SURVEY_QUESTIONS_V1: SurveyQuestionV1[] = [
  {
    field: 'age',
    category: 'Demographics',
    component: AgeQuestion,
    filled: (survey: SurveyV1) => survey.age !== null,
  },
  {
    field: 'height',
    category: 'Demographics',
    component: HeightQuestion,
    filled: (survey: SurveyV1) => survey.height !== null && (survey.height.foots !== null || survey.height.centimeters !== null),
  },
  {
    field: 'weight',
    category: 'Demographics',
    component: WeightQuestion,
    filled: (survey: SurveyV1) => survey.weight !== null,
  },
  {
    field: 'latinoOrigin',
    category: 'Demographics',
    component: LatinoOriginQuestion,
    filled: (survey: SurveyV1) => survey.latinoOrigin !== null,
  },
  {
    field: 'race',
    category: 'Demographics',
    component: RaceQuestion,
    filled: (survey: SurveyV1) => survey.race !== null && survey.race.length > 0,
  },
  {
    field: 'origin',
    category: 'Demographics',
    condition: (survey: SurveyV1 | null) => survey !== null && getSpecifiedRaces(survey.race).length > 0,
    component: OriginQuestion,
    filled: (survey: SurveyV1) => getOriginFilled(survey),
  },
  {
    field: 'bornPlace',
    category: 'Demographics',
    component: BornPlaceQuestion,
    filled: (survey: SurveyV1) => survey.bornPlace !== null && survey.bornPlace.country !== null && survey.bornPlace.city !== null,
  },
  {
    field: 'grandparentsBornPlace',
    category: 'Demographics',
    component: GrandparentsBornPlaceQuestion,
    filled: (survey: SurveyV1) => getGrandparentsBornPlaceFilled(survey),
  },
  {
    field: 'highBloodPressure',
    category: 'Health Status',
    component: HighBloodPressureQuestion,
    filled: (survey: SurveyV1) => survey.highBloodPressure !== null,
  },
  {
    field: 'bloodPressureMeasurement',
    category: 'Health Status',
    component: LastBloodPressureQuestion,
    condition: (survey: SurveyV1 | null) => survey?.highBloodPressure === 'yes',
    filled: (survey: SurveyV1) => survey.highBloodPressure !== null,
  },
  {
    field: 'bloodPressureMedications',
    category: 'Health Status',
    component: BloodPressureMedicationsQuestion,
    condition: (survey: SurveyV1 | null) => survey?.highBloodPressure === 'yes',
    filled: (survey: SurveyV1) => survey.bloodPressureMedications !== null,
  },
  {
    field: 'longTermHealthConditions',
    category: 'Health Status',
    component: HealthConditionsQuestion,
    filled: (survey: SurveyV1) => survey.longTermHealthConditions !== null,
  },
  {
    field: 'familyLongTermHealthConditions',
    category: 'Health Status',
    component: FamilyHealthConditionsQuestion,
    filled: (survey: SurveyV1) => survey.familyLongTermHealthConditions !== null,
  },
  {
    field: 'covidTested',
    category: 'Covid-19',
    component: CovidTestedQuestion,
    filled: (survey: SurveyV1) => survey.covidTested !== null,
  },
  {
    field: 'covidVaccinated',
    category: 'Covid-19',
    component: CovidVaccinatedQuestion,
    filled: (survey: SurveyV1) => survey.covidVaccinated !== null,
  },
  {
    field: 'covidVaccine',
    category: 'Covid-19',
    component: CovidVaccineQuestion,
    condition: (survey: SurveyV1 | null) => survey?.covidVaccinated === 'yes',
    filled: (survey: SurveyV1) => survey.covidVaccine !== null,
  },
  {
    field: 'covidSymptoms',
    category: 'Covid-19',
    component: SymptomsQuestion,
    condition: (survey: SurveyV1 | null) => survey?.covidTested === 'positive' || survey?.covidTested === 'unknown',
    filled: (survey: SurveyV1) => survey.covidSymptoms !== null,
  },
];
