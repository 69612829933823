import { Ancestry } from 'api/Reports/types';
import { AncestryFile } from 'api/User/types';

import omit from 'lodash/omit';

export const getAncestryProgress = (ancestries: Ancestry[], ancestryFiles: AncestryFile[], testKitReceived: boolean | null): number => {
  if (ancestries.length) {
    return 100;
  }

  if (testKitReceived || ancestryFiles.length > 0) {
    return 70;
  }

  return 0;
};

export const filterAncestry = (ancestry: Ancestry | null) => omit(ancestry, 'nat', 'providerName', 'reportDate');
