import HealthInfoQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HealthInfoQuestion/HealthInfoQuestion';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';

import React from 'react';

const DISEASES = [
  'Alzheimer',
  'Parkinson',
  'Lewy Body dementia',
  'Essential Tremor',
  'Multiple Sclerosis',
  'Retinal degeneration',
  'Macular degeneration',
  'Optic myelitis',
  'Other',
];

const NeurologicalDisorderQuestion: React.FC<QuestionPageProps> = ({ onNext, onPrev, onSubmit }) => {
  return <HealthInfoQuestion onPrev={onPrev} onNext={onNext} onSubmit={onSubmit} field="neurologicalDisorder" diseases={DISEASES} />;
};

export default NeurologicalDisorderQuestion;
