import { RootState } from 'store';
import { FormTextField } from 'common/components';
import { disallowComma } from 'common/helpers/disallowComma';
import { getUserMailingAddress, getUserProfileProgress } from 'common/helpers/user';
import { ANCESTRY_LIST } from 'common/constants/ancestry';
import { COUNTRIES_NAMES, STATES, USA } from 'common/constants/countries';
import { MALE, FEMALE, OTHER, UNSPECIFIED } from 'common/constants/genders';
import { MONTSERRAT_ALTERNATES } from 'common/constants/fonts';
import { midnight } from 'common/constants/colors';
import ProgressBar from 'components/Dashboard/components/ProgressBar/ProgressBar';
import styles from 'components/Dashboard/components/ProfilePage/ProfilePage.module.css';
import useProfileEdit from 'components/Dashboard/components/ProfilePage/hooks/useProfileEdit';
import useLocalizedProfile from 'components/Dashboard/components/ProfilePage/hooks/useLocalizedProfile';
import { AmplitudeEvent } from 'common/constants/amplitude';

import React from 'react';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { LogOnMount } from 'react-amplitude-hooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

const ProfilePage = () => {
  const { isMyUserLoading, myUser: user } = useSelector((state: RootState) => state.user);
  const [ isProfileEdit, setIsProfileEdit ] = React.useState<boolean>(false);
  const [ isDatePickerOpened, setIsDatePickerOpened ] = React.useState<boolean>(false);

  const { t, i18n } = useTranslation();
  const { getLocalizedRace, getLocalizedGender } = useLocalizedProfile();

  const isEnglish = i18n.language === 'en';

  const showEditForm = () => setIsProfileEdit(true);
  const hideEditForm = () => {
    setIsProfileEdit(false);
    setIsDatePickerOpened(false);
  };

  const { formik, isSubmitting, submitError } = useProfileEdit(hideEditForm);

  const userProfileProgress = getUserProfileProgress(user);

  const onAncestryChange = React.useCallback((e: SelectChangeEvent<typeof formik.values.race>) => {
    formik.setFieldValue('race', e.target.value);
  }, [ formik ]);

  const onStateChangeHandler = React.useCallback((_, newValue: string | null) => {
    formik.setFieldValue('state', newValue ?? '');
  }, [ formik ]);

  const onCountryChangeHandler = React.useCallback((_, newValue: string | null) => {
    formik.setFieldValue('country', newValue ?? '');
  }, [ formik ]);

  const openDatePicker = () => setIsDatePickerOpened(true);
  const closeDatePicker = () => setIsDatePickerOpened(false);

  return (
    <LogOnMount eventType={AmplitudeEvent.PROFILE_PAGE_VISITED}>
      <Grid
        padding={{ xs: '40px 16px', sm: 4, xl: 5 }}
        borderRadius={{ xs: '20px', sm: '30px' }}
        sx={{ backgroundColor: '#fff' }}
        minHeight="600px"
        data-testid="profile-page"
      >
        <Grid item xs={12} paddingBottom={{ xs: 5, sm: 6 }} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
          <Typography sx={{
            fontFamily: MONTSERRAT_ALTERNATES,
            fontWeight: 700,
            fontSize: { xs: '18px', sm: '28px' },
            lineHeight: '170%',
            letterSpacing: '-0.05em',
            color: midnight,
          }}
          >
            {t('dashboard.profile.profile')}
          </Typography>
          <ProgressBar horizontal={false} progress={userProfileProgress} />
        </Grid>
        {isMyUserLoading ? <CircularProgress /> : (
          <Grid item container xs={12}>
            <Grid item xs={12} sm={9} lg={6} paddingBottom={{ xs: 5, sm: 10, lg: 0 }}>
              <Grid rowSpacing={2} container>
                <Grid item flexWrap="nowrap" flexDirection={{ xs: 'column', sm: 'row' }} xs={6} sm={12} container>
                  <Grid item xs={12} sm={6} lg={4}>
                    <b>{t('dashboard.profile.first_name')}</b>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={8}>
                    <span>{user.firstName}</span>
                  </Grid>
                </Grid>
                <Grid item flexWrap="nowrap" flexDirection={{ xs: 'column', sm: 'row' }} xs={6} sm={12} container>
                  <Grid item xs={12} sm={6} lg={4}>
                    <b>{t('dashboard.profile.last_name')}</b>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={8}>
                    <span>{user.lastName}</span>
                  </Grid>
                </Grid>
                <Grid item flexWrap="nowrap" flexDirection={{ xs: 'column', sm: 'row' }} xs={6} sm={12} container>
                  <Grid item xs={12} sm={6} lg={4}>
                    <b>{t('dashboard.profile.email')}</b>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={8}>
                    <span>{user.username}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isProfileEdit ? (
              <Grid item xs={12} lg={6} container data-testid="profile-details-content">
                <Grid item xs={12} sm={9} borderRight={{ sm: '1px solid #f3b29d' }} paddingRight={2}>
                  <Grid rowSpacing={2} container>
                    <Grid item xs={6} sm={12} flexWrap="nowrap" flexDirection={{ xs: 'column', sm: 'row' }} container>
                      <Grid item xs={12} sm={6}>
                        <b>{t('dashboard.profile.date_of_birth')}</b>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <span>{user.dob ? DateTime.fromISO(user.dob).toFormat('MM/dd/yyyy') : 'Not specified'}</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sm={12} flexWrap="nowrap" flexDirection={{ xs: 'column', sm: 'row' }} container>
                      <Grid item xs={6}>
                        <b>{t('dashboard.profile.gender')}</b>
                      </Grid>
                      <Grid item xs={6}>
                        <span>{user.gender ? getLocalizedGender(user.gender.toLowerCase()) : t('dashboard.profile.not_specified')}</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={9} sm={12} flexWrap="nowrap" flexDirection={{ xs: 'column', sm: 'row' }} container>
                      <Grid item xs={12} sm={6}>
                        <b>{t('dashboard.profile.race_ethnicity')}</b>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <span>{user.race ? getLocalizedRace(user.race) : t('dashboard.profile.not_specified')}</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} flexWrap="nowrap" flexDirection={{ xs: 'column', sm: 'row' }} container>
                      <Grid item xs={12} sm={6}>
                        <b>{t('dashboard.profile.mailing_address')}</b>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <span>{getUserMailingAddress(user) || 'Not specified'}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid paddingTop={{ xs: 2, sm: 0 }} alignItems="center" justifyContent="center" item display="flex" xs={12} sm={3}>
                  <Button
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                    onClick={showEditForm}
                    variant="outlined"
                    data-testid="button-edit"
                  >
                    {t('dashboard.profile.button_edit')}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} lg={6} container data-testid="profile-edit-content">
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit} className={styles.form}>
                    <Grid container item spacing={3} xs={12} sm={6} paddingRight={{ sm: 2 }} borderRight={{ sm: '1px solid #f3b29d' }}>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
                          <DatePicker
                            open={isDatePickerOpened}
                            label={t('dashboard.profile.date_of_birth')}
                            value={formik.values.dob}
                            onChange={(newValue) => {
                              formik.setFieldValue('dob', newValue);
                            }}
                            onOpen={openDatePicker}
                            onClose={closeDatePicker}
                            renderInput={params => (
                              <TextField
                                {...params}
                                helperText={formik.touched.dob && formik.errors.dob}
                                error={formik.touched.dob && Boolean(formik.errors.dob)}
                                size="small"
                                data-testid="input-dob"
                                fullWidth
                                onClick={openDatePicker}
                                disabled={isSubmitting}
                              />
                            )}
                            disableFuture
                            disableMaskedInput
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            id="gender"
                            select
                            value={formik.values.gender.toLowerCase()}
                            label={t('dashboard.profile.gender')}
                            name="gender"
                            size="small"
                            disabled={isSubmitting}
                            onChange={formik.handleChange}
                            data-testid="input-gender"
                          >
                            <MenuItem value={MALE}>{getLocalizedGender('male')}</MenuItem>
                            <MenuItem value={FEMALE}>{getLocalizedGender('female')}</MenuItem>
                            <MenuItem value={OTHER}>{getLocalizedGender('other')}</MenuItem>
                            <MenuItem value={UNSPECIFIED}>{getLocalizedGender('unspecified')}</MenuItem>
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel size="small">{t('dashboard.profile.race_ethnicity')}</InputLabel>
                          <Select
                            multiple
                            value={formik.values.race}
                            name="race"
                            size="small"
                            error={formik.touched.race && Boolean(formik.errors.race)}
                            disabled={isSubmitting}
                            onChange={onAncestryChange}
                            input={<OutlinedInput size="small" label={t('dashboard.profile.race_ethnicity')} />}
                            label={t('dashboard.profile.race_ethnicity')}
                            renderValue={selected => selected.join(', ')}
                            data-testid="input-race"
                            variant="outlined"
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: { xs: '50%', md: '70%' },
                                  overflow: 'auto',
                                },
                              },
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>Choose one or more</em>
                            </MenuItem>
                            {ANCESTRY_LIST.map((ancestry: string) => (
                              <MenuItem key={ancestry} value={ancestry}>
                                <Checkbox checked={formik.values.race.includes(ancestry)} />
                                <ListItemText primary={getLocalizedRace(ancestry)} />
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.errors.race && (
                            <FormHelperText error>
                              {formik.errors.race}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          onChange={onCountryChangeHandler}
                          value={formik.values.country}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={t('dashboard.profile.country')}
                              name="country"
                              disabled={isSubmitting}
                              error={formik.touched.country && Boolean(formik.errors.country)}
                              helperText={formik.touched.country && formik.errors.country}
                              size="small"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                              data-testid="input-country"
                            />
                          )}
                          options={COUNTRIES_NAMES}
                        />
                      </Grid>
                      {formik.values.country === USA && (
                        <Grid item xs={12}>
                          <Autocomplete
                            onChange={onStateChangeHandler}
                            value={formik.values.state}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label={t('dashboard.profile.address.state')}
                                name="state"
                                disabled={isSubmitting}
                                error={formik.touched.state && Boolean(formik.errors.state)}
                                helperText={formik.touched.state && formik.errors.state}
                                size="small"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                data-testid="input-state"
                              />
                            )}
                            options={STATES}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          id="city"
                          name="city"
                          label={t('dashboard.profile.address.city')}
                          variant="outlined"
                          size="small"
                          inputProps={{
                            autoComplete: 'none',
                          }}
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          error={formik.touched.city && Boolean(formik.errors.city)}
                          helperText={formik.touched.city && formik.errors.city}
                          disabled={isSubmitting}
                          data-testid="input-city"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="street1"
                          name="street1"
                          label={t('dashboard.profile.address.street_address')}
                          variant="outlined"
                          size="small"
                          value={formik.values.street1}
                          onChange={formik.handleChange}
                          error={formik.touched.street1 && Boolean(formik.errors.street1)}
                          helperText={formik.touched.street1 && formik.errors.street1}
                          data-testid="input-street1"
                          disabled={isSubmitting}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="street2"
                          name="street2"
                          label={t('dashboard.profile.address.street_address_line2')}
                          variant="outlined"
                          size="small"
                          value={formik.values.street2}
                          onChange={formik.handleChange}
                          error={formik.touched.street2 && Boolean(formik.errors.street2)}
                          helperText={formik.touched.street2 && formik.errors.street2}
                          data-testid="input-street2"
                          disabled={isSubmitting}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="zip"
                          name="zip"
                          label={t('dashboard.profile.address.zip')}
                          variant="outlined"
                          size="small"
                          value={formik.values.zip}
                          onChange={formik.handleChange}
                          error={formik.touched.zip && Boolean(formik.errors.zip)}
                          helperText={formik.touched.zip && formik.errors.zip}
                          data-testid="input-zip"
                          disabled={isSubmitting}
                          fullWidth
                          onKeyDown={disallowComma}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormTextField
                          id="previousZipCodes"
                          name="previousZipCodes"
                          label="Previous US ZIP codes"
                          value={formik.values.previousZipCodes}
                          onChange={formik.handleChange}
                          error={formik.errors.previousZipCodes}
                          touched={formik.touched.previousZipCodes}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid paddingTop={{ xs: 5, sm: 0 }} alignItems="center" alignSelf={{ sm: 'center' }} sm={3} xs={12} justifyContent="center" item display="flex" flexDirection="column">
                      <Grid item xs={12} width="100%" display="flex">
                        <LoadingButton
                          sx={{ width: { xs: '100%' }, minWidth: isEnglish ? 'unset' : '90px', marginRight: '20px', marginLeft: { sm: '20px' } }}
                          loading={isSubmitting}
                          size="medium"
                          variant="contained"
                          type="submit"
                          data-testid="button-save"
                        >
                          {t('general.save')}
                        </LoadingButton>
                        <Button sx={{ width: { xs: '100%' }, boxSizing: 'unset' }} onClick={hideEditForm} size="medium" variant="outlined" data-testid="button-cancel">
                          {t('general.cancel')}
                        </Button>
                      </Grid>
                      {
                        submitError && (
                          <Grid marginTop={1.5}>
                            <Typography color="error" variant="subtitle2">
                              {submitError}
                            </Typography>
                          </Grid>
                        )
                      }
                    </Grid>
                  </form>
                </FormikProvider>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </LogOnMount>
  );
};

export default ProfilePage;
