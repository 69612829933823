import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import style from './PartnersGlobe.module.css';

const PartnersGlobe: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <Box height={{ xs: '400px', md: '600px' }} width="100%">
      <iframe title={t('landing.navigation.partners')} src="/globe/globe.htm" width="100%" height="100%" className={style.iframe} />
    </Box>
  );
};

export default PartnersGlobe;
