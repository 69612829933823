import { sampleSearchRequest, userSearchRequest } from 'api/Samples/api';
import { downloadCSVFile } from 'common/helpers/downloadCSVFile';

import i18next from 'i18next';
import { useState } from 'react';

export const useSampleQuerying = (onSuccess: () => void) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>('');

  const searchByUserId = (file: File) => {
    setError('');
    setIsLoading(true);

    const data = new FormData();
    data.append('uploadedFile', file);

    userSearchRequest(data)
      .then((response) => {
        onSuccess();
        downloadCSVFile('user_id_search_results', response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || i18next.t(('errors.server_error_try_again')));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchBySampleId = (file: File) => {
    setError('');
    setIsLoading(true);

    const data = new FormData();
    data.append('uploadedFile', file);

    sampleSearchRequest(data)
      .then((response) => {
        onSuccess();
        downloadCSVFile('sample_id_search_results', response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || i18next.t(('errors.server_error_try_again')));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    searchByUserId,
    searchBySampleId,
    isLoading,
    error,
  };
};
