import { postRequestNewCode } from 'api/Auth/api';
import { SET_MFA_SESSION_ID } from 'store/auth/types';

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useRequestNewCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ isCodeRequested, setIsCodeRequested ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ timeLeft, setTimeLeft ] = useState<number>(60); // seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    if (timeLeft <= 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [ timeLeft ]);

  const requestNewCode = (mfaSessionId: string) => {
    postRequestNewCode(mfaSessionId)
      .then((response) => {
        setTimeLeft(60);
        setIsCodeRequested(true);
        dispatch({ type: SET_MFA_SESSION_ID, mfaSessionId: response.data.mfaSessionId });
      })
      .catch((reason) => {
        const error = reason?.response?.data?.message || t('errors.server_error_try_again');
        if (error && error.startsWith('You can request new code')) {
          const value = error.match(/\d+/g);
          if (value) {
            setTimeLeft(Number(value.join('')));
          }
        } else {
          setError(error);
        }
      });
  };

  return { error, requestNewCode, timeLeft, isCodeRequested, setIsCodeRequested };
};

export default useRequestNewCode;
