import { RootState } from 'store';
import { Survey } from 'api/User/types';
import {
  getNextAvailableQuestion,
  getNextUnansweredQuestion,
  getPrevAvailableQuestion,
} from 'components/Dashboard/components/SurveyPage/helpers';
import { SurveyQuestion } from 'components/Dashboard/components/SurveyPage/types';

import { useState } from 'react';
import { useSelector } from 'react-redux';

const useSurvey = (questions: SurveyQuestion<any>[]) => {
  const { survey, surveyCompletedPercentage } = useSelector((store: RootState) => store.user.myUser);
  const [ currentQuestion, setCurrentQuestion ] = useState(getNextUnansweredQuestion(0, survey, questions));

  const submitQuestion = () => {};
  const goPrevQuestion = (survey: Survey) => setCurrentQuestion(getPrevAvailableQuestion(surveyCompletedPercentage, currentQuestion, survey, questions));
  const goNextQuestion = (survey: Survey) => setCurrentQuestion(getNextAvailableQuestion(surveyCompletedPercentage, currentQuestion, survey, questions));

  const hasNextQuestion = getNextAvailableQuestion(surveyCompletedPercentage, currentQuestion, survey, questions, false) < questions.length;

  return {
    currentQuestionIndex: currentQuestion,
    currentQuestion: questions[currentQuestion],
    submitQuestion,
    getNextQuestion: goNextQuestion,
    getPrevQuestion: goPrevQuestion,
    hasNextQuestion,
    surveyCompletedPercentage,
  };
};

export default useSurvey;
