import { Pagination } from 'common/types/Pagination';
import { UserReport } from 'api/ScienceReports/types';
import { getUserReports } from 'api/ScienceReports/api';
import { ReportsFilter } from 'api/Reports/types';

import { useCallback, useState } from 'react';
import omit from 'lodash/omit';

export const useUserReports = () => {
  const [ userReports, setUserReports ] = useState<UserReport[]>([]);
  const [ pagination, setPagination ] = useState<Pagination | undefined>();
  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  const requestUserReports = useCallback((page: number, pageSize: number, filter: ReportsFilter) => {
    setLoading(true);
    setError('');
    getUserReports(page, pageSize, filter)
      .then(({ data }) => {
        if (data) {
          setUserReports(data.results);
          setPagination(omit(data, 'results'));
        }
      })
      .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
      .finally(() => setLoading(false));
  }, []);

  return {
    requestUserReports,
    userReports,
    pagination,
    setUserKits: setUserReports,
    isLoading,
    error,
  };
};
