import useDebounce from 'common/hooks/useDebounce';
import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';

import React, { useEffect } from 'react';

export const useTable = (searchValue = '') => {
  const { setPageParam, setRowsParam, setUsernameParam } = usePaginationSearchParams();

  const debouncedSearchValue = useDebounce(searchValue, 300);

  useEffect(() => {
    setUsernameParam(debouncedSearchValue);
  }, [ debouncedSearchValue, setUsernameParam ]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageParam((newPage + 1).toString());
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsParam(event.target.value);
    setPageParam('1');
  };

  return {
    debouncedSearchValue,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
