export enum EHRStatusLabel {
  NOT_PULLED = 'Not pulled',
  PULLED = 'Pulled',
  MULTIPLE_MATCHES = 'Multiple matches',
  FAILED = 'Failed',
  IN_PROGRESS = 'In progress',
  NO_EHR_DATA = 'No EHR data',
  PATIENT_CHART_ERROR_454 = 'Poll request failed',
  CAN_NOT_IMPORT = 'Can\'t import',
}
