import { UTMParams } from 'api/User/types';
import { REGISTRATION_UTM_PARAMS } from 'common/constants/UTMParams';

export const getLocalUtmParams = (): UTMParams => {
  return REGISTRATION_UTM_PARAMS.reduce((result, param) => {
    if (localStorage.getItem(param)) {
      const paramName = param.replace('utm_', '');

      return {
        ...result,
        [paramName]: localStorage.getItem(param),
      };
    }

    return result;
  }, {});
};

export const deleteLocalUtmParams = () => {
  return REGISTRATION_UTM_PARAMS.forEach(param => localStorage.removeItem(param));
};
