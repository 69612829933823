import { removeRefreshToken, removeToken, setRefreshToken, setToken } from 'common/helpers/auth';
import { AuthState } from 'store/auth/models';
import { AUTH_LOADING, LOGIN_SUCCESS, SET_MFA_SESSION_ID, NOT_AUTHORIZED } from 'store/auth/types';

import { AnyAction } from 'redux';

export const initialState: AuthState = {
  mfaSessionId: null,
  isLoading: false,
  isAuthenticated: false,
};

export const authReducer = (
  state: AuthState = { ...initialState },
  action: AnyAction,
) => {
  switch (action.type) {
    case AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case LOGIN_SUCCESS:
      if (action.token) {
        setToken(action.token);
      }
      if (action.refreshToken) {
        setRefreshToken(action.refreshToken);
      }

      return {
        ...state,
        mfaSessionId: null,
        isLoading: false,
        isAuthenticated: true,
      };

    case SET_MFA_SESSION_ID:
      return {
        ...state,
        mfaSessionId: action.mfaSessionId,
        isLoading: false,
        isAuthenticated: false,
      };

    case NOT_AUTHORIZED:
      removeToken();
      removeRefreshToken();
      return {
        ...state,
        mfaSessionId: null,
        isLoading: false,
        isAuthenticated: false,
      };

    default:
      return { ...state };
  }
};
