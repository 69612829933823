import { AmplitudeEvent } from 'common/constants/amplitude';
import { DASHBOARD_ROUTE } from 'common/constants/urls';

import React, { useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LogOnMount } from 'react-amplitude-hooks';

const Completed: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGoDashboard = useCallback(() => {
    navigate(DASHBOARD_ROUTE);
  }, [ navigate ]);
  
  return (
    <LogOnMount eventType={AmplitudeEvent.SURVEY_COMPLETED}>
      <Grid container spacing={3} justifyContent="center" textAlign="center" data-testid="completed">
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.completed.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary">
            {t('dashboard.survey.completed.hint')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleGoDashboard} data-testid="button-go-dashboard">
            {t('dashboard.survey.completed.button_go_to_dashboard')}
          </Button>
        </Grid>
      </Grid>
    </LogOnMount>
  );
};

export default Completed;
