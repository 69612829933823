export enum AmplitudeEvent {
  LANDING_PAGE_VISITED = 'landing page visited',

  REGISTRATION_VISITED = 'registration visited',
  REGISTRATION_STEP_1_COMPLETED = 'registration step 1 completed',
  REGISTRATION_STEP_2_COMPLETED = 'registration step 2 completed',
  ACCOUNT_ACTIVATED = 'account activated',

  PROFILE_PAGE_VISITED = 'profile page visited',
  PROFILE_COMPLETED = 'profile completed',

  SURVEY_VISITED = 'survey visited',
  SURVEY_COMPLETED = 'survey completed',

  ANCESTRY_FILE_UPLOADED = 'ancestry file uploaded',
  CLICKING_VIEW_RESULTS_EMAIL_ACTION_BUTTON = 'clicking view results email action button',

  KIT_REQUESTED = 'kit requested',
  SHIPPING_LABEL_GENERATED = 'shipping label generated',
}
