import { useTable } from 'common/components/DataTable/hooks';
import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';
import { Pagination } from 'common/types/Pagination';
import { HeadCell } from 'common/types/MuiComponents';

import React, { useEffect } from 'react';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

interface Props {
  pagination: Pagination | undefined;
  headCells: HeadCell[];
  renderRows: () => JSX.Element[];
  searchValue?: string;
  updateDataCallback: (page: number, pageSize: number, username: string) => void
}

const DataTable: React.FC<Props> = ({ pagination, headCells, renderRows, searchValue, updateDataCallback }) => {
  const { pageParam, rowsParam, setPageParam } = usePaginationSearchParams();
  const { debouncedSearchValue, handleChangePage, handleChangeRowsPerPage } = useTable(searchValue);

  useEffect(() => {
    updateDataCallback(pageParam, rowsParam, debouncedSearchValue);
  }, [ updateDataCallback, pageParam, rowsParam, debouncedSearchValue ]);

  useEffect(() => {
    if (pagination && pagination.pageCount !== 0 && pageParam > pagination.pageCount) {
      setPageParam('1');
    }
  }, [ pagination, pageParam, setPageParam ]);

  return (
    <>
      <TableContainer data-testid="data-table">
        <Table sx={{ minWidth: 750 }}>
          <TableHead>
            <TableRow>
              {headCells.map(headCell => (
                <TableCell key={headCell.id}>
                  <Stack direction="row" justifyContent={headCell.numeric ? 'flex-end' : 'flex-start'} alignItems="center">
                    {headCell.label}
                    {headCell.button}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRows()}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[ 10, 25 ]}
          component="div"
          count={pagination.rowCount}
          labelRowsPerPage="Rows per page"
          rowsPerPage={rowsParam}
          page={pageParam <= pagination.pageCount ? pageParam - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      )}
    </>
  );
};

export default DataTable;
