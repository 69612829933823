import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey } from 'api/User/types';
import { getOnlyNumbers } from 'common/helpers/getOnlyNumbers';
import MeasurementSystem from 'common/components/MeasurementSystem/MeasurementSystem';
import { System } from 'common/components/MeasurementSystem/types';

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const HeightQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const [ error, setError ] = useState<string>('');
  const { submit, isSubmitting, submitError } = useQuestionForm();
  const [ system, setSystem ] = useState<System>(System.ENGLISH);
  const isMetric = system === System.METRIC;

  const [ feet, setFeet ] = useState<string>('');
  const [ inches, setInches ] = useState<string>('');
  const [ centimeters, setCentimeters ] = useState<string>('');

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if ((!feet && !isMetric) || (!centimeters && isMetric)) {
      setError(t('dashboard.survey.error_give_answer'));
    } else {
      submit({
        ...survey,
        height: {
          foots: Number(feet),
          inches: Number(inches),
          centimeters: Number(centimeters),
          system,
        },
      })
        .then((data: Survey) => onNext(data));
    }
  };

  const onFeetChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setFeet(getOnlyNumbers(event.target.value));
  };

  const onInchesChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setInches(getOnlyNumbers(event.target.value));
  };

  const onCentimetersChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setCentimeters(getOnlyNumbers(event.target.value));
  };
  
  useEffect(() => {
    if (survey && survey.height !== null) {
      setFeet(String(survey.height.foots));
      setInches(String(survey.height.inches));
      setCentimeters(String(survey.height.centimeters));
      setSystem(survey.height.system || System.ENGLISH);
    }
  }, [ survey ]);

  const onEnglishClick = () => setSystem(System.ENGLISH);
  const onMetricClick = () => setSystem(System.METRIC);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-height">
      <Grid container spacing={3}>
        <Grid item container spacing={2} xs={12} alignItems="end">
          <Grid item>
            <Typography variant="textXLBold" color="primary">
              {t('dashboard.survey.question_height.question')}
            </Typography>
          </Grid>
          <Grid item>
            <MeasurementSystem system={system} onEnglishClick={onEnglishClick} onMetricClick={onMetricClick} />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          {!isMetric && (
            <>
              <Grid item marginRight={2}>
                <TextField
                  size="small"
                  color="primary"
                  value={feet}
                  onChange={onFeetChanged}
                  sx={{ width: 95 }}
                  error={Boolean(error)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {t('general.feet_short')}
                      </InputAdornment>
                    ),
                    inputProps: { 'data-testid': 'input-feet' },
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  color="primary"
                  value={inches}
                  onChange={onInchesChanged}
                  sx={{ width: 95 }}
                  error={Boolean(error)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {t('general.inches_short')}
                      </InputAdornment>
                    ),
                    inputProps: { 'data-testid': 'input-inches' },
                  }}
                />
              </Grid>
            </>
          )}
          {isMetric && (
            <Grid item>
              <TextField
                size="small"
                color="primary"
                value={centimeters}
                onChange={onCentimetersChanged}
                sx={{ width: 95 }}
                error={Boolean(error)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t('general.centimeters_short')}
                    </InputAdornment>
                  ),
                  inputProps: { 'data-testid': 'input-centimeters' },
                }}
              />
            </Grid>
          )}
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default HeightQuestion;
