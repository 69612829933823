import { updateUserKitRequest } from 'api/UserKits/api';

import { useState, useEffect } from 'react';

export const useUpdateUserKit = (onUpdated: (userId: number, kitId: string) => void) => {
  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  useEffect(() => {
    return () => {
      setError('');
      setLoading(false);
    };
  }, []);

  const requestUpdateUserKit = (userId: number, newKitId: string) => {
    setError('');
    setLoading(true);

    updateUserKitRequest(userId, newKitId)
      .then(() => {
        onUpdated(userId, newKitId);
      })
      .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
      .finally(() => setLoading(false));
  };

  return {
    requestUpdateUserKit,
    isLoading,
    error,
    setError,
  };
};
