import { axiosInstance } from 'api/instance';
import { GetTracksParams, Track, Transactions } from 'api/Shipment/types';

import { AxiosResponse } from 'axios';

export const getTransactions = (): Promise<AxiosResponse<Transactions>> => {
  return axiosInstance.get<Transactions>('users/transactions');
};

export const createTransaction = (deliveryType: number): Promise<AxiosResponse<Transactions>> => {
  return axiosInstance.post<Transactions>('shippo/transaction', { deliveryType });
};

export const recreateTransaction = (userId: number, deliveryType: number): Promise<AxiosResponse<Transactions>> => {
  return axiosInstance.post<Transactions>(`shippo/recreate-transaction/${userId}`, { deliveryType });
};

export const getTrack = (params: GetTracksParams): Promise<AxiosResponse<Track>> => {
  return axiosInstance.post<Track>('shippo/tracks', params);
};
