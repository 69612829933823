import HealthInfoQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HealthInfoQuestion/HealthInfoQuestion';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';

import React from 'react';

const DISEASES = [
  'Hepatitis A',
  'Hepatitis B',
  'Hepatitis C',
  'Cirrhosis of the liver',
  'Fatty liver disease (non-alcoholic)',
  'Other',
];

const LiverDisorderQuestion: React.FC<QuestionPageProps> = ({ onNext, onPrev, onSubmit }) => {
  return <HealthInfoQuestion onPrev={onPrev} onNext={onNext} onSubmit={onSubmit} field="liverDisorder" diseases={DISEASES} />;
};

export default LiverDisorderQuestion;
