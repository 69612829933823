import { AuthorizationContainer, FormTextField, Label } from 'common/components';
import styles from 'components/Dashboard/components/Authorization/ResetPassword/ResetPasswordPage.module.css';
import useResetPassword from 'components/Dashboard/components/Authorization/hooks/useResetPassword';

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormikProvider } from 'formik';

interface Props {
  disableRegistration?: boolean
}

const ResetPasswordPage: React.FC<Props> = ({ disableRegistration }) => {
  const { t, i18n } = useTranslation();

  const { formik, isSubmitting, submitError, isSubmitted } = useResetPassword();
  const navigate = useNavigate();

  const isEnglish = i18n.language === 'en';

  const signInPath = disableRegistration ? '/admin-dashboard' : '/dashboard';

  const toggleSignUp = () => navigate('/dashboard/signup');
  const toggleSignIn = () => navigate(signInPath);

  return (
    <AuthorizationContainer>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={styles.form} data-testid="reset-password-form">
          {isSubmitted ? (
            <>
              <Label>{t('reset_password.reset_password')}</Label>
              <Typography className={styles.activateText} color="#3E355C" paddingBottom="40px">
                <Trans
                  t={t}
                  i18nKey="reset_password.link_sent"
                  values={{ email: formik.values.email }}
                  components={{ bold: <b /> }}
                />
              </Typography>
              <Box alignSelf="flex-start">
                <LoadingButton
                  onClick={formik.submitForm}
                  loading={isSubmitting}
                  variant="contained"
                  sx={{ padding: '10px 16px' }}
                >
                  {t('reset_password.send_link_again')}
                </LoadingButton>
              </Box>
            </>
          ) : (
            <>
              <Label>
                {t('auth.question_dont_remember_password')}
              </Label>
              <FormTextField
                id="email"
                name="email"
                type="email"
                label={t('general.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
                touched={formik.touched.email}
                disabled={isSubmitting}
              />
              {
                submitError && (
                  <Box marginTop={1}>
                    <Typography color="error" variant="subtitle2">
                      {submitError}
                    </Typography>
                  </Box>
                )
              }
              <Box
                marginTop={{ xs: 8, sm: 4 }}
                width="100%"
                display="flex"
                flexDirection={{ xs: 'column', sm: isEnglish ? 'row' : 'column' }}
                justifyContent="space-between"
                alignItems="center"
              >
                <LoadingButton
                  onClick={formik.submitForm}
                  loading={isSubmitting}
                  variant="contained"
                  sx={{
                    padding: '10px 16px',
                    width: { xs: '100%', sm: isEnglish ? 'unset' : '100%' },
                    marginBottom: { xs: 0, sm: isEnglish ? 0 : '32px' },
                  }}
                  data-testid="button-get-link"
                >
                  {t('reset_password.button_get_reset_link')}
                </LoadingButton>
                <span
                  onClick={toggleSignIn}
                  className={styles.signInButton}
                  data-testid="button-remember-password"
                >
                  {t('reset_password.i_remember_password')}
                </span>
              </Box>
              {!disableRegistration && (
                <Box className={styles.signUpContainer} color="#3e355c" marginTop={{ xs: 3, sm: 4 }} textAlign="center">
                  <span>
                    {t('auth.question_dont_have_account')}
                  </span>
                  <span
                    onClick={toggleSignUp}
                    className={styles.signUpButton}
                    data-testid="button-registration"
                  >
                    {t('auth.registration')}
                  </span>
                </Box>
              )}
            </>
          )}
        </form>
      </FormikProvider>
    </AuthorizationContainer>
  );
};

export default ResetPasswordPage;
