import { postRevokeTokens } from 'api/Auth/api';
import { getRefreshToken, getToken } from 'common/helpers/auth';
import { NOT_AUTHORIZED } from 'store/auth/types';
import { ReduxDispatch } from 'store';
import { RESET_USER } from 'store/user/types';

export const logout = () => (dispatch: ReduxDispatch) => {
  const token = getToken();
  const refreshToken = getRefreshToken();

  if (token || refreshToken) {
    postRevokeTokens(token, refreshToken)
      .then(() => {
        dispatch({ type: NOT_AUTHORIZED });
        dispatch({ type: RESET_USER });
      })
      .catch(() => {
        // todo logout error
      });
  }
};
