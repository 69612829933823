import AndMeLogo from 'components/Dashboard/components/AncestryPage/resources/23_and_me_logo.png';
import AncestryLogo from 'components/Dashboard/components/AncestryPage/resources/ancestry_logo.png';
import HeritageLogo from 'components/Dashboard/components/AncestryPage/resources/my_heritage_logo.png';
import { AncestryProvider } from 'api/Reports/types';

import { Button, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  provider?: AncestryProvider;
  onChange: (value: AncestryProvider) => void;
  onComplete: (value: boolean) => void;
}

const SelectProviderView: React.FC<Props> = ({ provider, onChange, onComplete }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const selectedRadioStyle = {
    width: 200,
    height: 112,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '6px',
    '&>span': { marginRight: 2 },
  };

  const radioStyle = {
    width: 200,
    height: 112,
    border: `2px solid  ${theme.palette.neutral[40]}`,
    borderRadius: '6px',
    '&>span': { marginRight: 2 },
  };

  const handleRadioChanged = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value as AncestryProvider);
  };

  return (
    <Stack
      flexGrow={1}
      spacing={2}
      direction="column"
      width="100%"
      height="100%"
      alignItems="flex-start"
      justifyContent="space-between"
      data-testid="select-provider-view"
    >
      <Typography variant="textXLBold">
        {t('dashboard.ancestry.select_provider.question')}
      </Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={provider}
        onChange={handleRadioChanged}
      >
        <Grid container spacing={4} rowSpacing={{ xs: 2, md: 4 }} paddingLeft={1.5}>
          <Grid item>
            <FormControlLabel
              value={AncestryProvider.TWENTY_THREE_AND_ME}
              control={<Radio />}
              sx={provider === AncestryProvider.TWENTY_THREE_AND_ME ? selectedRadioStyle : radioStyle}
              label={<img src={AndMeLogo} alt="" />}
              data-testid="radio-23andme"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              value={AncestryProvider.ANCESTRY}
              control={<Radio />}
              sx={provider === AncestryProvider.ANCESTRY ? selectedRadioStyle : radioStyle}
              label={<img src={AncestryLogo} alt="" />}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              value={AncestryProvider.MYHERITAGE}
              control={<Radio />}
              sx={provider === AncestryProvider.MYHERITAGE ? selectedRadioStyle : radioStyle}
              label={<img src={HeritageLogo} alt="" />}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              value={AncestryProvider.OTHER}
              control={<Radio />}
              sx={provider === AncestryProvider.OTHER ? selectedRadioStyle : radioStyle}
              label={t('dashboard.ancestry.provider_other')}
            />
          </Grid>
        </Grid>
      </RadioGroup>
      <Button
        disabled={!provider}
        variant="contained"
        onClick={() => onComplete(true)}
        data-testid="button-continue"
      >
        {t('dashboard.ancestry.select_provider.button_continue')}
      </Button>
    </Stack>
  );
};

export default SelectProviderView;
