import { RootState } from 'store';
import SamplesContainer from 'containers/SamplesContainer';
import PrivateRouter from 'common/components/PrivateRouter/PrivateRouter';
import NewsEditPage from 'components/AdminDashboard/components/NewsEditPage/NewsEditPage';
import NewsListPage from 'components/AdminDashboard/components/NewsListPage/NewsListPage';
import { UserAccessLevel } from 'api/User/types';
import ReportsPage from 'components/AdminDashboard/components/ReportsPage/ReportsPage';
import ShippingStatusPage from 'components/AdminDashboard/components/ShippingStatusPage/ShippingStatusPage';
import UsersPage from 'components/AdminDashboard/components/UsersPage/UsersPage';
import AdminRolesPage from 'components/AdminDashboard/components/AdminRolesPage/AdminRolesPage';
import OrganizationsPage from 'components/AdminDashboard/components/OrganizationsPage/OrganizationsPage';
import UserEditPage from 'components/AdminDashboard/components/UserEditPage/UserEditPage';
import UserAddPage from 'components/AdminDashboard/components/UserAddPage/UserAddPage';
import DashboardNavLink from 'common/components/DashboardNavLink/DashboardNavLink';
import { ADMIN_DASHBOARD_NAVIGATION_ITEMS, ADMIN_DASHBOARDS_USERS_PAGES } from 'components/AdminDashboard/constants';

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

const AdminDashboard = () => {
  const { myUser } = useSelector((store: RootState) => store.user);

  return (
    <Box data-testid="admin-dashboard-page" flex="1 1 auto" padding={{ xs: 2, sm: 4 }} display="flex" flexWrap="nowrap">
      <Stack
        sx={{
          display: { xs: 'none', lg: 'flex' },
          minWidth: 224,
        }}
      >
        {ADMIN_DASHBOARD_NAVIGATION_ITEMS
          .filter(item => item.roles.includes(myUser.accessRights))
          .map(item => (
            <DashboardNavLink to={item.to} key={item.to}>
              {item.label}
            </DashboardNavLink>
          ))}
      </Stack>
      <Box flexGrow={1} maxWidth={{ lg: 840, xl: 1140 }} margin="0 auto">
        <Routes>
          {ADMIN_DASHBOARDS_USERS_PAGES.map(path => (
            <Route
              key={path}
              path={path}
              element={(
                <PrivateRouter roles={[
                  UserAccessLevel.SUPER_ADMIN,
                  UserAccessLevel.ADMIN,
                  UserAccessLevel.BIO_BANK_ADMIN,
                  UserAccessLevel.DATA_SCIENCE_ADMIN,
                ]}
                >
                  <UsersPage isClinicalPatientPage={path === 'clinical-patients'} />
                </PrivateRouter>
              )}
            />
          ))}
          <Route
            path="shipping"
            element={(
              <PrivateRouter roles={[ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN, UserAccessLevel.BIO_BANK_ADMIN ]}>
                <ShippingStatusPage />
              </PrivateRouter>
            )}
          />
          <Route
            path="news"
            element={(
              <PrivateRouter roles={[ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN ]}>
                <NewsListPage />
              </PrivateRouter>
            )}
          />
          <Route
            path="news/:newsId"
            element={(
              <PrivateRouter roles={[ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN ]}>
                <NewsEditPage />
              </PrivateRouter>
            )}
          />
          <Route
            path="reports"
            element={(
              <PrivateRouter roles={[ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN, UserAccessLevel.DATA_SCIENCE_ADMIN ]}>
                <ReportsPage />
              </PrivateRouter>
            )}
          />
          <Route
            path="admin-roles"
            element={(
              <PrivateRouter roles={[ UserAccessLevel.SUPER_ADMIN ]}>
                <AdminRolesPage />
              </PrivateRouter>
            )}
          />
          <Route
            path="organizations"
            element={(
              <PrivateRouter roles={[ UserAccessLevel.SUPER_ADMIN ]}>
                <OrganizationsPage />
              </PrivateRouter>
            )}
          />
          <Route
            path="samples/*"
            element={(
              <PrivateRouter roles={[
                UserAccessLevel.SUPER_ADMIN,
                UserAccessLevel.ADMIN,
                UserAccessLevel.BIO_BANK_ADMIN,
                UserAccessLevel.DATA_SCIENCE_ADMIN,
              ]}
              >
                <SamplesContainer />
              </PrivateRouter>
            )}
          />
          {ADMIN_DASHBOARDS_USERS_PAGES.map(path => (
            <Route
              path={`${path}/:userId`}
              key={`${path}-edit`}
              element={(
                <PrivateRouter roles={[ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN, UserAccessLevel.BIO_BANK_ADMIN ]}>
                  <UserEditPage isClinicalPatientPage={path === 'clinical-patients'} />
                </PrivateRouter>
              )}
            />
          ))}
          {ADMIN_DASHBOARDS_USERS_PAGES.map(path => (
            <Route
              path={`${path}/add`}
              key={`${path}-add`}
              element={(
                <PrivateRouter roles={[ UserAccessLevel.SUPER_ADMIN, UserAccessLevel.ADMIN, UserAccessLevel.BIO_BANK_ADMIN ]}>
                  <UserAddPage isClinicalPatientPage={path === 'clinical-patients'} />
                </PrivateRouter>
              )}
            />
          ))}
          <Route path="/*" element={<Navigate to="users" />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
