import { postRefreshToken } from 'api/Auth/api';
import { getToken, getRefreshToken, removeRefreshToken, removeToken, setRefreshToken, setToken } from 'common/helpers/auth';
import { store } from 'store';
import { NOT_AUTHORIZED } from 'store/auth/types';
import { RESET_USER } from 'store/user/types';

import axios, { AxiosRequestHeaders } from 'axios';

export const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

axiosInstance.interceptors.request.use(
  config => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${getToken()}`,
    } as AxiosRequestHeaders,
  }),
  err => Promise.reject(err),
);

const dispatchLogout = () => {
  store.dispatch({ type: RESET_USER });
  store.dispatch({ type: NOT_AUTHORIZED });
};

let refreshingToken: ReturnType<typeof postRefreshToken> | null = null;

axiosInstance.interceptors.response.use(
  response => response,
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (
        err.response.status === 401 &&
        !originalConfig._retry &&
        originalConfig.url !== 'users/confirm' &&
        originalConfig.url !== 'users/request-new-code'
      ) {
        originalConfig._retry = true;
        removeToken();

        try {
          const storedRefreshToken = getRefreshToken();

          if (storedRefreshToken) {
            refreshingToken = refreshingToken || postRefreshToken(storedRefreshToken);
            const { data: { token, refreshToken } } = await refreshingToken;
            refreshingToken = null;

            setToken(token);
            setRefreshToken(refreshToken);

            originalConfig.headers.Authorization = `Bearer ${token}`;
          } else {
            dispatchLogout();
            return await Promise.reject(err.response.data);
          }

          return await axiosInstance(originalConfig);
        } catch (error: any) {
          removeRefreshToken();
          dispatchLogout();

          if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
          }

          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export default axiosInstance;
