import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { FamilyHealthCondition, Survey, SurveyV1 } from 'api/User/types';
import {
  EMPTY_CONDITION,
  FAMILY_MEMBERS,
  HEALTH_CONDITIONS,
} from 'components/Dashboard/components/SurveyPage/components/V1/FamilyHealthConditionsQuestion/constants';
import { TranslationsKey } from 'i18nextOptions';

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MenuItem from '@mui/material/MenuItem';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';

const FamilyHealthConditionsQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const theme = useTheme();
  const matchSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const matchMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const [ value, setValue ] = useState((survey as SurveyV1)?.familyLongTermHealthConditions?.value || '');
  const [ error, setError ] = useState('');
  const [ healthConditions, setHealthConditions ] = useState<FamilyHealthCondition[]>(
    (survey as SurveyV1)?.familyLongTermHealthConditions?.conditions || [ EMPTY_CONDITION ],
  );

  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    setError('');

    if (value === 'yes') {
      const filled = healthConditions.reduce((res, condition) => {
        return res && Boolean(condition.member) && Boolean(condition.condition);
      }, true);

      if (!filled) {
        setError(t('dashboard.survey.error_give_answer'));
        return;
      }

      submit({
        ...survey,
        familyLongTermHealthConditions: { value, conditions: healthConditions },
      })
        .then((data: Survey) => onNext(data));
    } else if (value !== 'yes') {
      submit({
        ...survey,
        familyLongTermHealthConditions: { value, conditions: null },
      })
        .then((data: Survey) => onNext(data));
    }
  }, [ t, value, healthConditions, submit, survey, onNext ]);

  const onRadioChanged = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  useEffect(() => {
    setValue((survey as SurveyV1)?.familyLongTermHealthConditions?.value || '');
    setHealthConditions((survey as SurveyV1)?.familyLongTermHealthConditions?.conditions || [ EMPTY_CONDITION ]);
  }, [ survey ]);

  const handleAddAnother = () => {
    setHealthConditions(prevState => [ ...prevState, { member: '', condition: '' } ]);
  };

  const handleMemberChanged = (value: string, index: number) => {
    setHealthConditions([
      ...healthConditions.slice(0, index),
      { ...healthConditions[index], member: value },
      ...healthConditions.slice(index + 1, healthConditions.length),
    ]);
  };

  const handleConditionChanged = (value: string, index: number) => {
    setHealthConditions([
      ...healthConditions.slice(0, index),
      { ...healthConditions[index], condition: value },
      ...healthConditions.slice(index + 1, healthConditions.length),
    ]);
  };

  const handleRemoveCondition = (index: number) => {
    setHealthConditions([
      ...healthConditions.slice(0, index),
      ...healthConditions.slice(index + 1, healthConditions.length),
    ]);
  };

  const inputsDisabled = value !== 'yes' || isSubmitting;

  return (
    <form onSubmit={onFormSubmit} data-testid="question-family-health-conditions">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_family_health_conditions.question')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              onChange={onRadioChanged}
              value={value}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel value="no" control={<Radio />} label={t('general.no')} />
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={1}>
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label={t('general.yes')}
                      data-testid="option-yes"
                    />
                  </Grid>
                  <Grid item container xs={12} md={11} spacing={3} paddingTop={0.5}>
                    {healthConditions.map((condition, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid item container spacing={{ xs: 2, md: 3 }} key={index}>
                        <Grid item xs={10} sm={5} md={3}>
                          <TextField
                            value={condition.member || ''}
                            label={t('dashboard.survey.question_family_health_conditions.family_member')}
                            onChange={event => handleMemberChanged(event.target.value, index)}
                            disabled={inputsDisabled}
                            error={!inputsDisabled && Boolean(error) && !condition.member}
                            color="primary"
                            select
                            fullWidth
                            size="small"
                          >
                            {FAMILY_MEMBERS.map(member => (
                              <MenuItem
                                key={member}
                                value={member}
                              >
                                {t(`dashboard.survey.family_members.${member}` as TranslationsKey)}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {matchSmDown && (
                          <Grid item xs={1}>
                            {(index > 0 || healthConditions.length > 1) && (
                              <Button
                                onClick={() => handleRemoveCondition(index)}
                                disabled={inputsDisabled}
                                sx={{ minWidth: '48px' }}
                                data-testid={`button-remove-${index}`}
                              >
                                <ClearIcon />
                              </Button>
                            )}
                          </Grid>
                        )}
                        <Grid xs={10} sm={5} md={3} item>
                          <TextField
                            label={t('dashboard.survey.long_term_condition')}
                            value={condition.condition || ''}
                            onChange={event => handleConditionChanged(event.target.value, index)}
                            error={!inputsDisabled && Boolean(error) && !condition.condition}
                            select
                            fullWidth
                            disabled={inputsDisabled}
                            size="small"
                          >
                            {HEALTH_CONDITIONS.map(condition => (
                              <MenuItem
                                key={condition}
                                value={condition}
                              >
                                {t(`dashboard.survey.health_conditions.${condition}` as TranslationsKey)}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {!matchSmDown && (
                          <Grid item xs={1}>
                            {(index > 0 || healthConditions.length > 1) && (
                              <Button
                                onClick={() => handleRemoveCondition(index)}
                                disabled={inputsDisabled}
                                sx={{ minWidth: '48px' }}
                                data-testid={`button-remove-${index}`}
                              >
                                <ClearIcon />
                              </Button>
                            )}
                          </Grid>
                        )}
                        { /* condition to avoid extra spacing on xs resolution */ }
                        {(!matchMdDown || index === healthConditions.length - 1) && (
                          <Grid xs={12} md={5} item>
                            {index === healthConditions.length - 1 && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleAddAnother}
                                startIcon={<AddCircleOutlineIcon />}
                                fullWidth
                                disabled={inputsDisabled}
                                data-testid="button-add"
                              >
                                {t('dashboard.survey.question_family_health_conditions.button_add_family_member')}
                              </Button>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="primary" maxWidth={425} fontWeight={400}>
            {t('dashboard.survey.hint_leave_blank')}
          </Typography>
        </Grid>
        {submitError && (
          <Grid item xs={12}>
            <Typography color="error">
              {submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default FamilyHealthConditionsQuestion;
