import { ShippingStatus } from 'api/Shipment/types';

import React from 'react';
import { ClickAwayListener, Popover, Typography } from '@mui/material';
import { DateTime } from 'luxon';

interface Props {
  anchorEl: HTMLElement;
  shippingPopoverStatus: ShippingStatus | null;
  onClose: () => void;
}

const ShippingStatusPopover:React.FC<Props> = ({ anchorEl, shippingPopoverStatus, onClose }) => (
  <ClickAwayListener onClickAway={onClose}>
    <Popover
      sx={{
        pointerEvents: 'none',
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 1,
        sx: { padding: 2 },
      }}
      onClose={onClose}
      data-testid="popover-shipping-status"
      disableRestoreFocus
    >
      <Typography variant="textSRegular" component="div" sx={{ padding: 0.5 }}>
        {shippingPopoverStatus?.statusDetails}
      </Typography>
      <Typography variant="textSRegular" component="div" sx={{ padding: 0.5 }}>
        {`Updated at: ${DateTime.fromISO(String(shippingPopoverStatus?.objectUpdated)).toFormat('MM/dd/yyyy h:mma')}`}
      </Typography>
    </Popover>
  </ClickAwayListener>
);

export default ShippingStatusPopover;
