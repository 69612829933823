export interface Sample {
  id: number;
  userId: number;
  sampleId: string;
  createdAt: string;
}

export interface Bag {
  id: number;
  bagId: string;
  createdAt: string;
  updatedAt: string;
}

export interface Plate {
  id: number;
  plateId: string;
  createdAt: string;
  updatedAt: string;
}

export type CreateSampleParams = Pick<Sample, 'userId' | 'sampleId'>;

export enum LabelType {
  BAG = 100,
  PLATE = 110,
  SAMPLE = 120,
}

export interface CreateLabelsParams {
  type: LabelType;
  countryCode: string;
  site: string;
  prefix: string;
  count: string;
}
