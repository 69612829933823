import { styled } from '@mui/material';

export const LoadingContainerRoot = styled('div')<{ ownerState: { isLoading: boolean } }>(
  ({ ownerState }) => ({
    position: 'relative',
    maxHeight: '100%',
    '& .overlay': {
      opacity: ownerState.isLoading ? 0.5 : 1,
      pointerEvents: ownerState.isLoading ? 'none' : 'auto',
    },
    '& .progress': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
);
