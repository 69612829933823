import { APP_BAR_HEIGHT } from 'common/constants/sizes';
import CornerSvg from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/corner.svg';
import SaasSvg
  from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/partners/saas.svg';
import StanfordSvg
  from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/partners/stanford.svg';
import BiohubSvg
  from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/partners/biohub.svg';
import WcgIrbSvg
  from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/partners/wcg_irb.svg';
import BlueMedSvg
  from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/partners/blue_med.svg';
import HemlifeSvg
  from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/partners/hemlife.svg';
import GalateaSvg from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/partners/galatea.svg';
import GenomelinkSvg from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/partners/genomelink.svg';
import PyramidSvg from 'components/Landing/components/PartnersContent/components/PartnershipPage/resources/pyramid.svg';

import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import styles from './PartnershipPage.module.css';

const PartnershipPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [ pageEndRef, isPageInView ] = useInView();

  const isEnglish = i18n.language === 'en';

  return (
    <Container
      maxWidth="xl"
      sx={{ height: '100vh', paddingTop: APP_BAR_HEIGHT, position: 'relative', overflow: { xs: 'hidden', md: 'visible' } }}
    >
      <Box
        position="absolute"
        left="-36vh"
        marginTop={APP_BAR_HEIGHT}
        sx={{
          height: '60vh',
          top: { xs: '12px', xl: '80px' },
        }}
      >
        <img src={PyramidSvg} alt="" height="100%" />
        <Box
          width="100%"
          height="100%"
          position="absolute"
          display="flex"
          flexDirection="column"
          left={0}
          top={0}
        >
          <Box height="28.5%" />
          <Box
            display="flex"
            alignItems="center"
            position="relative"
            left="35%"
            width="30%"
            height="21.7%"
            flexGrow={1}
          >
            <Typography
              className={styles.pyramidText}
              sx={{ fontSize: { xs: '15px', xl: isEnglish ? '20px' : '16px' } }}
            >
              <b>{t('landing.partners.main.mission_header')}</b>{` ${t('landing.partners.main.mission')}`}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            position="relative"
            left="35%"
            width={{ xs: '38%', lg: '42%' }}
            height="25%"
          >
            <Typography
              color="common.white"
              className={styles.pyramidText}
              sx={{ fontSize: { xs: '15px', xl: isEnglish ? '20px' : '16px' } }}
            >
              <b>{t('landing.partners.main.thesis_header')}</b>{` ${t('landing.partners.main.thesis')}`}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            position="relative"
            left="35%"
            width={{ xs: '38%', lg: '55%' }}
            height="21.3%"
          >
            <Typography
              className={styles.pyramidText}
              sx={{ fontSize: { xs: '15px', xl: isEnglish ? '20px' : '16px' } }}
            >
              <b>{t('landing.partners.main.what_we_do_header')}</b>{` ${t('landing.partners.main.what_we_do')}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box position="relative" height="100%" marginTop={{ xs: 2, lg: 4 }}>
        <Box maxWidth="850px" position="relative">
          <Typography
            variant="h1"
            color="primary"
            className={styles.title}
          >
            {t('landing.partners.main.title')}
          </Typography>
        </Box>
        <Box
          position="fixed"
          display={isPageInView ? 'block' : 'none'}
          sx={{
            bottom: { xs: 12, sm: 16 },
            width: { xs: '90%', sm: '50%' },
            maxWidth: '850px',
          }}
          zIndex={0}
        >
          <Typography
            color="primary"
            className={styles.partnersTitle}
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontWeight: 700,
              fontSize: { xs: '18px', lg: '20px' },
              lineHeight: '150%',
              textTransform: 'uppercase',
              marginBottom: { xs: '12px', lg: '16px' },
              zIndex: -1,
            }}
          >
            {t('landing.partners.main.our_partners')}
          </Typography>
          <Grid
            container
            spacing={2}
            display={isPageInView ? 'flex' : 'none'}
            flexDirection="row"
            sx={{ bottom: { xs: '120px', sm: '96px' } }}
            zIndex={0}
            flexWrap="wrap"
          >
            <Grid item xs={3}>
              <Box sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.galatea.bio/')}>
                <img src={GalateaSvg} height="100%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://genomelink.io/')}>
                <img src={GenomelinkSvg} height="100%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://saas.ad/')}>
                <img src={SaasSvg} height="100%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.stanford.edu/')}>
                <img src={StanfordSvg} height="100%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.czbiohub.org/')}>
                <img src={BiohubSvg} height="100%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.wcgirb.com/')}>
                <img src={WcgIrbSvg} height="100%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.bluemedconsultants.com/')}>
                <img src={BlueMedSvg} height="100%" alt="" />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://hemolifeamerica.org/')}>
                <img src={HemlifeSvg} height="100%" alt="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          position="fixed"
          width="50%"
          height="115%"
          overflow="hidden"
          zIndex={-1000}
          sx={{
            bottom: { xs: '-45%', md: '-25%' },
            right: { xs: '-10%', md: '-7%' },
            width: { xs: '40%', md: '50%' },
            display: { xs: 'none', sm: isPageInView ? 'block' : 'none' },
          }}
        >
          <img src={CornerSvg} alt="" width="100%" height="100%" />
        </Box>
      </Box>
      <Box ref={pageEndRef} position="absolute" bottom={{ xs: '30%', md: '10%' }} />
    </Container>
  );
};

export default PartnershipPage;
