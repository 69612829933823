import { getUserRequest } from 'api/User/api';
import { User } from 'api/User/types';

import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useGetUser = () => {
  const { t } = useTranslation();

  const [ user, setUser ] = useState<User>();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>('');

  const getUser = useCallback((userId: string) => {
    setIsLoading(true);
    setError('');

    getUserRequest(userId)
      .then((response) => {
        setUser(response.data);
      })
      .catch(error => setError(error?.response?.data?.message || t('errors.server_error_try_again')))
      .finally(() => setIsLoading(false));
  }, [ t ]);

  return {
    getUser,
    user,
    isLoading,
    error,
  };
};

export default useGetUser;
