import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV1 } from 'api/User/types';
import { ANCESTRY_LIST } from 'common/constants/ancestry';
import { TranslationsKey } from 'i18nextOptions';

import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const RaceQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();

  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const [ values, setValues ] = useState<string[]>([]);
  const [ error, setError ] = useState<string>('');
  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    if (!values.length) {
      setError(t('dashboard.survey.error_give_answer'));
    } else {
      submit({
        ...survey,
        race: values,
      })
        .then((data: Survey) => onNext(data));
    }
  }, [ t, onNext, submit, survey, values ]);

  useEffect(() => {
    setValues((survey as SurveyV1)?.race || []);
  }, [ survey ]);

  const handleChange = useCallback((event) => {
    if (event.target.checked) {
      setValues(prevState => [ ...prevState, event.target.value ]);
    } else {
      setValues((prevState) => {
        const removedRaceIndex = prevState.indexOf(event.target.value);

        return [
          ...prevState.slice(0, removedRaceIndex),
          ...prevState.slice(removedRaceIndex + 1, prevState.length),
        ];
      });
    }
  }, []);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-race">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="textXLBold">
            {t('dashboard.survey.question_race.question')}
          </Typography>
        </Grid>
        <Grid item container spacing={0}>
          {ANCESTRY_LIST.map(race => (
            <Grid item xs={12} sm={6} md={4} key={race}>
              <FormControlLabel
                control={<Checkbox onChange={handleChange} checked={values.includes(race)} value={race} />}
                label={String(t(`dashboard.profile.races.${race}` as TranslationsKey))}
              />
            </Grid>
          ))}
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default RaceQuestion;
