import AdminDashboard from 'components/AdminDashboard/AdminDashboard';
import PrivateRouter from 'common/components/PrivateRouter/PrivateRouter';
import { UserAccessLevel } from 'api/User/types';
import useAuth from 'common/hooks/useAuth';
import { getMyUser } from 'store/user/actions';
import { RootState } from 'store';
import AuthorizationPage from 'components/Dashboard/components/Authorization/AuthorizationPage';
import LogoIcon from 'common/icons/LogoIcon';
import { logout } from 'store/auth/actions';
import { ADMIN_DASHBOARD_ROUTE, DASHBOARD_ROUTE } from 'common/constants/urls';
import { purple600 } from 'common/constants/colors';
import { SATOSHI } from 'common/constants/fonts';
import { getOrganizations } from 'store/organizations/actions';

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, Popover, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import backgroundImage from './resources/background.svg';

const AdminDashboardContainer = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { myUser } = useSelector((store: RootState) => store.user);
  const dispatch = useDispatch();

  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const { t } = useTranslation();
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onLogoutClick = React.useCallback(() => {
    dispatch(logout());
    setAnchorEl(null);
  }, [ dispatch ]);

  const [ isLoading, setIsLoading ] = React.useState<boolean>(true);

  useEffect(() => {
    if (myUser.id) {
      dispatch(getOrganizations(0, 100));
      setIsLoading(false);
    }
  }, [ myUser, dispatch ]);

  useEffect(() => {
    if (isAuthenticated && !myUser.id) {
      dispatch(getMyUser());
    }
  }, [ dispatch, isAuthenticated, myUser ]);

  const renderRoutes = () => {
    if (!isAuthenticated) {
      return (
        <div data-testid="authorization-container">
          <AuthorizationPage disableRegistration />
        </div>
      );
    }

    return isLoading ? (
      <CircularProgress />
    ) : (
      <Routes>
        <Route
          path="*"
          element={(
            <PrivateRouter roles={[
              UserAccessLevel.SUPER_ADMIN,
              UserAccessLevel.DATA_SCIENCE_ADMIN,
              UserAccessLevel.BIO_BANK_ADMIN,
              UserAccessLevel.ADMIN,
            ]}
            >
              <AdminDashboard />
            </PrivateRouter>
          )}
        />
      </Routes>
    );
  };

  return (
    <Grid
      display="flex"
      minWidth="100%"
      flexDirection="column"
      minHeight="100vh"
      color={purple600}
      fontFamily={SATOSHI}
      sx={!isAuthenticated ? { background: `url(${backgroundImage}) #3e355c 50% 100% no-repeat`, backgroundColor: purple600 } : { backgroundColor: purple600 }}
      data-testid="admin-dashboard-container"
    >
      <Grid
        flex="0 1 auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={{ xs: '24px 16px', sm: '53px 32px', md: '32px 42px' }}
      >
        { pathname !== DASHBOARD_ROUTE && lgDown && isAuthenticated ? (
          <Button
            variant="text"
            color="ivory"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            sx={{ textTransform: 'none' }}
          >
            {t('general.back')}
          </Button>
        ) : (
          <LogoIcon
            color="primaryContrast"
            sx={{ width: 262, height: 60, cursor: 'pointer' }}
            onClick={() => navigate(ADMIN_DASHBOARD_ROUTE)}
          />
        )}
        {myUser?.id && (
          <Box>
            <Button color="primaryContrast" aria-describedby={id} variant="text" onClick={handleClick} sx={{ padding: 0 }}>
              <Box sx={{ p: 2 }} textTransform="capitalize" alignItems="center" display="flex">
                <AccountCircleOutlinedIcon fontSize="medium" sx={{ marginRight: { xs: 0, sm: 2 } }} />
                <Typography display={{ xs: 'none', sm: 'inherit' }}>{myUser.firstName} {myUser.lastName}</Typography>
              </Box>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Stack padding={1} direction="column" spacing={1}>
                <Button
                  variant="text"
                  data-testid="logout-button"
                  onClick={onLogoutClick}
                  sx={{ width: '102px' }}
                >
                  {t('auth.logout')}
                </Button>
              </Stack>
            </Popover>
          </Box>
        )}
      </Grid>
      {renderRoutes()}
    </Grid>
  );
};

export default AdminDashboardContainer;
