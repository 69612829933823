import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey } from 'api/User/types';

import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const BornPlaceQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const [ error, setError ] = useState<string>('');
  const { submit, isSubmitting, submitError } = useQuestionForm();

  const [ country, setCountry ] = useState<string>('');
  const [ city, setCity ] = useState<string>('');

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    if (!country || !city) {
      setError(t('dashboard.survey.error_give_answer'));
    } else {
      setError('');
      submit({
        ...survey,
        bornPlace: {
          country,
          city,
        },
      })
        .then((data: Survey) => onNext(data));
    }
  }, [ t, country, city, onNext, submit, survey ]);

  const onCountryChanged = useCallback((event) => {
    if (event.target.value && city) {
      setError('');
    }
    setCountry(event.target.value);
  }, [ city ]);

  const onCityChanged = useCallback((event) => {
    if (event.target.value && country) {
      setError('');
    }
    setCity(event.target.value);
  }, [ country ]);
  
  useEffect(() => {
    setCountry(survey?.bornPlace?.country || '');
    setCity(survey?.bornPlace?.city || '');
  }, [ survey ]);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-born-place">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_born_place.question')}
          </Typography>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('dashboard.profile.country')}
              size="small"
              color="primary"
              onChange={onCountryChanged}
              value={country}
              error={Boolean(error) && !country}
              inputProps={{ 'data-testid': 'input-country' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('general.city')}
              size="small"
              color="primary"
              value={city}
              onChange={onCityChanged}
              error={Boolean(error) && !city}
              inputProps={{ 'data-testid': 'input-city' }}
              fullWidth
            />
          </Grid>
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default BornPlaceQuestion;
