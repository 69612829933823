import Delete from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';

import styles from './LocalImg.module.css';

interface Props {
  file: File;
  setLogo: (base64: string, file?: File) => void;
  onDeleteFile: () => void;
}

const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => reject(error);
});

const LocalImg: React.FC<Props> = ({ file, setLogo, onDeleteFile }) => {
  const [ src, setSrc ] = useState('');

  const onClick = () => {
    onDeleteFile();
  };

  useEffect(() => {
    toBase64(file).then((str: string) => {
      setLogo(str, file);
      setSrc(str);
    });
  }, [ file, setLogo ]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img style={{ maxWidth: '100%', margin: 'auto' }} src={src} alt="" />
        <div className={styles.deletingWrap}>
          <Delete onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default LocalImg;
