import { axiosInstance } from 'api/instance';
import { News, NewsBase } from 'api/News/types';
import PaginatedData from 'common/types/PaginatedData';

import { AxiosResponse } from 'axios';

export const getNewsList = (): Promise<AxiosResponse<PaginatedData<NewsBase>>> => {
  return axiosInstance.get<PaginatedData<NewsBase>>('news?page=1&pageSize=100');
};

export const getNews = (id: string): Promise<AxiosResponse<News>> => {
  return axiosInstance.get<News>(`news/${id}`);
};

export const getNewsReadable = (readableId: string): Promise<AxiosResponse<News>> => {
  return axiosInstance.get<News>(`news/readable/${readableId}`);
};

export const createNews = (data: FormData): Promise<AxiosResponse<News>> => {
  return axiosInstance.post<News>('news', data);
};

export const updateNews = (id: string, data: FormData): Promise<AxiosResponse<News>> => {
  return axiosInstance.put<News>(`news/${id}`, data);
};

export const deleteNews = (id: string): Promise<AxiosResponse> => {
  return axiosInstance.delete(`news/${id}`);
};
