import { updateUserRequest } from 'api/User/api';
import { UpdateUserRequestParams, User } from 'api/User/types';
import { USA } from 'common/constants/countries';
import { getUSZipCodeRegExp } from 'common/helpers/user';

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

export interface FormValues {
  zip: string;
  username: string;
  firstName: string;
  lastName: string;
  gender: string;
  country: string;
  state: string;
  city: string;
  street1: string;
  dob: string;
  organizationId: number | null;
  organizationSiteId: string | null;
  phoneNumber: string;
  previousZipCodes: string;
  mrn: string;
}

const useUpdateUserForm = (user: User) => {
  const { t } = useTranslation();

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  const validationSchema = useMemo(() => yup.object({
    firstName: yup
      .string()
      .required(t('registration.hint_firstname')),
    lastName: yup
      .string()
      .required(t('registration.hint_lastname')),
    username: yup
      .string()
      .email(t('registration.error_email'))
      .nullable(),
    zip: yup
      .string()
      .when('country', {
        is: (country: string) => country === USA,
        then: yup.string().required(t('registration.hint_zip')),
        otherwise: yup.string(),
      }),
    gender: yup
      .string()
      .required(t('registration.hint_gender')),
    country: yup
      .string()
      .required(t('registration.hint_country')),
    dob: yup
      .date()
      .max(new Date(), t('registration.hint_dob'))
      .typeError(t('registration.hint_dob'))
      .required(t('registration.hint_dob')),
    phoneNumber: yup
      .number()
      .nullable()
      .typeError('Phone must be a number type'),
    mrn: yup
      .string()
      .max(32, 'MRN must be at most 32 characters')
      .matches(/^\w*$/, 'MRN must contain only alphanumerical characters')
      .nullable(),
    previousZipCodes: yup
      .string()
      .matches(getUSZipCodeRegExp(), 'Only the five-digit and nine-digit (ZIP+4) formats are allowed'),
  }), [ t ]);

  const formikSubmit = (formValues: FormValues) => {
    setIsSubmitting(true);
    setSubmitError('');

    const { country, state, city, street1, zip, previousZipCodes, organizationId, organizationSiteId, ...data } = formValues;
    const requestParams: UpdateUserRequestParams = {
      ...data,
      organizationId: organizationId?.toString() === '' ? null : organizationId,
      organizationSiteId: organizationSiteId?.toString() === '' ? null : organizationSiteId,
      mailingAddress: { country, state, city, street1, zip, previousZipCodes: previousZipCodes.split(',') },
    };
    updateUserRequest(user.id, requestParams)
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setIsSubmitting(false));
  };

  const initialValues = {
    id: user.id,
    username: user.username || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    zip: user.mailingAddress.zip || '',
    gender: user.gender || '',
    country: user.mailingAddress.country || '',
    state: user.mailingAddress.state || '',
    city: user.mailingAddress.city || '',
    street1: user.mailingAddress.street1 || '',
    dob: user.dob || '',
    organizationId: user.organization ? user.organization.id : null,
    organizationSiteId: user.organizationSite?.id || '',
    phoneNumber: user.phoneNumber || '',
    previousZipCodes: user.mailingAddress.previousZipCodes.join(','),
    mrn: user.mrn || '',
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: formikSubmit,
  });

  return { formik, isSubmitting, submitError };
};

export default useUpdateUserForm;
