import { authReducer } from 'store/auth/reducers';
import { userReducer } from 'store/user/reducers';
import { usersReducer } from 'store/users/reducers';
import { shippingReducer } from 'store/shipping/reducers';
import { organizationsReducer } from 'store/organizations/reducers';
import { samplesReducer } from 'store/samples/reducers';

import { combineReducers } from 'redux';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  users: usersReducer,
  shipping: shippingReducer,
  organizations: organizationsReducer,
  samples: samplesReducer,
});
