import { changeAccessLevel } from 'api/User/api';
import { UserAccessLevel } from 'api/User/types';
import { UPDATE_USER_ROLE } from 'store/users/types';

import { useDispatch } from 'react-redux';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useChangeRole = (onSuccessfulSubmit: () => void) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ isSubmitting, setSubmitting ] = useState<boolean>(false);
  const [ submitError, setSubmitError ] = useState<string>();

  const changeRole = useCallback((userId: number, userAccessLevel: UserAccessLevel) => {
    setSubmitting(true);
    setSubmitError('');
    const accessLevel = Number(userAccessLevel);

    changeAccessLevel(userId, Number(accessLevel))
      .then(() => {
        dispatch({ type: UPDATE_USER_ROLE, data: { userId, accessLevel } });
        onSuccessfulSubmit();
      })
      .catch(error => setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again')))
      .finally(() => setSubmitting(false));
  }, [ t, dispatch, onSuccessfulSubmit ]);

  return {
    changeRole,
    isSubmitting,
    submitError,
  };
};

export default useChangeRole;
