import rootReducer from 'store/rootReducer';

import { useDispatch } from 'react-redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { AnyAction, applyMiddleware, createStore, compose, Middleware } from 'redux';
import { createLogger } from 'redux-logger';

const middlewares: Middleware[] = [ thunk ];

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (reduxDevTools) {
    composeEnhancers = reduxDevTools;
  } else {
    middlewares.push(createLogger());
  }
}

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export type RootState = ReturnType<typeof store.getState>;

export type ReduxDispatch = ThunkDispatch<typeof store.getState, void, AnyAction>;
export const useThunkDispatch = () => useDispatch<ReduxDispatch>();
