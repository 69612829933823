import { RootState } from 'store';
import { UserAccessLevel } from 'api/User/types';

import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  children: JSX.Element;
  roles: UserAccessLevel[];
}

const PrivateRoute: React.FC<Props> = ({ children, roles }) => {
  const { t } = useTranslation();
  const { myUser } = useSelector((store: RootState) => store.user);
  const userHasRequiredRole = myUser && roles.includes(myUser.accessRights);

  if (!userHasRequiredRole) {
    return (
      <Typography color="error" data-testid="not-permitted-error">
        { t('errors.not_permitted') }
      </Typography>
    );
  }

  return children;
};

export default PrivateRoute;
