import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';
import { ReportsFilter } from 'api/Reports/types';

import { useFormik } from 'formik';

const useReportsFilter = (onSubmit: () => void) => {
  const {
    ancestryReportStatusParam,
    prsReportStatusParam,
    dnaUploadStatusParam,
    dnaSampleStatusParam,
    partnerParam,
    setReportsFilter,
    clearReportsFilter,
  } = usePaginationSearchParams();

  const formikSubmit = (params: ReportsFilter) => {
    setReportsFilter(params);
    onSubmit();
  };

  const initialValues = {
    ancestryReportStatus: ancestryReportStatusParam,
    prsReportStatus: prsReportStatusParam,
    dnaUploadedStatus: dnaUploadStatusParam,
    dnaSampleStatus: dnaSampleStatusParam,
    organizationIds: partnerParam !== '' ? Array.from(partnerParam.split(','), Number) : [],
  };

  const formik = useFormik<ReportsFilter>({
    initialValues,
    onSubmit: formikSubmit,
  });

  const clearFormAndSearchParams = () => {
    formik.setFieldValue('ancestryReportStatus', '');
    formik.setFieldValue('prsReportStatus', '');
    formik.setFieldValue('dnaUploadedStatus', '');
    formik.setFieldValue('dnaSampleStatus', '');
    formik.setFieldValue('organizationIds', []);
    clearReportsFilter();
  };

  return { formik, clearFormAndSearchParams };
};

export default useReportsFilter;
