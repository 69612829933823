import { EHRStatus } from 'api/User/types';
import { RootState } from 'store';

import React from 'react';
import { ClickAwayListener, Popover, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

interface Props {
  anchorEl: HTMLElement;
  userId: number;
  onClose: () => void;
}

const EHRStatusPopover:React.FC<Props> = ({ anchorEl, userId, onClose }) => {
  const { users } = useSelector((store: RootState) => store.users);
  const user = users.find(user => user.id === userId);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 1,
          sx: { padding: 1.5, maxWidth: '420px' },
        }}
        onClose={onClose}
        data-testid="popover-ehr-status"
        disableRestoreFocus
      >
        {user?.ehrStatus === EHRStatus.CAN_NOT_IMPORT && (
          <Typography variant="textSRegular" component="div">
            Country is not US or zip code is not valid
          </Typography>
        )}
        {user?.hgPipelineError && (
          <Typography variant="textSRegular" component="div">
            {user.hgPipelineError}
          </Typography>
        )}
      </Popover>
    </ClickAwayListener>
  );
};

export default EHRStatusPopover;
