export const LANDING_ROUTE = '/';
export const PARTNERS_ROUTE = '/partners';
export const PARTNERS_CONTACTUS_ROUTE = `${PARTNERS_ROUTE}#contactUs`;

export const DASHBOARD_ROUTE = '/dashboard';
export const SIGNUP_ROUTE = `${DASHBOARD_ROUTE}/signup`;
export const PROFILE_ROUTE = `${DASHBOARD_ROUTE}/profile`;
export const SURVEY_ROUTE = `${DASHBOARD_ROUTE}/survey`;
export const SHIPPING_ROUTE = `${DASHBOARD_ROUTE}/shipping`;
export const ANCESTRY_ROUTE = `${DASHBOARD_ROUTE}/ancestry`;
export const SETTINGS_ROUTE = `${DASHBOARD_ROUTE}/settings`;
export const VIEW_CONSENT_ROUTE = `${DASHBOARD_ROUTE}/view-consent`;

export const ADMIN_DASHBOARD_ROUTE = '/admin-dashboard';
export const ADMIN_DASHBOARD_USERS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/users`;
export const ADMIN_DASHBOARD_USERS_EDIT_ROUTE = (userId: number) => `${ADMIN_DASHBOARD_USERS_ROUTE}/${userId}`;
export const ADMIN_DASHBOARD_USERS_ADD_ROUTE = `${ADMIN_DASHBOARD_USERS_ROUTE}/add`;
export const ADMIN_DASHBOARD_CLINICAL_PATIENTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/clinical-patients`;
export const ADMIN_DASHBOARD_CLINICAL_PATIENTS_EDIT_ROUTE = (userId: number) => `${ADMIN_DASHBOARD_CLINICAL_PATIENTS_ROUTE}/${userId}`;
export const ADMIN_DASHBOARD_CLINICAL_PATIENTS_ADD_ROUTE = `${ADMIN_DASHBOARD_CLINICAL_PATIENTS_ROUTE}/add`;
export const ADMIN_DASHBOARD_NEWS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/news`;
export const ADMIN_DASHBOARD_REPORTS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/reports`;
export const ADMIN_DASHBOARD_SHIPPING_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/shipping`;
export const ADMIN_DASHBOARD_ADMIN_ROLES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/admin-roles`;
export const ADMIN_DASHBOARD_ORGANIZATIONS_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/organizations`;
export const ADMIN_DASHBOARD_SAMPLES_ROUTE = `${ADMIN_DASHBOARD_ROUTE}/samples`;
