import { ivory, purple200 } from 'common/constants/colors';

import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Hint: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        color={ivory}
        sx={{
          fontFamily: 'Montserrat Alternates',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: { xs: '36px', lg: '48px' },
          lineHeight: '150%',
          letterSpacing: '-0.05em',
        }}
      >
        {t('landing.points.see_how_can_help')}
      </Typography>
      <Typography
        color={purple200}
        sx={{
          fontFamily: 'Satoshi',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '18px',
          lineHeight: '150%',
          maxWidth: '400px',
        }}
      >
        {t('landing.welcome.explore_studies')}
      </Typography>
    </>
  );
};

export default Hint;
