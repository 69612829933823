import { ReduxDispatch } from 'store';
import { SET_DIRECT_SHIPPING_STATUS, SET_RETURN_SHIPPING_STATUS, SET_SHIPPING_LOADING, SET_TRANSACTIONS } from 'store/shipping/types';
import { getTrack, getTransactions } from 'api/Shipment/api';
import { Transactions } from 'api/Shipment/types';
import { isProduction } from 'common/utils/isProduction';

export const getMyShippings = (getDirectTrack: boolean = true, getReturnTrack: boolean = true) => (dispatch: ReduxDispatch) => {
  dispatch({ type: SET_SHIPPING_LOADING, value: true });

  // get transaction objects
  getTransactions()
    .then(({ data }) => {
      dispatch(setTransactions({ ...data }));

      // get return track
      if (isProduction() && data.returnTransaction && getReturnTrack) {
        getTrack({ carrier: 'usps', trackingNumber: data.returnTransaction.trackingNumber })
          .then(({ data }) => {
            dispatch({ type: SET_RETURN_SHIPPING_STATUS, data: data.trackingStatus });
          });
      }
      if (isProduction() && data.directTransaction && getDirectTrack) {
        getTrack({ carrier: 'usps', trackingNumber: data.directTransaction.trackingNumber })
          .then(({ data }) => {
            dispatch({ type: SET_DIRECT_SHIPPING_STATUS, data: data.trackingStatus });
          });
      }
    })
    .catch(() => {})
    .finally(() => {
      dispatch({ type: SET_SHIPPING_LOADING, value: false });
    });
};

export const getReturnTrack = (status: string) => (dispatch: ReduxDispatch) => {
  getTrack({ carrier: 'shippo', trackingNumber: `SHIPPO_${status}` })
    .then(({ data }) => {
      dispatch({ type: SET_RETURN_SHIPPING_STATUS, data: data.trackingStatus });
    });
};

export const getDirectTrack = (status: string) => (dispatch: ReduxDispatch) => {
  getTrack({ carrier: 'shippo', trackingNumber: `SHIPPO_${status}` })
    .then(({ data }) => {
      dispatch({ type: SET_DIRECT_SHIPPING_STATUS, data: data.trackingStatus });
    });
};

export const setTransactions = (data: Transactions) => ({
  type: SET_TRANSACTIONS,
  data,
});
