export const SET_USERS = 'SET_USERS';
export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export const SET_USERS_ERROR = 'SET_USERS_ERROR';
export const GET_USER_PIPELINE_EXECUTION = 'GET_USER_PIPELINE_EXECUTION';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ORGANIZATION = 'UPDATE_USER_ORGANIZATION';
export const RESTART_EHR_FLOW = 'RESTART_EHR_FLOW';
export const UPDATE_TEST_KIT_RECEIVED = 'UPDATE_TEST_KIT_RECEIVED';
export const CREATE_SAMPLE = 'CREATE_SAMPLE';
export const REMOVE_SAMPLE = 'REMOVE_SAMPLE';
