import React from 'react';
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const AuthorizationContainer: React.FC<Props> = ({ children, ...props }) => (
  <Box
    sx={{ backgroundColor: '#fff' }}
    display="flex"
    alignItems="center"
    justifyContent="center"
    margin={{ xs: 'auto 16px', sm: 'auto' }}
    flexDirection="column"
    borderRadius={{ xs: '20px', sm: '30px' }}
    minWidth={{ sm: 600 }}
    maxWidth={{ sm: 632 }}
    padding={{ xs: '40px 16px', sm: '64px 100px' }}
    {...props}
  >
    {children}
  </Box>
);

export default AuthorizationContainer;
