import HealthInfoQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HealthInfoQuestion/HealthInfoQuestion';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';

import React from 'react';

const DISEASES = [
  'Rheumatoid arthritis',
  'Psoriatic arthritis',
  'Ankylosing spondylitis',
  'Uveitis',
  'Systemic lupus erythematosus',
  'Granulomatosis with polyangiitis',
  'Other',
];

const RheumaticDiseaseQuestion: React.FC<QuestionPageProps> = ({ onNext, onPrev, onSubmit }) => {
  return <HealthInfoQuestion onPrev={onPrev} onNext={onNext} onSubmit={onSubmit} field="rheumaticDisease" diseases={DISEASES} />;
};

export default RheumaticDiseaseQuestion;
