export const getShippingCardLabel = (progress: number) => {
  if (progress === 0 || progress === -1) {
    return 'dashboard.shipping.card_button_request_a_kit';
  }

  if (progress === 100) {
    return 'dashboard.shipping.card_button_completed';
  }

  return 'dashboard.shipping.card_button_shipping_status';
};
