import ProgressBar from 'components/Dashboard/components/ProgressBar/ProgressBar';
import useSurvey from 'components/Dashboard/components/SurveyPage/hooks/useSurvey';
import Completed from 'components/Dashboard/components/SurveyPage/components/Completed/Completed';
import { MONTSERRAT_ALTERNATES } from 'common/constants/fonts';
import { midnight, white } from 'common/constants/colors';
import { RootState } from 'store';
import { SURVEY_QUESTIONS_V1 } from 'components/Dashboard/components/SurveyPage/questions/v1';
import { SURVEY_QUESTIONS_V2 } from 'components/Dashboard/components/SurveyPage/questions/v2';
import { TranslationsKey } from 'i18nextOptions';
import { AmplitudeEvent } from 'common/constants/amplitude';

import React, { useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { LogOnMount } from 'react-amplitude-hooks';

const SurveyPage: React.FC = () => {
  const { t } = useTranslation();
  const { survey, surveyVersion } = useSelector((store: RootState) => store.user.myUser);
  const questions = surveyVersion === 1 ? SURVEY_QUESTIONS_V1 : SURVEY_QUESTIONS_V2;

  const {
    currentQuestionIndex,
    currentQuestion,
    submitQuestion,
    getNextQuestion,
    getPrevQuestion,
    hasNextQuestion,
    surveyCompletedPercentage,
  } = useSurvey(questions);

  const QuestionComponent = currentQuestion?.component;
  const hasQuestions = currentQuestion && surveyCompletedPercentage !== 100;

  const onNextClick = useCallback(() => {
    if (currentQuestionIndex !== questions.length - 1) {
      getNextQuestion(survey!);
    }
  }, [ currentQuestionIndex, getNextQuestion, survey, questions ]);
  const onPrevClick = useCallback(() => {
    if (currentQuestionIndex !== 0) {
      getPrevQuestion(survey!);
    }
  }, [ currentQuestionIndex, getPrevQuestion, survey ]);

  return (
    <LogOnMount eventType={AmplitudeEvent.SURVEY_VISITED}>
      <Grid
        display="flex"
        flexDirection="column"
        padding={{ xs: '40px 16px', sm: 4, xl: 5 }}
        color={midnight}
        borderRadius={{ xs: '20px', sm: '30px' }}
        width="100%"
        minHeight="600px"
        sx={{ background: white }}
        data-testid="survey-page"
      >
        <Grid paddingBottom={{ xs: 6, md: 5 }} display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between">
          <Grid display="flex" justifyContent="space-between">
            <Typography
              color="primary"
              sx={{
                fontFamily: MONTSERRAT_ALTERNATES,
                fontWeight: 400,
                fontSize: { xs: '18px', md: '28px' },
                lineHeight: '170%',
                letterSpacing: '-0.05em',
                paddingBottom: { xs: 3, md: 0 },
              }}
            >
              <b>{hasQuestions ? `${t('dashboard.survey.survey')}: ` : t('dashboard.survey.survey_status_completed')}</b>
              {hasQuestions ? t(`dashboard.survey.categories.${currentQuestion.category}` as TranslationsKey) : null}
            </Typography>
            {currentQuestion && (
              <Grid display={{ xs: 'flex', md: 'none' }}>
                <Button
                  onClick={onPrevClick}
                  variant="text"
                  color="primary"
                  size="small"
                  disabled={!hasQuestions || currentQuestionIndex === 0}
                  sx={{ padding: '8', minWidth: 0, height: '24px' }}
                  data-testid="button-prev-question"
                >
                  <ArrowBackIosIcon fontSize="small" />
                </Button>
                <Button
                  onClick={onNextClick}
                  variant="text"
                  color="primary"
                  disabled={!hasQuestions || !hasNextQuestion}
                  sx={{ padding: '8', minWidth: 0, height: '24px' }}
                  data-testid="button-next-question"
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid display="flex" alignItems={{ md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
            <ProgressBar horizontal={false} progress={surveyCompletedPercentage} />
            {currentQuestion && (
              <Grid display={{ xs: 'none', md: 'flex' }}>
                <Button
                  onClick={onPrevClick}
                  variant="text"
                  color="primary"
                  size="small"
                  disabled={!hasQuestions || currentQuestionIndex === 0}
                  sx={{ padding: '8', minWidth: 0, height: '24px' }}
                >
                  <ArrowBackIosIcon fontSize="small" />
                </Button>
                <Button
                  onClick={onNextClick}
                  variant="text"
                  color="primary"
                  disabled={!hasQuestions || !hasNextQuestion}
                  sx={{ padding: '8', minWidth: 0, height: '24px' }}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid display="flex" flexDirection="column" justifyContent="center" flexGrow={1} marginBottom={{ xs: '124.5px', md: '87.5px' }}>
          {hasQuestions ? (
            <QuestionComponent
              onPrev={getPrevQuestion}
              onNext={getNextQuestion}
              onSubmit={submitQuestion}
            />
          ) : (
            <Completed />
          )}
        </Grid>
      </Grid>
    </LogOnMount>
  );
};

export default SurveyPage;
