import styles from 'components/Dashboard/components/ProgressBar/ProgressBar.module.css';

import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';

interface Props {
  horizontal: boolean
  progress: number
}

const ProgressBar = ({ horizontal, progress }: Props) => {
  const { t } = useTranslation();
  
  const getLabel = (progress: number) => {
    if (!progress) {
      return t('dashboard.progress_bar.status_not_started');
    }
    if (progress > 0) {
      return t('dashboard.progress_bar.status_progress', { progress });
    }
    return t('dashboard.progress_bar.status_locked');
  };

  return (
    <div className={horizontal ? styles.horizontal : styles.vertical}>
      <LinearProgress classes={{ bar: styles.bar, determinate: styles.progress }} variant="determinate" value={progress} />
      <span className={styles.percents}>{getLabel(progress)}</span>
    </div>
  );
};

export default ProgressBar;
