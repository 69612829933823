import { Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';

interface Props {
  title: string;
  description: string;
  renderImg: () => ReactElement<any, any>;
}

const Step: React.FC<Props> = ({ title, description, renderImg }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth={340}
      sx={{
        alignItems: { xs: 'center' },
        textAlign: { xs: 'center', lg: 'left' },
        margin: { xs: '16px', lg: '0' },
      }}
    >
      <Paper
        sx={{
          width: '200px',
          height: '200px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
        }}
        elevation={2}
      >
        {renderImg()}
      </Paper>
      <Typography
        color="purple.main"
        sx={{
          fontFamily: 'Montserrat Alternates',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '30px',
          lineHeight: '125%',
          letterSpacing: '-0.05em',
          marginTop: '28px',
        }}
      >
        {title}
      </Typography>
      <Typography
        color="purple.main"
        sx={{
          fontFamily: 'Satoshi',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '18px',
          lineHeight: '150%',
          marginTop: '11px',
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default Step;
