import { DashboardNavLinkRoot } from 'common/components/DashboardNavLink/DashboardNavLinkStyle';

import { useLocation } from 'react-router-dom';
import React from 'react';

interface Props {
  exact?: boolean;
  to: string;
}

const DashboardNavLink: React.FC<Props> = ({ to, exact = false, children }) => {
  const location = useLocation();
  const isLinkActive = (path: string, exact?: boolean) => {
    if (exact) {
      return location.pathname === path;
    }

    return location.pathname.includes(to);
  };

  return (
    <DashboardNavLinkRoot
      to={to}
      ownerState={{ isActive: isLinkActive(to, exact) }}
    >
      {children}
    </DashboardNavLinkRoot>
  );
};

export default DashboardNavLink;
