import { AncestryProvider } from 'api/Reports/types';
import { AncestryFile } from 'api/User/types';
import { RootState } from 'store';
import TickImg from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/resources/tick.svg';
import FileImg from 'components/Dashboard/components/AncestryPage/resources/file.svg';
import AncestryReportView from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/AncestryReportView';
import ProviderLogo from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/ProviderLogo/ProviderLogo';
import { getReportStatus } from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/helpers';
import { isDeliveredStatus } from 'common/helpers/shipping';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DateTime } from 'luxon';
import React from 'react';

const AncestryFilesView = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { myUser: { testKitReceived, ancestries, ancestryFiles } } = useSelector((store: RootState) => store.user);
  const { returnShippingStatus } = useSelector((store: RootState) => store.shipping);

  const needToAddWGSFile = testKitReceived && !ancestryFiles.find(a => a.providerName === AncestryProvider.WHOLE_GENOME_SEQUENCING);
  const files: AncestryFile[] = needToAddWGSFile ? [
    { id: AncestryProvider.WHOLE_GENOME_SEQUENCING, providerName: AncestryProvider.WHOLE_GENOME_SEQUENCING, sourceFileUploadedAt: '' },
    ...ancestryFiles,
  ] : ancestryFiles;

  const hasAncestry = Boolean(ancestries.length);
  const hasReturnShipping = Boolean(returnShippingStatus);
  const reportInProgress = files.length > 0 || isDeliveredStatus(returnShippingStatus?.status);

  const needReportTabs = hasAncestry || hasReturnShipping || reportInProgress;

  const reportStatus = getReportStatus(reportInProgress, hasReturnShipping);

  const renderUploadedFiles = (files: AncestryFile[]) => files.map((file) => {
    return (
      <Grid item xs={12} sm={6} md={4} key={file.id}>
        <Stack
          width="100%"
          height={200}
          alignItems="center"
          padding={1}
          sx={{
            border: `2px solid  ${theme.palette.neutral[40]}`,
            borderRadius: '6px',
          }}
        >
          <Box display="flex" flexGrow={1} alignItems="center">
            <ProviderLogo provider={file.providerName} />
          </Box>
          <Stack direction="row" justifyContent="center" spacing={1.5}>
            <img src={TickImg} alt="" />
            <Typography>
              {t('dashboard.ancestry.status_uploaded')}
            </Typography>
          </Stack>
          <Typography variant="textSRegular">
            {file.sourceFileUploadedAt && DateTime.fromISO(file.sourceFileUploadedAt).toFormat('MM/dd/yyyy')}
          </Typography>
        </Stack>
      </Grid>
    );
  });

  return (
    <Grid container spacing={4} data-testid="ancestry-files-view">
      {needReportTabs && (
        <Grid item>
          <AncestryReportView status={reportStatus} />
          {/* https://app.clickup.com/t/8669pvamj */}
          {/* <PRSReportView status={reportStatus} /> */}
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="textXLBold" color="neutral.90">
          {files.length ?
            t('dashboard.ancestry.sources') :
            t('dashboard.ancestry.there_is_no_sources')}
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        {files.length ?
          renderUploadedFiles(files) : (
            <Grid item>
              <Box paddingLeft={2} paddingTop={5} paddingBottom={5}>
                <img src={FileImg} width={90} height={90} alt="" />
              </Box>
            </Grid>
          )}
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={() => navigate('add')}
          startIcon={<AddCircleOutlineIcon />}
          data-testid="button-add"
        >
          {t('dashboard.ancestry.button_add_data')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AncestryFilesView;
