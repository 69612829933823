import EditKitModal from 'components/AdminDashboard/components/ShippingStatusPage/components/EditKitModal/EditKitModal';
import RenewShippingLabelsModal from 'components/AdminDashboard/components/ShippingStatusPage/components/RenewShippingLabelsModal/RenewShippingLabelsModal';
import ChangeTestKitReceivedModal from 'common/components/ChangeTestKitReceivedModal/ChangeTestKitReceivedModal';
import { useUserKits } from 'components/AdminDashboard/components/ShippingStatusPage/hooks';
import { UserWithShipping, WithKit } from 'api/UserKits/types';
import {
  getCurrentShipping,
  getDirectionLabel,
  getStatusLabel,
  isRenewingAllowed,
  isDeliveredFromUser,
} from 'components/AdminDashboard/components/ShippingStatusPage/helpers';
import { ShippingStatus, Transactions } from 'api/Shipment/types';
import ShippingStatusPopover from 'components/AdminDashboard/components/ShippingStatusPage/components/ShippingStatusPopover/ShippingStatusPopover';
import OverviewStats from 'components/AdminDashboard/components/ShippingStatusPage/components/OverviewStats/OverviewStats';
import SearchUserInput from 'common/components/SearchUserInput/SearchUserInput';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import DataTable from 'common/components/DataTable/DataTable';
import { HeadCell } from 'common/types/MuiComponents';
import { formatDateTime } from 'common/helpers/dateTime';

import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, IconButton, Paper, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Refresh, Edit, CallMade, CallReceived } from '@mui/icons-material';

const ShippingStatusPage: React.FC = () => {
  const {
    requestUserKits,
    requestTransactionsOverview,
    userKits,
    transactionsOverview,
    pagination,
    setUserKits,
    isLoading,
    isOverviewLoading,
  } = useUserKits();

  const [ searchValue, setSearchValue ] = useState('');

  const [ shippingPopoverAnchorEl, setShippingPopoverAnchorEl ] = useState<HTMLElement | null>(null);
  const [ shippingPopoverStatus, setShippingPopoverStatus ] = useState<ShippingStatus | null>(null);

  const [ editKitEntity, setEditKitEntity ] = useState<UserWithShipping | null>(null);
  const [ renewKitEntity, setRenewKitEntity ] = useState<WithKit | null>(null);
  const [ changeTestKitReceivedUserId, setChangeTestKitReceivedUserId ] = useState<number | null>(null);

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'requestDate',
      numeric: false,
      label: 'Request date',
    },
    {
      id: 'userId',
      numeric: false,
      label: 'User id',
    },
    {
      id: 'user',
      numeric: false,
      label: 'User',
      button: <SearchUserInput onChange={e => setSearchValue(e.target.value)} />,
    },
    {
      id: 'shippingStatus',
      numeric: true,
      label: 'Shipping Status',
    },
    {
      id: 'kitId',
      numeric: true,
      label: 'Kit id',
    },
    {
      id: 'testKitReceived',
      numeric: false,
      label: 'Test Kit Received',
    },
  ], []);

  const handleKitChanged = (userId: number, kitId: string) => {
    const kitIndex = userKits.findIndex(item => item.user.id === userId);

    setUserKits([
      ...userKits.slice(0, kitIndex),
      { ...userKits[kitIndex], user: { ...userKits[kitIndex].user, kitId } },
      ...userKits.slice(kitIndex + 1, userKits.length),
    ]);

    setEditKitEntity(null);
  };

  const handleLabelsChanged = (userId: number, transactions: Transactions) => {
    const kitIndex = userKits.findIndex(item => item.user.id === userId);

    setUserKits([
      ...userKits.slice(0, kitIndex),
      { ...userKits[kitIndex], transactions },
      ...userKits.slice(kitIndex + 1, userKits.length),
    ]);

    setRenewKitEntity(null);
  };

  const handleTestKitReceivedChanged = (userId: number, testKitReceived: boolean) => {
    const kitIndex = userKits.findIndex(item => item.user.id === userId);

    setUserKits([
      ...userKits.slice(0, kitIndex),
      { ...userKits[kitIndex], user: { ...userKits[kitIndex].user, testKitReceived } },
      ...userKits.slice(kitIndex + 1, userKits.length),
    ]);

    setChangeTestKitReceivedUserId(null);
  };

  const onEdit = useCallback((index: number) => {
    setEditKitEntity(userKits[index].user);
  }, [ userKits, setEditKitEntity ]);

  const onRenew = useCallback((index: number) => {
    setRenewKitEntity(userKits[index]);
  }, [ userKits, setRenewKitEntity ]);

  const onChangeTestKitReceived = useCallback((index: number) => {
    setChangeTestKitReceivedUserId(userKits[index].user.id);
  }, [ userKits, setChangeTestKitReceivedUserId ]);

  useEffect(() => {
    requestTransactionsOverview();
  }, [ requestTransactionsOverview ]);

  const handleShippingPopoverShow = (event: React.MouseEvent<HTMLElement>, shippingStatus: ShippingStatus | null) => {
    setShippingPopoverAnchorEl(event.currentTarget);
    setShippingPopoverStatus(shippingStatus);
  };
  const handleShippingPopoverClose = () => {
    setShippingPopoverAnchorEl(null);
    setShippingPopoverStatus(null);
  };

  const renderRows = useCallback(() => userKits
    .map((row, index: number) => {
      const currentShipping = getCurrentShipping(row.user);
      const isReceivedFromUser = isDeliveredFromUser(currentShipping);
      const testKitReceived = isReceivedFromUser || !!row.user.testKitReceived;

      return (
        <TableRow hover tabIndex={-1} key={row.user.username}>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>
            {formatDateTime(row?.transactions?.directTransaction?.objectCreated || row?.transactions?.returnTransaction?.objectCreated)}
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {row.user.id}
            </Typography>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            padding="normal"
          >
            <Typography variant="textSRegular" component="div">
              {`${row.user.firstName} ${row.user.lastName}`}
            </Typography>
            <Typography variant="textSRegular" component="div">
              {row.user.username}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography
              variant="textSRegular"
              component="div"
              sx={{
                textDecorationStyle: 'dashed',
                textDecorationLine: 'underline',
                textDecorationColor: 'gray',
                textUnderlineOffset: '2px',
                cursor: 'pointer',
              }}
              onClick={(e: MouseEvent<HTMLElement>) => handleShippingPopoverShow(e, currentShipping.status)}
              data-testid="label-status-hint"
            >
              {getStatusLabel(currentShipping.status)}
            </Typography>
            <Typography variant="textSRegular" component="div">
              {getDirectionLabel(currentShipping.direction)}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontFamily: 'Red Hat Mono',
                whiteSpace: 'nowrap',
              }}
            >
              {row.user.kitId}
            </Typography>
            <Tooltip title="Edit test kit id">
              <IconButton color="primary" size="small" onClick={() => onEdit(index)} data-testid="button-edit">
                <Edit />
              </IconButton>
            </Tooltip>
            {row.transactions.directTransaction && (
              <Tooltip title="Open direct shipping label">
                <IconButton size="small" color="primary" onClick={() => window.open(row.transactions.directTransaction!.labelURL)}>
                  <CallMade />
                </IconButton>
              </Tooltip>
            )}
            {row.transactions.returnTransaction && (
              <Tooltip title="Open return shipping label">
                <IconButton color="primary" size="small" onClick={() => window.open(row.transactions.returnTransaction!.labelURL)}>
                  <CallReceived />
                </IconButton>
              </Tooltip>
            )}
            {isRenewingAllowed(currentShipping) && (
              <Tooltip title="Renew labels">
                <IconButton color="primary" size="small" onClick={() => onRenew(index)} data-testid="button-renew">
                  <Refresh />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
          <TableCell>
            <Checkbox
              disabled={isReceivedFromUser}
              onChange={() => onChangeTestKitReceived(index)}
              checked={testKitReceived}
              value={testKitReceived}
            />
          </TableCell>
        </TableRow>
      );
    }), [ userKits, onEdit, onRenew, onChangeTestKitReceived ]);

  return (
    <LoadingContainer isLoading={isLoading || isOverviewLoading}>
      <OverviewStats overview={transactionsOverview} />
      <Box data-testid="shipping-status-page">
        <Paper sx={{ maxWidth: '100%' }}>
          <DataTable
            pagination={pagination}
            headCells={headCells}
            renderRows={renderRows}
            searchValue={searchValue}
            updateDataCallback={requestUserKits}
          />
        </Paper>
        {editKitEntity &&
        <EditKitModal onCancel={() => setEditKitEntity(null)} onSave={handleKitChanged} userWithShipping={editKitEntity} />}
        {renewKitEntity && (
          <RenewShippingLabelsModal onCancel={() => setRenewKitEntity(null)} onSave={handleLabelsChanged} userWithKit={renewKitEntity} />
        )}
        {shippingPopoverAnchorEl && (
          <ShippingStatusPopover anchorEl={shippingPopoverAnchorEl} shippingPopoverStatus={shippingPopoverStatus} onClose={handleShippingPopoverClose} />
        )}
        {changeTestKitReceivedUserId && (
          <ChangeTestKitReceivedModal
            onCancel={() => setChangeTestKitReceivedUserId(null)}
            onSave={handleTestKitReceivedChanged}
            userId={changeTestKitReceivedUserId}
          />
        )}
      </Box>
    </LoadingContainer>
  );
};

export default ShippingStatusPage;
