import HaveKitImage from 'components/Dashboard/components/ShippingPage/components/Instructions/resources/havekit.svg';
import DontHaveKitImage from 'components/Dashboard/components/ShippingPage/components/Instructions/resources/donthavekit.svg';
import styles from 'components/Dashboard/components/ShippingPage/components/Instructions/Instructions.module.css';

import { Button, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChosen: (flow: number) => void;
}

const Instructions: React.FC<Props> = ({ onChosen }) => {
  const { t } = useTranslation();
  
  return (
    <Grid container justifyContent="center" spacing={{ xs: 5, md: 3 }}>
      <Grid container item xs={12} md={6} justifyContent="center" alignItems="center" spacing={3}>
        <Grid item>
          <img src={HaveKitImage} alt="" />
        </Grid>
        <Grid item>
          <div className={styles.title}>{t('dashboard.shipping.instruction.title_have_kit')}</div>
          <div className={styles.description}>
            {t('dashboard.shipping.instruction.description_have_kit')}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={() => onChosen(2)} fullWidth data-testid="button-have-kit">
            {t('dashboard.shipping.button_have_kit')}
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} justifyContent="center" alignItems="center" spacing={3} rowSpacing={{ xs: 6, md: 3 }}>
        <Grid item>
          <img src={DontHaveKitImage} alt="" />
        </Grid>
        <Grid item>
          <div className={styles.title}>{t('dashboard.shipping.instruction.title_dont_have_kit')}</div>
          <div className={styles.description}>
            {t('dashboard.shipping.instruction.description_dont_have_kit')}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={() => onChosen(3)} fullWidth>
            {t('dashboard.shipping.button_dont_have_kit')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Instructions;
