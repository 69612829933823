import { postDiscardAgreement } from 'api/Auth/api';
import { LANDING_ROUTE } from 'common/constants/urls';
import { RESET_USER } from 'store/user/types';
import { NOT_AUTHORIZED } from 'store/auth/types';

import React, { ChangeEvent, useState } from 'react';
import { Box, Button, Grid, Modal, Paper, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void;
}

const RevokeConsentPopover: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation();

  const confirmText = t('dashboard.settings.revoke_dialog.delete_confirm_text').toUpperCase();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ confirmValue, setConfirmValue ] = useState('');
  const [ error, setError ] = useState('');

  const handleConfirmChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmValue(e.target.value.toUpperCase());
  };

  const handleConfirm = () => {
    postDiscardAgreement()
      .then(() => {
        dispatch({ type: RESET_USER });
        dispatch({ type: NOT_AUTHORIZED });
        navigate(LANDING_ROUTE, { state: { userRemoved: true } });
      })
      .catch((error) => {
        setError(error?.response?.data?.message || t('errors.server_error_try_again'));
      });
  };

  const confirmDisabled = confirmText !== confirmValue;

  return (
    <Modal
      BackdropProps={{
        invisible: false,
        sx: {
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          root: {
            '&:focus': {
              outline: 'none',
            },
          },
        },
      }}
      open
      data-testid="popover-revoke-consent"
    >
      <Paper
        sx={{
          borderRadius: '30px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
        }}
      >
        <Grid container padding={{ xs: 3, md: 5 }} spacing={{ xs: 2, md: 5 }} maxWidth={652}>
          <Grid item xs={12}>
            <Typography variant="textXLBold" color="error">
              {t('dashboard.settings.revoke_dialog.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="textLMedium">
              {t('dashboard.settings.revoke_dialog.description')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box width="100%" maxWidth={380}>
              <TextField
                variant="outlined"
                size="small"
                value={confirmValue}
                onChange={handleConfirmChanged}
                inputProps={{ 'data-testid': 'input-confirm' }}
                fullWidth
              />
            </Box>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error">
                {error}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <Button variant="contained" disabled={confirmDisabled} onClick={handleConfirm} data-testid="button-confirm">
                {t('dashboard.settings.revoke_dialog.button_confirm')}
              </Button>
              <Button variant="outlined" onClick={onCancel} data-testid="button-cancel">
                {t('dashboard.settings.revoke_dialog.button_cancel')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default RevokeConsentPopover;
