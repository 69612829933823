import { midnight, purple200, purple900 } from 'common/constants/colors';
import NewsView from 'components/Landing/components/NewsView/NewsView';
import PartnersImg from 'components/Landing/components/MainContent/components/JoiningUsPage/resources/partners.svg';
import useContactUs from 'components/Landing/components/MainContent/components/JoiningUsPage/hooks/useContactUs';
import {
  CONTACT_US_BUTTON_ID,
} from 'components/Landing/components/PartnersContent/components/LetsBePartnersPage/constants';

import { Backdrop, Box, Button, Container, Fade, Grid, Hidden, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  outline: 'none',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '100%', sm: 612 },
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  padding: { xs: 2, sm: 4 },
};

const consentModalIconStyles = {
  position: 'absolute' as 'absolute',
  top: 24,
  right: 24,
  fontSize: '30px',
  cursor: 'pointer',
};

const LetsBePartnersPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [ open, setOpen ] = React.useState(false);
  const { formik, isLoading, submitError, isSuccess } = useContactUs();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.setFieldValue('message', '');
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [ isSuccess ]);

  useEffect(() => {
    if (location.hash === `#${CONTACT_US_BUTTON_ID}`) {
      handleOpen();
    }
  }, [ location ]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: midnight,
          paddingBottom: { xs: '240px', lg: '430px' },
        }}
      />
      <Box
        position="relative"
        sx={{ background: `linear-gradient(0deg, ${purple900} 0%, ${midnight} 100%)` }}
        paddingBottom={20}
      >
        <Container maxWidth="xl">
          <Box
            position="relative"
            sx={{
              top: { xs: '-120px', lg: '-300px' },
              marginBottom: { xs: '-120px', lg: '-300px' },
            }}
          >
            <NewsView />
          </Box>
          <Typography
            color="white"
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '40px',
              lineHeight: '125%',
              letterSpacing: '-0.05em',
              marginTop: 7.5,
            }}
          >
            {t('landing.partners.lets_be_partners.title')}
          </Typography>
          <Typography
            color={purple200}
            sx={{
              fontFamily: 'Satoshi',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '24px',
              lineHeight: '175%',
              marginTop: 6,
              maxWidth: '780px',
            }}
          >
            {t('landing.partners.lets_be_partners.body')}
          </Typography>
          <Hidden lgDown>
            <Box
              position="absolute"
              bottom="58px"
              right="60px"
              display="flex"
              flexDirection="row-reverse"
            >
              <Box sx={{ width: { lg: '85%', xl: '100%' } }}>
                <img src={PartnersImg} alt="" width="100%" />
              </Box>
            </Box>
          </Hidden>
          <Box marginTop={8}>
            <Button
              id={CONTACT_US_BUTTON_ID}
              variant="contained"
              color="orange"
              size="large"
              onClick={handleOpen}
              sx={{ width: '320px' }}
            >
              {t('landing.partners.lets_be_partners.contact_button')}
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Close onClick={handleClose} sx={consentModalIconStyles} />
                  <Typography variant="h6" component="h2">
                    {t('landing.contact_dialog.title')}
                  </Typography>
                  <Typography paddingBottom={2} sx={{ mt: 2 }}>
                    {t('landing.contact_dialog.body')}
                  </Typography>
                  <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                      <TextField
                        type="email"
                        label={t('general.email')}
                        name="email"
                        size="small"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        sx={{ paddingBottom: 2 }}
                        error={formik.touched.email && !!formik.errors.email}
                        helperText={formik.touched.email && formik.errors.email}
                        variant="outlined"
                        fullWidth
                      />
                      <TextField
                        label={t('general.message')}
                        name="message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        multiline
                        error={formik.touched.message && !!formik.errors.message}
                        helperText={formik.touched.message && formik.errors.message}
                        rows={4}
                        sx={{ paddingBottom: 2 }}
                        variant="outlined"
                        fullWidth
                      />
                      <LoadingButton loading={isLoading} disabled={!formik.values.message || !formik.values.email} type="submit" variant="contained">
                        {t('general.submit')}
                      </LoadingButton>
                      {submitError && (
                        <Grid item xs={12}>
                          <Typography color="error" paddingTop={2}>
                            {submitError}
                          </Typography>
                        </Grid>
                      )}
                    </form>
                  </FormikProvider>
                </Box>
              </Fade>
            </Modal>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LetsBePartnersPage;
