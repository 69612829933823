import useUploadAncestryReport from 'components/AdminDashboard/components/ReportsPage/components/UploadReportModal/hooks';
import FileInput from 'common/components/FileInput/FileInput';
import { formatDate } from 'common/helpers/dateTime';
import { Ancestry, AncestryProvider } from 'api/Reports/types';
import { AncestryFile } from 'api/User/types';

import React from 'react';
import { Button, FormControl, Grid, MenuItem, Modal, Paper, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormikProvider } from 'formik';

interface Props {
  userId: number;
  files: AncestryFile[];
  onCancel: () => void;
  onUpdate: (userId: number, ancestries: Ancestry[]) => void;
}

const UploadReportModal: React.FC<Props> = ({ userId, onCancel, onUpdate, files }) => {
  const { formik, isSubmitting, submitError } = useUploadAncestryReport(userId, onUpdate);
  const sources = files
    .filter((file: AncestryFile) => file.providerName !== AncestryProvider.WHOLE_GENOME_SEQUENCING)
    .map((file: AncestryFile) => {
      const date = file.sourceFileUploadedAt ? formatDate(file.sourceFileUploadedAt) : '';
      return {
        id: file.id,
        label: `${file.providerName} ${date}`,
      };
    });

  return (
    <Modal open onClose={onCancel}>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '640px' },
        }}
        data-testid="upload-report-modal"
      >
        <Grid container item spacing={3} xs={12}>
          <Grid item xs={12}>
            <Typography variant="textXLBold">
              Upload ancestry report
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        id="source"
                        select
                        value={formik.values.source}
                        label="Source"
                        name="source"
                        size="small"
                        disabled={isSubmitting}
                        onChange={formik.handleChange}
                        helperText={formik.touched.source && formik.errors.source}
                        error={formik.touched.source && Boolean(formik.errors.source)}
                      >
                        <MenuItem value="wgs">Whole Genome Sequence</MenuItem>
                        {sources.map(source => (<MenuItem key={source.id} value={source.id}>{source.label}</MenuItem>))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid container spacing={2} item xs={12}>
                    <Grid item>
                      <TextField
                        value={formik.values.file ? formik.values.file.name : ''}
                        size="small"
                        disabled
                        helperText={formik.touched.file && formik.errors.file}
                        error={formik.touched.file && Boolean(formik.errors.file)}
                      />
                    </Grid>
                    <Grid item>
                      <FileInput
                        loading={isSubmitting}
                        label="Select file"
                        onChange={(newValue) => {
                          formik.setFieldValue('file', newValue);
                        }}
                        accept=".csv, .tsv"
                        ButtonProps={{
                          variant: 'contained',
                          sx: { height: '100%' },
                        }}
                      />
                    </Grid>
                  </Grid>
                  {submitError && (
                    <Grid item xs={12}>
                      <Typography color="error" variant="subtitle2">
                        {submitError}
                      </Typography>
                    </Grid>
                  )}
                  <Grid container spacing={2} item xs={12}>
                    <Grid item>
                      <LoadingButton variant="contained" type="submit" disabled={isSubmitting}>
                        Upload
                      </LoadingButton>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" onClick={onCancel} disabled={isSubmitting}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormikProvider>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default UploadReportModal;
