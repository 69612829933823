import { styled, Button } from '@mui/material';

export const ControlPanelButton = styled(Button)(() => ({
  '&:last-of-type': {
    marginLeft: '16px',
  },
  backgroundColor: 'white',
  minWidth: '110px',
  height: '40px',
  textTransform: 'none',
}));
