import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV2 } from 'api/User/types';

import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SmokerQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  const { survey } = useSelector((store: RootState) => store.user.myUser);

  const [ error, setError ] = useState('');
  const [ smoker, setSmoker ] = useState('');

  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    if (smoker) {
      submit({ ...survey, smoker })
        .then((data: Survey) => onNext(data));
    } else {
      setError(t('dashboard.survey.error_give_answer'));
    }
  }, [ t, smoker, submit, survey, onNext ]);

  const onRadioChanged = useCallback((event) => {
    setError('');
    setSmoker(event.target.value);
  }, []);

  useEffect(() => {
    setSmoker((survey as SurveyV2)?.smoker || '');
  }, [ survey ]);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-smoker">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_smoker.question')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              onChange={onRadioChanged}
              value={smoker}
            >
              <FormControlLabel value="current" control={<Radio />} label={t('dashboard.survey.question_smoker.options.current')} />
              <FormControlLabel value="former" control={<Radio />} label={t('dashboard.survey.question_smoker.options.former')} />
              <FormControlLabel value="never" control={<Radio />} label={t('dashboard.survey.question_smoker.options.never')} />
              <FormControlLabel value="unknown" control={<Radio />} label={t('dashboard.survey.question_smoker.options.unknown')} />
            </RadioGroup>
          </FormControl>
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default SmokerQuestion;
