import { NAVIGATION_ITEMS } from 'common/constants/navigation';
import { RootState } from 'store';

import React, { useCallback } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavigationMenu: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { myUser } = useSelector((store: RootState) => store.user);
  const [ anchorElNav, setAnchorElNav ] = React.useState<HTMLElement | null>(null);

  const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  }, []);

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const renderLanguageSwitcher = () => {
    const isEnglish = i18n.language === 'en';
    const toLanguage = isEnglish ? 'es' : 'en';

    return (
      <MenuItem onClick={() => i18n.changeLanguage(toLanguage)}>
        <Typography textAlign="center">
          {isEnglish ? t('general.es_lang') : t('general.en_lang')}
        </Typography>
      </MenuItem>
    );
  };
  
  const renderNavigationItems = useCallback(() => NAVIGATION_ITEMS.map((page) => {
    if (page.condition && !page.condition(myUser)) {
      return null;
    }

    return (
      <MenuItem key={page.label} data-testid={page.label} onClick={() => { navigate(page.path); handleCloseNavMenu(); }}>
        <Typography textAlign="center">
          {t(page.label)}
        </Typography>
      </MenuItem>
    );
  }), [ myUser, t, navigate, handleCloseNavMenu ]);
  
  return (
    <Box>
      <IconButton
        size="large"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="primary"
        data-testid="menu-button"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', lg: 'none' },
        }}
      >
        {renderLanguageSwitcher()}
        {renderNavigationItems()}
      </Menu>
    </Box>
  );
};

export default NavigationMenu;
