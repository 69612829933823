import LocalImg from 'common/components/ImageDropzone/components/LocalImg/LocalImg';
import ExternalImg from 'common/components/ImageDropzone/components/ExternalImg/ExternalImg';

import { Box, Typography } from '@mui/material';
import { DropzoneArea } from 'react-mui-dropzone';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import styles from './ImageDropzone.module.css';

interface Props {
  logo?: string;
  error?: string | boolean;
  id: string;
  onImageChanged: (base64: string, file?: File) => void;
}

const ImageDropzone: React.FC<Props> = ({ id, logo, onImageChanged, error }) => {
  const [ files, setFiles ] = useState<File[]>([]);

  const onDeleteFile = useCallback(() => {
    setFiles([]);
    onImageChanged('', undefined);
  }, [ onImageChanged ]);

  if (files.length > 0) {
    return (
      <LocalImg
        onDeleteFile={onDeleteFile}
        setLogo={onImageChanged}
        file={files[0]}
      />
    );
  }

  if (logo) {
    return (
      <ExternalImg
        onDeleteFile={onDeleteFile}
        setLogo={onImageChanged}
        logo={logo}
      />
    );
  }

  const dropzoneClassName = classNames(styles.dropZone, error && styles.error);

  return (
    <div id={id}>
      <DropzoneArea
        showPreviewsInDropzone={false}
        showAlerts={false}
        filesLimit={1}
        onChange={setFiles}
        acceptedFiles={[ 'image/jpeg', 'image/png' ]}
        dropzoneText="DROP YOUR FILE HERE"
        dropzoneParagraphClass={styles.label}
        dropzoneClass={dropzoneClassName}
      />
      {
        error && (
          <Box marginLeft={1.75}>
            <Typography variant="caption" color="error">{error}</Typography>
          </Box>
        )
      }
    </div>
  );
};

export default ImageDropzone;
