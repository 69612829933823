import React, { useRef } from 'react';
import { Box, ButtonProps } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  label: string;
  loading: boolean;
  onChange?: (param: File) => void;
  'data-testid'?: string;
  accept?: string;
  ButtonProps?: ButtonProps;
  ButtonComponent?: React.FC;
}

const FileInput: React.FC<Props> = ({ ButtonComponent = LoadingButton, label, onChange, loading, ButtonProps, ...props }) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (event: any) => {
    const files: File[] = Array.from(event.target.files);
    const [ file ] = files;
    if (onChange) {
      onChange(file);
    }
  };

  return (
    <Box>
      <ButtonComponent
        onClick={() => ref.current && ref.current.click()}
        data-testid={`button-${props['data-testid']}`}
        disabled={loading}
        loading={ButtonComponent === LoadingButton ? loading : undefined}
        {...ButtonProps}
      >
        {label}
      </ButtonComponent>
      <input
        ref={ref}
        type="file"
        accept=".zip, .txt"
        hidden
        onChange={handleChange}
        {...props}
        data-testid={`input-${props['data-testid']}`}
      />
    </Box>
  );
};

export default FileInput;
