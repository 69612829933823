import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const useChartData = () => {
  const { t } = useTranslation();

  const chartData = useMemo(() => ([
    {
      label: '2008',
      minAngle: 1.5,
      data: [
        { value: 0.96, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.3, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 1.01, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 97.72, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2009',
      minAngle: 1.5,
      data: [
        { value: 5.82, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.16, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 0.45, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 0.31, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 2.66, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 90.6, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2010',
      minAngle: 1.5,
      data: [
        { value: 9.1, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.4, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 0.57, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 0.05, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 1.8, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 88.08, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2011',
      minAngle: 1.5,
      data: [
        { value: 11.92, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.19, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 4.56, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 0.23, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 1.31, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 81.8, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2012',
      minAngle: 1.5,
      data: [
        { value: 12.09, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.18, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 7.92, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 1.65, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 1.8, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 76.35, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2013',
      minAngle: 1.5,
      data: [
        { value: 6.46, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.29, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 5.05, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 0.87, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 0.33, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 87, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2014',
      minAngle: 1.5,
      data: [
        { value: 13.03, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 1.26, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 2.48, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 0.94, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 0.69, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 81.6, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2015',
      minAngle: 1.5,
      data: [
        { value: 4.82, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.14, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 1.04, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 0.74, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 0.47, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 92.78, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2016',
      minAngle: 1.5,
      data: [
        { value: 2.67, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.11, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 1.68, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 1.87, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 0.12, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 93.56, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2017',
      minAngle: 1.5,
      data: [
        { value: 4.92, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.71, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 1.99, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 1.91, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 0.68, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 89.79, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2018',
      minAngle: 1.5,
      data: [
        { value: 5.39, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.53, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 0.57, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 0.47, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 0.4, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 92.63, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2019',
      minAngle: 1.5,
      data: [
        { value: 3.3, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.76, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 1.42, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 1.19, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 1.52, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 91.81, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2020',
      minAngle: 1.5,
      data: [
        { value: 10.2, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.11, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 0.33, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 0.42, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 6.16, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 82.78, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
    {
      label: '2021',
      minAngle: 1.5,
      data: [
        { value: 2.16, name: t('landing.chart_page.asian'), color: '#70AAD8' },
        { value: 0.02, name: t('landing.chart_page.african'), color: '#E89F56' },
        { value: 0.02, name: t('landing.chart_page.african_american_and_afrocaribbean'), color: '#F6CF6D' },
        { value: 0.06, name: t('landing.chart_page.hispanic_or_latin'), color: '#BA93BE' },
        { value: 0.31, name: t('landing.chart_page.other_mixed'), color: '#6FBBBD' },
        { value: 97.43, name: t('landing.chart_page.european'), color: '#D4798B' },
      ],
      dataLabels: [
        t('landing.chart_page.european'),
        t('landing.chart_page.asian'),
        t('landing.chart_page.african'),
        t('landing.chart_page.african_american_and_afrocaribbean'),
        t('landing.chart_page.hispanic_or_latin'),
        t('landing.chart_page.other_mixed'),
      ],
    },
  ]), [ t ]);

  return { chartData };
};
