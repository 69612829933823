import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV1 } from 'api/User/types';
import { TranslationsKey } from 'i18nextOptions';

import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SYMPTOMS = [
  'Fatigue', 'Headache', 'Attention disorder', 'Hair loss',
  'Ageusia (loss of taste)', 'Dyspnea (shortness of breath)',
  'Anosmia (loss of smell)', 'Post -activity polypnea (panting after exercise)',
  'Joint pain', 'Cough', 'Sweat', 'Nausea or vomiting',
  'Chest pain discomfort', 'Memory loss', 'Hearing loss or tinnitus', 'Anxiety',
  'Depression', 'Digestive disorders', 'Weight loss', 'Cutaneous signs',
  'Resting heart rate increase', 'Palpitations', 'Pain', 'Intermittent fever',
  'Sleep disorder', 'Reduced pulmonary capacity', 'Sleep apnea', 'Chills',
  'Mental health problems', 'Psychiatric Illness', 'Red eyes', 'Pulmonary fibrosis',
  'Discontinuous flushing', 'Diabetes mellitus', 'Sputum', 'Limb edema',
  'Dizziness', 'Stroke', 'Throat pain', 'Mood disorders',
  'Dysphoria (general unease or dissatisfaction)', 'Obsessive Compulsive Disorder (OCD)',
  'New hypertension', 'Myocarditis', 'Renal failure', 'Post-traumatic stress disorder (PTSD)',
  'Arrhythmia', 'Paranoia',
];

const SymptomsQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const [ values, setValues ] = useState<string[]>([]);
  const [ other, setOther ] = useState('');
  const [ error, setError ] = useState<string>('');
  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    if (!values.length || (values.includes('other') && !other)) {
      setError(t('dashboard.survey.error_give_answer'));
    } else {
      submit({
        ...survey,
        covidSymptoms: {
          values,
          other,
        },
      })
        .then((data: Survey) => onNext(data));
    }
  }, [ t, onNext, other, submit, survey, values ]);

  useEffect(() => {
    setValues((survey as SurveyV1)?.covidSymptoms?.values || []);
    setOther((survey as SurveyV1)?.covidSymptoms?.other || '');
  }, [ survey ]);

  const handleChange = useCallback((event) => {
    if (event.target.checked) {
      setValues(prevState => [ ...prevState, event.target.value ]);
    } else {
      setValues((prevState) => {
        const removedRaceIndex = prevState.indexOf(event.target.value);

        return [
          ...prevState.slice(0, removedRaceIndex),
          ...prevState.slice(removedRaceIndex + 1, prevState.length),
        ];
      });
    }
  }, []);

  const onOtherChanged = useCallback((event) => {
    setOther(event.target.value);
  }, []);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-symptoms">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="textXLBold">
            {t('dashboard.survey.question_symptoms.question')}
          </Typography>
        </Grid>
        <Grid item container>
          {SYMPTOMS.map(symptom => (
            <Grid item xs={12} sm={6} key={symptom}>
              <FormControlLabel
                control={<Checkbox onChange={handleChange} checked={values.includes(symptom)} value={symptom} />}
                label={String(t(`dashboard.survey.question_symptoms.symptoms.${symptom}` as TranslationsKey))}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <FormControlLabel
              value="other"
              control={<Checkbox onChange={handleChange} checked={values.includes('other')} value="other" />}
              label={t('general.other')}
              data-testid="option-other"
            />
          </Grid>
          <Grid item xs={12} sm>
            <TextField
              variant="outlined"
              size="small"
              color="primary"
              label={t('dashboard.survey.hint_which_ones')}
              value={other}
              onChange={onOtherChanged}
              error={Boolean(error) && values.includes('other') && !other}
              disabled={!values.includes('other')}
              fullWidth
              inputProps={{ 'data-testid': 'input-other' }}
            />
          </Grid>
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default SymptomsQuestion;
