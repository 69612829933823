import { FileFormat } from 'api/User/types';
import { UserPageProps } from 'components/AdminDashboard/components/UsersPage/types';
import { useExportUsers } from 'components/AdminDashboard/components/UsersPage/components/UsersControlPanel/components/ExportPopover/hooks';

import React from 'react';
import { Menu, MenuItem } from '@mui/material';

interface Props extends UserPageProps {
  setIsExporting: (exporting: boolean) => void;
  onClose: () => void;
  data: Element | null;
}

const ExportPopover: React.FC<Props> = ({ data, onClose, setIsExporting, isClinicalPatientPage }) => {
  const { exportUsers } = useExportUsers(setIsExporting, onClose);

  return (
    <Menu
      open={Boolean(data)}
      anchorEl={data}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MenuItem onClick={() => exportUsers(!!isClinicalPatientPage, FileFormat.CSV)} sx={{ fontSize: '14px' }}>
        Export as csv
      </MenuItem>
      <MenuItem onClick={() => exportUsers(!!isClinicalPatientPage, FileFormat.TSV)} sx={{ fontSize: '14px' }}>
        Export as tsv
      </MenuItem>
    </Menu>
  );
};

export default ExportPopover;
