import { VIEW_CONSENT_ROUTE } from 'common/constants/urls';

import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RevokeConsentPopover from './components/RevokeConsentPopover/RevokeConsentPopover';

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();
  
  const navigate = useNavigate();
  const [ isRevokeWarning, setRevokeWarning ] = useState(false);
  
  return (
    <Box
      padding={{ xs: '40px 16px', sm: 4, xl: 5 }}
      borderRadius={{ xs: '20px', sm: '30px' }}
      maxWidth="1100px"
      sx={{ backgroundColor: '#fff' }}
      height="100%"
      data-testid="settings-page"
    >
      <Typography variant="headingM">
        {t('dashboard.settings.settings')}
      </Typography>
      <Stack direction="column" alignItems="flex-start" spacing={2} marginTop="48px">
        <Button color="primary" variant="text" onClick={() => navigate(VIEW_CONSENT_ROUTE)} data-testid="button-view-consent">
          {t('dashboard.settings.view_consent')}
        </Button>
        <Button color="error" variant="text" onClick={() => setRevokeWarning(true)} data-testid="button-revoke-consent">
          {t('dashboard.settings.revoke_consent_and_delete_profile')}
        </Button>
      </Stack>
      {isRevokeWarning &&
        <RevokeConsentPopover onCancel={() => setRevokeWarning(false)} />}
    </Box>
  );
};

export default SettingsPage;
