import { grey600 } from 'common/constants/colors';
import { formatDate } from 'common/helpers/dateTime';
import { Ancestry, AncestryProvider } from 'api/Reports/types';

import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface Props {
  defaultValue: number;
  ancestries: Ancestry[];
  onChange: (e: SelectChangeEvent<number>) => void
  smallItems?: boolean
}

const AncestrySelect: React.FC<Props> = ({ defaultValue, ancestries, onChange, smallItems = false }) => {
  return (
    <Select
      id="ancestry"
      value={defaultValue}
      name="ancestry"
      size="small"
      onChange={onChange}
      sx={{ fontSize: smallItems ? '14px' : 'inherit' }}
    >
      {ancestries.map((ancestry, i) => {
        const isWGS = ancestry.providerName === AncestryProvider.WHOLE_GENOME_SEQUENCING;
        const providerName = isWGS ? 'Whole Genome' : ancestry.providerName;
        const isDividerNeeded = isWGS && ancestries.length > 1;
        const date = formatDate(ancestry.reportDate);

        return (
          <MenuItem
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${ancestry.providerName}_${ancestry.reportDate}_${i}`}
            value={i}
            sx={{
              fontSize: smallItems ? '14px' : 'inherit',
              borderTop: isDividerNeeded ? `1px solid ${grey600}` : 'none',
            }}
          >
            {`${providerName} ${date || ''}`}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default AncestrySelect;
