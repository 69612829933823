import { RootState } from 'store';
import {
  STATUS_DELIVERED,
  STATUS_FAILURE,
  STATUS_PRE_TRANSIT,
  STATUS_RETURNED,
  STATUS_TRANSIT,
} from 'api/Shipment/constants';
import { getDirectTrack, getReturnTrack } from 'store/shipping/actions';
import styles from 'components/Dashboard/components/ShippingPage/components/TrackingPage/HaveKitFlow/HaveKitFlow.module.css';
import { getUserMailingAddress } from 'common/helpers/user';
import AddressEditForm from 'components/Dashboard/components/ShippingPage/components/TrackingPage/AddressEditForm/AddressEditForm';
import TrackingStepIcon
  from 'components/Dashboard/components/ShippingPage/components/TrackingPage/TrackingStepIcon/TrackingStepIcon';
import { isErrorTrack } from 'components/Dashboard/components/ShippingPage/components/helpers';
import {
  useLocalizedDontHaveKitFlow,
  useRequestKit,
} from 'components/Dashboard/components/ShippingPage/components/TrackingPage/DontHaveKitFlow/hooks';
import { getActiveStepIndex, getTrackDescription } from 'components/Dashboard/components/ShippingPage/components/TrackingPage/DontHaveKitFlow/helpers';
import { DontHaveKitSteps } from 'components/Dashboard/components/ShippingPage/components/TrackingPage/DontHaveKitFlow/types';
import IllustrationImg from 'components/Dashboard/components/ShippingPage/components/TrackingPage/DontHaveKitFlow/resources/illustration.svg';
import { isProduction } from 'common/utils/isProduction';

import classNames from 'classnames';
import { Box, Button, Grid, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const steps = [
  {
    label: 'Requesting test kit',
    description: 'Check your mailing address and request a test kit',
  },
  {
    label: 'Processing your request',
    description: 'We’ve received your request and will ship your test kit soon.',
  },
  {
    label: 'Transit to you',
  },
  {
    label: 'Delivered to you',
  },
  {
    label: 'Collecting sample',
    description: 'Please follow the kit\'s instruction, collect the sample and ship it back to us with the label provided inside the box',
  },
  {
    label: 'Transit to BBOfA',
  },
  {
    label: 'Delivered to BBOfA',
  },
];

const DIRECT_STATUS_ORDER = [ STATUS_PRE_TRANSIT, STATUS_TRANSIT, STATUS_DELIVERED ];
const RETURN_STATUS_ORDER = [ STATUS_PRE_TRANSIT, STATUS_TRANSIT, STATUS_DELIVERED, STATUS_RETURNED, STATUS_FAILURE ];

const DontHaveKitFlow = () => {
  const { t } = useTranslation();
  const { getLocalizedLabel, getLocalizedDescription } = useLocalizedDontHaveKitFlow();

  const { returnTransaction, directTransaction } = useSelector((store: RootState) => store.shipping.transactions);
  const { directShippingStatus, returnShippingStatus } = useSelector((store: RootState) => store.shipping);
  const { myUser } = useSelector((store: RootState) => store.user);

  const { requestKit, isLoading, error } = useRequestKit();

  const [ activeStep, setActiveStep ] = React.useState(
    () => getActiveStepIndex(directTransaction, returnTransaction, directShippingStatus, returnShippingStatus),
  );

  // todo just status rotator for mocked api
  const [ currentMockedStatus, setCurrentMockedStatus ] = useState(0);

  const [ editAddress, setEditAddress ] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setActiveStep(getActiveStepIndex(directTransaction, returnTransaction, directShippingStatus, returnShippingStatus));
  }, [ returnTransaction, directShippingStatus, returnShippingStatus, directTransaction ]);

  useEffect(() => {
    if (currentMockedStatus === 0) {
      return;
    }

    // todo real statuses
    if (currentMockedStatus < 4) { // directTrack.trackingStatus.status !== TrackStatus.DELIVERED
      dispatch(getDirectTrack(DIRECT_STATUS_ORDER[currentMockedStatus - 1]));
    } else {
      dispatch(getReturnTrack(RETURN_STATUS_ORDER[currentMockedStatus - 4]));
    }
  }, [ dispatch, currentMockedStatus ]);

  const errorState = isErrorTrack(directShippingStatus) || isErrorTrack(returnShippingStatus);
  const labelClassName = classNames(styles.label, { [styles.error]: errorState });

  return (
    <Grid container item spacing={3} data-testid="dont-have-kit-flow">
      <Grid container item xs={5} justifyContent="right" sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Box marginRight={10} marginTop={17}>
          <img src={IllustrationImg} alt="" />
        </Box>
      </Grid>
      <Grid container item spacing={4} alignContent="flex-start" xs={12} md={7}>
        {activeStep === 4 && (
          <Grid item xs={12}>
            <div className={styles.warning}>{t('dashboard.shipping.warning_your_sample')}</div>
          </Grid>
        )}
        <Grid container item spacing={4} alignItems="center">
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <span className={styles.title}>{t('dashboard.profile.mailing_address')}</span>
            </Grid>
            {!editAddress ? (
              <Grid container item xs={12} rowSpacing={2}>
                <Grid item xs={12} md={4}>
                  <span className={styles.label}>{getUserMailingAddress(myUser) || t('dashboard.profile.not_specified')}</span>
                </Grid>
                <Grid container item xs={12} justifyContent="space-between">
                  {(!directTransaction || directTransaction.status === 'ERROR') && (
                    <Grid item>
                      <Button variant="outlined" size="small" onClick={() => setEditAddress(true)}>
                        Edit Address
                      </Button>
                    </Grid>
                  )}
                  {!isProduction() && (
                    <Button variant="outlined" onClick={() => setCurrentMockedStatus(prevState => prevState + 1)}>
                      {t('dashboard.shipping.next_status')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            ) : (
              <AddressEditForm onClose={() => setEditAddress(false)} />
            )}
          </Grid>
          {error && (
            <Grid container item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container item>
          <Stepper className={styles.stepper} activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={TrackingStepIcon}>
                  <span className={styles.title}>{getLocalizedLabel(step.label)}</span>
                </StepLabel>
                <StepContent>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                      <span className={labelClassName}>
                        {step.description ?
                          getLocalizedDescription(step.description) :
                          getTrackDescription(directShippingStatus, returnShippingStatus)}
                      </span>
                    </Grid>
                    {index === DontHaveKitSteps.REQUEST && (
                      <Grid item xs={12} md={8}>
                        <LoadingButton
                          variant="contained"
                          onClick={requestKit}
                          loading={isLoading}
                        >
                          {t('dashboard.shipping.button_request_kit')}
                        </LoadingButton>
                      </Grid>
                    )}
                  </Grid>
                  <Box sx={{ mb: 2 }} />
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DontHaveKitFlow;
