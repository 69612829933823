import { LoadingContainerRoot } from 'common/components/LoadingContainer/LoadingContainerStyle';

import React from 'react';
import { CircularProgress } from '@mui/material';

interface Props {
  isLoading: boolean;
}

const LoadingContainer: React.FC<Props> = ({ children, isLoading }) => {
  return (
    <LoadingContainerRoot ownerState={{ isLoading }}>
      <div className="overlay">
        {children}
      </div>
      {isLoading &&
        <CircularProgress className="progress" />}
    </LoadingContainerRoot>
  );
};

export default LoadingContainer;
