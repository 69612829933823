export enum System {
  ENGLISH = 'english',
  METRIC = 'metric',
}

export interface Props {
  system: System;
  onEnglishClick: () => void;
  onMetricClick: () => void;
}
