import CardsPage from 'components/Dashboard/components/CardsPage/CardsPage';
import ProfilePage from 'components/Dashboard/components/ProfilePage/ProfilePage';
import { RootState } from 'store';
import ShippingPage from 'components/Dashboard/components/ShippingPage/ShippingPage';
import SettingsPage from 'components/Dashboard/components/SettingsPage/SettingsPage';
import AncestryPage from 'components/Dashboard/components/AncestryPage/AncestryPage';
import SurveyPage from 'components/Dashboard/components/SurveyPage/SurveyPage';
import DashboardNavLink from 'common/components/DashboardNavLink/DashboardNavLink';
import { DASHBOARD_NAVIGATION_ITEMS } from 'components/Dashboard/constants';
import { AmplitudeEvent } from 'common/constants/amplitude';

import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';

const Dashboard = () => {
  const { t } = useTranslation();
  const { logEvent } = useAmplitude();
  const [ searchParams, setSearchParams ] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('from') && searchParams.get('from') === 'email') {
      logEvent(AmplitudeEvent.CLICKING_VIEW_RESULTS_EMAIL_ACTION_BUTTON);
      searchParams.delete('from');
      setSearchParams(searchParams);
    }
  }, [ searchParams, setSearchParams, logEvent ]);

  const { myUser, isMyUserLoading } = useSelector((store: RootState) => store.user);
  const { isShippingLoading } = useSelector((store: RootState) => store.shipping);

  if (!myUser.id || isMyUserLoading || isShippingLoading) {
    return <CircularProgress />;
  }

  return (
    <Box data-testid="dashboard-page" flex="1 1 auto" padding={{ xs: 2, sm: 4 }} display="flex" flexWrap="nowrap">
      <Stack
        sx={{
          display: { xs: 'none', lg: 'flex' },
          minWidth: 224,
          flexDirection: 'column',
        }}
      >
        {DASHBOARD_NAVIGATION_ITEMS.map(item => (
          <DashboardNavLink to={item.to} key={item.to} exact={item?.exact}>
            {t(item.label)}
          </DashboardNavLink>
        ))}
      </Stack>
      <Box flexGrow={1} maxWidth={{ xs: '100%', lg: 840, xl: 1040 }} margin="0 auto">
        <Routes>
          <Route path="" element={<CardsPage />} />
          <Route path="ancestry/*" element={<AncestryPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="survey" element={<SurveyPage />} />
          <Route path="shipping" element={<ShippingPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="/*" element={<Navigate to="" />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Dashboard;
