import { Ancestry } from 'api/Reports/types';
import { filterAncestry } from 'common/helpers/ancestry';
import { TranslationsKey } from 'i18nextOptions';
import { AncestryData } from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/types';
import { ANCESTRY_COLORS } from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/constants';

import { useTranslation } from 'react-i18next';
import reduce from 'lodash/reduce';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';

export const useAncestryData = (ancestry: Ancestry | null): { mapData: AncestryData[], chartData: AncestryData[] } => {
  const { t } = useTranslation();
  const filteredAncestry = filterAncestry(ancestry);

  const mapData = orderBy(reduce(filteredAncestry, (result, ancestry, prop) => {
    return [
      ...result,
      {
        ancestry: prop,
        title: String(t(`dashboard.ancestry.regions.${prop}` as TranslationsKey)),
        value: round(ancestry, 2),
        color: ANCESTRY_COLORS[prop as keyof typeof ANCESTRY_COLORS],
      },
    ];
  }, [] as AncestryData[]), 'value', 'desc');
  const chartData = mapData.filter(ancestry => ancestry.value !== 0);

  return {
    mapData,
    chartData,
  };
};
