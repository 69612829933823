import { FormTextField } from 'common/components';
import useCreateLabels from 'components/AdminDashboard/components/SamplesPage/components/SamplesControlPanel/components/CreateLabelsPopover/hooks';
import CreateLabelsModal from 'components/AdminDashboard/components/SamplesPage/components/SamplesControlPanel/components/CreateLabelsModal/CreateLabelsModal';
import { LabelType } from 'api/Samples/types';

import React, { useState } from 'react';
import { Button, FormControl, Grid, MenuItem, Popover, TextField, Typography } from '@mui/material';
import { FormikProvider } from 'formik';

interface Props {
  onClose: () => void;
  anchorEl: HTMLButtonElement | null;
}

const CreateLabelsPopover: React.FC<Props> = ({ anchorEl, onClose }) => {
  const [ isCreateLabelsModalOpen, setIsCreateLabelsModalOpen ] = useState<boolean>(false);
  const openCreateLabelsModal = () => setIsCreateLabelsModalOpen(true);
  const { formik, isSubmitting, submitError } = useCreateLabels(openCreateLabelsModal);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 1,
        sx: { padding: 4 },
      }}
      onClose={onClose}
      data-testid="popover-create-labels"
      disableRestoreFocus
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display="flex" flexDirection="column" item container spacing={3}>
            <Grid item>
              <Typography>
                Create labels
              </Typography>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  id="type"
                  select
                  value={formik.values.type}
                  label="Type"
                  name="type"
                  size="small"
                  disabled={isSubmitting}
                  onChange={formik.handleChange}
                  helperText={formik.touched.type && formik.errors.type}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  data-testid="input-type"
                >
                  <MenuItem value={LabelType.SAMPLE}>Sample</MenuItem>
                  <MenuItem value={LabelType.BAG}>Bag</MenuItem>
                  <MenuItem value={LabelType.PLATE}>Plate</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            {formik.values.type === LabelType.SAMPLE && (
              <Grid item>
                <FormTextField
                  id="countryCode"
                  name="countryCode"
                  label="Country code"
                  placeholder="US"
                  value={formik.values.countryCode}
                  onChange={formik.handleChange}
                  error={formik.errors.countryCode}
                  touched={formik.touched.countryCode}
                  disabled={isSubmitting}
                />
              </Grid>
            )}
            {formik.values.type === LabelType.SAMPLE && (
              <Grid item>
                <FormTextField
                  id="site"
                  name="site"
                  label="Site number"
                  placeholder="01"
                  value={formik.values.site}
                  onChange={formik.handleChange}
                  error={formik.errors.site}
                  touched={formik.touched.site}
                  disabled={isSubmitting}
                />
              </Grid>
            )}
            {formik.values.type !== LabelType.SAMPLE && (
              <Grid item>
                <FormTextField
                  id="prefix"
                  name="prefix"
                  label="Prefix"
                  placeholder={formik.values.type === LabelType.BAG ? 'USB' : 'USP'}
                  value={formik.values.prefix}
                  onChange={formik.handleChange}
                  error={formik.errors.prefix}
                  touched={formik.touched.prefix}
                  disabled={isSubmitting}
                />
              </Grid>
            )}
            <Grid item>
              <FormTextField
                id="count"
                name="count"
                label="Total count"
                placeholder="500"
                value={formik.values.count}
                onChange={formik.handleChange}
                error={formik.errors.count}
                touched={formik.touched.count}
                disabled={isSubmitting}
              />
            </Grid>
            {
              submitError && (
                <Grid item xs={12}>
                  <Typography color="error" variant="subtitle2">
                    {submitError}
                  </Typography>
                </Grid>
              )
            }
            <Grid item display="flex" justifyContent="flex-end">
              <Button
                disabled={isSubmitting}
                variant="contained"
                data-testid="button-create-labels"
                type="submit"
              >
                Create labels
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
      {isCreateLabelsModalOpen && <CreateLabelsModal onCancel={onClose} />}
    </Popover>
  );
};

export default CreateLabelsPopover;
