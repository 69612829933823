import { AncestryFileLink } from 'api/Reports/types';
import { AncestryFile } from 'api/User/types';

import each from 'lodash/each';
import { DateTime } from 'luxon';

export const groupAncestryFilesByProvider = (ancestryFiles: AncestryFile[], userId: number) => {
  const filesGroupedByProvider: { [providerName: string]: AncestryFileLink[] } = {};

  each(ancestryFiles, (file) => {
    const { providerName, sourceFileUploadedAt, id } = file;
    const date = sourceFileUploadedAt && DateTime.fromISO(sourceFileUploadedAt).toFormat('MM/dd/yyyy');
    const fileName = date ? `${userId} - ${providerName} - ${date}.zip` : `${userId} - ${providerName}.zip`;
    const data = { id, sourceFileUploadedAt: date, fileName };

    (filesGroupedByProvider[providerName] || (filesGroupedByProvider[providerName] = [])).push(data);
  });

  return filesGroupedByProvider;
};
