import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';
import { UsersFilter } from 'api/User/types';

import { useFormik } from 'formik';

const useUsersFilter = (onSubmit: () => void) => {
  const {
    genderParam,
    partnerParam,
    testKitShippedParam,
    registrationParam,
    ehrStatusParam,
    surveyParam,
    setUsersFilter,
    clearUsersFilter,
  } = usePaginationSearchParams();

  const formikSubmit = (params: UsersFilter) => {
    setUsersFilter(params);
    onSubmit();
  };

  const initialValues = {
    partners: partnerParam !== '' ? Array.from(partnerParam.split(','), Number) : [],
    gender: genderParam,
    testKitShipped: testKitShippedParam,
    registration: registrationParam,
    ehrStatus: ehrStatusParam,
    survey: surveyParam,
  };

  const formik = useFormik<UsersFilter>({
    initialValues,
    onSubmit: formikSubmit,
  });

  const clearFormAndSearchParams = () => {
    formik.setFieldValue('gender', '');
    formik.setFieldValue('partners', []);
    formik.setFieldValue('testKitShipped', '');
    formik.setFieldValue('registration', '');
    formik.setFieldValue('ehrStatus', '');
    formik.setFieldValue('survey', '');
    clearUsersFilter();
  };

  return { formik, clearFormAndSearchParams };
};

export default useUsersFilter;
