import { ShippingState } from 'store/shipping/models';
import {
  SET_DIRECT_SHIPPING_STATUS,
  SET_RETURN_SHIPPING_STATUS,
  SET_SHIPPING_LOADING,
  SET_TRANSACTIONS,
} from 'store/shipping/types';
import { Transactions } from 'api/Shipment/types';

import { AnyAction } from 'redux';

export const initialState: ShippingState = {
  isShippingLoading: false,
  transactions: {
    returnTransaction: null,
    directTransaction: null,
  },
  directShippingStatus: undefined,
  returnShippingStatus: undefined,
};

export const shippingReducer = (
  state: ShippingState = { ...initialState },
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_SHIPPING_LOADING:
      return {
        ...state,
        isShippingLoading: action.value,
      };

    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: { ...action.data } as Transactions,
      };

    case SET_DIRECT_SHIPPING_STATUS:
      return {
        ...state,
        directShippingStatus: action.data,
      };

    case SET_RETURN_SHIPPING_STATUS:
      return {
        ...state,
        returnShippingStatus: action.data,
      };

    default:
      return { ...state };
  }
};
