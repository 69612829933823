import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey } from 'api/User/types';
import { getOnlyNumbers } from 'common/helpers/getOnlyNumbers';
import MeasurementSystem from 'common/components/MeasurementSystem/MeasurementSystem';
import { System } from 'common/components/MeasurementSystem/types';

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const WeightQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const [ value, setValue ] = useState('');
  const [ error, setError ] = useState<string>('');
  const { submit, isSubmitting, submitError } = useQuestionForm();
  const [ system, setSystem ] = useState<System>(System.ENGLISH);
  const isMetric = system === System.METRIC;

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!value) {
      setError(t('dashboard.survey.error_give_answer'));
    } else {
      submit({
        ...survey,
        weight: parseInt(value, 10),
        weightSystem: system,
      })
        .then((data: Survey) => onNext(data));
    }
  };

  useEffect(() => {
    if (survey && survey.weight !== null) {
      setValue(String(survey.weight));
      setSystem(survey.weightSystem || System.ENGLISH);
    }
  }, [ survey ]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(getOnlyNumbers(event.target.value));
  };

  const onEnglishClick = () => setSystem(System.ENGLISH);
  const onMetricClick = () => setSystem(System.METRIC);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-weight">
      <Grid container spacing={3}>
        <Grid item container spacing={2} xs={12} alignItems="end">
          <Grid item>
            <Typography variant="textXLBold" color="primary">
              {t('dashboard.survey.question_weight.question')}
            </Typography>
          </Grid>
          <Grid item>
            <MeasurementSystem system={system} onEnglishClick={onEnglishClick} onMetricClick={onMetricClick} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            color="primary"
            size="small"
            value={value}
            onChange={handleChange}
            sx={{ width: 120 }}
            error={Boolean(error)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isMetric ? t('general.kilograms_short') : t('general.pounds_short')}
                </InputAdornment>
              ),
              inputProps: { 'data-testid': 'input-weight' },
            }}
          />
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default WeightQuestion;
