import { APP_BAR_HEIGHT } from 'common/constants/sizes';
import { useNews } from 'components/Landing/components/NewsPage/hooks';
import { SATOSHI } from 'common/constants/fonts';
import { getResourceUrl } from 'common/utils/getResourceUrl';

import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';

const NewsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const { newsId } = useParams<{ newsId: string }>();
  const {
    requestNews,
    news,
    isLoading,
    error,
  } = useNews();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (newsId) {
      requestNews(newsId);
    }
  }, [ newsId, requestNews ]);

  return (
    <Container
      maxWidth="xl"
      sx={{ paddingTop: APP_BAR_HEIGHT, overflow: 'hidden', flexGrow: 1, position: 'relative', marginBottom: 4 }}
      data-testid="news-page"
    >
      <Button
        onClick={() => navigate(-1)}
        startIcon={<ArrowBackIcon />}
        sx={{ position: 'absolute', zIndex: '5', marginTop: '16px' }}
      >
        {t('general.back')}
      </Button>
      <Box
        display="flex"
        zIndex={1}
        justifyContent="space-around"
        position="relative"
        marginBottom={3}
        sx={{ marginTop: { xs: '56px', md: '0' } }}
      >
        {isLoading ?
          <CircularProgress /> : (
            <Grid maxWidth="696px" container spacing={3}>
              {!news && error ? (
                <Typography color="error">
                  {error}
                </Typography>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography sx={{
                      fontFamily: SATOSHI,
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '3rem',
                      lineHeight: '125%',
                      letterSpacing: '0.05rem',
                    }}
                    >
                      {news.title.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box maxWidth="100%" maxHeight={456} display="flex" justifyContent="space-around">
                      <img src={getResourceUrl(news.title.dataUrl)} width="100%" alt="" />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="textLRegular" sx={{ whiteSpace: 'pre-line' }}>
                      {news.body.body}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="outlined" size="large" startIcon={<FileOpenOutlinedIcon />} onClick={() => window.open(news.body.resourceUrl)}>
                      {t('landing.publications.learn_more')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
      </Box>
    </Container>
  );
};

export default NewsPage;
