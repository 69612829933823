import { ivory, purple600 } from 'common/constants/colors';
import BackgroundImg from 'components/Landing/components/MainContent/components/PieChartPage/resources/background.svg';
import style from 'components/Landing/components/MainContent/components/PieChartPage/PieChartsPage.module.css';
import { useChartData } from 'components/Landing/components/MainContent/components/PieChartPage/hooks';

import React, { useMemo, useState } from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';

const compareData = (a: { value: number }, b: { value: number }) => (a.value < b.value ? 1 : -1);

const PieChartPage = () => {
  const { t } = useTranslation();
  const { chartData } = useChartData();
  
  const [ currentYear, setCurrentYear ] = useState(chartData.length - 1);

  const option = useMemo(() => ({
    tooltip: {
      trigger: 'item',
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: 'Participants ancestry',
        type: 'pie',
        minAngle: chartData[currentYear].minAngle,
        radius: [ '58%', '96%' ],
        startAngle: 90,
        avoidLabelOverlap: false,
        legend: {
          show: false,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '40',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        color: chartData[currentYear].data.map(({ color }) => color),
        data: chartData[currentYear].data.map(({ value, name }) => ({ value, name })),
      },
    ],
  }), [ chartData, currentYear ]);

  const handlePrev = () => setCurrentYear(prevState => (prevState > 0 ? prevState - 1 : 0));
  const handleNext = () => setCurrentYear(prevState => (prevState < chartData.length - 1 ? prevState + 1 : chartData.length - 1));
  
  return (
    <Box
      id="chart"
      position="relative"
      width="100%"
      sx={{
        overflow: 'hidden',
        backgroundColor: purple600,
      }}
      paddingTop={{ xs: 2, lg: 16 }}
      paddingBottom={{ xs: 2, lg: 16 }}
    >
      <Container
        maxWidth="xl"
        sx={{
          position: 'relative',
          display: { xs: 'flex', lg: 'block' },
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className={style.textContainer}>
          <div className={style.title}>
            {t('landing.chart_page.title')}
          </div>
          <div className={style.subtitle}>
            {t('landing.chart_page.body')}
          </div>
          <Grid
            container
            sx={{ maxWidth: { xs: '400px', lg: '40%' }, margin: { xs: '0 auto', lg: '0' } }}
            columnSpacing={{ xs: 0, lg: 7 }}
            rowSpacing={{ xs: 7, lg: 7 }}
            marginTop={1}
          >
            {[ ...chartData[currentYear].data ].sort(compareData).map(({ color, value, name }) => (
              <Grid item xs={4} key={name}>
                <Box sx={{ backgroundColor: color }} className={style.legendIcon} />
                <div className={style.legendValue}>{`${value}%`}</div>
                <div className={style.legendLabel}>{`${name}`}</div>
              </Grid>
            ))}
          </Grid>
        </div>
        <Box sx={{
          zIndex: 0,
          position: 'absolute',
          right: { xs: 'unset', lg: -1342 },
          bottom: { xs: -1610, lg: -1640 },
          margin: { xs: '0 auto', lg: 'unset' },
        }}
        >
          <img src={BackgroundImg} alt="" />
        </Box>
        <Box
          zIndex={5}
          sx={{
            width: { xs: '400px', lg: '900px' },
            height: { xs: '400px', lg: '900px' },
            position: { xs: 'relative', lg: 'absolute' },
            right: { xs: 'unset', lg: -110 },
            marginTop: { xs: -8, lg: 0 },
            bottom: { xs: -120, lg: -400 },
          }}
        >
          <ReactECharts
            option={option}
            notMerge
            lazyUpdate
            theme="theme_name"
            className={style.chart}
            style={{ width: '100%', height: '100%' }}
          />
          <Box
            position="absolute"
            top="20%"
            left="20%"
            width="60%"
            height="60%"
            sx={{ backgroundColor: ivory, borderRadius: '50%' }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-around"
              width="100%"
            >
              <Button variant="round" className={style.chartButton} onClick={handlePrev}>
                <ArrowBackIcon />
              </Button>
              <span className={style.chartLabel}>{chartData[currentYear].label}</span>
              <Button variant="round" onClick={handleNext}>
                <ArrowForwardIcon />
              </Button>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PieChartPage;
