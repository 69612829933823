import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';
import { HeadCell } from 'common/types/MuiComponents';
import SearchUserInput from 'common/components/SearchUserInput/SearchUserInput';
import { pluralize } from 'common/helpers/pluralize';
import { isDataScienceAdmin } from 'common/helpers/user';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import DataTable from 'common/components/DataTable/DataTable';
import { getUsers } from 'store/users/actions';
import { RootState } from 'store';
import SamplesListModal from 'components/AdminDashboard/components/SamplesPage/components/SamplesListModal/SamplesListModal';

import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';

const UsersSamples: React.FC = () => {
  const dispatch = useDispatch();
  const { usernameParam } = usePaginationSearchParams();

  const fetchUsers = useCallback((page: number, pageSize: number, userName: string) => {
    dispatch(getUsers(page, pageSize, { userName }));
  }, [ dispatch ]);

  const { myUser } = useSelector((store: RootState) => store.user);
  const { users, pagination, isLoading, error } = useSelector((store: RootState) => store.users);
  const [ searchValue, setSearchValue ] = useState(usernameParam);
  const isUserDataScienceAdmin = isDataScienceAdmin(myUser);

  const [ samplesListUserId, setSamplesListUserId ] = useState<number | null>(null);
  const handleOpenSamplesModal = (id: number) => setSamplesListUserId(id);
  const handleCloseSamplesModal = () => setSamplesListUserId(null);

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'userId',
      numeric: false,
      label: 'User id',
    },
    {
      id: 'user',
      numeric: false,
      label: !isUserDataScienceAdmin ? 'User' : '',
      button: <SearchUserInput onChange={e => setSearchValue(e.target.value)} hasLeftPadding={!isUserDataScienceAdmin} />,
    },
    {
      id: 'samples',
      numeric: false,
      label: 'Samples',
    },
    {
      id: 'partner',
      numeric: false,
      label: 'Partner',
    },
  ], [ isUserDataScienceAdmin ]);

  const renderRows = useCallback(() => users
    .map((row) => {
      return (
        <TableRow hover tabIndex={-1} key={row.id}>
          <TableCell>
            <Typography variant="textSRegular">
              {row.id}
            </Typography>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            padding="normal"
          >
            {!isUserDataScienceAdmin && (
              <>
                <Typography variant="textSRegular" component="div">
                  {`${row.firstName} ${row.lastName}`}
                </Typography>
                <Typography variant="textSRegular" component="div" whiteSpace="nowrap">
                  {row.username}
                </Typography>
              </>
            )}
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {pluralize(row.samples.length, 'sample')}
            </Typography>
            <Tooltip title="Samples list">
              <Box component="span">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => handleOpenSamplesModal(row.id)}
                  sx={{ marginLeft: 1 }}
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Typography variant="textSRegular">
              {row.organizationName || row.utmParams?.source}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }), [ users, isUserDataScienceAdmin ]);

  return (
    <LoadingContainer isLoading={isLoading}>
      { error && (
        <Grid item xs={12} padding={2}>
          <Typography color="error" variant="subtitle2" sx={{ whiteSpace: 'break-spaces' }}>
            {error}
          </Typography>
        </Grid>
      )}
      <DataTable
        pagination={pagination}
        headCells={headCells}
        renderRows={renderRows}
        searchValue={searchValue}
        updateDataCallback={fetchUsers}
      />
      {samplesListUserId && (
        <SamplesListModal userId={samplesListUserId} onCancel={handleCloseSamplesModal} />
      )}
    </LoadingContainer>
  );
};

export default UsersSamples;
