import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { BornPlace } from 'api/User/types';
import { TranslationsKey } from 'i18nextOptions';
import {
  getInitialBornPlaces,
} from 'components/Dashboard/components/SurveyPage/components/Common/GrandparentsBornPlaceQuestion/helpers';

import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import { useTranslation } from 'react-i18next';

export const GRANDPARENTS_LIST = [
  'Maternal grandfather',
  'Maternal grandmother',
  'Paternal grandfather',
  'Paternal grandmother',
];

const GrandparentsBornPlaceQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();

  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const { submit, isSubmitting, submitError } = useQuestionForm();
  const [ values, setValues ] = useState<{ [key: string]: BornPlace }>(getInitialBornPlaces(GRANDPARENTS_LIST));
  const [ fieldErrors, setFieldErrors ] = useState<{ [key: string]: string }>({});

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    const checkEmptyField = (result: { [key: string]: string }, grandparent: string) => {
      if (!values[grandparent]?.dontKnow && (!values[grandparent]?.city || !values[grandparent]?.country)) {
        return { ...result, [grandparent]: t('dashboard.survey.error_give_answer') };
      }
      return result;
    };

    const emptyErrors = GRANDPARENTS_LIST.reduce(checkEmptyField, {});
    setFieldErrors(emptyErrors);

    if (isEmpty(emptyErrors)) {
      submit({
        ...survey,
        grandparentsBornPlace: { ...values },
      })
        .then(data => onNext(data));
    }
  }, [ t, onNext, submit, survey, values ]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [ grandparentId, fieldId ] = event.target.id.split('|');

    if ((fieldId === 'country' && event.target.value && values[grandparentId]?.city) ||
      (fieldId === 'city' && event.target.value && values[grandparentId]?.country)) {
      setFieldErrors(prevState => omit(prevState, grandparentId));
    }

    setValues({
      ...values,
      [grandparentId]: {
        ...values[grandparentId],
        [fieldId]: event.target.value,
      },
    });
  };

  const handleDontKnowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [ grandparentId, fieldId ] = event.target.id.split('|');

    setValues(prevState => ({
      ...prevState,
      [grandparentId]: {
        ...prevState[grandparentId],
        [fieldId]: event.target.checked,
      },
    }));
  };

  useEffect(() => {
    const getDefaultValues = (result: { [key: string]: BornPlace }, grandparentId: string) => {
      if (survey?.grandparentsBornPlace && survey?.grandparentsBornPlace[grandparentId]) {
        return { ...result, [grandparentId]: { ...survey.grandparentsBornPlace[grandparentId] } };
      }
      return { ...result, [grandparentId]: { city: '', country: '', dontKnow: false } };
    };

    const values = GRANDPARENTS_LIST.reduce(getDefaultValues, {});
    setValues(values);
  }, [ survey ]);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-grandparents-born-place">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary" fontWeight="600">
            {t('dashboard.survey.question_grandparents_born_place.question')}
          </Typography>
        </Grid>
        {GRANDPARENTS_LIST.map((grandparent, index) => (
          <Grid item container spacing={3} key={grandparent}>
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} lg={3}>
                <Typography variant="body1" color="primary" fontWeight="600">
                  {t(`dashboard.survey.family_members.${grandparent}` as TranslationsKey)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={3}>
                <TextField
                  label={t('dashboard.profile.country')}
                  size="small"
                  color="primary"
                  onChange={handleTextChange}
                  disabled={values[grandparent]?.dontKnow}
                  value={values[grandparent]?.country}
                  error={Boolean(fieldErrors[grandparent]) && !values[grandparent]?.country && !values[grandparent]?.dontKnow}
                  inputProps={{ id: `${grandparent}|country`, 'data-testid': `input-country-${index}` }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={3}>
                <TextField
                  label={t('general.city')}
                  size="small"
                  color="primary"
                  value={values[grandparent]?.city}
                  disabled={values[grandparent]?.dontKnow}
                  onChange={handleTextChange}
                  error={Boolean(fieldErrors[grandparent]) && !values[grandparent]?.city && !values[grandparent]?.dontKnow}
                  inputProps={{ id: `${grandparent}|city`, 'data-testid': `input-city-${index}` }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2} lg={3}>
                <FormControlLabel
                  sx={{ span: { fontSize: '15px', marginLeft: { md: '-4px', lg: 0 } }, marginRight: 0 }}
                  control={(
                    <Checkbox
                      onChange={handleDontKnowChange}
                      value={values[grandparent]?.dontKnow || false}
                      checked={values[grandparent]?.dontKnow || false}
                      id={`${grandparent}|dontKnow`}
                      data-testid={`checkbox-dontknow-${index}`}
                    />
                  )}
                  label={t('general.i_dont_know')}
                />
              </Grid>
            </Grid>
            {fieldErrors[grandparent] && (
              <Grid item xs={12}>
                <Typography color="error" data-testid={`label-error-${index}`}>
                  {fieldErrors[grandparent]}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))}
        {submitError && (
          <Grid item xs={12}>
            <Typography color="error">
              {submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default GrandparentsBornPlaceQuestion;
