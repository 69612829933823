import { greenHaze, starship, hopBush, pictonBlue, tenn, deepCerulean, orangePeel } from 'common/constants/colors';
import { Ancestry } from 'api/Reports/types';

type KeysEnum<T> = { [P in keyof Required<T>]: string };

export const ANCESTRY_COLORS: KeysEnum<Omit<Ancestry, 'providerName' | 'reportDate'>> = {
  amr: greenHaze,
  afr: starship,
  eas: hopBush,
  eur: pictonBlue,
  lme: tenn,
  oce: deepCerulean,
  sas: orangePeel,
};
