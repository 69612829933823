import { ReportStatus } from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/types';

export const getReportStatus = (reportInProgress: boolean, waitingForSample: boolean) => {
  if (reportInProgress) {
    return ReportStatus.IN_PROGRESS;
  }
  if (waitingForSample) {
    return ReportStatus.WAITING_SAMPLE;
  }
  
  return ReportStatus.NONE;
};
