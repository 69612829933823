import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV1 } from 'api/User/types';
import styles from 'components/Dashboard/components/SurveyPage/components/inputStyles.module.css';

import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CovidVaccineQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);

  const [ value, setValue ] = useState('');
  const [ error, setError ] = useState('');
  const [ other, setOther ] = useState('');

  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    if (!value || (value === 'other' && !other)) {
      setError(t('dashboard.survey.error_give_answer'));
    } else {
      submit({
        ...survey,
        covidVaccine: { value, other },
      })
        .then((data: Survey) => onNext(data));
    }
  }, [ t, value, other, submit, survey, onNext ]);

  const onRadioChanged = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  const onOtherChanged = useCallback((event) => {
    setOther(event.target.value);
  }, []);

  useEffect(() => {
    setValue((survey as SurveyV1)?.covidVaccine?.value || '');
    setOther((survey as SurveyV1)?.covidVaccine?.other || '');
  }, [ survey ]);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-covid-vaccine">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_vaccine.question')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              onChange={onRadioChanged}
              value={value}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel value="pfizer" control={<Radio />} label={t('dashboard.survey.question_vaccine.options.pfizer')} data-testid="option-pfizer" />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel value="moderna" control={<Radio />} label={t('dashboard.survey.question_vaccine.options.moderna')} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel value="janssen" control={<Radio />} label={t('dashboard.survey.question_vaccine.options.janssen')} />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    classes={{
                      label: styles.otherRadioWrapper,
                      root: styles.otherRadioWrapper,
                    }}
                    data-testid="option-other"
                    label={(
                      <Box display="flex" justifyContent="center" alignItems="center" className={styles.otherInputWrapper}>
                        <span>{t('dashboard.survey.question_vaccine.options.other')}</span>
                        <TextField
                          sx={{ marginLeft: { xs: 0, sm: 2 }, width: { xs: '100%', sm: 300 }, marginTop: { xs: 1, sm: 0 } }}
                          variant="outlined"
                          size="small"
                          color="primary"
                          label={t('dashboard.survey.hint_which_one')}
                          value={other}
                          onChange={onOtherChanged}
                          error={Boolean(error) && value === 'other'}
                          disabled={value !== 'other'}
                          inputProps={{ 'data-testid': 'input-other' }}
                        />
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="primary" maxWidth={425} fontWeight={400}>
            {t('dashboard.survey.hint_leave_blank')}
          </Typography>
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default CovidVaccineQuestion;
