import { useSampleQuerying } from 'components/AdminDashboard/components/SamplesPage/components/SamplesControlPanel/components/SearchPopover/hooks';
import FileInput from 'common/components/FileInput/FileInput';

import React from 'react';
import { Menu, MenuItem } from '@mui/material';

interface Props {
  onClose: () => void;
  anchorEl: HTMLButtonElement | null;
}

const SearchPopover: React.FC<Props> = ({ anchorEl, onClose }) => {
  const { searchByUserId, searchBySampleId, isLoading } = useSampleQuerying(onClose);

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <FileInput
        loading={isLoading}
        label="Sample ID search"
        onChange={searchBySampleId}
        accept=".csv, .tsv"
        ButtonComponent={MenuItem}
        ButtonProps={{
          sx: { fontSize: '14px' },
        }}
      />
      <FileInput
        loading={isLoading}
        label="User ID search"
        onChange={searchByUserId}
        accept=".csv, .tsv"
        ButtonComponent={MenuItem}
        ButtonProps={{
          sx: { fontSize: '14px' },
        }}
      />
    </Menu>
  );
};

export default SearchPopover;
