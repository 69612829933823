import { APP_BAR_HEIGHT } from 'common/constants/sizes';
import ServicesCard from 'components/Landing/components/PartnersContent/components/ServicesPage/components/ServicesCard/ServicesCard';
import ServicesHint from 'components/Landing/components/PartnersContent/components/ServicesPage/components/ServicesHint/ServicesHint';
import ConfettiImg from 'components/Landing/components/PartnersContent/components/ServicesPage/resources/confetti.svg';
import BiobankingImg from 'components/Landing/components/PartnersContent/components/ServicesPage/resources/biobanking.svg';
import AlgorithmsImg from 'components/Landing/components/PartnersContent/components/ServicesPage/resources/algorithms.svg';
import ClinicalTestingImg from 'components/Landing/components/PartnersContent/components/ServicesPage/resources/clinical_testing.svg';
import DiagnosisImg from 'components/Landing/components/PartnersContent/components/ServicesPage/resources/diagnosis.svg';

import { Box, Container, Hidden, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const ServicesPage: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [ pageRef, isPageInView ] = useInView();
  const [ breakpointRef, isBreakpointInView ] = useInView();

  useEffect(() => {
    if (!isBreakpointInView && isPageInView) {
      // onIntersectNavbar(true);
    } else {
      // onIntersectNavbar(false);
    }
  }, [ isPageInView, isBreakpointInView ]);

  const cardsData = useMemo(() => [
    {
      title: t('landing.partners.services.biobanking_title'),
      description: t('landing.partners.services.biobanking_body'),
      renderImg: () => (
        <Box position="absolute" top="0" bottom="0" right="38px">
          <img src={BiobankingImg} alt="" height="100%" />
        </Box>
      ),
    },
    {
      title: t('landing.partners.services.algorithms_title'),
      description: t('landing.partners.services.algorithms_body'),
      renderImg: () => (
        <Box position="absolute" top="79px" right="0">
          <img src={AlgorithmsImg} alt="" height="100%" />
        </Box>
      ),
    },
    {
      title: t('landing.partners.services.testing_title'),
      description: t('landing.partners.services.testing_body'),
      renderImg: () => (
        <Box position="absolute" top="54px" right="0">
          <img src={ClinicalTestingImg} alt="" height="100%" />
        </Box>
      ),
    },
    {
      title: t('landing.partners.services.diagnosis_title'),
      description: t('landing.partners.services.diagnosis_body'),
      renderImg: () => (
        <Box position="absolute" top="106px" right={0}>
          <img src={DiagnosisImg} alt="" height="100%" />
        </Box>
      ),
    },
  ], [ t ]);
  
  const renderCards = useCallback(() => {
    // TODO fix key prop when real data used
    return cardsData.map((data) => {
      return (
        <Box marginTop={16} key={data.title}>
          <ServicesCard data={data} />
        </Box>
      );
    });
  }, [ cardsData ]);

  return (
    <Box
      position="relative"
      width="100%"
      sx={{ backgroundColor: theme.palette.purple.main }}
      ref={pageRef}
      paddingTop={{ xs: 0, lg: 16 }}
      paddingBottom={16}
    >
      <Box position="absolute" top={`-${APP_BAR_HEIGHT}`} ref={breakpointRef} />
      <Container maxWidth="xl" sx={{ position: 'relative' }}>
        <Hidden lgUp>
          <Box top="164px" marginTop="164px" maxWidth="650px">
            <Box marginLeft={2} marginRight={2}>
              <ServicesHint />
            </Box>
          </Box>
        </Hidden>
        <Box sx={{ position: 'relative', marginRight: { xs: 0, lg: '47%' } }}>
          <Box position="relative" zIndex={1}>
            {renderCards()}
          </Box>
          <Hidden lgUp>
            <Box position="absolute" top="-8%" display="flex" justifyContent="center" zIndex={-1}>
              <img src={ConfettiImg} alt="" width="100%" />
            </Box>
          </Hidden>
        </Box>
        <Hidden lgDown>
          <Box position="absolute" top={0} right="0" height="100%" maxWidth="50%">
            <Box position="sticky" top="20%" marginTop="5%" display="flex" justifyContent="center" maxWidth="650px">
              <Box marginLeft={18}>
                <ServicesHint />
              </Box>
            </Box>
          </Box>
        </Hidden>
        <Hidden lgDown>
          <Box position="absolute" top={-320} left={50} height="100%" maxWidth="50%">
            <Box position="sticky" top="0%" marginTop="120px" display="flex" justifyContent="center" maxWidth="650px">
              <img src={ConfettiImg} alt="" />
            </Box>
          </Box>
        </Hidden>
      </Container>
    </Box>
  );
};

export default ServicesPage;
