import { RegisterResult } from 'api/Auth/types';
import { PartnerInfo } from 'api/User/types';
import SignUp from 'components/Dashboard/components/Authorization/SignUpPage/components/SignUp/SignUp';
import { resendActivateLinkRequest } from 'api/User/api';
import { AuthorizationContainer, Label } from 'common/components';

import React, { useState, useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  partnerValues?: PartnerInfo
}

const SignUpPage = ({ partnerValues }: Props) => {
  const { t } = useTranslation();
  const [ signUpData, setSignUpData ] = useState<RegisterResult>();
  const onSuccessSignUp = useCallback((data: RegisterResult) => {
    setSignUpData(data);
  }, []);

  const resendActivationLink = useCallback(() => {
    resendActivateLinkRequest(signUpData!.username).then((response) => {
      console.log(response.data);
    });
  }, [ signUpData ]);

  if (signUpData) {
    return (
      <AuthorizationContainer>
        <Label>{t('registration.confirm_email')}</Label>
        <Typography fontSize="15px" color="#3E355C" paddingBottom={{ xs: 8, sm: 5 }}>
          <Trans t={t} i18nKey="registration.we_sent_activation_link" values={{ username: signUpData.username }}>
            We’ve sent the activation link to <b>{signUpData.username}</b>. Please open email and activate your account
          </Trans>
        </Typography>
        <Button
          sx={{ width: { xs: '100%', sm: 'unset' }, alignSelf: { xs: 'unset', sm: 'flex-start' } }}
          onClick={resendActivationLink}
          size="small"
        >
          {t('reset_password.send_link_again')}
        </Button>
      </AuthorizationContainer>
    );
  }

  return <SignUp partnerValues={partnerValues} onSuccess={onSuccessSignUp} />;
};

export default SignUpPage;
