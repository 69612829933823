import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material';

export const DashboardNavLinkRoot = styled(NavLink)<{ ownerState: { isActive: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.common.white,
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '24px',
    textDecoration: 'none',
    opacity: ownerState.isActive ? 1 : 0.75,
    fontWeight: ownerState.isActive ? 'bold' : 'normal',
  }),
);
