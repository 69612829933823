import { midnight } from 'common/constants/colors';

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

interface IProps {
  title: string;
  value: number;
}

const OverviewStatsBox: React.FC<IProps> = ({ title, value }) => (
  <Grid item xs={12} xl={3}>
    <Box
      textAlign="center"
      width="100%"
      sx={{ color: midnight, backgroundColor: '#fff' }}
      padding={{ xs: 2 }}
      borderRadius={{ xs: '4px' }}
    >
      <Typography>{title}</Typography>
      <Typography>{value}</Typography>
    </Box>
  </Grid>
);

export default OverviewStatsBox;
