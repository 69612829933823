import { SamplesTab } from 'containers/types/tabs';

import { useLocation } from 'react-router-dom';

export const useCurrentTab = (tabs: readonly SamplesTab[]) => {
  const { pathname } = useLocation();
  const test = pathname.split('/').at(-1);

  for (let i = 0; i < tabs.length; i += 1) {
    const pattern = tabs[i];
    if (test === pattern) {
      return pattern;
    }
  }

  return tabs[0];
};
