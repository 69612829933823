import { EHRStatus } from 'api/User/types';
import { EHRStatusLabel } from 'components/AdminDashboard/components/UsersPage/components/EHRColumn/types';

export const getEHRStatusLabel = (ehrStatus: EHRStatus): EHRStatusLabel => {
  switch (ehrStatus) {
    case EHRStatus.PULLED:
      return EHRStatusLabel.PULLED;

    case EHRStatus.FAILED:
      return EHRStatusLabel.FAILED;

    case EHRStatus.CAN_NOT_IMPORT:
      return EHRStatusLabel.CAN_NOT_IMPORT;

    case EHRStatus.IN_PROGRESS:
      return EHRStatusLabel.IN_PROGRESS;

    case EHRStatus.NO_EHR_DATA:
      return EHRStatusLabel.NO_EHR_DATA;

    case EHRStatus.PATIENT_CHART_ERROR_454:
      return EHRStatusLabel.PATIENT_CHART_ERROR_454;

    case EHRStatus.MULTIPLE_MATCHES:
      return EHRStatusLabel.MULTIPLE_MATCHES;

    case EHRStatus.NOT_PULLED:
      return EHRStatusLabel.NOT_PULLED;

    default:
      return EHRStatusLabel.NOT_PULLED;
  }
};

export const getBadgeColor = (ehrStatus: EHRStatus) => {
  switch (ehrStatus) {
    case EHRStatus.PULLED:
      return 'success';

    case EHRStatus.FAILED:
      return 'error';

    case EHRStatus.CAN_NOT_IMPORT:
      return 'secondary';

    case EHRStatus.IN_PROGRESS:
      return 'warning';

    case EHRStatus.NO_EHR_DATA:
      return 'secondary';

    case EHRStatus.PATIENT_CHART_ERROR_454:
      return 'error';

    case EHRStatus.MULTIPLE_MATCHES:
      return 'error';

    case EHRStatus.NOT_PULLED:
      return 'primary';

    default:
      return 'primary';
  }
};

export const hasPopover = (ehrStatus: EHRStatus): boolean => {
  const supportedStatuses = [ EHRStatus.FAILED, EHRStatus.NO_EHR_DATA, EHRStatus.CAN_NOT_IMPORT, EHRStatus.PATIENT_CHART_ERROR_454 ];

  return supportedStatuses.includes(ehrStatus);
};
