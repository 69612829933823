import { MONTSERRAT_ALTERNATES } from 'common/constants/fonts';
import { grey800, midnight } from 'common/constants/colors';
import { DASHBOARD_ROUTE } from 'common/constants/urls';
import { RootState } from 'store';

import React, { ChangeEvent, useState, useEffect } from 'react';
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  submitError?: string;
  isSubmitting?: boolean;
  onConsentSubmit?: (signature: string, parentSignature: string) => void;
  viewMode?: boolean;
  email?: string;
  onEmailEdit?: () => void;
  currentAge: number;
}

const ConsentPage: React.FC<Props> = ({
  submitError,
  isSubmitting,
  onConsentSubmit,
  viewMode,
  email,
  onEmailEdit,
  currentAge,
}) => {
  const { t } = useTranslation();
  
  const { myUser } = useSelector((store: RootState) => store.user);
  const navigate = useNavigate();

  const [ agreement, setAgreement ] = useState<string>(viewMode ? 'yes' : 'no');
  const [ signature, setSignature ] = useState<string>('');
  const [ signatureError, setSignatureError ] = useState<string>('');
  const [ parentSignature, setParentSignature ] = useState<string>('');
  const [ parentSignatureError, setParentSignatureError ] = useState<string>('');

  useEffect(() => {
    setSignature(myUser.signature);
    setParentSignature(myUser.parentSignature || '');
  }, [ myUser ]);

  const onSignatureChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSignature(e.target.value);
    setSignatureError('');
  };
  const onParentSignatureChange = (e: ChangeEvent<HTMLInputElement>) => {
    setParentSignature(e.target.value);
    setParentSignatureError('');
  };
  const onAgreementChange = (e: ChangeEvent<HTMLInputElement>) => setAgreement(e.target.value);

  const isYoungerThan13 = currentAge < 13;

  const onSubmitHandler = () => {
    if (!onConsentSubmit) {
      return;
    }

    let hasError = false;

    if (!signature) {
      hasError = true;
      setSignatureError(t('consent.field_is_required'));
    }

    if (isYoungerThan13 && !parentSignature) {
      hasError = true;
      setParentSignatureError(t('consent.field_is_required'));
    }

    if (!hasError) {
      onConsentSubmit(signature, parentSignature);
    }
  };

  const { ref: firstMenuRef, inView: firstMenuInView } = useInView();
  const { ref: secondMenuRef, inView: secondMenuInView } = useInView();
  const { ref: thirdMenuRef, inView: thirdMenuInView } = useInView();
  const { ref: fourthMenuRef, inView: fourthMenuInView } = useInView();
  const { ref: fifthMenuRef, inView: fifthMenuInView } = useInView();
  const { ref: sixthMenuRef, inView: sixthMenuInView } = useInView();
  const { ref: seventhMenuRef, inView: seventhMenuInView } = useInView();
  const { ref: eighthMenuRef, inView: eighthMenuInView } = useInView();
  const { ref: ninthMenuRef, inView: ninthMenuInView } = useInView();

  const getNavBarLinkStyles = React.useCallback((visible: boolean) => ({
    fontWeight: visible ? 'bold' : 'normal',
    opacity: visible ? 1 : 0.75,
    textDecoration: 'none',
    color: '#fff',
  }), []);

  return (
    <Grid container display="flex" flexWrap="nowrap" marginTop={{ xs: 5, md: 10 }}>
      <Grid
        sx={{ position: 'sticky', top: 20, display: { xs: 'none', lg: 'flex' } }}
        alignSelf="flex-start"
        item
        minWidth={260}
        maxWidth={260}
        paddingLeft={4}
        paddingRight={4}
      >
        <Stack spacing={3}>
          {viewMode && (
            <Box sx={{ color: 'white' }}>
              <Button
                variant="text"
                color="inherit"
                startIcon={<ArrowBackIcon />}
                sx={{ marginRight: -20 }}
                onClick={() => navigate(DASHBOARD_ROUTE)}
              >
                {t('consent.back_to_dashboard')}
              </Button>
            </Box>
          )}
          <a href="#firstMenu" style={getNavBarLinkStyles(firstMenuInView)}>{t('consent.What are research goals?')}</a>
          <a href="#secondMenu" style={getNavBarLinkStyles(!firstMenuInView && secondMenuInView)}>{t('consent.What am I agreeing to?')}</a>
          <a href="#thirdMenu" style={getNavBarLinkStyles(!secondMenuInView && thirdMenuInView)}>{t('consent.How do I provide my genetic sample and/or genetic data?')}</a>
          <a href="#fourthMenu" style={getNavBarLinkStyles(!thirdMenuInView && fourthMenuInView)}>{t('consent.How do I provide my clinical data?')}</a>
          <a href="#fifthMenu" style={getNavBarLinkStyles(!fourthMenuInView && fifthMenuInView)}>{t('consent.How will my data be used in research?')}</a>
          <a href="#sixthMenu" style={getNavBarLinkStyles(!fifthMenuInView && sixthMenuInView)}>{t('consent.What will you do with my data?')}</a>
          <a href="#seventhMenu" style={getNavBarLinkStyles(!sixthMenuInView && seventhMenuInView)}>{t('consent.Are there any benefits or risks to participating?')}</a>
          <a href="#eighthMenu" style={getNavBarLinkStyles(!seventhMenuInView && eighthMenuInView)}>{t('consent.How do I withdraw from this study?')}</a>
          <a href="#ninthMenu" style={getNavBarLinkStyles(!eighthMenuInView && ninthMenuInView)}>{t('consent.Whom do I contact if I have questions?')}</a>
        </Stack>
      </Grid>
      <Grid
        item
        sx={{ backgroundColor: '#fff' }}
        lineHeight="135%"
        borderRadius={{ xs: '20px', sm: '30px' }}
        padding={{ xs: '40px 16px', sm: 5 }}
        margin={{ xs: '0px 16px', md: '0 160px 60px 160px' }}
      >
        <Box maxWidth="860px">

          <Box ref={firstMenuRef} id="firstMenu" marginBottom={4}>
            <Typography
              sx={{
                fontFamily: MONTSERRAT_ALTERNATES,
                fontWeight: 700,
                fontSize: { xs: '22px', sm: '28px' },
                lineHeight: '170%',
                letterSpacing: '-0.05em',
                marginBottom: 4,
                color: midnight,
              }}
            >
              {t('consent.Informed Consent to Participate in the Galatea Bio Research Biobank')}
            </Typography>
            <p>{t('consent.Taking part in this research is voluntary')}</p>
            <p>{t('consent.If you have any questions, concerns, or complaint')}</p>
            <p>{t('consent.In this consent form')}</p>
            <p>{t('consent.Galatea Bio would like to invite you')}</p>
            <p>{t('consent.We invite you to collaborate with us to help us answer the following pressing questions')}</p>
            <ol>
              <li>{t('consent.Why is it that some people get severe symptoms for the same disease and others do not?')}</li>
              <li>{t('consent.Why is it that some people continue to suffer long-term complications')}</li>
              <li>{t('consent.Do infectious diseases pose a risk to people')}</li>
              <li>{t('consent.Are there any associations between genetics and rare diseases?')}</li>
            </ol>
            {t('consent.Our overarching goals are to')}
          </Box>

          <Box ref={secondMenuRef} id="secondMenu" marginBottom={4}>
            <b style={{ fontSize: 20 }}>{t('consent.What am I agreeing to?')}</b>
            <Trans
              style={{ marginTop: 32 }}
              parent="p"
              t={t}
              i18nKey="consent.Most importantly, your participation in the Galatea Bio Research"
              components={{ underline: <u /> }}
            />
            <p>{t('consent.The research consists of using your genetic data')}</p>
            <ul>
              <li>{t('consent.The purpose and nature of the research')}</li>
              <li>{t('consent.The process of the research')}
                <ul>
                  <li>{t('consent.How do you provide the data')}</li>
                  <li>{t('consent.What we do with your data')}</li>
                  <li>{t('consent.What you are being asked to do')}</li>
                </ul>
              </li>
              <li>{t('consent.Any risks and benefits of participation')}</li>
              <li>{t('consent.Who to contact if you have questions.')}</li>
            </ul>
          </Box>

          <Box ref={thirdMenuRef} id="thirdMenu" marginBottom={4}>
            <b style={{ fontSize: 20 }}>{t('consent.How do I provide my genetic sample and/or genetic data?')}</b>
            <p style={{ marginTop: 32 }}>{t('consent.After you consent, we will obtain a biosample')}</p>
            <ol>
              <li>{t('consent.From your healthcare provider or clinical testing laboratory')}</li>
              <li>{t('consent.From our online platform, if you request')}</li>
              <li>{t('consent.From our online platform, by uploading')}</li>
              <li>{t('consent.From our clinical')}</li>
              <li>{t('consent.From our collaborators')}</li>
            </ol>
            <p>{t('consent.In some rare cases')}</p>
            <p>{t('consent.Samples and data will continue to be stored')}</p>
          </Box>

          <Box ref={fourthMenuRef} id="fourthMenu" marginBottom={4}>
            <b style={{ fontSize: 20 }}>{t('consent.How do I provide my clinical data?')}</b>
            <p style={{ marginTop: 32 }}>{t('consent.If you choose to provide access')}</p>
          </Box>
          
          <Box ref={fifthMenuRef} id="fifthMenu" marginBottom={4}>
            <b style={{ fontSize: 20 }}>{t('consent.How will my data be used in research?')}</b>
            <p style={{ marginTop: 32 }}>{t('consent.Your data will be pooled with information')}</p>
            <p>{t('consent.If you participate, Galatea Bio might use your genetic data')}</p>
            <p>{t('consent.Through the clinico-genomics platform')}</p>
          </Box>

          <Box ref={sixthMenuRef} id="sixthMenu" marginBottom={4}>
            <b style={{ fontSize: 20 }}>{t('consent.What will you do with my data?')}</b>
            <p style={{ marginTop: 32 }}>{t('consent.Galatea Bio will compare all participants’ information')}</p>
            <p>{t('consent.Under this informed consent, our research partners will only')}</p>
          </Box>

          <Box ref={seventhMenuRef} id="seventhMenu" marginBottom={4}>
            <b style={{ fontSize: 20 }}>{t('consent.Are there any benefits or risks to participating?')}</b>
            <div style={{ marginTop: 32 }} />
            <b style={{ fontSize: 18, color: grey800 }}>{t('consent.Benefits')}</b>
            <br />
            {t('consent.It cannot be promised')}
            <p>{t('consent.One of Galatea Bio’s missions is to make meaningful scientific contributions')}</p>
            <p>{t('consent.Galatea Bio might discover that aspects')}</p>
            <ul>
              <li>{t('consent.If you participated through a healthcare provider')}</li>
              <li>{t('consent.If you chose not to provide EHR data')}</li>
            </ul>
            <p style={{ marginBottom: 32 }}>{t('consent.You may choose to obtain a copy of the sequenced data')}</p>

            <b style={{ fontSize: 18, color: grey800 }}>{t('consent.Risks')}</b>
            <br />
            {t('consent.There are some potential risks to participating in the Galatea Bio research study')}
            <p>{t('consent.Galatea Bio uses a range of physical, technical, and administrative')}</p>
            <p>{t('consent.The Genetic Information Nondiscrimination Act (GINA) protects')}</p>
            <p>{t('consent.Galatea Bio has strong security procedures in place to minimize')}</p>
            <p>{t('consent.If you elect to receive a copy of your genetic data, you might')}</p>
            <p>{t('consent.If Galatea Bio investigators publish results of this research study')}</p>
            <p>{t('consent.As with any online service, if you disclose your account password to others')}</p>
            <p>{t('consent.There may also be additional risks to participation that are currently unforeseeable')}</p>
            <p style={{ marginBottom: 32 }}>{t('consent.None of the surveys or other procedures used by the investigators in this')}</p>

            <b style={{ fontSize: 18, color: grey800 }}>{t('consent.Compensation')}</b>
            <br />
            {t('consent.You will not receive any financial compensation or other direct benefits')}
            <p>{t('consent.Participants may choose to obtain a copy of their genotyped data')}</p>
            <p style={{ marginBottom: 32 }}>{t('consent.Galatea Bio might provide a genetic global ancestry estimation')}</p>

            <b style={{ fontSize: 18, color: grey800 }}>{t('consent.Alternatives')}</b>
            <br />
            {t('consent.This is not a treatment study')}

            <div style={{ marginTop: 32 }} />
            <b style={{ fontSize: 18, color: grey800 }}>{t('consent.Confidentiality')}</b>
            <br />
            {t('consent.Your private information')}
            <ul>
              <li>{t('consent.The research sponsor')}</li>
              <li>{t('consent.People working with or partnered with')}</li>
              <li>{t('consent.Government agencies, such as the Food and Drug Administration')}</li>
              <li>{t('consent.The Institutional Review Board (IRB) that reviewed this research')}</li>
            </ul>
            <p>{t('consent.We may publish the results of this research')}</p>
            <p>{t('consent.We protect your information from disclosure')}</p>
          </Box>
          
          <Box ref={eighthMenuRef} id="eighthMenu" marginBottom={4}>
            <b style={{ fontSize: 20 }}>{t('consent.How do I withdraw from this study?')}</b>
            <p style={{ marginTop: 32 }}>{t('consent.Your participation in this study is voluntary')}</p>
            <p>{t('consent.Your participation in this study may be stopped')}</p>
            <ul>
              <li>{t('consent.If it is in your best interest')}</li>
            </ul>
            <p>{t('consent.After you have provided your consent and submitted your survey response to us')}</p>
          </Box>
          
          <Box ref={ninthMenuRef} id="ninthMenu" marginBottom={4}>
            <b style={{ fontSize: 20 }}>{t('consent.Whom do I contact if I have questions?')}</b>
            <p style={{ marginTop: 32 }}>{t('consent.If you have any questions')}</p>
            <p>
              {t('consent.Dr. Michael Lee')}
              <br />
              {t('consent.Galatea Bio, Inc.')}
              <br />
              {t('consent.14350 Commerce Way. Miami Lake, Fl. 33016, USA')}
            </p>
            <p>{t('consent.This research is being overseen by WCG IRB')}</p>
            <ul>
              <li>{t('consent.You have questions, concerns')}</li>
              <li>{t('consent.You are not getting answers from the research team')}</li>
              <li>{t('consent.You cannot reach the research team')}</li>
              <li>{t('consent.You want to talk to someone else about the research')}</li>
              <li>{t('consent.You have questions about your rights as a research subject')}</li>
            </ul>
          </Box>

          {!isYoungerThan13 ? (
            <Box marginBottom={4}>
              <b style={{ fontSize: 20 }}>{t('consent.Online Signature of the Informed Consent')}</b>
              <p style={{ marginTop: 32 }}>{t('consent.I agree to participate in the research study')}</p>
              <RadioGroup value={agreement} onChange={onAgreementChange}>
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={t('general.yes')}
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                  disabled={viewMode}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={t('general.no')}
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                  disabled={viewMode}
                />
              </RadioGroup>

              <Box display="flex" flexDirection="column" marginTop={2}>
                <b>{t('consent.Please type your name in the box')}</b>
                <TextField
                  sx={{ marginTop: 1, marginBottom: 1, width: { sm: 400 } }}
                  value={signature}
                  onChange={onSignatureChange}
                  error={Boolean(signatureError)}
                  disabled={viewMode}
                  inputProps={{ autoComplete: 'off' }}
                />
                {(submitError || signatureError) && (
                  <Grid>
                    <Typography color="error" variant="subtitle2">
                      {submitError || signatureError}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Box>
          ) : (
            <Box marginBottom={4}>
              <b style={{ fontSize: 20 }}>{t('consent.Online Signature of the Informed Assent')}</b>
              <ul>
                <li>{t('consent.Minors aged 13 to 17 are required to assent')}</li>
                <li>{t('consent.If assent is obtained, the parent')}</li>
              </ul>
              <p>{t('consent.I agree to participate in the research study')}</p>
              <RadioGroup value={agreement} onChange={onAgreementChange}>
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={t('general.yes')}
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                  disabled={viewMode}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={t('general.no')}
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                  disabled={viewMode}
                />
              </RadioGroup>
              <Box display="flex" flexDirection="column" marginTop={2}>
                <b>{t('consent.If you are a parent or legal guardian of a minor aged 0-12')}</b>
                <TextField
                  sx={{ marginTop: 1, marginBottom: 1, width: { sm: 400 } }}
                  value={signature}
                  onChange={onSignatureChange}
                  error={Boolean(signatureError)}
                  disabled={viewMode}
                  inputProps={{ autoComplete: 'off' }}
                />
                {(submitError || signatureError) && (
                  <Grid>
                    <Typography color="error" variant="subtitle2">
                      {submitError || signatureError}
                    </Typography>
                  </Grid>
                )}
              </Box>
              <Box display="flex" flexDirection="column" marginTop={2}>
                <b>{t('consent.If you are a parent or legal guardian')}</b>
                <TextField
                  sx={{ marginTop: 1, marginBottom: 1, width: { sm: 400 } }}
                  value={parentSignature}
                  onChange={onParentSignatureChange}
                  error={Boolean(parentSignatureError)}
                  disabled={viewMode}
                  inputProps={{ autoComplete: 'off' }}
                />
                {(submitError || parentSignatureError) && (
                  <Grid>
                    <Typography color="error" variant="subtitle2">
                      {submitError || parentSignatureError}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Box>
          )}

          {!viewMode && email && onEmailEdit && (
            <Box marginTop={4}>
              <Typography sx={{ display: 'inline-block', fontSize: '16px', marginRight: 2, marginBottom: 2 }}>
                <Trans t={t} i18nKey="consent.We’ll send" values={{ email }}>
                  We’ll send the activation link to <b>{email}</b>
                </Trans>
              </Typography>
              <Button sx={{ fontSize: '16px' }} size="small" variant="contained" onClick={onEmailEdit}>
                {t('consent.Edit email')}
              </Button>
            </Box>
          )}

          {!viewMode && (
            <LoadingButton
              disabled={agreement === 'no'}
              loading={isSubmitting}
              variant="contained"
              sx={{ width: 160, marginTop: 4 }}
              onClick={onSubmitHandler}
            >
              Submit
            </LoadingButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConsentPage;
