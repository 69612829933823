import { styled, Alert } from '@mui/material';

export const InstructionList = styled('ol')(({ theme }) => ({
  paddingInlineStart: '20px',

  '& a': {
    color: theme.palette.common.black,
    fontWeight: 700,
  },
}));

export const InstructionLink = styled('div')(({ theme }) => ({
  cursor: 'pointer',

  '& a': {
    color: theme.palette.common.black,
  },
}));

export const ErrorAlert = styled(Alert)(() => ({
  alignItems: 'center',
  borderRadius: '6px',
}));
