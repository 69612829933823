import { Ancestry } from 'api/Reports/types';
import AncestrySelect from 'common/components/AncestrySelect/AncestrySelect';
import { filterAncestry } from 'common/helpers/ancestry';

import React, { useState } from 'react';
import { Popover, SelectChangeEvent, Stack, Typography } from '@mui/material';
import map from 'lodash/map';
import round from 'lodash/round';

export interface Data {
  anchorEl: Element;
  ancestries: Ancestry[];
}

interface Props {
  onClose: () => void;
  data: Data;
}

const AncestryPopover: React.FC<Props> = ({ data, onClose }) => {
  const [ selectedAncestryIndex, setSelectedAncestryIndex ] = useState<number>(0);
  const [ selectedAncestry, setSelectedAncestry ] = useState<Ancestry>(data.ancestries[0]);
  const filteredAncestry = filterAncestry(selectedAncestry);

  const onSelectAncestry = (e: SelectChangeEvent<number>) => {
    const index = Number(e.target.value);
    setSelectedAncestryIndex(index);
    setSelectedAncestry(data.ancestries[index]);
  };
  
  return (
    <Popover
      open={Boolean(data)}
      anchorEl={data?.anchorEl}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{ sx: { padding: 2 } }}
    >
      <AncestrySelect ancestries={data.ancestries} defaultValue={selectedAncestryIndex} onChange={onSelectAncestry} smallItems />
      <Stack spacing={1} marginTop={1}>
        {map(filteredAncestry, (ancestry, ancestryKey) => (
          <Typography key={ancestryKey} sx={{ fontSize: '14px', fontFamily: 'Red Hat Mono' }}>
            {`${ancestryKey}: ${round(ancestry, 2)}%`}
          </Typography>
        ))}
      </Stack>
    </Popover>
  );
};

export default AncestryPopover;
