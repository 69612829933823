export interface News extends NewsBase {
  body: {
    body: string;
    resourceUrl: string;
  }
}

export interface NewsBase {
  id: string;
  newsType: NewsType;
  readableId: string;
  title: {
    title: string;
    imageUrl: string;
    dataUrl: string;
  };
  newsDate: string;
}

export interface NewsSubmitData {
  type: NewsType;
  title: string;
  body: string;
  readableId: string;
  newsDate: string;
  imageFile?: File;
  dataFile?: File;
  resourceUrl: string;
}

export enum NewsType {
  PUBLICATION,
  NEWS,
}
