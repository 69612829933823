import { MONTSERRAT_ALTERNATES } from 'common/constants/fonts';
import { midnight } from 'common/constants/colors';
import ProgressBar from 'components/Dashboard/components/ProgressBar/ProgressBar';
import AddAncestryView from 'components/Dashboard/components/AncestryPage/components/AddAncestryView/AddAncestryView';
import AncestryFilesView from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/AncestryFilesView';
import { DashboardLocationState } from 'components/Dashboard/components/CardsPage/types';
import { RootState } from 'store';
import { getAncestryProgress } from 'common/helpers/ancestry';
import { DASHBOARD_ROUTE } from 'common/constants/urls';

import { CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AncestryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { myUser: { ancestries, testKitReceived, ancestryFiles, surveyCompletedPercentage }, isMyUserLoading } = useSelector((state: RootState) => state.user);
  const isSurveyCompleted = surveyCompletedPercentage === 100;
  const ancestryProgress = getAncestryProgress(ancestries, ancestryFiles, testKitReceived);

  useEffect(() => {
    if (!isSurveyCompleted) {
      const state: DashboardLocationState = { showAncestryLock: true };
      navigate(DASHBOARD_ROUTE, { state });
    }
  });
  
  if (isMyUserLoading) {
    return <CircularProgress data-testid="loader" />;
  }

  return (
    <Grid
      padding={{ xs: '40px 16px', sm: 4, xl: 5 }}
      borderRadius={{ xs: '20px', sm: '30px' }}
      sx={{ backgroundColor: '#fff' }}
      display="flex"
      flexDirection="column"
      minHeight="600px"
      data-testid="ancestry-page"
    >
      <Grid item xs={12} paddingBottom={{ xs: 5, sm: 6 }} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
        <Typography sx={{
          fontFamily: MONTSERRAT_ALTERNATES,
          fontWeight: 700,
          fontSize: { xs: '18px', sm: '28px' },
          lineHeight: '170%',
          letterSpacing: '-0.05em',
          color: midnight,
        }}
        >
          {t('dashboard.ancestry.ancestry')}
        </Typography>
        <ProgressBar horizontal={false} progress={ancestryProgress} />
      </Grid>
      <Routes>
        <Route path="/" element={<AncestryFilesView />} />
        <Route path="/add" element={<AddAncestryView />} />
      </Routes>
    </Grid>
  );
};

export default AncestryPage;
