import { Sample } from 'api/Samples/types';
import { useRemoveSample } from 'components/AdminDashboard/components/SamplesPage/components/SamplesListModal/components/RemoveSampleModal/hooks';

import React from 'react';
import { Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
  onCancel: () => void;
  sample: Sample;
}

const RemoveSampleModal: React.FC<Props> = ({ onCancel, sample }) => {
  const { removeSample, isLoading, error } = useRemoveSample();

  return (
    <Modal open onClose={onCancel}>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '600px' },
        }}
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="textXLBold">
                Remove sample
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Are you sure you want to remove the sample?
              </Typography>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography color="error" variant="subtitle2" sx={{ whiteSpace: 'break-spaces' }}>
                  {error}
                </Typography>
              </Grid>
            )}
            <Grid container spacing={2} item xs={12}>
              <Grid item>
                <LoadingButton variant="contained" onClick={() => removeSample(sample)} loading={isLoading}>
                  Yes
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onCancel} disabled={isLoading}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};

export default RemoveSampleModal;
