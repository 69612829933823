import { NewsType } from 'api/News/types';
import { createNews, getNews, updateNews } from 'api/News/api';
import { NEWS_ID_NEW } from 'components/AdminDashboard/components/NewsEditPage/constants';
import { getReadableId, getValidationSchema } from 'components/AdminDashboard/components/NewsEditPage/helpers';
import { NewsSubmitDataValues } from 'components/AdminDashboard/components/NewsEditPage/types';

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const EMPTY_SUBMIT_DATA = {
  type: 0,
  title: '',
  body: '',
  readableId: '',
  newsDate: '',
  imageFile: undefined,
  imageFilePreview: undefined,
  resourceUrl: '',
  dataFile: undefined,
  dataFilePreview: undefined,
};

export const useEditNews = (newsId: string | undefined) => {
  const navigate = useNavigate();

  const [ initialValues, setInitialValues ] = useState<NewsSubmitDataValues>(EMPTY_SUBMIT_DATA);
  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  const [ newsType, setNewsType ] = useState<NewsType>(NewsType.PUBLICATION);

  const [ submitError, setSubmitError ] = useState('');
  const [ isSubmitting, setSubmitting ] = useState(false);

  useEffect(() => {
    if (newsId && newsId !== NEWS_ID_NEW) {
      setLoading(true);
      getNews(newsId)
        .then(({ data }) => {
          setNewsType(data.newsType);
          setInitialValues({
            type: parseInt(String(data.newsType), 10),
            title: data.title.title,
            body: data.body.body,
            readableId: data.readableId,
            newsDate: data.newsDate,
            imageFilePreview: data.title.imageUrl,
            dataFilePreview: data.title.dataUrl,
            resourceUrl: data.body.resourceUrl,
          });
        })
        .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
        .finally(() => setLoading(false));
    }
  }, [ newsId ]);

  const formikSubmit = ({ title, body, newsDate, imageFile, dataFile, resourceUrl }: NewsSubmitDataValues) => {
    setSubmitting(true);

    const data = new FormData();
    data.append('type', String(newsType));
    data.append('title', title);
    data.append('body', body);
    data.append('readableId', getReadableId(title));
    data.append('newsDate', newsDate);
    if (imageFile) {
      data.append('imageFile', imageFile);
    }
    if (dataFile) {
      data.append('dataFile', dataFile);
    }
    data.append('resourceUrl', resourceUrl);

    if (newsId === NEWS_ID_NEW) {
      createNews(data)
        .then(() => {
          navigate('/admin-dashboard/news');
        })
        .catch((error) => {
          setSubmitError(error?.response?.data?.message || 'Server error. Please try again.');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else if (newsId) {
      updateNews(newsId, data)
        .then(() => { // then print response status
          navigate('/admin-dashboard/news');
        })
        .catch((error) => {
          setSubmitError(error?.response?.data?.message || 'Server error. Please try again.');
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const formik = useFormik<NewsSubmitDataValues>({
    initialValues,
    onSubmit: formikSubmit,
    enableReinitialize: true,
    validationSchema: getValidationSchema(newsType),
  });

  return {
    formik,
    isSubmitting,
    isLoading,
    error,
    submitError,
    newsType,
    setNewsType,
  };
};
