import { Organization } from 'api/Organizations/types';
import useReportsFilter from 'components/AdminDashboard/components/ReportsPage/components/ReportsFilterPopover/hooks';
import { RootState } from 'store';

import React from 'react';
import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Popover, Select, SelectChangeEvent } from '@mui/material';
import { FormikProvider } from 'formik';
import { useSelector } from 'react-redux';

interface Props {
  onClose: () => void;
  data: HTMLButtonElement | null;
}

const ReportsFilterPopover: React.FC<Props> = ({ data, onClose }) => {
  const { formik, clearFormAndSearchParams } = useReportsFilter(onClose);
  const { organizations } = useSelector((store: RootState) => store.organizations);

  const onClearClick = () => clearFormAndSearchParams();

  const onPartnersChange = (event: SelectChangeEvent<typeof formik.values.organizationIds>) => {
    const { target: { value } } = event;
    const partners: number[] = typeof value === 'string' ? [ Number(value) ] : value!;
    formik.setFieldValue('organizationIds', partners);
  };

  return (
    <Popover
      open={Boolean(data)}
      anchorEl={data}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 1,
        sx: { padding: 4, width: 320 },
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display="flex" flexDirection="column" item container spacing={3}>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="organization-ids-label">Partner</InputLabel>
                <Select
                  labelId="organization-ids-label"
                  multiple
                  value={formik.values.organizationIds}
                  name="organizationIds"
                  size="small"
                  onChange={onPartnersChange}
                  input={<OutlinedInput size="small" label="Partner" />}
                  renderValue={selectedOrg => organizations
                    .filter((org: Organization) => selectedOrg.includes(org.id))
                    .map((org: Organization) => org.name)
                    .join(', ')}
                  variant="outlined"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: { xs: '50%', md: '70%' },
                        overflow: 'auto',
                      },
                    },
                  }}
                >
                  {organizations.map((org: Organization) => (
                    <MenuItem key={org.id} value={org.id}>
                      <Checkbox checked={formik.values.organizationIds?.includes(org.id)} />
                      <ListItemText primary={org.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="ancestry-report-status-label">Ancestry Report status</InputLabel>
                <Select
                  labelId="ancestry-report-status-label"
                  value={formik.values.ancestryReportStatus}
                  name="ancestryReportStatus"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="Ancestry Report status" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Generated">Generated</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="prs-report-status-label">PRS Report status</InputLabel>
                <Select
                  labelId="prs-report-status-label"
                  value={formik.values.prsReportStatus}
                  name="prsReportStatus"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="PRS Report status" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Generated">Generated</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="dna-uploaded-status-label">DNA uploaded status</InputLabel>
                <Select
                  labelId="dna-uploaded-status-label"
                  value={formik.values.dnaUploadedStatus}
                  name="dnaUploadedStatus"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="DNA uploaded status" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="Uploaded">Uploaded</MenuItem>
                  <MenuItem value="NotUploaded">Not uploaded</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="dna-sample-status-label">DNA sample status</InputLabel>
                <Select
                  labelId="dna-sample-status-label"
                  value={formik.values.dnaSampleStatus}
                  name="dnaSampleStatus"
                  size="small"
                  variant="outlined"
                  input={<OutlinedInput size="small" label="DNA samole status" />}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="NotStarted">Not started</MenuItem>
                  <MenuItem value="Received">Received</MenuItem>
                  <MenuItem value="Requested">Requested</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} marginTop={1} container flexDirection="row-reverse">
              <Button type="submit" variant="contained" size="large" sx={{ marginLeft: 2 }}>
                Apply
              </Button>

              <Button variant="outlined" size="large" onClick={onClearClick}>
                Clear
              </Button>
            </Grid>

          </Grid>
        </form>
      </FormikProvider>
    </Popover>
  );
};

export default ReportsFilterPopover;
