import { getToken } from 'common/helpers/auth';
import { RootState } from 'store';
import { AUTH_LOADING, LOGIN_SUCCESS, NOT_AUTHORIZED } from 'store/auth/types';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useAuth = () => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((store: RootState) => store.auth);

  useEffect(() => {
    dispatch({ type: AUTH_LOADING });

    const token = getToken();

    if (token) {
      dispatch({ type: LOGIN_SUCCESS, token });
    } else {
      dispatch({ type: NOT_AUTHORIZED });
    }
  }, [ dispatch ]);

  return { isAuthenticated };
};

export default useAuth;
