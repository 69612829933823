import ProcessingImg from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/resources/processing.svg';
import WaitingImg from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/resources/waiting.svg';
import { ReportStatus } from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/types';
import AncestrySelect from 'common/components/AncestrySelect/AncestrySelect';
import { RootState } from 'store';
import { useAncestryData } from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/hooks';
import { grey300 } from 'common/constants/colors';
import { Ancestry } from 'api/Reports/types';
import AncestryChart
  from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/components/AncestryChart/AncestryChart';
import AncestryMap
  from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/components/AncestryMap/AncestryMap';
import AncestrySevenLabels
  from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/components/AncestrySevenLabels/AncestrySevenLabels';

import html2canvas from 'html2canvas';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { Download, Share } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  status: ReportStatus;
}

const AncestryReportView: React.FC<Props> = ({ status }) => {
  const { myUser: { ancestries } } = useSelector((store: RootState) => store.user);
  const { t } = useTranslation();
  const shareContentRef = useRef<HTMLElement | null>(null);
  const [ canShareImage, setCanShareImage ] = useState(false);
  const [ showSharingError, setShowSharingError ] = useState(false);

  const [ selectedAncestryIndex, setSelectedAncestryIndex ] = useState<number>(0);
  const [ selectedAncestry, setSelectedAncestry ] = useState<Ancestry | null>(ancestries.length > 0 ? ancestries[0] : null);
  const { mapData, chartData } = useAncestryData(selectedAncestry);

  const onSelectAncestry = (e: SelectChangeEvent<number>) => {
    const index = Number(e.target.value);
    setSelectedAncestryIndex(index);
    setSelectedAncestry(ancestries![index]);
  };

  useEffect(() => {
    if (shareContentRef === null || shareContentRef.current === null) {
      return;
    }

    const generateBlob = async () => {
      const canvas = await html2canvas(shareContentRef.current!, { logging: false });

      if (canvas) {
        canvas.toBlob(async (blob) => {
          const image = new File([ blob! ], 'Ancestry.png', { type: blob!.type });
          const data = { files: [ image ] };
          // Unfortunately, we can't store data in the state because there is a bunch of chart animations on load

          if (navigator.canShare && navigator.canShare(data)) {
            setCanShareImage(true);
          }
        });
      }
    };

    generateBlob();
  }, [ shareContentRef ]);

  const handleDownload = async () => {
    if (shareContentRef === null || shareContentRef.current === null) {
      return;
    }

    const canvas = await html2canvas(shareContentRef.current, { logging: false });
    const image = canvas.toDataURL('image/png', 1);
    const link = document.createElement('a');
    link.download = 'Ancestry.png';
    link.href = image;
    link.click();
  };

  const handleShare = async () => {
    if (shareContentRef === null || shareContentRef.current === null) {
      return;
    }

    const canvas = await html2canvas(shareContentRef.current, { logging: false });
    canvas.toBlob(async (blob) => {
      const image = new File([ blob! ], 'Ancestry.png', { type: blob!.type });
      const data = { files: [ image ] };

      try {
        await navigator.share(data);
      } catch (err) {
        if ((err as DOMException).code !== DOMException.ABORT_ERR) {
          setShowSharingError(true);
        }
      }
    });
  };

  if (ancestries.length === 0) {
    return (
      <Stack spacing={{ xs: 2, md: 4 }} direction="row" alignItems="center" width="100%" data-testid="ancestry-report-status">
        {
          status === ReportStatus.IN_PROGRESS ? (
            <Box minWidth={{ xs: '27px', md: '54px' }} width={{ xs: '27px', md: '54px' }} height={{ xs: '35px', md: '70px' }}>
              <img src={ProcessingImg} alt="" width="100%" height="100%" />
            </Box>
          ) : (
            <Box minWidth={{ xs: '34px', md: '68px' }} width={{ xs: '34px', md: '68px' }} height={{ xs: '34px', md: '68px' }}>
              <img src={WaitingImg} alt="" width="100%" height="100%" />
            </Box>
          )
        }
        <Box maxWidth={616}>
          <Typography variant="textLMedium" color="neutral.90" sx={{ maxWidth: 616 }}>
            {
              status === ReportStatus.IN_PROGRESS ?
                t('dashboard.ancestry.hint_ancestry_report_in_progress') :
                t('dashboard.ancestry.hint_ancestry_report_waiting_sample')
            }
          </Typography>
        </Box>
      </Stack>
    );
  }

  return (
    <>
      <Box ref={shareContentRef} sx={{ backgroundColor: grey300, borderRadius: '18px', padding: { xs: 2, sm: 4, lg: 8 } }} data-testid="ancestry-report">
        <Grid container spacing={2}>
          <Grid xs={12} sm={5} item>
            <AncestryChart ancestryData={chartData} />
          </Grid>
          <Grid xs={12} sm={7} item>
            <AncestryMap ancestryData={mapData} />
          </Grid>
          <Grid xs={12} item>
            <Grid container direction="row" justifyContent={{ xs: 'flex-start', sm: 'space-between' }} flexWrap="wrap" spacing={2}>
              {chartData.map(({ color, title, value }) => (
                <Grid item xs={12} sm="auto" key={title}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Box sx={{ backgroundColor: color }} width="19px" height="19px" />
                    <Typography whiteSpace="nowrap" variant="textMMedium">{`${title} ${value}%`}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {showSharingError && (
        <Alert sx={{ borderRadius: '6px', marginTop: 2 }} severity="warning">
          <Trans t={t} i18nKey="dashboard.ancestry.sharing.error">
            Web Share API is not supported in your browser, but you can
            <Button
              sx={{ verticalAlign: 'unset', padding: 0, textTransform: 'none', '&:hover': { backgroundColor: 'inherit' } }}
              size="small"
              onClick={handleDownload}
            />
          </Trans>
        </Alert>
      )}
      <Stack justifyContent="space-between" flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }} marginTop={2}>
        <AncestrySelect defaultValue={selectedAncestryIndex} ancestries={ancestries} onChange={onSelectAncestry} />
        {!showSharingError && (
          <Button
            startIcon={canShareImage ? <Share /> : <Download />}
            variant="contained"
            onClick={canShareImage ? handleShare : handleDownload}
            sx={{ marginTop: { xs: 2, md: 0 } }}
          >
            {canShareImage ?
              t('dashboard.ancestry.sharing.share') :
              t('dashboard.ancestry.sharing.download')}
          </Button>
        )}
      </Stack>
      <AncestrySevenLabels />
    </>
  );
};

export default AncestryReportView;
