import { AncestryProvider } from 'api/Reports/types';

import React, { useState } from 'react';
import { Stack } from '@mui/material';

import UploadFileView from './components/UploadFileView/UploadFileView';
import SelectProviderView from './components/SelectProviderView/SelectProviderView';

const AddAncestryView: React.FC = () => {
  const [ provider, setProvider ] = useState<AncestryProvider>(AncestryProvider.EMPTY);
  const [ providerSelected, setProviderSelected ] = useState(false);

  const handleProviderChange = (value: AncestryProvider) => setProvider(value);

  return (
    <Stack
      flexGrow={1}
      spacing={2}
      direction="column"
      width="100%"
      height="100%"
      alignItems="flex-start"
      justifyContent="space-between"
      marginBottom={{ xs: '124.5px', md: '87.5px' }}
      data-testid="add-ancestry-view"
    >
      {!providerSelected ?
        <SelectProviderView provider={provider} onChange={handleProviderChange} onComplete={setProviderSelected} /> :
        <UploadFileView provider={provider!} />}
    </Stack>
  );
};

export default AddAncestryView;
