import HealthInfoQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HealthInfoQuestion/HealthInfoQuestion';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';

import React from 'react';

const DISEASES = [
  'Acquired Immune Deficiency Syndrome (AIDS)',
  'Mpox',
  'Chagas',
  'Dengue',
  'Yellow Fever',
  'Chikungunya',
  'COVID-19',
  'Other',
];

const InfectiousDiseaseQuestion: React.FC<QuestionPageProps> = ({ onNext, onPrev, onSubmit }) => {
  return <HealthInfoQuestion onPrev={onPrev} onNext={onNext} onSubmit={onSubmit} field="infectiousDisease" diseases={DISEASES} />;
};

export default InfectiousDiseaseQuestion;
