import { formatDate } from 'common/helpers/dateTime';
import { isDataScienceAdmin } from 'common/helpers/user';
import SampleMaskedInput from 'components/AdminDashboard/components/SamplesPage/components/SamplesListModal/components/SampleMaskedInput/SampleMaskedInput';
import RemoveSampleModal from 'components/AdminDashboard/components/SamplesPage/components/SamplesListModal/components/RemoveSampleModal/RemoveSampleModal';
import { useCreateSample } from 'components/AdminDashboard/components/SamplesPage/components/SamplesListModal/components/SampleInput/hooks';
import { SAMPLE_ID_LENGTH } from 'api/Samples/constants';
import { Sample } from 'api/Samples/types';
import { RootState } from 'store';

import { FormHelperText, Grid, IconButton, OutlinedInput, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Clear } from '@mui/icons-material';
import { useSelector } from 'react-redux';

interface Props {
  sample: Sample;
  removeEmptySample: () => void;
}

const SampleInput: React.FC<Props> = ({ sample, removeEmptySample }) => {
  const { myUser } = useSelector((store: RootState) => store.user);

  const [ value, setValue ] = React.useState(sample.id === 0 ? '' : sample.sampleId);
  const [ sampleToRemove, setSampleToRemove ] = useState<Sample | null>(null);

  const closeModal = () => setSampleToRemove(null);

  const {
    createSample,
    isLoading,
    error,
    setError,
  } = useCreateSample(removeEmptySample);
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setValue(event.target.value);
  };

  const handleRemove = () => {
    if (sample.id === 0) {
      removeEmptySample();
    } else {
      setSampleToRemove(sample);
    }
  };

  const handleSave = () => {
    if (value.length < SAMPLE_ID_LENGTH) {
      setError(`Sample id must contain ${SAMPLE_ID_LENGTH} characters`);
      return;
    }

    createSample(sample.userId, value);
  };

  return (
    <Grid item xs={12} sx={{ '&:not(:last-of-type)': { marginTop: 2 } }}>
      <Grid item container spacing={2} xs={12} alignItems="center">
        <Grid item sx={{ minWidth: '446px' }}>
          {sample.id !== 0 ? (
            <Typography variant="textLRegular" sx={{ minWidth: '430px', fontFamily: 'Red Hat Mono' }}>
              {sample.sampleId}
            </Typography>
          ) : (
            <OutlinedInput
              name={`sample-${sample.id}`}
              value={value}
              onChange={handleChange}
              inputComponent={SampleMaskedInput as any}
              error={Boolean(error)}
              size="small"
              disabled={sample.id !== 0 || isLoading}
              sx={{
                minWidth: '430px',
                fontFamily: 'Red Hat Mono',
              }}
            />
          )}
        </Grid>
        <Grid item>
          {sample.id === 0 ? (
            <LoadingButton variant="contained" onClick={handleSave} loading={isLoading}>
              Save
            </LoadingButton>
          ) : (
            <Typography variant="textSRegular">
              {formatDate(sample.createdAt)}
            </Typography>
          )}
        </Grid>
        {!isDataScienceAdmin(myUser) && (
          <Grid item>
            <Tooltip title="Remove sample">
              <IconButton
                disabled={isLoading}
                color="primary"
                size="small"
                onClick={handleRemove}
              >
                <Clear fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {error && (
        <Grid item xs={12}>
          <FormHelperText error data-testid="error-kit-length">
            {error}
          </FormHelperText>
        </Grid>
      )}
      {sampleToRemove && <RemoveSampleModal onCancel={closeModal} sample={sampleToRemove} />}
    </Grid>
  );
};

export default SampleInput;
