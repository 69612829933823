import HealthInfoQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HealthInfoQuestion/HealthInfoQuestion';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';

import React from 'react';

const DISEASES = [
  'Type 1 diabetes',
  'Type 2 diabetes',
  'Familial hypercholesterolemia',
  'Hypothyroidism',
  'Other',
];

const MetabolicDiseaseQuestion: React.FC<QuestionPageProps> = ({ onNext, onPrev, onSubmit }) => {
  return <HealthInfoQuestion onPrev={onPrev} onNext={onNext} onSubmit={onSubmit} field="metabolicDisease" diseases={DISEASES} />;
};

export default MetabolicDiseaseQuestion;
