import { FileFormat } from 'api/User/types';

import { DateTime } from 'luxon';

export const downloadCSVFile = (fileName: string, data: string, extension = FileFormat.CSV) => {
  const a = document.createElement('a');
  const event = new MouseEvent('click');
  const href = `data:text/csv;charset=UTF-8,\uFEFF${encodeURIComponent(data)}`;
  const currentDate = DateTime.fromJSDate(new Date()).toFormat('MM_dd_yyyy_h_mma');

  a.href = href;
  a.download = `${fileName}_${currentDate}.${extension}`;
  a.dispatchEvent(event);
};
