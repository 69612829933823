import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV1 } from 'api/User/types';
import styles from 'components/Dashboard/components/SurveyPage/components/inputStyles.module.css';

import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LatinoOriginQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);

  const [ error, setError ] = useState('');
  const [ value, setValue ] = useState('');
  const [ otherValue, setOtherValue ] = useState('');

  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    if (value === 'other' && otherValue) {
      submit({
        ...survey,
        latinoOrigin: { value, other: otherValue },
      })
        .then((data: Survey) => onNext(data));
    } else if (value && value !== 'other') {
      submit({
        ...survey,
        latinoOrigin: { value },
      })
        .then((data: Survey) => onNext(data));
    } else {
      setError(t('dashboard.survey.error_give_answer'));
    }
  }, [ t, value, otherValue, submit, survey, onNext ]);

  const onRadioChanged = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  const onOtherChanged = useCallback((event) => {
    setOtherValue(event.target.value);
  }, []);

  useEffect(() => {
    setValue((survey as SurveyV1)?.latinoOrigin?.value || '');
    setOtherValue((survey as SurveyV1)?.latinoOrigin?.other || '');
  }, [ survey ]);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-latino-origin">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_latino_origin.question')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              onChange={onRadioChanged}
              value={value}
            >
              <FormControlLabel value="no" control={<Radio />} label={t('dashboard.survey.question_latino_origin.options.no')} data-testid="option-no" />
              <FormControlLabel value="mexican" control={<Radio />} label={t('dashboard.survey.question_latino_origin.options.mexican')} />
              <FormControlLabel value="puertoRican" control={<Radio />} label={t('dashboard.survey.question_latino_origin.options.puerto_rican')} />
              <FormControlLabel value="cuban" control={<Radio />} label={t('dashboard.survey.question_latino_origin.options.cuban')} />
              <FormControlLabel
                value="other"
                classes={{
                  label: styles.otherRadioWrapper,
                  root: styles.otherRadioWrapper,
                }}
                control={<Radio />}
                data-testid="option-other"
                label={(
                  <Box display="flex" justifyContent="center" alignItems="center" className={styles.otherInputWrapper}>
                    <span>{t('dashboard.survey.question_latino_origin.yes_another')}</span>
                    <TextField
                      sx={{ marginLeft: { xs: 0, sm: 2 }, width: { xs: '100%', sm: 300 }, marginTop: { xs: 1, sm: 0 } }}
                      variant="outlined"
                      size="small"
                      color="primary"
                      value={otherValue}
                      onChange={onOtherChanged}
                      error={Boolean(error) && value === 'other'}
                      disabled={value !== 'other'}
                      inputProps={{ 'data-testid': 'input-other' }}
                    />
                  </Box>
                )}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="primary" maxWidth={425} fontWeight={400}>
            {t('dashboard.survey.question_latino_origin.hint_another')}
          </Typography>
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default LatinoOriginQuestion;
