import { addAdmin } from 'api/User/api';
import { UserAccessLevel } from 'api/User/types';
import { getUsers } from 'store/users/actions';

import { useDispatch } from 'react-redux';
import { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export interface AddAdminFormValues {
  email: string;
  role: UserAccessLevel | null;
}

const useAddAdmin = (onSuccessfulSubmit: () => void) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  const validationSchema = useMemo(() => yup.object({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Enter an email'),
    role: yup
      .string()
      .nullable()
      .required('Select a role'),
  }), []);

  const onSubmit = ({ email, role }: AddAdminFormValues) => {
    setSubmitting(true);
    setSubmitError('');

    addAdmin(email, role!)
      .then(() => {
        dispatch(getUsers(0, 10, { onlyAdmins: true }));
        onSuccessfulSubmit();
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    email: '',
    role: null,
  };

  const formik = useFormik<AddAdminFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return { formik, isSubmitting, submitError };
};

export default useAddAdmin;
