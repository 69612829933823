import { AuthorizationContainer, FormTextField, Label } from 'common/components';
import { PartnerInfo } from 'api/User/types';
import { RegisterResult } from 'api/Auth/types';
import { ageFromGivenDate } from 'common/helpers/dateTime';
import { getLocalUtmParams } from 'common/helpers/getLocalUtmParams';
import useSignUpForm from 'components/Dashboard/components/Authorization/SignUpPage/components/SignUp/hooks/useSignUpForm';
import ConsentPage from 'components/Dashboard/components/Authorization/SignUpPage/components/SignUp/components/ConsentPage';
import AssentPage from 'components/Dashboard/components/Authorization/SignUpPage/components/SignUp/components/AssentPage';
import useLocalizedProfile from 'components/Dashboard/components/ProfilePage/hooks/useLocalizedProfile';
import { AmplitudeEvent } from 'common/constants/amplitude';
import { COUNTRIES_NAMES } from 'common/constants/countries';
import { MALE, FEMALE, OTHER, UNSPECIFIED } from 'common/constants/genders';
import { disallowComma } from 'common/helpers/disallowComma';

import React, { useCallback, useState, useMemo } from 'react';
import { FormikProvider } from 'formik';
import { Autocomplete, Box, Button, FormControl, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LogOnMount } from 'react-amplitude-hooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { noop } from 'lodash';

interface Props {
  partnerValues?: PartnerInfo
  onSuccess: (data: RegisterResult) => void
}

const SignUp = ({ partnerValues, onSuccess }: Props) => {
  const { t, i18n } = useTranslation();
  const [ firstStepCompleted, setFirstStepCompleted ] = useState<boolean>(false);
  const [ isDatePickerOpened, setIsDatePickerOpened ] = useState<boolean>(false);
  const { formik, isSubmitting, submitError, signUp } = useSignUpForm(onSuccess, setFirstStepCompleted, partnerValues);
  const { getLocalizedGender } = useLocalizedProfile();
  const utmParams = getLocalUtmParams();

  const onConsentCompleteHandler = useCallback((signature: string, parentSignature: string) => {
    signUp(formik.values, signature, parentSignature);
  }, [ formik.values, signUp ]);

  const currentAge = useMemo(() => {
    if (!formik.values.dob) {
      return 18; // if there is no dob then we can suppose that a user is over 18 years old
    }
    return ageFromGivenDate(formik.values.dob);
  }, [ formik.values.dob ]);

  const isBetween13And18 = currentAge >= 13 && currentAge < 18;

  const openDatePicker = () => setIsDatePickerOpened(true);
  const closeDatePicker = () => setIsDatePickerOpened(false);

  const onCountryChangeHandler = useCallback((_, newValue: string | null) => {
    formik.setFieldValue('country', newValue ?? '');
  }, [ formik ]);

  const renderSecondStep = useCallback(() => (
    isBetween13And18 ? (
      <AssentPage
        submitError={submitError}
        isSubmitting={isSubmitting}
        onConsentSubmit={onConsentCompleteHandler}
        email={formik.values.email}
        onEmailEdit={() => setFirstStepCompleted(false)}
      />
    ) : (
      <ConsentPage
        currentAge={currentAge}
        submitError={submitError}
        isSubmitting={isSubmitting}
        onConsentSubmit={onConsentCompleteHandler}
        email={formik.values.email}
        onEmailEdit={() => setFirstStepCompleted(false)}
      />
    )
  ), [ submitError, isSubmitting, onConsentCompleteHandler, formik.values.email, currentAge, isBetween13And18 ]);

  return !firstStepCompleted ? (
    <LogOnMount eventType={AmplitudeEvent.REGISTRATION_VISITED}>
      <AuthorizationContainer>
        <Label>{t('auth.registration')}</Label>
        <Box minWidth={{ sm: 400 }}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormTextField
                    id="firstName"
                    name="firstName"
                    label={t('dashboard.profile.first_name')}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.errors.firstName}
                    touched={formik.touched.firstName}
                    disabled={isSubmitting}
                    onKeyDown={disallowComma}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    id="signUpLastName"
                    name="lastName"
                    label={t('dashboard.profile.last_name')}
                    type="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.errors.lastName}
                    touched={formik.touched.lastName}
                    disabled={isSubmitting}
                    onKeyDown={disallowComma}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    id="signUpEmail"
                    name="email"
                    label={t('general.email')}
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.errors.email}
                    touched={formik.touched.email}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
                    <DatePicker
                      open={isDatePickerOpened}
                      label={t('dashboard.profile.date_of_birth')}
                      value={formik.values.dob}
                      onChange={(newValue) => {
                        formik.setFieldValue('dob', newValue);
                      }}
                      onOpen={openDatePicker}
                      onClose={closeDatePicker}
                      renderInput={params => (
                        <TextField
                          {...params}
                          helperText={formik.touched.dob && formik.errors.dob}
                          error={formik.touched.dob && Boolean(formik.errors.dob)}
                          size="small"
                          fullWidth
                          data-testid="input-dob"
                          onClick={openDatePicker}
                        />
                      )}
                      disabled={isSubmitting}
                      disableFuture
                      disableMaskedInput
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      id="gender"
                      select
                      value={formik.values.gender.toLowerCase()}
                      label={t('dashboard.profile.gender')}
                      name="gender"
                      size="small"
                      disabled={isSubmitting}
                      onChange={formik.handleChange}
                      helperText={formik.touched.gender && formik.errors.gender}
                      error={formik.touched.gender && Boolean(formik.errors.gender)}
                      data-testid="input-gender"
                    >
                      <MenuItem value={MALE}>{getLocalizedGender('male')}</MenuItem>
                      <MenuItem value={FEMALE}>{getLocalizedGender('female')}</MenuItem>
                      <MenuItem value={OTHER}>{getLocalizedGender('other')}</MenuItem>
                      <MenuItem value={UNSPECIFIED}>{getLocalizedGender('unspecified')}</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    onChange={onCountryChangeHandler}
                    value={formik.values.country}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t('dashboard.profile.country')}
                        name="country"
                        disabled={isSubmitting}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        helperText={formik.touched.country && formik.errors.country}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                    options={COUNTRIES_NAMES}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    id="zip"
                    name="zip"
                    label={t('dashboard.profile.address.zip')}
                    value={formik.values.zip}
                    onChange={formik.handleChange}
                    error={formik.errors.zip}
                    touched={formik.touched.zip}
                    disabled={isSubmitting}
                    onKeyDown={disallowComma}
                  />
                </Grid>
                {utmParams.source && (
                  <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        id="utmSource"
                        name="utmSource"
                        label={t('registration.partner')}
                        disabled
                        value={utmParams.source}
                        onChange={noop}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        id="partnerProvidedId"
                        name="partnerProvidedId"
                        label={t('registration.partner_provided_id')}
                        value={formik.values.partnerProvidedId}
                        onChange={formik.handleChange}
                        error={formik.errors.partnerProvidedId}
                        touched={formik.touched.partnerProvidedId}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      id="signUpPassword"
                      name="password"
                      label={t('auth.password')}
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.errors.password}
                      touched={formik.touched.password}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      id="signUpConfirmPassword"
                      name="confirmPassword"
                      label={t('registration.confirm_password')}
                      type="password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={formik.errors.confirmPassword}
                      touched={formik.touched.confirmPassword}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize="12px" color="#3E355C">{t('registration.hint_password_strength')}</Typography>
                </Grid>
                {submitError && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="subtitle2">
                      {submitError}
                    </Typography>
                  </Grid>
                )}
                <Grid marginTop={1} item container alignItems="center" spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ padding: '10px 50px' }}
                      type="submit"
                      data-testid="submit-signup-button"
                      disabled={isSubmitting}
                      fullWidth
                    >
                      {t('general.continue')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Typography color="#3E355C" variant="subtitle2" textAlign="center">
                      <Trans t={t} i18nKey="auth.already_have_account">
                        Already have an account? <Link to="/dashboard" style={{ color: '#035c9b' }}>Sign in</Link>.
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
                {
                  partnerValues?.partnerName && (
                    <Typography color="#000124" width="100%" textAlign="center" paddingTop={3} variant="subtitle2">
                      in partnership with <b>{partnerValues.partnerName}</b>
                    </Typography>
                  )
                }
              </Grid>
            </form>
          </FormikProvider>
        </Box>
      </AuthorizationContainer>
    </LogOnMount>
  ) : renderSecondStep();
};

export default SignUp;
