import { createTransaction } from 'api/Shipment/api';
import { DELIVERY_TYPE_TO_USER_AND_RETURN } from 'api/Shipment/constants';
import { setTransactions } from 'store/shipping/actions';
import { TranslationsKey } from 'i18nextOptions';
import { AmplitudeEvent } from 'common/constants/amplitude';

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';

export const useRequestKit = () => {
  const { t } = useTranslation();
  const { logEvent } = useAmplitude();
  const dispatch = useDispatch();

  const [ isLoading, setLoading ] = useState(false);
  const [ error, setError ] = useState('');

  const requestKit = () => {
    setLoading(true);
    setError('');

    createTransaction(DELIVERY_TYPE_TO_USER_AND_RETURN)
      .then(({ data }) => {
        logEvent(AmplitudeEvent.KIT_REQUESTED);
        if (data) {
          dispatch(setTransactions(data));
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setLoading(false));
  };
  
  return {
    requestKit,
    isLoading,
    error,
  };
};

export const useLocalizedDontHaveKitFlow = () => {
  const { t } = useTranslation();

  const getLocalizedLabel = useCallback((label: string) => {
    return t(`dashboard.shipping.dont_have_kit_status.label.${label}` as TranslationsKey);
  }, [ t ]);
  
  const getLocalizedDescription = useCallback((description: string) => {
    return t(`dashboard.shipping.dont_have_kit_status.description.${description}` as TranslationsKey);
  }, [ t ]);

  return {
    getLocalizedLabel,
    getLocalizedDescription,
  };
};
