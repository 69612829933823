import { PROFILE_ROUTE, SURVEY_ROUTE } from 'common/constants/urls';
import { RootState } from 'store';
import { getUserProfileProgress } from 'common/helpers/user';
import { DashboardLocationState } from 'components/Dashboard/components/CardsPage/types';
import DashboardCard from 'components/Dashboard/components/CardsPage/DashboardCard/DashboardCard';
import ShippingDashboardCard from 'components/Dashboard/components/CardsPage/ShippingDashboardCard/ShippingDashboardCard';
import AncestryDashboardCard from 'components/Dashboard/components/CardsPage/AncestryDashboardCard/AncestryDashboardCard';

import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  const { t } = useTranslation();
  const { myUser } = useSelector((state: RootState) => state.user);

  const location = useLocation();
  const state = location.state as DashboardLocationState || {};
  const forceShowAncestryPopover = state.showAncestryLock;
  const forceShowShippingPopover = state.showShippingLock;

  const profileProgress = getUserProfileProgress(myUser);

  const isSurveyCompleted = myUser.surveyCompletedPercentage === 100;
  const surveyCardLabel = isSurveyCompleted ? t('dashboard.survey.button_completed') : t('dashboard.survey.button_take_survey');

  return (
    <Grid container rowSpacing={{ xs: 2, sm: 4 }} columnSpacing={{ xs: 1, md: 2 }} data-testid="cards-page">
      <Grid item xs={12} sm={6} xl={4}>
        <DashboardCard
          label={t('dashboard.profile.profile')}
          buttonLabel={t('dashboard.profile.button_open_profile')}
          link={PROFILE_ROUTE}
          progress={profileProgress}
          data-testid="profile-card"
        />
      </Grid>
      <Grid item xs={12} sm={6} xl={4}>
        <DashboardCard
          disabled={isSurveyCompleted}
          label={t('dashboard.survey.survey')}
          buttonLabel={surveyCardLabel}
          link={SURVEY_ROUTE}
          progress={myUser.surveyCompletedPercentage}
          data-testid="survey-card"
        />
      </Grid>
      <Grid item xs={12} sm={6} xl={4}>
        <ShippingDashboardCard forceShowPopover={forceShowShippingPopover} data-testid="shipping-card" />
      </Grid>
      <Grid item xs={12} sm={6} xl={4}>
        <AncestryDashboardCard forceShowPopover={forceShowAncestryPopover} data-testid="ancestry-card" />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
