import { styled } from '@mui/styles';
import { Input } from '@mui/material';

export const StyledInputBase = styled(Input)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
    // vertical padding + font size from searchIcon
    width: '100%',
    paddingRight: 0,
    [theme.breakpoints.up('md')]: {
      width: '12ch',
    },
  },
}));
