import { RootState } from 'store';
import {
  STATUS_DELIVERED,
  STATUS_FAILURE,
  STATUS_PRE_TRANSIT,
  STATUS_RETURNED,
  STATUS_TRANSIT,
} from 'api/Shipment/constants';
import { getUserMailingAddress } from 'common/helpers/user';
import { getReturnTrack } from 'store/shipping/actions';
import TrackingStepIcon
  from 'components/Dashboard/components/ShippingPage/components/TrackingPage/TrackingStepIcon/TrackingStepIcon';
import AddressEditForm from 'components/Dashboard/components/ShippingPage/components/TrackingPage/AddressEditForm/AddressEditForm';
import openInNewTab from 'common/helpers/openInNewTab';
import { isErrorTrack } from 'components/Dashboard/components/ShippingPage/components/helpers';
import {
  useGenerateLabel,
  useLocalizedHaveKitFlow,
} from 'components/Dashboard/components/ShippingPage/components/TrackingPage/HaveKitFlow/hooks';
import { HaveKitSteps } from 'components/Dashboard/components/ShippingPage/components/TrackingPage/HaveKitFlow/types';
import { getActiveStepIndex } from 'components/Dashboard/components/ShippingPage/components/TrackingPage/HaveKitFlow/helpers';
import styles from 'components/Dashboard/components/ShippingPage/components/TrackingPage/HaveKitFlow/HaveKitFlow.module.css';
import { isProduction } from 'common/utils/isProduction';

import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const steps = [
  {
    label: 'Generating shipping label',
    description: 'Check your mailing address and generate shipping label',
  },
  {
    label: 'Processing',
    description: 'Print or download shipping label and ship your kit with the USPS using the label.',
  },
  {
    label: 'Transit to BBOfA',
  },
  {
    label: 'Delivered to BBOfA',
  },
];

const STATUS_ORDER = [ STATUS_PRE_TRANSIT, STATUS_TRANSIT, STATUS_DELIVERED, STATUS_RETURNED, STATUS_FAILURE ];

const HaveKitFlow = () => {
  const { t } = useTranslation();
  const { getLocalizedLabel, getLocalizedDescription } = useLocalizedHaveKitFlow();
  
  const { returnTransaction } = useSelector((store: RootState) => store.shipping.transactions);
  const { returnShippingStatus } = useSelector((store: RootState) => store.shipping);
  const { myUser } = useSelector((store: RootState) => store.user);

  const { generateLabel, isLoading, error } = useGenerateLabel();

  const [ activeStep, setActiveStep ] = React.useState(() => getActiveStepIndex(returnTransaction, returnShippingStatus));
  // todo just status rotator for mocked api
  const [ currentStatus, setCurrentStatus ] = useState(0);

  const [ editAddress, setEditAddress ] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setActiveStep(getActiveStepIndex(returnTransaction, returnShippingStatus));
  }, [ returnShippingStatus, returnTransaction ]);

  useEffect(() => {
    if (currentStatus) {
      dispatch(getReturnTrack(STATUS_ORDER[currentStatus - 1]));
    }
  }, [ dispatch, currentStatus ]);

  const renderStepContentButtons = (stepIndex: number) => {
    switch (stepIndex) {
      case HaveKitSteps.GENERATE:
        return (
          <LoadingButton variant="outlined" onClick={generateLabel} loading={isLoading} data-testid="button-generate-label">
            {t('dashboard.shipping.button_generate_shipping_label')}
          </LoadingButton>
        );

      case HaveKitSteps.PROCESSING:
        return (
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Button variant="outlined" onClick={() => openInNewTab(returnTransaction?.labelURL)} data-testid="button-download-label">
                {t('dashboard.shipping.button_download_shipping_label')}
              </Button>
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  const errorState = isErrorTrack(returnShippingStatus);
  const labelClassName = classNames(styles.label, { [styles.error]: errorState });

  return (
    <Grid container item spacing={3} data-testid="have-kit-flow">
      <Grid container item xs spacing={4} alignContent="flex-start">
        {!returnShippingStatus && (
          <Grid item xs={12}>
            <div className={styles.warning}>{t('dashboard.shipping.warning_your_sample')}</div>
          </Grid>
        )}
        <Grid container item spacing={4} alignItems="center">
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <span className={styles.title}>{t('dashboard.profile.mailing_address')}</span>
            </Grid>
            {!editAddress ? (
              <Grid container item xs={12} rowSpacing={1}>
                <Grid item xs={12} md={4}>
                  <span className={styles.label}>{getUserMailingAddress(myUser) || t('dashboard.profile.not_specified')}</span>
                </Grid>
                <Grid container item xs={12} justifyContent="space-between">
                  {!returnShippingStatus && (
                    <Grid item>
                      <Button variant="outlined" size="small" onClick={() => setEditAddress(true)} data-testid="button-edit-address">
                        Edit Address
                      </Button>
                    </Grid>
                  )}
                  {!isProduction() && (
                    <Grid item>
                      <Button variant="outlined" onClick={() => setCurrentStatus(prevState => prevState + 1)}>
                        {t('dashboard.shipping.next_status')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <AddressEditForm onClose={() => setEditAddress(false)} />
            )}
          </Grid>
          {error && (
            <Grid container item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container item>
          <Stepper className={styles.stepper} activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label} expanded={index === activeStep || (index === HaveKitSteps.GENERATE && activeStep === HaveKitSteps.PROCESSING)}>
                <StepLabel StepIconComponent={TrackingStepIcon}>
                  <span className={styles.title}>{getLocalizedLabel(step.label)}</span>
                </StepLabel>
                <StepContent>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                      <span className={labelClassName}>
                        {step.description ?
                          getLocalizedDescription(step.description) :
                          returnShippingStatus?.statusDetails}
                      </span>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      {renderStepContentButtons(index)}
                    </Grid>
                  </Grid>
                  <Box sx={{ mb: 2 }} />
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HaveKitFlow;
