import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV1 } from 'api/User/types';
import { TranslationsKey } from 'i18nextOptions';

import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const HEALTH_CONDITIONS = [
  'Heart disease',
  'Hypertension',
  'Diabetes',
  'Immunological / Rheumatic',
  'Neurological / Psychiatric',
  'Cancer',
  'Other',
];

const HealthConditionsQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);

  const [ values, setValues ] = useState<string[]>([]);
  const [ other, setOther ] = useState('');

  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    submit({
      ...survey,
      longTermHealthConditions: { values, other },
    })
      .then((data: Survey) => onNext(data));
  }, [ values, other, submit, survey, onNext ]);

  const handleChange = useCallback((event) => {
    if (event.target.checked) {
      setValues(prevState => [ ...prevState, event.target.value ]);
    } else {
      setValues((prevState) => {
        const removedConditionIndex = prevState.indexOf(event.target.value);

        return [
          ...prevState.slice(0, removedConditionIndex),
          ...prevState.slice(removedConditionIndex + 1, prevState.length),
        ];
      });
    }
  }, []);

  const onOtherChanged = useCallback((event) => {
    setOther(event.target.value);
  }, []);

  useEffect(() => {
    setValues((survey as SurveyV1)?.longTermHealthConditions?.values || []);
    setOther((survey as SurveyV1)?.longTermHealthConditions?.other || '');
  }, [ survey ]);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-health-conditions">
      <Grid container spacing={3}>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="textXLBold" color="primary">
              {t('dashboard.survey.question_health_condition.question')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="textXLBold" color="primary">
              {t('dashboard.survey.question_health_condition.mark_all_apply')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={0}>
          {HEALTH_CONDITIONS.map(condition => (
            <Grid item xs={12} sm={6} key={condition}>
              {condition !== 'Other' ? (
                <FormControlLabel
                  control={<Checkbox onChange={handleChange} checked={values.includes(condition)} value={condition} />}
                  label={String(t(`dashboard.survey.health_conditions.${condition}` as TranslationsKey))}
                />
              ) : (
                <>
                  <FormControlLabel
                    value="Other"
                    label={condition}
                    control={<Checkbox onChange={handleChange} checked={values.includes(condition)} value={condition} />}
                    data-testid="option-other"
                  />
                  <TextField
                    sx={{ marginLeft: { xs: 0, sm: 2 }, width: { xs: '100%' }, maxWidth: { xs: '100%', sm: 300 }, marginTop: { xs: 1, sm: 0 } }}
                    variant="outlined"
                    size="small"
                    color="primary"
                    label={t('dashboard.survey.hint_which_ones')}
                    value={other}
                    onChange={onOtherChanged}
                    disabled={!values.includes('Other')}
                    inputProps={{ 'data-testid': 'input-other' }}
                  />
                </>
              )}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <Typography variant="body1" color="primary" maxWidth={425} fontWeight={400}>
            {t('dashboard.survey.hint_leave_blank')}
          </Typography>
        </Grid>
        {submitError && (
          <Grid item xs={12}>
            <Typography color="error">
              {submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default HealthConditionsQuestion;
