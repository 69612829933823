import { NewsType } from 'api/News/types';

import * as yup from 'yup';

export const getValidationSchema = (newsType: NewsType) => {
  if (newsType === NewsType.NEWS) {
    return yup.object({
      title: yup
        .string()
        .required('Title is required'),
      body: yup
        .string()
        .required('News content is required'),
      newsDate: yup
        .string()
        .required('News date is required'),
      dataFilePreview: yup
        .string()
        .required('Content image is required'),
    });
  } else {
    return yup.object({
      type: yup
        .number()
        .required('News type is required'),
      title: yup
        .string()
        .required('Title is required'),
      body: yup
        .string()
        .required('News content is required'),
      newsDate: yup
        .string()
        .required('News date is required'),
      imageFilePreview: yup
        .string()
        .required('Preview image is required'),
      resourceUrl: yup
        .string()
        .required('Publication url is required'),
      dataFilePreview: yup
        .string()
        .required('Content image is required'),
    });
  }
};

export const getReadableId = (str: string) => {
  if (!str) {
    return '';
  }

  const charsAndDigitsOnly = str.replace(/[^A-Za-z0-9]/g, '');
  return charsAndDigitsOnly.replace(/\s/g, '');
};
