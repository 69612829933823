import { NewsBase } from 'api/News/types';
import { getNewsList } from 'api/News/api';

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useNewsList = () => {
  const { t } = useTranslation();
  
  const [ isLoading, setLoading ] = useState(false);
  const [ newsList, setNewsList ] = useState<NewsBase[]>([]);
  const [ error, setError ] = useState('');

  const requestNews = useCallback(() => {
    setLoading(true);
    setError('');

    getNewsList()
      .then(({ data }) => {
        setNewsList(data.results);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setLoading(false));
  }, [ t ]);

  return {
    requestNews,
    newsList,
    setNewsList,
    isLoading,
    error,
  };
};
