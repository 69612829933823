import { SpecifiedRace } from 'components/Dashboard/components/SurveyPage/components/V1/OriginQuestion/types';

export const SPECIFIED_RACES: SpecifiedRace[] = [
  {
    label: 'Black or African American',
    hint: 'For example African American, Jamaican, Haitian, Nigerian, Ethiopian, Somali, etc.',
  },
  {
    label: 'White',
    hint: 'For example German, Irish, English, Italian, Lebanese, Egyptian, etc.',
  },
  {
    label: 'American Indian or Alaska Native',
    hint: `Name of enrolled or principal tribe(s), for example, Navajo Nation, Blackfeet Tribe,
      Mayan, Aztec, Native Village of Barrow Inupiat
      Traditional Government, Eskimo Community, etc`,
  },
  {
    label: 'Other Asian',
    hint: 'For example Pakistani, Cambodian, Hmong, etc.',
  },
  {
    label: 'Other Pacific Islander',
    hint: 'For example Tongan, Fijian, Marshallese, etc.',
  },
  {
    label: 'Some other race',
    hint: '',
  },
];
