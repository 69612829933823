import ImageDropzone from 'common/components/ImageDropzone/ImageDropzone';
import styles from 'components/Dashboard/components/ProfilePage/ProfilePage.module.css';
import { useEditNews } from 'components/AdminDashboard/components/NewsEditPage/hooks';
import { ADMIN_DASHBOARD_NEWS_ROUTE } from 'common/constants/urls';
import { isLocalFile } from 'common/helpers/isLocalFile';
import { getResourceUrl } from 'common/utils/getResourceUrl';
import { NEWS_ID_NEW } from 'components/AdminDashboard/components/NewsEditPage/constants';
import { NewsType } from 'api/News/types';

import React, { useCallback } from 'react';
import { Button, CircularProgress, FormHelperText, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

const NewsEditPage: React.FC = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const navigate = useNavigate();
  
  const {
    formik,
    isSubmitting,
    isLoading,
    error,
    submitError,
    newsType,
    setNewsType,
  } = useEditNews(newsId);

  const getImageValue = (str?: string) => {
    if (!str) {
      return undefined;
    }

    if (isLocalFile(str)) {
      return str;
    }

    return getResourceUrl(str);
  };

  const handlePreviewImageChanged = useCallback((base64: string, file?: File) => {
    formik.setFieldValue('imageFile', file);
    formik.setFieldValue('imageFilePreview', base64);
    // formik deps cause refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewsImageChanged = useCallback((base64: string, file?: File) => {
    formik.setFieldValue('dataFile', file);
    formik.setFieldValue('dataFilePreview', base64);
    // formik deps cause refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper sx={{ maxWidth: '100%', padding: '24px' }} data-testid="news-edit-page">
      {isLoading ?
        <CircularProgress /> : (
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <Grid container spacing={4} width="800px">
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Select news type
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="type"
                    name="type"
                    select
                    fullWidth
                    disabled={isSubmitting}
                    value={newsType}
                    onChange={e => setNewsType(e.target.value as unknown as NewsType)}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  >
                    <MenuItem value={NewsType.PUBLICATION}>Publication</MenuItem>
                    <MenuItem value={NewsType.NEWS}>News</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    News title
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="title"
                    name="title"
                    variant="outlined"
                    fullWidth
                    disabled={isSubmitting}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
              </Grid>
              {newsType === NewsType.PUBLICATION && (
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      Select news preview (5:4 recommended)
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <ImageDropzone
                      id="imageFile"
                      logo={getImageValue(formik.values.imageFilePreview)}
                      onImageChanged={handlePreviewImageChanged}
                      error={formik.touched.imageFilePreview && Boolean(formik.errors.imageFilePreview)}
                    />
                  </Grid>
                  {formik.touched.imageFilePreview && formik.errors.imageFilePreview && (
                    <Grid item xs={12}>
                      <FormHelperText error>
                        {formik.errors.imageFilePreview}
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Select news content image (16:9 recommended)
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <ImageDropzone
                    id="logo"
                    logo={getImageValue(formik.values.dataFilePreview)}
                    onImageChanged={handleNewsImageChanged}
                    error={formik.touched.dataFilePreview && Boolean(formik.errors.dataFilePreview)}
                  />
                </Grid>
                {formik.touched.dataFilePreview && formik.errors.dataFilePreview && (
                  <Grid item xs={12}>
                    <FormHelperText error>
                      {formik.errors.dataFilePreview}
                    </FormHelperText>
                  </Grid>
                )}
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    News content
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="body"
                    name="body"
                    value={formik.values.body}
                    multiline
                    rows={10}
                    fullWidth
                    onChange={formik.handleChange}
                    disabled={isSubmitting}
                    error={formik.touched.body && Boolean(formik.errors.body)}
                    helperText={formik.touched.body && formik.errors.body}
                  />
                </Grid>
              </Grid>
              {newsType === NewsType.PUBLICATION && (
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      Publication url
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="resourceUrl"
                      name="resourceUrl"
                      value={formik.values.resourceUrl}
                      multiline
                      fullWidth
                      onChange={formik.handleChange}
                      disabled={isSubmitting}
                      error={formik.touched.resourceUrl && Boolean(formik.errors.resourceUrl)}
                      helperText={formik.touched.resourceUrl && formik.errors.resourceUrl}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Publication date
                  </Typography>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      value={formik.values.newsDate}
                      onChange={(newValue) => {
                        formik.setFieldValue('newsDate', newValue);
                      }}
                      renderInput={params => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                      disabled={isSubmitting}
                      disableMaskedInput
                    />
                  </LocalizationProvider>
                  {formik.errors.newsDate && (
                    <FormHelperText error>
                      {formik.errors.newsDate}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item>
                  <Button variant="contained" disabled={isSubmitting} type="submit">
                    {newsId === NEWS_ID_NEW ? 'Create News' : 'Edit News'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={() => navigate(ADMIN_DASHBOARD_NEWS_ROUTE)} disabled={isSubmitting}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {(error || submitError) && (
              <FormHelperText error>
                {error || submitError}
              </FormHelperText>
            )}
          </form>
        )}
    </Paper>
  );
};

export default NewsEditPage;
