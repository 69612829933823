import { removeSampleRequest } from 'api/Samples/api';
import { Sample } from 'api/Samples/types';
import { REMOVE_SAMPLE } from 'store/users/types';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

export const useRemoveSample = () => {
  const dispatch = useDispatch();

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();

  const removeSample = (sample: Sample) => {
    setError('');
    setIsLoading(true);

    removeSampleRequest(sample.id)
      .then(() => {
        dispatch({ type: REMOVE_SAMPLE, data: { ...sample } });
      })
      .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
      .finally(() => setIsLoading(false));
  };

  return {
    removeSample,
    isLoading,
    error,
  };
};
