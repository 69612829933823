import { OLD_TEST_KIT_ID_LENGTH, CORRUPTED_OLD_TEST_KIT_ID_LENGTH, OLD_MASK, NEW_MASK } from 'api/UserKits/constants';

import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const KitMaskedInput = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
  const { onChange, value, ...other } = props;
  const [ kitId, setKitId ] = useState(value);

  useEffect(() => {
    // For kit ids from batch export there are no dashes, so we insert them manually for consistency
    if (value.length === CORRUPTED_OLD_TEST_KIT_ID_LENGTH) {
      setKitId(`${value.slice(0, 14)}-${value.slice(14, 22)}-${value.slice(22, 30)}-${value.slice(30)}`);
    }
  }, []);

  const mask = kitId.length === OLD_TEST_KIT_ID_LENGTH || kitId[2] === ':' ? OLD_MASK : NEW_MASK;

  return (
    <IMaskInput
      {...other}
      mask={mask}
      placeholder={mask}
      value={kitId}
      inputRef={ref as any}
      onAccept={(value: any) => {
        setKitId(value);
        onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

export default KitMaskedInput;
