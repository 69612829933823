import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { Link } from '@mui/material';

const useQuestions = () => {
  const { t } = useTranslation();

  return [
    {
      question: t('landing.faq.question_funding'),
      answer: t('landing.faq.answer_funding'),
    },
    {
      question: t('landing.faq.question_stored'),
      answer: t('landing.faq.answer_stored'),
    },
    {
      question: t('landing.faq.question_who_have_access'),
      answer: t('landing.faq.answer_who_have_access'),
    },
    {
      question: t('landing.faq.question_IRB_approval'),
      answer: (
        <Trans t={t} i18nKey="landing.faq.answer_IRB_approval">
          Yes, BBofA has an approved protocol under the WCG-IRB
          <Link href="https://www.wcgirb.com/" target="_blank" rel="noreferrer" />
          . In addition BBofA is a HIPAA compliant entity.
        </Trans>
      ),
    },
    {
      question: t('landing.faq.question_can_federal_agency'),
      answer: t('landing.faq.answer_can_federal_agency'),
    },
    {
      question: t('landing.faq.question_used_non_medical'),
      answer: t('landing.faq.answer_used_non_medical'),
    },
  ];
};

export default useQuestions;
