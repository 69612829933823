import { postRegister } from 'api/Auth/api';
import { PartnerInfo } from 'api/User/types';
import { RegisterResult } from 'api/Auth/types';
import { postValidateEmail } from 'api/User/api';
import { AmplitudeEvent } from 'common/constants/amplitude';
import { USA } from 'common/constants/countries';
import { getLocalUtmParams, deleteLocalUtmParams } from 'common/helpers/getLocalUtmParams';

import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useAmplitude } from 'react-amplitude-hooks';

export interface SignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  dob: string | null;
  gender: string;
  country: string;
  zip: string;
  partnerProvidedId: string;
}

const useSignUpForm = (onSuccessSignUp: (data: RegisterResult) => void, setFirstStepCompleted: (value: boolean) => void, partnerValues?: PartnerInfo) => {
  const { t } = useTranslation();
  const { logEvent } = useAmplitude();

  const validationSchema = useMemo(() => yup.object({
    firstName: yup
      .string()
      .required(t('registration.hint_firstname')),
    lastName: yup
      .string()
      .required(t('registration.hint_lastname')),
    email: yup
      .string()
      .email(t('registration.error_email'))
      .required(t('registration.hint_email')),
    password: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g,
        t('registration.error_password_strength'),
      )
      .required(t('registration.hint_password')),
    confirmPassword: yup
      .string()
      .required(t('registration.hint_confirm_password'))
      .oneOf([ yup.ref('password'), null ], t('registration.error_password_doesnt_match')),
    dob: yup
      .date()
      .max(new Date(), t('registration.hint_dob'))
      .typeError(t('registration.hint_dob'))
      .required(t('registration.hint_dob')),
    gender: yup
      .string()
      .required(t('registration.hint_gender')),
    country: yup
      .string()
      .required(t('registration.hint_country')),
    zip: yup
      .string()
      .when('country', {
        is: (country: string) => country === USA,
        then: yup.string().required(t('registration.hint_zip')),
        otherwise: yup.string(),
      }),
  }), [ t ]);

  const signUp = (
    { firstName, lastName, email, password, dob, gender, country, zip, partnerProvidedId }: SignUpFormValues,
    signature: string,
    parentSignature: string,
  ) => {
    setSubmitting(true);
    setSubmitError('');

    postRegister({
      firstName,
      lastName,
      password,
      username: email,
      signature,
      utmParams: getLocalUtmParams(),
      dob,
      gender,
      mailingAddress: { zip, country },
      parentSignature,
      partnerProvidedId,
    })
      .then((response) => {
        setSuccess(true);
        onSuccessSignUp(response.data);
        setFirstStepCompleted(false);
        deleteLocalUtmParams();
        logEvent(AmplitudeEvent.REGISTRATION_STEP_2_COMPLETED);
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setSubmitting(false));
  };

  const formikSubmit = ({ email }: SignUpFormValues) => {
    setSubmitting(true);
    setSubmitError('');

    postValidateEmail(email)
      .then(() => {
        setFirstStepCompleted(true);
        logEvent(AmplitudeEvent.REGISTRATION_STEP_1_COMPLETED);
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    firstName: partnerValues?.firstName || '',
    lastName: partnerValues?.lastName || '',
    email: partnerValues?.username || '',
    password: '',
    confirmPassword: '',
    dob: partnerValues?.dob || null,
    gender: partnerValues?.gender || '',
    country: '',
    zip: '',
    partnerProvidedId: '',
  };

  const formik = useFormik<SignUpFormValues>({
    initialValues,
    validationSchema,
    onSubmit: formikSubmit,
  });

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ isSuccess, setSuccess ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  return { formik, isSuccess, isSubmitting, submitError, signUp };
};

export default useSignUpForm;
