import { updateMyAddressRequest } from 'api/User/api';
import { User } from 'api/User/types';
import { RootState } from 'store';
import { SET_MY_USER } from 'store/user/types';
import { USA } from 'common/constants/countries';

import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

export interface AddressEditFormValues {
  zip: string;
  state: string;
  city: string;
  street1: string;
  street2: string;
  country: string;
}

const useAddressEdit = (onSuccessCallback: () => void) => {
  const { t } = useTranslation();

  const user: User = useSelector((state: RootState) => state.user.myUser);
  const dispatch = useDispatch();

  const formikValidate = useCallback(({ zip, state, city, street1, country }: AddressEditFormValues) => {
    let errors: {
      zip?: string,
      state?: string,
      street1?: string,
      country?: string,
      city?: string,
    } = {};
    if (zip || state || city || street1 || country) {
      const addressError = t('dashboard.profile.errors.complete_address');

      if (!zip) {
        errors = { ...errors, zip: addressError };
      } else if (zip.length < 4) {
        errors = { ...errors, zip: t('dashboard.profile.errors.zip_error') };
      }
      if (!state && country === USA) {
        errors = { ...errors, state: addressError };
      }
      if (!city) {
        errors = { ...errors, city: addressError };
      }
      if (!street1) {
        errors = { ...errors, street1: addressError };
      }
      if (!country) {
        errors = { ...errors, country: addressError };
      }
    }

    return errors;
  }, [ t ]);

  const formikSubmit = ({ zip, state, city, street1, street2, country }: AddressEditFormValues) => {
    setSubmitting(true);

    updateMyAddressRequest({ zip, state: country === USA ? state : '', city, street1, street2, country })
      .then((response) => {
        dispatch({ type: SET_MY_USER, user: response });
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    zip: user.mailingAddress.zip || '',
    state: user.mailingAddress.state || '',
    city: user.mailingAddress.city || '',
    street1: user.mailingAddress.street1 || '',
    street2: user.mailingAddress.street2 || '',
    country: user.mailingAddress.country || '',
  };

  const formik = useFormik<AddressEditFormValues>({
    initialValues,
    onSubmit: formikSubmit,
    validate: formikValidate,
    enableReinitialize: true,
  });

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  return { formik, isSubmitting, submitError };
};

export default useAddressEdit;
