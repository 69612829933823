import { ivory } from 'common/constants/colors';

import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ServicesHint: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Typography
      color={ivory}
      sx={{
        fontFamily: 'Montserrat Alternates',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: { xs: '36px', lg: '48px' },
        lineHeight: '150%',
        letterSpacing: '-0.05em',
      }}
    >
      {t('landing.partners.services.services_we_offer')}
    </Typography>
  );
};

export default ServicesHint;
