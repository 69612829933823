export const FAMILY_MEMBERS = [
  'Father',
  'Mother',
  'Sibling',
  'Daughter',
  'Son',
];

export const HEALTH_CONDITIONS = [
  'Heart disease',
  'Hypertension',
  'Diabetes',
  'Immunological / Rheumatic',
  'Neurological / Psychiatric',
  'Cancer',
];

export const EMPTY_CONDITION = { member: '', condition: '' };
