import { ivory, midnight } from 'common/constants/colors';
import NewsView from 'components/Landing/components/NewsView/NewsView';
import Steps from 'components/Landing/components/MainContent/components/JoiningUsPage/components/Steps/Steps';

import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const JoiningUsPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const navigateToSignUp = () => navigate('/dashboard/signup');

  return (
    <>
      <Box
        sx={{
          backgroundColor: ivory,
          paddingBottom: { xs: '240px', lg: '430px' },
        }}
      >
        <Container maxWidth="xl">
          <Typography sx={{
            marginTop: { xs: '130px', lg: '260px' },
            fontFamily: 'Montserrat Alternates',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: { xs: '42px', lg: '60px' },
            lineHeight: '125%',
            letterSpacing: '-0.05em',
            textAlign: 'center',
          }}
          >
            {t('landing.join_us.title')}
          </Typography>
          <Steps />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              flexDirection: { xs: 'column', lg: 'row' },
              marginTop: { xs: 8, lg: 14 },
            }}
          >
            <Box width="320px">
              <Button variant="contained" color="primary" size="large" fullWidth onClick={navigateToSignUp}>
                {t('landing.join_us.participate_button')}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        position="relative"
        sx={{ backgroundColor: midnight }}
        paddingBottom={10}
      >
        <Container maxWidth="xl">
          <Box
            position="relative"
            sx={{
              top: { xs: '-120px', lg: '-300px' },
              marginBottom: { xs: '-120px', lg: '-300px' },
            }}
          >
            <NewsView />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default JoiningUsPage;
