import { peach, white } from 'common/constants/colors';
import BackgroundImg from 'components/Landing/components/MainContent/components/QuotePage/resources/background.svg';
import AvatarImg from 'components/Landing/components/MainContent/components/QuotePage/resources/avatar.jpg';
import QuoteImg from 'components/Landing/components/MainContent/components/QuotePage/resources/quote.svg';

import { Box, Button, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const QuotePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToSignUp = () => navigate('/dashboard/signup');
  return (
    <Box
      position="relative"
      overflow="hidden"
      sx={{
        background: `url(${BackgroundImg}) ${white} 50% 40% no-repeat`,
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ margin: { xs: '0', lg: '0 8px' } }}>
          <Typography
            color="midnight.main"
            sx={{
              marginTop: 25,
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: { xs: '32px', lg: '48px' },
              lineHeight: '150%',
              letterSpacing: '-0.05em',
            }}
          >
            {t('landing.quote.title')}
          </Typography>
          <Typography
            color="orange.main"
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: '800',
              fontSize: { xs: '72px', lg: '150px' },
              lineHeight: '100%',
              letterSpacing: '0.1em',
            }}
          >
            500 000
          </Typography>
          <Typography
            color="orange.main"
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: { xs: '48px', lg: '64px' },
              lineHeight: '100%',
              letterSpacing: '-0.05em',
            }}
          >
            {t('landing.quote.title_people')}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Paper
            elevation={3}
            sx={{
              backgroundColor: peach,
              borderRadius: '30px',
              marginTop: '42px',
              maxWidth: '873px',
              padding: { xs: '32px 24px 24px 24px', lg: '75px 63px 55px 63px' },
              position: 'relative',
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                color="purple.main"
                textAlign="center"
                sx={{
                  fontFamily: 'Satoshi',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: { xs: '24px', lg: '43px' },
                  lineHeight: '150%',
                }}
              >
                {t('landing.quote.quote')}
              </Typography>
              <Box>
                <img src={AvatarImg} alt="" width={94} height={94} style={{ borderRadius: '100%' }} />
              </Box>
              <Typography
                color="purple.main"
                sx={{
                  fontFamily: 'Satoshi',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '150%',
                }}
              >
                {t('landing.quote.author')}
              </Typography>
              <Typography
                color="purple.main"
                sx={{
                  fontFamily: 'Satoshi',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '13px',
                  lineHeight: '150%',
                  textAlign: 'center',
                  maxWidth: '280px',
                }}
              >
                {t('landing.quote.author_titles')}
              </Typography>
            </Box>
            <Box
              position="absolute"
              sx={{
                top: { xs: '-9px', lg: '-18px' },
                left: { xs: '20px', lg: '41px' },
                width: { xs: '49px', lg: '98px' },
                height: { xs: '40px', lg: '80px' },
              }}
            >
              <img src={QuoteImg} alt="" width="100%" />
            </Box>
          </Paper>
          <Box
            display="flex"
            marginTop={9}
            marginBottom={25}
            alignItems="center"
            sx={{ flexDirection: { xs: 'column', lg: 'row' } }}
          >
            <Button
              color="primary"
              size="large"
              variant="contained"
              sx={{ width: '320px', height: '52px' }}
              onClick={navigateToSignUp}
            >
              {t('landing.quote.button')}
            </Button>
            <Typography
              color="purple.main"
              sx={{
                fontFamily: 'Satoshi',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '18px',
                lineHeight: '150%',
                textAlign: { xs: 'center', lg: 'left' },
                maxWidth: { xs: '320px', lg: '400px' },
                marginLeft: { xs: '0', lg: '36px' },
                marginTop: { xs: '8px', lg: 0 },
              }}
            >
              {t('landing.welcome.explore_studies')}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default QuotePage;
