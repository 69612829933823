import { Organization } from 'api/Organizations/types';
import useOrganization from 'components/AdminDashboard/components/OrganizationsPage/components/OrganizationModal/hooks';
import { FormTextField } from 'common/components';

import React from 'react';
import { Button, Grid, IconButton, Modal, Paper, Tooltip, Typography } from '@mui/material';
import { FieldArray, FormikProvider } from 'formik';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import { v4 as uuid } from 'uuid';

interface Props {
  org: Organization | null;
  onCancel: () => void;
}

const OrganizationModal: React.FC<Props> = ({ onCancel, org }) => {
  const { formik, isSubmitting, submitError } = useOrganization(onCancel, org);
  const orgSiteIds: string[] = org ? org.sites.map(s => s.id) : [];

  return (
    <Modal open onClose={onCancel}>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '600px' },
        }}
        data-testid="modal-change-test-kit-received"
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid display="flex" flexDirection="column" item container spacing={3}>

              <Grid item>
                <Typography>
                  {org === null ? 'Add an' : 'Edit'} organization
                </Typography>
              </Grid>

              <Grid item xs={12} display="flex" flexDirection="column-reverse" sx={{ maxHeight: 600, overflowY: 'auto' }}>

                <Grid item xs={12}>
                  <FieldArray
                    name="sites"
                    render={(arrayHelpers) => {
                      return formik.values.sites.map((value, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid item container xs={12} key={i} marginBottom={3} spacing={1}>
                          <Grid item xs={9}>
                            <FormTextField
                              id={`sites.${i}.id`}
                              name={`sites.${i}.name`}
                              label="Site"
                              value={value.name}
                              onChange={formik.handleChange}
                              error={typeof formik.errors.sites === 'object' ? formik.errors.sites[i]?.toString() : undefined}
                              touched={!!formik.touched.sites}
                              disabled={isSubmitting}
                            />
                          </Grid>
                          {((i > 0 || formik.values.sites.length > 1) && !orgSiteIds.includes(value.id)) && (
                            <Grid item xs={1}>
                              <Tooltip title="Remove site">
                                <IconButton
                                  size="small"
                                  onClick={() => arrayHelpers.remove(i)}
                                  disabled={isSubmitting}
                                  data-testid={`button-remove-${i}`}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          {(i === formik.values.sites.length - 1) && (
                            <Grid item xs={1}>
                              <Tooltip title="Add site">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => arrayHelpers.push({ id: uuid(), name: '', isNew: true })}
                                  disabled={isSubmitting}
                                  data-testid="button-add"
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      ));
                    }}
                  />
                </Grid>

                <Grid item marginBottom={3} marginRight={3}>
                  <FormTextField
                    id="primaryEmail"
                    name="primaryEmail"
                    label="Email"
                    type="email"
                    value={formik.values.primaryEmail}
                    onChange={formik.handleChange}
                    error={formik.errors.primaryEmail}
                    touched={formik.touched.primaryEmail}
                    disabled={isSubmitting}
                  />
                </Grid>

                <Grid item marginBottom={3} marginRight={3}>
                  <FormTextField
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.errors.name}
                    touched={formik.touched.name}
                    disabled={isSubmitting}
                  />
                </Grid>

              </Grid>

              {submitError && (
                <Grid item xs={12}>
                  <Typography color="error" variant="subtitle2">
                    {submitError}
                  </Typography>
                </Grid>
              )}

              <Grid container spacing={2} item xs={12}>
                <Grid item>
                  <LoadingButton
                    disabled={isSubmitting}
                    variant="contained"
                    data-testid="button-add-organization"
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={onCancel} disabled={isSubmitting}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>

            </Grid>
          </form>
        </FormikProvider>
      </Paper>
    </Modal>
  );
};

export default OrganizationModal;
