import { MONTSERRAT_ALTERNATES } from 'common/constants/fonts';
import { midnight } from 'common/constants/colors';
import { DASHBOARD_ROUTE } from 'common/constants/urls';
import { RootState } from 'store';

import React, { ChangeEvent, useState } from 'react';
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  submitError?: string;
  isSubmitting?: boolean;
  onConsentSubmit?: (signature: string, parentSignature: string) => void;
  viewMode?: boolean;
  email?: string;
  onEmailEdit?: () => void;
}

const AssentPage: React.FC<Props> = ({
  submitError,
  isSubmitting,
  onConsentSubmit,
  viewMode,
  email,
  onEmailEdit,
}) => {
  const { t } = useTranslation();
  
  const { myUser } = useSelector((store: RootState) => store.user);
  const navigate = useNavigate();

  const [ agreement, setAgreement ] = useState<string>(viewMode ? 'yes' : 'no');
  const [ signature, setSignature ] = useState<string>(myUser.signature);
  const [ signatureError, setSignatureError ] = useState<string>('');

  const onSignatureChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSignature(e.target.value);
    setSignatureError('');
  };
  const onAgreementChange = (e: ChangeEvent<HTMLInputElement>) => setAgreement(e.target.value);

  const onSubmitHandler = () => {
    if (!onConsentSubmit) {
      return;
    }

    if (!signature) {
      setSignatureError(t('consent.field_is_required'));
    } else {
      onConsentSubmit(signature, '');
    }
  };

  return (
    <Grid container display="flex" flexWrap="nowrap" marginTop={{ xs: 5, md: 10 }}>
      <Grid
        item
        sx={{ backgroundColor: '#fff' }}
        lineHeight="135%"
        borderRadius={{ xs: '20px', sm: '30px' }}
        padding={{ xs: '40px 16px', sm: 5 }}
        margin={{ xs: '0px 16px', md: '0 auto 60px' }}
      >
        <Box maxWidth="860px">
          {viewMode && (
            <Grid item marginBottom={2}>
              <Button
                variant="text"
                color="inherit"
                startIcon={<ArrowBackIcon />}
                sx={{ marginRight: -20 }}
                onClick={() => navigate(DASHBOARD_ROUTE)}
              >
                {t('consent.back_to_dashboard')}
              </Button>
            </Grid>
          )}

          <Typography
            sx={{
              fontFamily: MONTSERRAT_ALTERNATES,
              fontWeight: 700,
              fontSize: { xs: '18px', sm: '28px' },
              lineHeight: '170%',
              letterSpacing: '-0.05em',
              marginBottom: 4,
              color: midnight,
            }}
          >
            {t('assent.Online Assent')}
          </Typography>

          <Box marginBottom={4}>
            <Trans i18nKey="assent.We are doing a research study" parent="p" />
            <Trans i18nKey="assent.There are some things about this study" parent="p" />
            <Trans i18nKey="assent.Not everyone who takes part in this study will benefit" parent="p" />
            <p>{t('assent.When we are finished with this study')}</p>
            <p>{t('assent.You do not have to be in this study')}</p>
            <p>{t('consent.I agree to participate in the research study')}</p>
            <RadioGroup value={agreement} onChange={onAgreementChange}>
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label={t('general.yes')}
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                disabled={viewMode}
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label={t('general.no')}
                sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                disabled={viewMode}
              />
            </RadioGroup>
          </Box>

          <Box display="flex" flexDirection="column">
            <b>{t('assent.If you are a minor aged 13-17')}</b>
            <TextField
              sx={{ marginTop: 1, marginBottom: 1, width: { sm: 400 } }}
              value={signature}
              onChange={onSignatureChange}
              error={Boolean(signatureError)}
              disabled={viewMode}
              inputProps={{
                autoComplete: 'off',
              }}
            />
            {(submitError || signatureError) && (
              <Grid>
                <Typography color="error" variant="subtitle2">
                  {submitError || signatureError}
                </Typography>
              </Grid>
            )}
          </Box>

          {!viewMode && email && onEmailEdit && (
            <Box marginTop={4}>
              <Typography sx={{ display: 'inline-block', fontSize: '16px', marginRight: 2, marginBottom: 2 }}>
                <Trans t={t} i18nKey="consent.We’ll send" values={{ email }}>
                  We’ll send the activation link to <b>{email}</b>
                </Trans>
              </Typography>
              <Button sx={{ fontSize: '16px' }} size="small" variant="contained" onClick={onEmailEdit}>
                {t('consent.Edit email')}
              </Button>
            </Box>
          )}
          {!viewMode && (
            <LoadingButton
              disabled={agreement === 'no'}
              loading={isSubmitting}
              variant="contained"
              sx={{ width: 160, marginTop: 4 }}
              onClick={onSubmitHandler}
            >
              Submit
            </LoadingButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AssentPage;
