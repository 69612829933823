import { peach } from 'common/constants/colors';
import Step
  from 'components/Landing/components/MainContent/components/JoiningUsPage/components/Steps/components/Step/Step';

import { Box } from '@mui/system';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Step3Img from './resources/step_3.svg';
import Step2Img from './resources/step_2.svg';
import Step1Img from './resources/step_1.svg';

const Steps: React.FC = () => {
  const { t } = useTranslation();
  const steps = useMemo(() => ([
    {
      title: t('landing.join_us.step_1_title'),
      description: t('landing.join_us.step_1_body'),
      renderImg: () => <img src={Step1Img} alt="" />,
    },
    {
      title: t('landing.join_us.step_2_title'),
      description: t('landing.join_us.step_2_body'),
      renderImg: () => <img src={Step2Img} alt="" />,
    },
    {
      title: t('landing.join_us.step_3_title'),
      description: t('landing.join_us.step_3_body'),
      renderImg: () => <img src={Step3Img} alt="" />,
    },
  ]), [ t ]);

  const renderSteps = useCallback(() => {
    return steps.map(({ title, description, renderImg }) => {
      return <Step description={description} title={title} key={title} renderImg={renderImg} />;
    });
  }, [ steps ]);

  return (
    <Box position="relative" marginTop={11}>
      <Box
        display="flex"
        justifyContent="space-between"
        position="relative"
        zIndex={5}
        sx={{
          alignItems: { xs: 'center', lg: 'flex-start' },
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderSteps()}
      </Box>
      <Box
        position="absolute"
        width="calc(100% - 150px)"
        height="5px"
        top="96px"
        margin="0 80px"
        sx={{
          backgroundColor: peach,
          display: { xs: 'none', lg: 'block' },
        }}
      />
    </Box>
  );
};

export default Steps;
