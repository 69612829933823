import { StyledInputBase } from 'common/components/SearchUserInput/SearchUserInputStyles';
import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';

import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment } from '@mui/material';

interface Props {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  hasLeftPadding?: boolean;
  placeholder?: string;
}

const SearchUserInput: React.FC<Props> = ({ onChange, hasLeftPadding = true, placeholder = 'Search User' }) => {
  const { usernameParam } = usePaginationSearchParams();
  const searchInputWrapper = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (searchInputWrapper.current) {
      (searchInputWrapper.current.children[0] as HTMLInputElement).value = usernameParam;
    }
  }, [ usernameParam ]);

  return (
    <Box paddingLeft={hasLeftPadding ? 3 : 0}>
      <StyledInputBase
        ref={searchInputWrapper}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        size="small"
        onChange={onChange}
        endAdornment={(
          <InputAdornment sx={{ paddingRight: 1 }} position="end">
            <SearchIcon />
          </InputAdornment>
        )}
      />
    </Box>
  );
};

export default SearchUserInput;
