import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV2, HealthInfo } from 'api/User/types';
import { TranslationsKey } from 'i18nextOptions';

import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'lodash';

interface Props extends QuestionPageProps {
  field: keyof SurveyV2;
  diseases: string[];
}

const HealthInfoQuestion: React.FC<Props> = ({ onNext, field, diseases }) => {
  const { t } = useTranslation();
  const transKey = snakeCase(field);
  const { survey } = useSelector((store: RootState) => store.user.myUser);

  const [ error, setError ] = useState('');
  const [ values, setValues ] = useState<string[]>([]);
  const [ other, setOther ] = useState('');
  const [ answer, setAnswer ] = useState('');

  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    if (answer) {
      submit({
        ...survey,
        [field]: { answer, values, other },
      })
        .then((data: Survey) => onNext(data));
    } else {
      setError(t('dashboard.survey.error_give_answer'));
    }
  }, [ t, answer, values, other, submit, survey, onNext, field ]);

  const onRadioChanged = useCallback((event) => {
    const { target: { value } } = event;
    if (value !== 'yes') {
      setValues([]);
      setOther('');
    }
    setAnswer(value);
  }, []);

  const handleChange = useCallback((event) => {
    if (event.target.checked) {
      setValues(prevState => [ ...prevState, event.target.value ]);
    } else {
      setValues((prevState) => {
        const removedDiseaseIndex = prevState.indexOf(event.target.value);

        return [
          ...prevState.slice(0, removedDiseaseIndex),
          ...prevState.slice(removedDiseaseIndex + 1, prevState.length),
        ];
      });
    }
  }, []);

  const onOtherChanged = useCallback((event) => {
    setOther(event.target.value);
  }, []);

  useEffect(() => {
    setAnswer(((survey as SurveyV2)?.[field] as HealthInfo)?.answer || '');
    setValues(((survey as SurveyV2)?.[field] as HealthInfo)?.values || []);
    setOther(((survey as SurveyV2)?.[field] as HealthInfo)?.other || '');
  }, [ survey, field ]);

  const disableValues = answer !== 'yes';

  return (
    <form onSubmit={onFormSubmit} data-testid={`question-${field}`}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t(`dashboard.survey.question_${transKey}.question` as TranslationsKey)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              onChange={onRadioChanged}
              value={answer}
            >
              <FormControlLabel value="no" control={<Radio />} label={t('general.no')} />
              <FormControlLabel value="i dont know" control={<Radio />} label={t('general.i_dont_know')} />
              <FormControlLabel value="yes" control={<Radio />} label={t('general.yes')} data-testid="option-yes" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.hint_which_ones')}
          </Typography>
        </Grid>
        <Grid item container spacing={0}>
          {diseases.map(condition => (
            <Grid item xs={12} sm={6} key={condition}>
              <FormControlLabel
                disabled={disableValues}
                label={String(t(`dashboard.survey.question_${transKey}.diseases.${condition}` as TranslationsKey))}
                control={<Checkbox onChange={handleChange} checked={values.includes(condition)} value={condition} />}
                data-testid={`option-${condition}`}
              />
              {condition === 'Other' && (
                <TextField
                  sx={{ marginLeft: { xs: 0, sm: 2 }, width: { xs: '100%' }, maxWidth: { xs: '100%', sm: 300 }, marginTop: { xs: 1, sm: 0 } }}
                  variant="outlined"
                  size="small"
                  color="primary"
                  value={other}
                  onChange={onOtherChanged}
                  disabled={!values.includes('Other') || disableValues}
                  inputProps={{ 'data-testid': 'input-other' }}
                />
              )}
            </Grid>
          ))}
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default HealthInfoQuestion;
