import { APP_BAR_HEIGHT } from 'common/constants/sizes';
import Card from 'components/Landing/components/MainContent/components/AdvantagesPage/components/Card/Card';
import Hint from 'components/Landing/components/MainContent/components/AdvantagesPage/components/Hint/Hint';
import ConfettiImg from 'components/Landing/components/MainContent/components/AdvantagesPage/resources/confetti.svg';
import CanJoinImg from 'components/Landing/components/MainContent/components/AdvantagesPage/resources/can_join.svg';
import DiversityImg from 'components/Landing/components/MainContent/components/AdvantagesPage/resources/diversity.svg';
import PrivacyImg from 'components/Landing/components/MainContent/components/AdvantagesPage/resources/privacy.svg';
import DnaImg from 'components/Landing/components/MainContent/components/AdvantagesPage/resources/dna.svg';

import { Box, Container, Hidden, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AdvantagesPage: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const navigateToSignIn = React.useCallback(() => navigate('/dashboard'), [ navigate ]);

  const [ pageRef, isPageInView ] = useInView();
  const [ breakpointRef, isBreakpointInView ] = useInView();

  useEffect(() => {
    if (!isBreakpointInView && isPageInView) {
      // onIntersectNavbar(true);
    } else {
      // onIntersectNavbar(false);
    }
  }, [ isPageInView, isBreakpointInView ]);

  const cardsData = useMemo(() => [
    {
      title: t('landing.points.why_dna_title'),
      description: t('landing.points.why_dna_body'),
      renderImg: () => (
        <Box position="absolute" top="0" bottom="0" right="26px">
          <img src={DnaImg} alt="" height="100%" />
        </Box>
      ),
    },
    {
      title: t('landing.points.diversity_title'),
      description: t('landing.points.diversity_body'),
      buttonLabel: t('landing.points.diversity_button'),
      buttonAction: () => {
        const chartView = document.getElementById('chart');
        if (chartView) {
          chartView.scrollIntoView({ behavior: 'smooth' });
        }
      },
      renderImg: () => (
        <Box position="absolute" top="0" bottom="0" right="0">
          <img src={DiversityImg} alt="" height="100%" />
        </Box>
      ),
    },
    {
      title: t('landing.points.privacy_title'),
      description: `${t('landing.points.privacy_body_part1')}
      ${t('landing.points.privacy_body_part2')}`,
      renderImg: () => (
        <Box position="absolute" top="154px" right="31px" width="193px" height="198px">
          <img src={PrivacyImg} alt="" height="100%" />
        </Box>
      ),
    },
    {
      title: t('landing.points.who_can_join_title'),
      description: `${t('landing.points.who_can_join_body')}`,
      buttonLabel: t('landing.points.who_can_join_button'),
      buttonAction: navigateToSignIn,
      renderImg: () => (
        <Box position="absolute" bottom="-7px" right="6px">
          <img src={CanJoinImg} alt="" height="100%" />
        </Box>
      ),
    },
  ], [ t, navigateToSignIn ]);
  
  const renderCards = useCallback(() => {
    // TODO fix key prop when real data used
    return cardsData.map((data) => {
      return (
        <Box marginTop={16} key={data.title}>
          <Card data={data} />
        </Box>
      );
    });
  }, [ cardsData ]);

  return (
    <Box
      position="relative"
      width="100%"
      sx={{ backgroundColor: theme.palette.purple.main }}
      ref={pageRef}
      paddingTop={{ xs: 0, lg: 16 }}
      paddingBottom={16}
    >
      <Box position="absolute" top={`-${APP_BAR_HEIGHT}`} ref={breakpointRef} />
      <Container maxWidth="xl" sx={{ position: 'relative' }}>
        <Hidden lgUp>
          <Box top="164px" marginTop="164px" maxWidth="650px">
            <Box marginLeft={2} marginRight={2}>
              <Hint />
            </Box>
          </Box>
        </Hidden>
        <Box sx={{ position: 'relative', marginRight: { xs: 0, lg: '47%' } }}>
          <Box position="relative" zIndex={1}>
            {renderCards()}
          </Box>
          <Hidden lgUp>
            <Box position="absolute" top="-8%" display="flex" justifyContent="center" zIndex={-1}>
              <img src={ConfettiImg} alt="" width="100%" />
            </Box>
          </Hidden>
        </Box>
        <Hidden lgDown>
          <Box position="absolute" top={0} right="0" height="100%" maxWidth="50%">
            <Box position="sticky" top="20%" marginTop="5%" display="flex" justifyContent="center" maxWidth="650px">
              <Box marginLeft={18}>
                <Hint />
              </Box>
            </Box>
          </Box>
        </Hidden>
        <Hidden lgDown>
          <Box position="absolute" top={-320} left={50} height="100%" maxWidth="50%">
            <Box position="sticky" top="0%" marginTop="120px" display="flex" justifyContent="center" maxWidth="650px">
              <img src={ConfettiImg} alt="" />
            </Box>
          </Box>
        </Hidden>
      </Container>
    </Box>
  );
};

export default AdvantagesPage;
