import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV1 } from 'api/User/types';
import { getOnlyNumbers } from 'common/helpers/getOnlyNumbers';

import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LastBloodPressureQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const [ error, setError ] = useState<string>('');
  const { submit, isSubmitting, submitError } = useQuestionForm();

  const [ systolic, setSystolic ] = useState<string>('');
  const [ diastolic, setDiastolic ] = useState<string>('');

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    submit({
      ...survey,
      bloodPressureMeasurement: {
        systolic: Number(systolic),
        diastolic: Number(diastolic),
      },
    })
      .then((data: Survey) => onNext(data));
  }, [ systolic, diastolic, onNext, submit, survey ]);

  const onSystolicChanged = useCallback((event) => {
    setError('');
    setSystolic(getOnlyNumbers(event.target.value));
  }, []);

  const onDiastolicChanged = useCallback((event) => {
    setError('');
    setDiastolic(getOnlyNumbers(event.target.value));
  }, []);
  
  useEffect(() => {
    setSystolic(String((survey as SurveyV1)?.bloodPressureMeasurement?.systolic || ''));
    setDiastolic(String((survey as SurveyV1)?.bloodPressureMeasurement?.diastolic || ''));
  }, [ survey ]);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-last-blood-pressure">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_last_blood_pressure.question')}
          </Typography>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="body1" fontWeight="600" width={100}>
              {t('dashboard.survey.question_last_blood_pressure.systolic')}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              size="small"
              color="primary"
              value={systolic}
              onChange={onSystolicChanged}
              error={Boolean(error)}
              inputProps={{ 'data-testid': 'input-systolic' }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="body1" fontWeight="600" width={100}>
              {t('dashboard.survey.question_last_blood_pressure.diastolic')}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              size="small"
              color="primary"
              value={diastolic}
              onChange={onDiastolicChanged}
              error={Boolean(error)}
              inputProps={{ 'data-testid': 'input-diastolic' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary" fontWeight={400}>
            {t('dashboard.survey.hint_leave_blank')}
          </Typography>
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default LastBloodPressureQuestion;
