import {
  midnight, midnight50, midnight1000,
  peach, peach50, peach400, peach500, peach700,
  red, red50, red600, red300, red900,
  purple50, purple100, purple600, purple700,
  grey200, grey300, grey400, grey500, grey600, grey700, grey800, grey900, grey1000,
  purple300, purple500, purple650, purple800,
  rust, rust300, rust600, rust900,
  ivory, white, limeGreen,
} from 'common/constants/colors';
import { APP_BAR_HEIGHT } from 'common/constants/sizes';
import { SATOSHI, MONTSERRAT_ALTERNATES } from 'common/constants/fonts';

import { ThemeOptions } from '@mui/material';
import { PaletteColorOptions } from '@mui/material/styles/createPalette';

export const theme: ThemeOptions = {
  palette: {
    primary: {
      main: midnight,
      dark: purple700,
      surface: midnight50,
      border: midnight1000,
      hover: purple700,
      pressed: purple800,
    } as PaletteColorOptions,
    secondary: {
      main: peach,
      light: peach,
      dark: peach500,
      contrastText: midnight,
    },
    orange: {
      main: peach,
      light: peach,
      dark: peach500,
      contrastText: midnight,
      surface: peach50,
      border: peach400,
      hover: peach500,
      pressed: peach700,
    },
    purple: {
      main: purple600,
      light: purple600,
      dark: purple500,
      contrastText: purple600,
      surface: purple50,
      border: purple300,
      hover: purple500,
      pressed: purple650,
    },
    error: {
      main: red,
      light: red,
      dark: red600,
      contrastText: red,
      surface: red50,
      border: red300,
      hover: red600,
      pressed: red900,
    } as PaletteColorOptions,
    warning: {
      main: rust,
      dark: rust600,
      surface: red50,
      border: rust300,
      hover: rust600,
      pressed: rust900,
    } as PaletteColorOptions,
    special: {
      main: purple100,
      light: purple100,
      dark: purple100,
      contrastText: purple600,
    },
    primaryContrast: {
      main: ivory,
      light: ivory,
      dark: ivory,
      contrastText: ivory,
    },
    ivory: {
      main: ivory,
      light: ivory,
      dark: ivory,
      contrastText: ivory,
    },
    success: {
      main: purple600,
    },
    neutral: {
      20: grey200,
      30: grey300,
      40: grey400,
      50: grey500,
      60: grey600,
      70: grey700,
      80: grey800,
      90: grey900,
      100: grey1000,
    },
  },
  typography: {
    fontFamily: [
      SATOSHI,
      MONTSERRAT_ALTERNATES,
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    h3: {
      fontFamily: SATOSHI,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: '125%',
      letterSpacing: '0.05rem',
    },
    button: {
      fontFamily: SATOSHI,
      fontStyle: 'normal',
      fontSize: '1rem',
      lineHeight: '150%',
      letterSpacing: '-0.011em',
    },
    headingL: {
      fontFamily: MONTSERRAT_ALTERNATES,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: '122%',
      letterSpacing: '-0.022em',
    },
    headingM: {
      fontFamily: MONTSERRAT_ALTERNATES,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: '129%',
      letterSpacing: '-0.021em',
    },
    headingS: {
      fontFamily: MONTSERRAT_ALTERNATES,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '140%',
      letterSpacing: '-0.017em',
    },
    textSRegular: {
      fontFamily: SATOSHI,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '143%',
      letterSpacing: '-0.006em',
    },
    textSMedium: {
      fontFamily: SATOSHI,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '143%',
      letterSpacing: '-0.006em',
    },
    textMRegular: {
      fontFamily: SATOSHI,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '150%',
      letterSpacing: '-0.011em',
    },
    textMMedium: {
      fontFamily: SATOSHI,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '150%',
      letterSpacing: '-0.011em',
    },
    textLRegular: {
      fontFamily: SATOSHI,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: '156%',
      letterSpacing: '-0.014em',
    },
    textLMedium: {
      fontFamily: SATOSHI,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: '156%',
      letterSpacing: '-0.014em',
    },
    textXLBold: {
      fontFamily: SATOSHI,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '125%',
      letterSpacing: '0.05em',
    },
    body1: {
      fontFamily: SATOSHI,
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '125%',
      letterSpacing: '0.05em',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1472,
    },
  },
  components: {
    MuiBadge: {
      variants: [
        {
          props: { variant: 'dot', color: 'success' },
          style: {
            '& .MuiBadge-badge': {
              backgroundColor: limeGreen,
            },
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          headingL: 'h4',
          headingM: 'h5',
          headingS: 'h6',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          position: 'fixed',
          height: APP_BAR_HEIGHT,
          justifyContent: 'center',
          backgroundColor: white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
        contained: {
          boxShadow: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'contained', size: 'medium' },
          style: {
            padding: '8px 16px',
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: ({ theme }) => ({
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
            '&:active': {
              backgroundColor: theme.palette.primary.pressed,
            },
            '&:disabled': {
              backgroundColor: theme.palette.neutral[30],
              boxShadow: `inset 0px 0px 0px 1px ${theme.palette.neutral[60]}`,
            },
          }),
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: ({ theme }) => ({
            '&:hover': {
              backgroundColor: theme.palette.purple.surface,
            },
            '&:active': {
              backgroundColor: theme.palette.primary.surface,
            },
          }),
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: ({ theme }) => ({
            borderColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.purple.surface,
            },
            '&:active': {
              backgroundColor: theme.palette.primary.surface,
            },
          }),
        },
        {
          props: { variant: 'round' },
          style: ({ theme }) => ({
            textTransform: 'none',
            border: `solid 2px ${theme.palette.primary.main}`,
            borderRadius: '50%',
            minWidth: 0,
            width: '48px',
            height: '48px',
          }),
        },
        {
          props: { variant: 'round', disabled: true },
          style: ({ theme }) => ({
            border: `solid 1px ${theme.palette.neutral[30]}`,
          }),
        },
        {
          props: { variant: 'round', size: 'small' },
          style: {
            width: '36px',
            height: '36px',
          },
        },
        {
          props: { variant: 'round', size: 'large' },
          style: {
            width: '60px',
            height: '60px',
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: '23px',
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 400,
          borderRadius: 4,
          '&.MuiInputBase-root.Mui-disabled': {
            backgroundColor: theme.palette.neutral[30],
            '&>fieldset': {
              borderColor: theme.palette.neutral[60],
            },
            '&>input': {
              WebkitTextFillColor: theme.palette.neutral[60],
            },
          },
        }),
        notchedOutline: ({ theme }) => ({
          borderWidth: '2px',
          borderColor: theme.palette.neutral[60],
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          paddingLeft: '8px',
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderLeftWidth: '2px',
          borderColor: theme.palette.primary.main,
          paddingLeft: '28px',
        }),
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: ({ theme }) => ({
          borderLeftWidth: '2px',
          borderColor: theme.palette.primary.main,
        }),
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          display: 'flex',
          margin: 'auto',
        },
      },
    },
  },
};
