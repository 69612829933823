import { UserAccessLevel } from 'api/User/types';
import { AccessLevelLabels } from 'components/AdminDashboard/components/AdminRolesPage/types';

export const getAccessLevelLabel = (accessLevel: UserAccessLevel): AccessLevelLabels => {
  switch (accessLevel) {
    case UserAccessLevel.ADMIN:
      return AccessLevelLabels.ADMIN;

    case UserAccessLevel.PARTNER:
      return AccessLevelLabels.PARTNER;

    case UserAccessLevel.BIO_BANK_ADMIN:
      return AccessLevelLabels.BIO_BANK_ADMIN;

    case UserAccessLevel.DATA_SCIENCE_ADMIN:
      return AccessLevelLabels.DATA_SCIENCE_ADMIN;

    case UserAccessLevel.SUPER_ADMIN:
      return AccessLevelLabels.SUPER_ADMIN;

    default:
      return AccessLevelLabels.USER;
  }
};
