import { styled } from '@mui/material';

const TrackingStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.primary.main,
    }),
    '& .TrackingStepIcon-circle-completed': {
      marginLeft: '2px',
      width: '22px',
      height: '22px',
      padding: '3px',
      color: 'white',
      zIndex: 1,
      fontSize: 12,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
    },
    '& .TrackingStepIcon-circle': {
      marginLeft: '2px',
      width: '22px',
      height: '22px',
      border: '2px solid #000123',
      borderRadius: '50%',
      backgroundColor: 'white',
      ...(ownerState.active && {
        color: theme.palette.primary.main,
        backgroundBlendMode: 'multiply',
        boxShadow: '0px 0px 6px 4px rgba(0, 0, 0, 0.25)',
        animation: 'pulse 2s infinite',
      }),
    },
    '@keyframes pulse': {
      '0%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.4)',
      },
      '70%': {
        boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
      },
      '100%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
      },
    },
  }),
);

export default TrackingStepIconRoot;
