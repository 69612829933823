import { News, NewsType } from 'api/News/types';
import { getNewsReadable } from 'api/News/api';

import { useCallback, useState } from 'react';

const DEFAULT_NEWS: News = {
  id: '',
  newsType: NewsType.PUBLICATION,
  readableId: '',
  title: {
    title: '',
    imageUrl: '',
    dataUrl: '',
  },
  newsDate: '',
  body: {
    body: '',
    resourceUrl: '',
  },
};

export const useNews = () => {
  const [ isLoading, setLoading ] = useState(false);
  const [ news, setNews ] = useState<News>(DEFAULT_NEWS);
  const [ error, setError ] = useState('');

  const requestNews = useCallback((id: string) => {
    setLoading(true);
    setError('');

    getNewsReadable(id)
      .then(({ data }) => {
        setNews(data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || 'Server error. Please try again.');
      })
      .finally(() => setLoading(false));
  }, []);

  return {
    requestNews,
    news,
    isLoading,
    error,
  };
};
