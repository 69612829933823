import HealthInfoQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HealthInfoQuestion/HealthInfoQuestion';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';

import React from 'react';

const DISEASES = [
  'Chronic obstructive pulmonary disease (COPD)',
  'Asthma',
  'Long-term COVID',
  'Other',
];

const PulmonaryDiseaseQuestion: React.FC<QuestionPageProps> = (props) => {
  return <HealthInfoQuestion {...props} field="pulmonaryDisease" diseases={DISEASES} />;
};

export default PulmonaryDiseaseQuestion;
