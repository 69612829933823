import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV2 } from 'api/User/types';

import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AncestralOriginQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const [ error, setError ] = useState<string>('');
  const [ ancestralOrigin, setAncestralOrigin ] = useState<string>('');

  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    if (!ancestralOrigin) {
      setError(t('dashboard.survey.error_give_answer'));
    } else {
      submit({ ...survey, ancestralOrigin })
        .then((data: Survey) => onNext(data));
    }
  }, [ t, onNext, submit, survey, ancestralOrigin ]);

  useEffect(() => {
    if (survey) {
      const { ancestralOrigin } = survey as SurveyV2;
      if (ancestralOrigin) {
        setAncestralOrigin(ancestralOrigin);
      }
    }
  }, [ survey ]);

  const handleChange = useCallback((event) => {
    setError('');
    setAncestralOrigin(event.target.value);
  }, []);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-ancestral-origin">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_ancestral_origin.question')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            color="primary"
            size="small"
            value={ancestralOrigin}
            onChange={handleChange}
            error={Boolean(error)}
            fullWidth
            placeholder={t('dashboard.survey.question_ancestral_origin.placeholder')}
            inputProps={{ 'data-testid': 'input-ancestral-origin' }}
          />
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default AncestralOriginQuestion;
