import { midnight } from 'common/constants/colors';
import PartnersGlobe
  from 'components/Landing/components/PartnersContent/components/GlobePage/components/PartnersGlobe/PartnersGlobe';

import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const GlobePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      id="chart"
      position="relative"
      width="100%"
      sx={{
        overflow: 'hidden',
        backgroundColor: midnight,
      }}
      height={{ xs: '500px', md: '800px' }}
      paddingTop={{ xs: 2, lg: 12 }}
      paddingBottom={{ xs: 2, lg: 12 }}
    >
      <Container maxWidth="xl" sx={{ marginBottom: -2 }}>
        <Typography
          color="secondary"
          sx={{
            fontFamily: 'Montserrat Alternates',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: { xs: '32px', lg: '48px' },
            lineHeight: '150%',
            letterSpacing: '-0.05em',
            maxWidth: '960px',
          }}
        >
          {t('landing.partners.globe.our_partners_around_the_world')}
        </Typography>
        <Box marginTop={2} width="100%" textAlign="center">
          <PartnersGlobe />
        </Box>
      </Container>
    </Box>
  );
};

export default GlobePage;
