export interface GetTracksParams {
  carrier: string;
  trackingNumber: string;
}

export interface Transaction {
  object: number;
  objectId: string;
  objectState: string;
  status: string;
  objectCreated: string;
  objectUpdated: string;
  objectOwner: string;
  wasTest: boolean | null;
  trackingNumber: string;
  trackingStatus: string;
  trackingUrlProvider: string;
  labelURL: string;
  commercialInvoiceUrl: string | null;
}

export interface Transactions {
  directTransaction: Transaction | null;
  returnTransaction: Transaction | null;
}

export enum TrackStatus {
  DELIVERED = 1,
  TRANSIT = 2,
  RETURNED = 3,
  FAILURE = 4,
  PRE_TRANSIT = 5,
}

export enum TrackStatusLiteral {
  DELIVERED = 'DELIVERED',
  TRANSIT = 'TRANSIT',
  RETURNED = 'RETURNED',
  FAILURE = 'FAILURE',
  PRE_TRANSIT = 'PRE_TRANSIT',
}

export interface Track {
  trackingNumber: string;
  carrier: string;
  servicelevel: {
    name: string;
    token: string;
  };
  addressFrom: {
    city: string;
    state: string;
    zip: string;
    country: string;
  },
  addressTo: {
    city: string;
    state: string;
    zip: string;
    country: string;
  },
  eta: string;
  originalEta: string;
  metadata: string;
  test: string;
  trackingStatus: {
    statusDate: string;
    statusDetails: string;
    location: {
      city: string;
      state: string;
      zip: string;
      country: string;
    },
    substatus: string;
    objectCreated: string;
    objectUpdated: string;
    objectId: string;
    status: TrackStatus | TrackStatusLiteral;
  },
  tracking_history: [
    {
      statusDate: string;
      statusDetails: string; // "The carrier has received the electronic shipment information."
      location: string;
      substatus: string;
      objectCreated: string;
      objectUpdated: string;
      objectId: string;
      status: number; // "UNKNOWN" ...
    },
    {
      statusDate: string;
      statusDetails: string;
      location: {
        city: string;
        state: string;
        zip: string;
        country: string;
      },
      substatus: string;
      object_created: string;
      object_updated: string;
      object_id: string;
      status: number;
    },
  ],
  messages: string;
}

export interface ShippingStatus {
  transactionId: string;
  substatus: string;
  statusDate: string;
  statusDetails: string;
  status: TrackStatus | TrackStatusLiteral;
  objectUpdated: string;
  objectCreated: string;
}
