import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV1 } from 'api/User/types';
import { getOnlyNumbers } from 'common/helpers/getOnlyNumbers';

import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AgeQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const [ error, setError ] = useState<string>('');
  const [ value, setValue ] = useState<string>('');

  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    if (!value) {
      setError(t('dashboard.survey.error_give_answer'));
    } else {
      submit({
        ...survey,
        age: parseInt(value, 10),
      })
        .then((data: Survey) => onNext(data));
    }
  }, [ t, onNext, submit, survey, value ]);

  useEffect(() => {
    if (survey && (survey as SurveyV1).age !== null) {
      setValue(String((survey as SurveyV1).age));
    }
  }, [ survey ]);

  const handleChange = useCallback((event) => {
    setError('');
    setValue(getOnlyNumbers(event.target.value));
  }, []);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-age">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_age.question')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            color="primary"
            size="small"
            value={value}
            onChange={handleChange}
            error={Boolean(error)}
            sx={{ width: 100 }}
            inputProps={{ 'data-testid': 'input-age' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary" fontWeight={400}>
            {t('dashboard.survey.question_age.hint')}
          </Typography>
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default AgeQuestion;
