import { Organization } from 'api/Organizations/types';
import useChangeOrganization from 'components/AdminDashboard/components/AdminRolesPage/components/ChangeOrganizationPopover/hooks';
import { RootState } from 'store';

import React, { ChangeEvent, useState } from 'react';
import { Button, Grid, FormControl, MenuItem, Popover, Typography, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

interface Props {
  userId: number
  organizationId: number | null
  onClose: () => void;
  onChange: (value: number) => void;
  anchorEl: HTMLElement;
}

const ChangeOrganizationPopover: React.FC<Props> = ({ userId, organizationId, anchorEl, onClose, onChange }) => {
  const { organizations } = useSelector((store: RootState) => store.organizations);

  const { changeOrganization, isSubmitting, submitError } = useChangeOrganization(onClose);

  const [ orgName, setOrgName ] = useState<string | undefined>();

  const handleSelectChanged = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value));
    setOrgName(organizations.find(o => o.id === Number(e.target.value))?.name);
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 1,
        sx: { padding: 4 },
      }}
      onClose={onClose}
      data-testid="popover-change-organization"
      disableRestoreFocus
    >
      <Grid container display="flex" flexDirection="column">
        <Grid item sx={{ marginBottom: 4 }}>
          <Typography>
            Select an organization:
          </Typography>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              id="organization"
              select
              value={organizationId ?? 0}
              label="Organization"
              name="organization"
              size="small"
              disabled={isSubmitting}
              onChange={handleSelectChanged}
              data-testid="input-organization"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {organizations.map((item: Organization) => <MenuItem key={item.name} value={item.id}>{item.name}</MenuItem>)}
            </TextField>
          </FormControl>
        </Grid>
        {
          submitError && (
            <Grid item xs={12}>
              <Typography color="error" variant="subtitle2">
                {submitError}
              </Typography>
            </Grid>
          )
        }
        <Grid item display="flex" justifyContent="flex-end">
          <Button
            disabled={isSubmitting || organizationId === null}
            variant="contained"
            onClick={() => changeOrganization(userId, organizationId!, orgName!)}
            data-testid="button-change-role"
            sx={{ marginTop: 4 }}
          >
            Change
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default ChangeOrganizationPopover;
