import { LANDING_ROUTE } from 'common/constants/urls';

import React from 'react';
import { Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserRemovedModal: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOk = () => {
    navigate(LANDING_ROUTE, { replace: true });
  };

  return (
    <Modal
      BackdropProps={{
        invisible: false,
        sx: {
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
        },
      }}
      open
      disableRestoreFocus
    >
      <Paper
        sx={{
          borderRadius: '30px',
          elevation: 5,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
        }}
        data-testid="modal-user-removed"
      >
        <Grid container padding={{ xs: 3, md: 5 }} spacing={{ xs: 2, md: 5 }} maxWidth={640}>
          <Grid item xs={12}>
            <Typography variant="textXLBold">
              {t('landing.account_deleted.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="textLMedium">
              {t('landing.account_deleted.body')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleOk} data-testid="button-close-user-removed">
              {t('general.ok')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default UserRemovedModal;
