import HealthInfoQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HealthInfoQuestion/HealthInfoQuestion';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';

import React from 'react';

const DISEASES = [
  'Myocardial (heart) infarction',
  'Stroke',
  'Arrhythmia',
  'Hypertension',
  'Other',
];

const HeartDiseaseQuestion: React.FC<QuestionPageProps> = ({ onNext, onPrev, onSubmit }) => {
  return <HealthInfoQuestion onPrev={onPrev} onNext={onNext} onSubmit={onSubmit} field="heartDisease" diseases={DISEASES} />;
};

export default HeartDiseaseQuestion;
