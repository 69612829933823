import HealthInfoQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HealthInfoQuestion/HealthInfoQuestion';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';

import React from 'react';

const DISEASES = [
  'Ulcerative colitis',
  'Celiac disease',
  'Crohn\'s disease',
  'Other inflammatory bowel disease',
  'Sclerosing cholangitis',
  'Colon or rectal cancer',
  'Cholangiocarcinoma',
  'Other',
];

const DigestiveDisorderQuestion: React.FC<QuestionPageProps> = ({ onNext, onPrev, onSubmit }) => {
  return <HealthInfoQuestion onPrev={onPrev} onNext={onNext} onSubmit={onSubmit} field="digestiveDisorder" diseases={DISEASES} />;
};

export default DigestiveDisorderQuestion;
