import WelcomePage from 'components/Landing/components/MainContent/components/WelcomePage/WelcomePage';
import AdvantagesPage from 'components/Landing/components/MainContent/components/AdvantagesPage/AdvantagesPage';
import QuotePage from 'components/Landing/components/MainContent/components/QuotePage/QuotePage';
import PieChartPage from 'components/Landing/components/MainContent/components/PieChartPage/PieChartPage';
import JoiningUsPage from 'components/Landing/components/MainContent/components/JoiningUsPage/JoiningUsPage';
import FaqPage from 'components/Landing/components/MainContent/components/FaqPage/FaqPage';

import React from 'react';

const MainContent: React.FC = () => (
  <>
    <WelcomePage />
    <AdvantagesPage />
    <QuotePage />
    <PieChartPage />
    <JoiningUsPage />
    <FaqPage />
  </>
);

export default MainContent;
