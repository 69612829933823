import { peach } from 'common/constants/colors';
import MillionImg from 'components/Landing/components/PartnersContent/components/OurGoalPage/resources/million.svg';

import { Box, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const OurGoalPage: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <Box sx={{ backgroundColor: peach, overflow: 'hidden' }}>
      <Container maxWidth="xl" sx={{ position: 'relative' }}>
        <Box
          paddingTop={25}
          sx={{
            marginLeft: { xs: 0, lg: 8 },
          }}
        >
          <Typography
            color="purple.main"
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: { xs: '32px', lg: '48px' },
              lineHeight: '150%',
              letterSpacing: '-0.05em',
              maxWidth: '900px',
            }}
          >
            {t('landing.goals.title')}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="37px"
            sx={{
              flexDirection: { xs: 'column', lg: 'row' },
            }}
          >
            <Typography
              color="primary.main"
              sx={{
                fontFamily: 'Satoshi',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '24px',
                lineHeight: '175%',
                maxWidth: '670px',
              }}
            >
              {t('landing.goals.body')}
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              // width="636px"
              sx={{
                flexWrap: { xs: 'wrap', lg: 'no-wrap' },
                marginTop: { xs: '32px', lg: '0' },
              }}
            >
              <Paper
                sx={{
                  width: { xs: '160px', lg: '180px' },
                  height: { xs: '187px', lg: '210px' },
                  borderRadius: '30px',
                  padding: { xs: '40px 28px 8px 28px', lg: '48px 32px 8px 32px' },
                  margin: { xs: '12px', lg: '24px' },
                }}
              >
                <Typography
                  color="primary.main"
                  sx={{
                    fontFamily: 'Montserrat Alternates',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '28px',
                    lineHeight: '170%',
                    letterSpacing: '-0.05em',
                  }}
                >
                  {t('landing.goals.goal_1_title')}
                </Typography>
                <Typography
                  color="orange.main"
                  sx={{
                    fontFamily: 'Satoshi',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '18px',
                    lineHeight: '150%',
                    marginTop: '14px',
                  }}
                >
                  {t('landing.goals.goal_1_body')}
                </Typography>
              </Paper>
              <Paper sx={{
                width: { xs: '160px', lg: '180px' },
                height: { xs: '187px', lg: '210px' },
                borderRadius: '30px',
                padding: { xs: '40px 28px 8px 28px', lg: '48px 32px 8px 32px' },
                margin: { xs: '12px', lg: '24px' },
              }}
              >
                <Typography
                  color="primary.main"
                  sx={{
                    fontFamily: 'Montserrat Alternates',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '28px',
                    lineHeight: '170%',
                    letterSpacing: '-0.05em',
                  }}
                >
                  {t('landing.goals.goal_2_title')}
                </Typography>
                <Typography
                  color="orange.main"
                  sx={{
                    fontFamily: 'Satoshi',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '18px',
                    lineHeight: '150%',
                    marginTop: '14px',
                  }}
                >
                  {t('landing.goals.goal_2_body')}
                </Typography>
              </Paper>
              <Paper sx={{
                width: { xs: '160px', lg: '180px' },
                height: { xs: '187px', lg: '210px' },
                borderRadius: '30px',
                padding: { xs: '40px 28px 8px 28px', lg: '48px 32px 8px 32px' },
                margin: { xs: '12px', lg: '24px' },
              }}
              >
                <Typography
                  color="primary.main"
                  sx={{
                    fontFamily: 'Montserrat Alternates',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '28px',
                    lineHeight: '170%',
                    letterSpacing: '-0.05em',
                  }}
                >
                  500+
                </Typography>
                <Typography
                  color="orange.main"
                  sx={{
                    fontFamily: 'Satoshi',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '18px',
                    lineHeight: '150%',
                    marginTop: '14px',
                  }}
                >
                  {t('landing.goals.goal_3_body')}
                </Typography>
              </Paper>
            </Box>
          </Box>
          <Box
            position="relative"
            width="100%"
            sx={{
              bottom: { xs: '-30px', lg: '-80px' },
            }}
          >
            <img src={MillionImg} alt="" width="100%" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OurGoalPage;
