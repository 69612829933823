import { postContactUs } from 'api/User/api';

import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';

interface ContactUsFormValues {
  email: string
  message: string
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter correct email address.')
    .required('Enter your email address.'),
  message: yup
    .string()
    .required('Enter your message.'),
});

const useContactUs = () => {
  const [ submitError, setSubmitError ] = useState<string>('');
  const [ isSuccess, setIsSuccess ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const formikSubmit = (values: ContactUsFormValues) => {
    setIsLoading(true);
    setSubmitError('');
    postContactUs(values.email, values.message).then(() => {
      setIsSuccess(true);
    }).catch(() => {
      setSubmitError('Something went wrong. Please try again later.');
    }).finally(() => setIsLoading(false));
  };
  useEffect(() => {

  }, []);

  const formik = useFormik<ContactUsFormValues>({
    initialValues: { email: '', message: '' },
    validationSchema,
    onSubmit: formikSubmit,
  });

  return { isLoading, formik, isSuccess, submitError };
};

export default useContactUs;
