import { Sample, Bag, Plate } from 'api/Samples/types';
import { Pagination } from 'common/types/Pagination';
import { SamplesState } from 'store/samples/models';
import {
  SET_SAMPLES,
  SET_BAGS,
  SET_PLATES,
  SET_SAMPLES_LOADING,
  SET_SAMPLES_ERROR,
} from 'store/samples/types';

import { AnyAction } from 'redux';
import omit from 'lodash/omit';

export const initialState: SamplesState = {
  isLoading: false,
  samples: [],
  bags: [],
  plates: [],
  pagination: {
    currentPage: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
    pageCount: 0,
    pageSize: 0,
    rowCount: 0,
  },
  error: '',
};

export const samplesReducer = (
  state: SamplesState = { ...initialState },
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_SAMPLES_LOADING:
      return {
        ...state,
        isLoading: action.value as boolean,
      };

    case SET_SAMPLES:
      return {
        ...state,
        samples: action.data.results as Sample[],
        pagination: omit(action.data, 'results') as Pagination,
        error: '',
      };

    case SET_BAGS:
      return {
        ...state,
        bags: action.data.results as Bag[],
        pagination: omit(action.data, 'results') as Pagination,
        error: '',
      };

    case SET_PLATES:
      return {
        ...state,
        plates: action.data.results as Plate[],
        pagination: omit(action.data, 'results') as Pagination,
        error: '',
      };

    case SET_SAMPLES_ERROR:
      return {
        ...state,
        error: action.value as string,
      };

    default:
      return { ...state };
  }
};
