import PartnershipPage from 'components/Landing/components/PartnersContent/components/PartnershipPage/PartnershipPage';
import ServicesPage from 'components/Landing/components/PartnersContent/components/ServicesPage/ServicesPage';
import OurGoalPage from 'components/Landing/components/PartnersContent/components/OurGoalPage/OurGoalPage';
import LetsBePartnersPage
  from 'components/Landing/components/PartnersContent/components/LetsBePartnersPage/LetsBePartnersPage';
import DistinctionsPage
  from 'components/Landing/components/PartnersContent/components/DistinctionsPage/DistinctionsPage';
import GlobePage from 'components/Landing/components/PartnersContent/components/GlobePage/GlobePage';

import React, { useEffect } from 'react';
import { Box } from '@mui/material';

const PartnersContent: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Box data-testid="content-partners">
      <PartnershipPage />
      <ServicesPage />
      <DistinctionsPage />
      <OurGoalPage />
      <GlobePage />
      <LetsBePartnersPage />
    </Box>
  );
};

export default PartnersContent;
