import { AncestryProvider } from 'api/Reports/types';
import AndMeLogo from 'components/Dashboard/components/AncestryPage/resources/23_and_me_logo.png';
import AncestryLogo from 'components/Dashboard/components/AncestryPage/resources/ancestry_logo.png';
import HeritageLogo from 'components/Dashboard/components/AncestryPage/resources/my_heritage_logo.png';
import Sequencing from 'components/Dashboard/components/AncestryPage/resources/sequencing.svg';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  provider: AncestryProvider;
}

const ProviderLogo: React.FC<Props> = ({ provider }) => {
  const { t } = useTranslation();

  switch (provider) {
    case AncestryProvider.TWENTY_THREE_AND_ME:
      return <img src={AndMeLogo} alt="23andMe" />;
    case AncestryProvider.ANCESTRY:
      return <img src={AncestryLogo} alt="Ancestry" />;
    case AncestryProvider.MYHERITAGE:
      return <img src={HeritageLogo} alt="My heritage" />;
    case AncestryProvider.WHOLE_GENOME_SEQUENCING:
      return (
        <Box display="flex" flexDirection="column">
          <img data-testid="sequencing" height={80} src={Sequencing} alt="" />
          <Typography>{t('dashboard.ancestry.provider_sequencing')}</Typography>
        </Box>
      );

    default:
      return <Typography>{t('dashboard.ancestry.provider_other')}</Typography>;
  }
};

export default ProviderLogo;
