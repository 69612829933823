import { AuthorizationContainer, FormTextField, Label } from 'common/components';
import useSetPassword from 'components/Dashboard/components/Authorization/hooks/useSetPasword';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormikProvider } from 'formik';

const SetPasswordPage = () => {
  const { key } = useParams();
  const { t } = useTranslation();
  const { formik, isSubmitting, submitError } = useSetPassword(key!);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!key) {
      navigate('/dashboard');
    }
  }, [ key, navigate ]);

  return (
    <AuthorizationContainer>
      <Label>{t('reset_password.set_new_password')}</Label>
      <Box minWidth="100%">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} data-testid="set-password-form">
            <FormTextField
              id="password"
              name="password"
              type="password"
              label={t('auth.password')}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
              touched={formik.touched.password}
              disabled={isSubmitting}
            />
            <Typography marginLeft="5px" marginBottom="16px" fontSize="12px" color="#3E355C">
              {t('registration.hint_password_strength')}
            </Typography>
            <FormTextField
              id="confirm-password"
              name="confirmPassword"
              type="password"
              label={t('registration.confirm_password')}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.errors.confirmPassword}
              touched={formik.touched.confirmPassword}
              disabled={isSubmitting}
            />
            {
              submitError && (
                <Box marginTop={1}>
                  <Typography color="error" variant="subtitle2">
                    {submitError}
                  </Typography>
                </Box>
              )
            }
            <Box
              marginTop={{ xs: 8, sm: 5 }}
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <LoadingButton
                onClick={formik.submitForm}
                loading={isSubmitting}
                variant="contained"
                sx={{ padding: '10px 16px', width: { xs: '100%', sm: '160px' } }}
                data-testid="button-submit"
              >
                {t('general.submit')}
              </LoadingButton>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </AuthorizationContainer>
  );
};

export default SetPasswordPage;
