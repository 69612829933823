import usePartnerInvite from 'components/Dashboard/components/Authorization/hooks/usePartnerInvite';
import SignUpPage from 'components/Dashboard/components/Authorization/SignUpPage/SignUpPage';
import ResetPasswordPage from 'components/Dashboard/components/Authorization/ResetPassword/ResetPasswordPage';
import SetPasswordPage from 'components/Dashboard/components/Authorization/SetPassword/SetPasswordPage';
import VerifyPage from 'components/Dashboard/components/Authorization/Verify/VerifyPage';
import SignInPage from 'components/Dashboard/components/Authorization/SignInPage/SignInPage';

import { Route, Routes, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import React from 'react';

interface Props {
  disableRegistration?: boolean
}

const AuthorizationPage: React.FC<Props> = ({ disableRegistration }) => {
  const navigate = useNavigate();
  const { partnerValues, isPartnerInfoLoading } = usePartnerInvite();

  React.useEffect(() => {
    if (partnerValues) {
      navigate('/dashboard/signup');
    }
  }, [ partnerValues, navigate ]);

  if (isPartnerInfoLoading) {
    return <CircularProgress />;
  }

  return (
    <Routes>
      <Route path="signup" element={<SignUpPage partnerValues={partnerValues} />} />
      <Route path="reset-password" element={<ResetPasswordPage disableRegistration={disableRegistration} />} />
      <Route path="reset-password/:key" element={<SetPasswordPage />} />
      <Route path="verify/:key" element={<VerifyPage />} />
      <Route path="*" element={<SignInPage />} />
    </Routes>
  );
};

export default AuthorizationPage;
