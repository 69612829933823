import { createUserRequest } from 'api/User/api';
import { CreateUserRequestParams } from 'api/User/types';
import { NEW_TEST_KIT_ID_LENGTH } from 'api/UserKits/constants';
import { USA } from 'common/constants/countries';
import { getUSZipCodeRegExp } from 'common/helpers/user';

import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface AddUserFormValues {
  firstName: string;
  lastName: string;
  email: string;
  organizationId: string | null;
  organizationSiteId: string | null;
  birthdate: string;
  gender: string;
  zip: string;
  phoneNumber: string;
  country: string;
  state: string;
  city: string;
  street1: string;
  testKitId: string;
  testKitReceived: boolean;
  mrn: string;
  previousZipCodes: string;
  sampleSampleId: string;
  sampleProviderName: string;
  sampleProductLine: string;
  sampleAccount: string;
  sampleElisOrderId: string;
  sampleSpecimenType: string;
  sampleDiagnosisCodes: string;
  samplePanel: string;
}

const useAddUserForm = (onSubmit: () => void, clinicalPatient: boolean) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => yup.object({
    firstName: yup
      .string()
      .required(t('registration.hint_firstname')),
    lastName: yup
      .string()
      .required(t('registration.hint_lastname')),
    email: yup
      .string()
      .email(t('registration.error_email')),
    birthdate: yup
      .date()
      .typeError(t('registration.hint_dob'))
      .required(t('registration.hint_dob')),
    gender: yup
      .string()
      .required(t('registration.hint_gender')),
    phoneNumber: yup
      .string()
      .matches(
        // https://github.com/dockwa/simple-react-validator/blob/master/src/simple-react-validator.js#L42
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        t('registration.error_phone'),
      ),
    testKitId: yup
      .string()
      .min(NEW_TEST_KIT_ID_LENGTH, `Kit id must contain ${NEW_TEST_KIT_ID_LENGTH} characters`),
    zip: yup
      .string()
      .when('country', {
        is: (country: string) => country === USA,
        then: yup.string().required(t('registration.hint_zip')),
        otherwise: yup.string(),
      }),
    sampleId: yup
      .string()
      .max(32, 'Sample ID must be at most 32 characters')
      .matches(/^[\w:-]*$/, 'Sample ID must contain only alphanumeric characters'),
    mrn: yup
      .string()
      .max(32, 'MRN must be at most 32 characters')
      .matches(/^\w*$/, 'MRN must contain only alphanumerical characters'),
    previousZipCodes: yup
      .string()
      .matches(getUSZipCodeRegExp(), 'Only the five-digit and nine-digit (ZIP+4) formats are allowed'),
  }), [ t ]);

  const formikSubmit = (formValues: AddUserFormValues) => {
    setSubmitting(true);
    setSubmitError('');

    const { country, state, city, street1, zip, previousZipCodes, organizationId, organizationSiteId, ...data } = formValues;
    const requestParams: CreateUserRequestParams = {
      ...data,
      organizationId: organizationId === '' ? null : organizationId,
      organizationSiteId: organizationSiteId === '' ? null : organizationSiteId,
      mailingAddress: { country, state, city, street1, zip, previousZipCodes: previousZipCodes.split(',') },
    };
    createUserRequest(requestParams, clinicalPatient)
      .then(() => {
        onSubmit();
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    organizationId: '',
    organizationSiteId: '',
    birthdate: '',
    gender: '',
    zip: '',
    phoneNumber: '',
    country: '',
    state: '',
    city: '',
    street1: '',
    testKitId: '',
    testKitReceived: false,
    mrn: '',
    previousZipCodes: '',
    sampleSampleId: '',
    sampleProviderName: '',
    sampleProductLine: '',
    sampleAccount: '',
    sampleElisOrderId: '',
    sampleSpecimenType: '',
    sampleDiagnosisCodes: '',
    samplePanel: '',
  };

  const formik = useFormik<AddUserFormValues>({
    initialValues,
    validationSchema,
    onSubmit: formikSubmit,
  });

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  return { formik, isSubmitting, submitError };
};

export default useAddUserForm;
