import { createLabels } from 'api/Samples/api';
import { CreateLabelsParams, LabelType } from 'api/Samples/types';

import { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

const useCreateLabels = (onSuccess: () => void) => {
  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  const validationSchema = useMemo(() => yup.object({
    type: yup
      .mixed()
      .oneOf(Object.values(LabelType))
      .required('Select a type'),
    countryCode: yup
      .string()
      .matches(/^(?=.{1,2}$)[A-Z]{2}/s, 'Enter a valid country code')
      .when('type', {
        is: LabelType.SAMPLE,
        then: yup.string().required('Enter a country code'),
      }),
    site: yup
      .string()
      .matches(/^(?=.{1,2}$)[a-z0-9]{2}/s, 'Enter a valid site number')
      .when('type', {
        is: LabelType.SAMPLE,
        then: yup.string().required('Enter a site number'),
      }),
    prefix: yup
      .string()
      .matches(/^(?=.{1,3}$)[A-Z]{3}/s, 'Enter a valid prefix')
      .when('type', (type, schema) => {
        if (type !== LabelType.SAMPLE) {
          return schema.required('Enter a prefix');
        }
        return schema;
      }),
    count: yup
      .number()
      .typeError('Enter a number')
      .max(500, 'Total count must be less than or equal to 500')
      .min(1, 'Total count must be greater than or equal to 1')
      .required('Enter a total count'),
  }), []);

  const onSubmit = (params: CreateLabelsParams) => {
    setSubmitting(true);
    setSubmitError('');

    createLabels(params)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || 'Server error. Please try again.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    type: LabelType.SAMPLE,
    countryCode: '',
    site: '',
    prefix: '',
    count: '',
  };

  const formik = useFormik<CreateLabelsParams>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return { formik, isSubmitting, submitError };
};

export default useCreateLabels;
