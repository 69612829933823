import { midnight, peach } from 'common/constants/colors';

import CookieConsent from 'react-cookie-consent';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BbofaCookieConsent: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <CookieConsent
      style={{ background: midnight, justifyContent: 'center', padding: '28px 0' }}
      contentStyle={{ flex: '' }}
      buttonStyle={{ backgroundColor: peach, borderRadius: '6px' }}
      buttonText={t('general.button_cookie_consent')}
      customButtonProps={{ variant: 'contained', color: 'primary' }}
      ButtonComponent={Button}
    >
      {t('general.cookie_consent')}
    </CookieConsent>
  );
};

export default BbofaCookieConsent;
