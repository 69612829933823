import React, { ChangeEvent, KeyboardEvent } from 'react';
import { TextField } from '@mui/material';

interface Props {
  id: string;
  name: string;
  label: string;
  value: string;
  error?: string;
  type?: string;
  helpText?: string;
  disabled: boolean;
  touched?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  variant?: 'outlined' | 'standard' | 'filled';
  placeholder?: string;
}

const FormTextField = ({ id, type, disabled, name, label, value, error, onChange, onKeyDown, touched, helpText, placeholder, variant = 'outlined' }: Props) => {
  return (
    <TextField
      id={id}
      name={name}
      label={label}
      type={type || 'text'}
      size="small"
      inputProps={{ 'data-testid': `${name}-input` }}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      error={touched && Boolean(error)}
      helperText={helpText || (touched && error)}
      disabled={disabled}
      variant={variant}
      placeholder={placeholder}
      fullWidth
    />
  );
};

export default FormTextField;
