import { axiosInstance } from 'api/instance';
import { CreateSampleParams, Sample, CreateLabelsParams, Bag, Plate } from 'api/Samples/types';
import PaginatedData from 'common/types/PaginatedData';

import { AxiosResponse } from 'axios';

export const createSampleRequest = (params: CreateSampleParams): Promise<AxiosResponse<Sample>> => {
  return axiosInstance.post<Sample>('samples/sample', { ...params });
};

export const removeSampleRequest = (id: number): Promise<AxiosResponse> => {
  return axiosInstance.delete(`samples/sample/${id}`);
};

export const sampleSearchRequest = (fileData: FormData): Promise<AxiosResponse> => {
  return axiosInstance.post(
    'samples/sample-search',
    fileData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const userSearchRequest = (fileData: FormData): Promise<AxiosResponse> => {
  return axiosInstance.post(
    'samples/user-search',
    fileData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const findUserRequest = (sampleId: string): Promise<AxiosResponse<number>> => {
  return axiosInstance.get<number>(`samples/find-user/${sampleId}`);
};

export const createLabels = (params: CreateLabelsParams) => {
  return axiosInstance.post('samples/generate-ids', { ...params }, {
    responseType: 'arraybuffer',
  });
};

export const getSamplesRequest = (page: number, pageSize: number): Promise<AxiosResponse<PaginatedData<Sample>>> => {
  return axiosInstance.post<PaginatedData<Sample>>(`samples/samples?page=${page}&pageSize=${pageSize}`);
};

export const getBagsRequest = (page: number, pageSize: number): Promise<AxiosResponse<PaginatedData<Bag>>> => {
  return axiosInstance.post<PaginatedData<Bag>>(`samples/bags?page=${page}&pageSize=${pageSize}`);
};

export const getPlatesRequest = (page: number, pageSize: number): Promise<AxiosResponse<PaginatedData<Plate>>> => {
  return axiosInstance.post<PaginatedData<Plate>>(`samples/plates?page=${page}&pageSize=${pageSize}`);
};
