import DashboardContainer from 'containers/DashboardContainer';
import AdminDashboardContainer from 'containers/AdminDashboardContainer';
import Landing from 'components/Landing/Landing';
import i18nextOptions from 'i18nextOptions';
import { RootState } from 'store';
import BbofaCookieConsent from 'components/BbofaCookieConsent/BbofaCookieConsent';
import { useSaveUtmParams } from 'common/hooks/useSaveUtmParams';

import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import amplitude from 'amplitude-js';
import { Amplitude, AmplitudeProvider } from 'react-amplitude-hooks';
import { useSelector } from 'react-redux';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(i18nextOptions);

const App = () => {
  const { myUser } = useSelector((store: RootState) => store.user);
  const saveUtmParams = useSaveUtmParams();
  const amplitudeUserId = myUser.id ? `userid_${myUser.id}` : undefined;

  useEffect(() => {
    saveUtmParams();
  }, [ saveUtmParams ]);

  return (
    <AmplitudeProvider
      amplitudeInstance={amplitude.getInstance()}
      apiKey={String(process.env.REACT_APP_AMPLITUDE_KEY || '')}
      userId={amplitudeUserId}
      config={{ includeUtm: true, includeReferrer: true }}
    >
      <Amplitude userProperties={myUser.username ? { email: myUser.username, organization: myUser.organization?.name } : undefined}>
        <Routes>
          <Route path="/dashboard/*" element={<DashboardContainer />} />
          <Route path="/admin-dashboard/*" element={<AdminDashboardContainer />} />
          <Route path="/*" element={<Landing />} />
        </Routes>
        <BbofaCookieConsent />
      </Amplitude>
    </AmplitudeProvider>
  );
};

export default App;
