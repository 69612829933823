import { isDataScienceAdmin } from 'common/helpers/user';
import { SamplesTab } from 'containers/types/tabs';
import QuerySearchPopover from 'components/AdminDashboard/components/SamplesPage/components/SamplesControlPanel/components/SearchPopover/SearchPopover';
import CreateLabelsPopover from 'components/AdminDashboard/components/SamplesPage/components/SamplesControlPanel/components/CreateLabelsPopover/CreateLabelsPopover';
import SampleSearchInput from 'components/AdminDashboard/components/SamplesPage/components/SamplesControlPanel/components/SampleSearchInput/SampleSearchInput';
import { ControlPanelButton } from 'components/AdminDashboard/components/SamplesPage/components/SamplesControlPanel/SamplesControlPanelStyle';
import { RootState } from 'store';

import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

interface Props {
  currentTab: SamplesTab;
}

const SamplesControlPanel: React.FC<Props> = ({ currentTab }) => {
  const { myUser } = useSelector((store: RootState) => store.user);

  const [ querySearchPopoverEl, setQuerySearchPopoverEl ] = useState<HTMLButtonElement | null>(null);
  const onSearchClick = (event: React.MouseEvent<HTMLButtonElement>) => setQuerySearchPopoverEl(event.currentTarget);
  const onQuerySearchPopoverClose = () => setQuerySearchPopoverEl(null);

  const [ createLabelsPopoverEl, setCreateLabelsPopoverEl ] = useState<HTMLButtonElement | null>(null);
  const onCreateLabelsClick = (event: React.MouseEvent<HTMLButtonElement>) => setCreateLabelsPopoverEl(event.currentTarget);
  const onCreateLabelsPopoverClose = () => setCreateLabelsPopoverEl(null);

  const canSearchSample = currentTab === SamplesTab.USERS_SAMPLES;
  const canQuerySearch = canSearchSample || currentTab === SamplesTab.SAMPLES;
  const alignment = canSearchSample ? 'space-between' : 'flex-end';

  return (
    <Box mb={2} display="flex" justifyContent={alignment}>
      {canSearchSample && <SampleSearchInput />}
      <Box>
        {canQuerySearch && (
          <ControlPanelButton variant="text" onClick={onSearchClick}>
            Search
          </ControlPanelButton>
        )}
        {!isDataScienceAdmin(myUser) && (
          <ControlPanelButton variant="text" onClick={onCreateLabelsClick}>
            Create labels
          </ControlPanelButton>
        )}
      </Box>
      <QuerySearchPopover anchorEl={querySearchPopoverEl} onClose={onQuerySearchPopoverClose} />
      {createLabelsPopoverEl && (
        <CreateLabelsPopover anchorEl={createLabelsPopoverEl} onClose={onCreateLabelsPopoverClose} />
      )}
    </Box>
  );
};

export default SamplesControlPanel;
