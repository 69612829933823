import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { getSpecifiedRaces } from 'components/Dashboard/components/SurveyPage/helpers';
import { SpecifiedRace } from 'components/Dashboard/components/SurveyPage/components/V1/OriginQuestion/types';
import { TranslationsKey } from 'i18nextOptions';
import { SurveyV1 } from 'api/User/types';

import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import { useTranslation } from 'react-i18next';

const OriginQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);
  const { submit, isSubmitting, submitError } = useQuestionForm();
  const [ values, setValues ] = useState<{ [key: string]: string }>({});
  const [ fieldErrors, setFieldErrors ] = useState<{ [key: string]: string }>({});

  const [ specRaces, setSpecRaces ] = useState<SpecifiedRace[]>([]);

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    const checkEmptyField = (result: { [key: string]: string }, specRace: SpecifiedRace) => {
      if (!values[specRace.label]) {
        return { ...result, [specRace.label]: t('dashboard.survey.error_give_answer') };
      }
      return result;
    };

    const emptyErrors = specRaces.reduce(checkEmptyField, {});
    setFieldErrors(emptyErrors);

    if (isEmpty(emptyErrors)) {
      submit({
        ...survey,
        origin: { ...values },
      })
        .then(data => onNext(data));
    }
  }, [ t, onNext, specRaces, submit, survey, values ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fieldErrors[event.target.id]) {
      setFieldErrors(prevState => omit(prevState, event.target.id));
    }
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  useEffect(() => {
    if (survey) {
      const surveyV1 = survey as SurveyV1;
      const specRaces = getSpecifiedRaces(surveyV1.race);
      setSpecRaces(specRaces);

      const checkInitValues = (acc: { [key: string]: string }, specRace: SpecifiedRace) => {
        if (surveyV1?.origin && surveyV1?.origin[specRace.label]) {
          return { ...acc, [specRace.label]: surveyV1.origin[specRace.label] };
        }
        return { ...acc, [specRace.label]: '' };
      };

      setValues(specRaces.reduce(checkInitValues, {}));
    }
  }, [ survey ]);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-origin">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_origin.question')}
          </Typography>
        </Grid>
        {specRaces.map(specRace => (
          <Grid item container spacing={3} key={specRace.label}>
            <Grid item container xs={12} spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="body1" color="primary" fontWeight="600">
                  {t(`dashboard.survey.question_origin.labels.${specRace.label}` as TranslationsKey)}
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  size="small"
                  color="primary"
                  value={values[specRace.label]}
                  onChange={handleChange}
                  error={Boolean(fieldErrors[specRace.label])}
                  inputProps={{ id: specRace.label, 'data-testid': `input-${specRace.label}` }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="primary" sx={{ maxWidth: '615px' }}>
                {t(`dashboard.survey.question_origin.hints.${specRace.label}` as TranslationsKey)}
              </Typography>
            </Grid>
            {fieldErrors[specRace.label] && (
              <Grid item xs={12}>
                <Typography color="error" data-testid={`label-error-${specRace.label}`}>
                  {fieldErrors[specRace.label]}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))}
        {submitError && (
          <Grid item xs={12}>
            <Typography color="error">
              {submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default OriginQuestion;
