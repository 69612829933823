import HealthInfoQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HealthInfoQuestion/HealthInfoQuestion';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';

import React from 'react';

const DISEASES = [
  'Lung or broncheus',
  'Breast',
  'Ovarian',
  'Prostate',
  'Colorectal',
  'Melanoma (skin)',
  'Pancreas',
  'Brain',
  'Stomach',
  'Other',
];

const CancerQuestion: React.FC<QuestionPageProps> = ({ onNext, onPrev, onSubmit }) => {
  return <HealthInfoQuestion onPrev={onPrev} onNext={onNext} onSubmit={onSubmit} field="cancer" diseases={DISEASES} />;
};

export default CancerQuestion;
