export const white = '#FFF';

export const midnight50 = '#CCCCD3';
export const midnight = '#000124';
export const midnight1000 = '#000007';

export const peach50 = '#FDF0EB';
export const peach = '#F3B29D';
export const peach400 = '#F7CCBE';
export const peach500 = '#CA9483';
export const peach700 = '#A27769';

export const purple50 = '#F2EFFF';
export const purple100 = 'rgba(62, 53, 92, 0.5)';
export const purple200 = '#978BBF';
export const purple300 = '#7E7892';
export const purple400 = '#7A709B';
export const purple500 = '#342C4D';
export const purple600 = '#3E355C';
export const purple650 = '#29233D';
export const purple700 = '#2B2B49';
export const purple800 = '#1C1E4D';
export const purple900 = '#020024';

export const red50 = '#FFECEC';
export const red300 = '#FFD7D7';
export const red = '#E00000';
export const red600 = '#A20000';
export const red900 = '#630000';

export const rust300 = '#EBBCAC';
export const rust = '#D7795A';
export const rust600 = '#B3654B';
export const rust900 = '#6C3D2D';

export const ivory = '#F3ECE2';
export const hopBush = '#CC79A7';
export const magenta = '#DA2D6B';

export const tenn = '#D55E00';
export const orangePeel = '#E69F00';
export const starship = '#F0E442';
export const yellow = '#F6CF6D';
export const aquamarine = '#6FBBBD';

export const grey200 = '#F5F5F5';
export const grey300 = '#EDEDED';
export const grey400 = '#E0E0E0';
export const grey500 = '#C2C2C2';
export const grey600 = '#9E9E9E';
export const grey700 = '#757575';
export const grey800 = '#616161';
export const grey900 = '#424242';
export const grey1000 = '#0A0A0A';

export const greenHaze = '#009E73';
export const limeGreen = '#2E7D32';

export const pictonBlue = '#56B4E9';
export const deepCerulean = '#0072B2';
export const darkBlue = '#035C9B';
