import { peach } from 'common/constants/colors';

import { Box, Typography } from '@mui/material';
import React from 'react';
import { DateTime } from 'luxon';

interface Props {
  title: string;
  date: string;
  preview: string;
}

const NewsCard: React.FC<Props> = ({ title, date, preview }) => {
  return (
    <Box
      width="100%"
      height="500px"
      maxWidth="100%"
      position="relative"
      borderRadius="30px"
      overflow="hidden"
      display="flex"
      flexDirection="column"
      sx={{ cursor: 'pointer' }}
    >
      <Box height="calc(100% - 164px)">
        <img src={preview} alt="" height="100%" width="100%" style={{ objectFit: 'cover' }} />
      </Box>
      <Box
        position="absolute"
        width="100%"
        bottom="0"
        sx={{
          backgroundColor: peach,
          height: '164px',
        }}
      >
        <Box padding={{ xs: '12px 32px', sm: '18px 32px' }}>
          <Typography
            color="purple.main"
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: { xs: '20px', md: '22px' },
              lineHeight: '125%',
              letterSpacing: '-0.05em',
              maxHeight: '90px',
            }}
          >
            {title}
          </Typography>
          <Typography
            position="absolute"
            color="purple.main"
            sx={{
              fontFamily: 'Satoshi',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '16px',
              lineHeight: '175%',
              bottom: '15px',
            }}
          >
            {DateTime.fromISO(date).toFormat('MMM dd yyyy')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsCard;
