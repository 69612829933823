import { Box, Button, Hidden, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';

interface Props {
  data: {
    title: string,
    description: string,
    buttonLabel?: string,
    buttonAction?: () => void;
    renderImg: () => ReactElement<any, any>;
  }
}

const Card: React.FC<Props> = ({ data: { title, description, buttonLabel, buttonAction, renderImg } }) => {
  const theme = useTheme();
  return (
    <Box
      borderRadius={10}
      overflow="hidden"
      position="relative"
      sx={{
        backgroundColor: theme.palette.orange.main,
        padding: { lg: 8, xs: 4 },
        width: { xs: '100%', lg: '750px', xl: '837px' },
        minHeight: { xs: '300px', md: '471px' },
      }}
    >
      <Box
        display="flex"
        position="relative"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        height="100%"
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: 'Montserrat Alternates',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: { xs: '24px', lg: '32px' },
            lineHeight: '150%',
            letterSpacing: '-0.05rem',
            maxWidth: { xs: '100%', lg: '424px' },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Satoshi',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '150%',
            marginTop: { xs: '8px', lg: '24px' },
            maxWidth: { xs: '100%', lg: '450px', xl: '580px' },
            whiteSpace: 'pre-line',
          }}
        >
          {description}
        </Typography>
        {buttonLabel && (
          <Button
            color="special"
            variant="outlined"
            size="large"
            sx={{ width: { xs: '100%', lg: '320px' }, marginTop: { xs: '32px', lg: '74px' } }}
            onClick={buttonAction}
          >
            {buttonLabel}
          </Button>
        )}
      </Box>
      {renderImg && (
        <Hidden lgDown>
          {renderImg()}
        </Hidden>
      )}
    </Box>
  );
};

export default Card;
