import { AuthorizationContainer, FormTextField, Label } from 'common/components';
import useAuthForm from 'components/Dashboard/components/Authorization/hooks/useAuthForm';
import styles from 'components/Dashboard/components/Authorization/SignInPage/components/AuthForm/AuthForm.module.css';

import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';

interface Props {
  disableRegistration?: boolean
}

const AuthForm: React.FC<Props> = ({ disableRegistration }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { formik, isLoading, error, setError } = useAuthForm();
  const [ timeLeft, setTimeLeft ] = useState(0); // seconds
  const resetPasswordRootPath = disableRegistration ? '/admin-dashboard/' : '/dashboard/';
  
  const toggleSignUp = () => navigate('/dashboard/signup');
  const toggleResetPassword = () => navigate(`${resetPasswordRootPath}reset-password`);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    if (timeLeft <= 0) {
      clearInterval(timer);
      setError('');
    }

    return () => {
      clearInterval(timer);
    };
  }, [ timeLeft, setError ]);

  useEffect(() => {
    if (error && error.startsWith('Too many login attempts')) {
      const value = error.match(/\d+/g);
      if (value) {
        setTimeLeft(Number(value.join('')));
      }
    }
  }, [ error ]);
  
  return (
    <AuthorizationContainer data-testid="auth-form">
      
      <Label>{t('auth.signin')}</Label>
      
      <div className={styles.container}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            
            <Box marginBottom={3}>
              <FormTextField
                id="email"
                name="email"
                label={t('general.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
                touched={formik.touched.email}
                disabled={isLoading}
              />
            </Box>
            
            <Box marginTop={1} marginBottom={3}>
              <FormTextField
                id="password"
                name="password"
                label={t('auth.password')}
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.errors.password}
                touched={formik.touched.password}
                disabled={isLoading}
              />
            </Box>
            
            {error && timeLeft <= 0 && (
              <Box marginTop={1} marginBottom={3}>
                <Typography color="error" variant="subtitle2">
                  {error}
                </Typography>
              </Box>
            )}
            
            {timeLeft > 0 && (
              <Box marginTop={1} marginBottom={3}>
                <Typography color="error" variant="subtitle2">
                  {`Too many attempts. Please try after ${timeLeft} seconds`}
                </Typography>
              </Box>
            )}
            
            <Stack direction={{ xs: 'column', sm: 'row' }} marginTop={{ xs: 6, sm: 4 }} display="flex" justifyContent="space-between" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                className={styles.signInButton}
                data-testid="submit-login-button"
                disabled={isLoading}
              >
                {t('auth.signin')}
              </Button>
              <span className={styles.resetPassword} onClick={toggleResetPassword}>{t('auth.question_dont_remember_password')}</span>
            </Stack>
            
            {!disableRegistration && (
              <Box className={styles.signUpContainer}>
                <span>{t('auth.question_dont_have_account')}</span>
                <span onClick={toggleSignUp} className={styles.signUpButton}>{t('auth.registration')}</span>
              </Box>
            )}
            
          </form>
        </FormikProvider>
      </div>
      
    </AuthorizationContainer>
  );
};

export default AuthForm;
