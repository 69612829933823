import { SET_MY_USER } from 'store/user/types';
import { updateMySurveyRequest } from 'api/User/api';
import { Survey } from 'api/User/types';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useQuestionForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submit = (data: Partial<Survey>) => {
    setSubmitting(true);
    setSubmitError('');

    return updateMySurveyRequest(data)
      .then(({ data }) => {
        dispatch({ type: SET_MY_USER, user: data });
        return data.survey!;
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
        return Promise.reject();
      })
      .finally(() => setSubmitting(false));
  };

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  return { submit, isSubmitting, submitError };
};

export default useQuestionForm;
