import { REGISTRATION_UTM_PARAMS } from 'common/constants/UTMParams';

import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export const useSaveUtmParams = () => {
  const [ searchParams ] = useSearchParams();

  return useCallback(() => {
    REGISTRATION_UTM_PARAMS.forEach((param) => {
      if (searchParams.has(param) && !localStorage.getItem(param)) {
        localStorage.setItem(param, searchParams.get(param)!);
      }
    });
  }, [ searchParams ]);
};
