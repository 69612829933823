import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import useGetUser from 'components/AdminDashboard/components/UserEditPage/hooks';
import UserEditForm from 'components/AdminDashboard/components/UserEditPage/components/UserEditForm/UserEditForm';
import { UserPageProps } from 'components/AdminDashboard/components/UsersPage/types';

import React, { useEffect } from 'react';
import { Box, Button, Grid, Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';

const UserEditPage: React.FC<UserPageProps> = ({ isClinicalPatientPage }) => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const { getUser, user, isLoading } = useGetUser();

  useEffect(() => {
    if (userId) {
      getUser(userId);
    }
  }, [ getUser, userId ]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <Paper sx={{ maxWidth: '100%' }} data-testid="user-metadata-page">
        <Box
          sx={{ backgroundColor: '#fff' }}
          display="flex"
          flexDirection="column"
          borderRadius={4}
          padding="48px 64px 100px"
        >
          <Grid item xs={6} marginBottom={6}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<ArrowBackIcon />}
              sx={{ marginRight: -20 }}
              onClick={() => navigate(-1)}
            >
              Back to admin dashboard
            </Button>
          </Grid>
          {user && <UserEditForm user={user} isClinicalPatientPage={isClinicalPatientPage} />}
        </Box>
      </Paper>
    </LoadingContainer>
  );
};

export default UserEditPage;
