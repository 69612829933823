import { RootState } from 'store';

import React from 'react';
import { Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

interface Props {
  onCancel: () => void;
}

const CreateLabelsModal: React.FC<Props> = ({ onCancel }) => {
  const { myUser: { username } } = useSelector((store: RootState) => store.user);

  return (
    <Modal open onClose={onCancel}>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '600px' },
        }}
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="textXLBold">
                Create labels
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                We will send a file with generated labels by email to <b>{username}</b>
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item>
                <Button variant="outlined" onClick={onCancel}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};

export default CreateLabelsModal;
