import { ShippingStatus } from 'api/Shipment/types';

export enum ShippingDirection {
  RETURN = 'return',
  DIRECT = 'direct',
  NOT_STARTED = 'notStarted',
}

export interface CurrentShipping {
  direction: ShippingDirection;
  status: ShippingStatus | null;
}
