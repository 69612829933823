import { LOGIN_SUCCESS } from 'store/auth/types';
import { setPasswordRequest } from 'api/User/api';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

interface SetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

const useSetPassword = (forgotPasswordKey: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validationSchema = yup.object({
    password: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g,
        t('registration.error_password_strength'),
      )
      .required(t('registration.hint_password')),
    confirmPassword: yup
      .string()
      .oneOf([ yup.ref('password'), null ], t('registration.error_password_doesnt_match')),
  });

  const formikSubmit = (values: SetPasswordFormValues) => {
    setSubmitting(true);

    setPasswordRequest(values.password, forgotPasswordKey)
      .then(({ data }) => {
        dispatch({ type: LOGIN_SUCCESS, token: data.token, refreshToken: data.refreshToken });
        navigate('/dashboard');
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik<SetPasswordFormValues>({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema,
    onSubmit: formikSubmit,
  });

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  return { formik, isSubmitting, submitError };
};

export default useSetPassword;
