import { Grid } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

interface Props {
  submitting: boolean;
}

const SubmitButtons: React.FC<Props> = ({ submitting }) => {
  const { t } = useTranslation();
  
  return (
    <Grid item container justifyContent="space-between">
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        loading={submitting}
        sx={{ width: { xs: '100%', md: 'auto' } }}
        data-testid="button-submit-question"
      >
        {t('dashboard.survey.button_submit_and_go_next')}
      </LoadingButton>
    </Grid>
  );
};

export default SubmitButtons;
