import { RootState } from 'store';

import React from 'react';
import { Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

interface Props {
  open: boolean;
  onClose: () => void;
}

const BatchUploadSummary: React.FC<Props> = ({ open, onClose }) => {
  const { myUser: { username } } = useSelector((store: RootState) => store.user);

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '600px' },
        }}
        data-testid="modal-batch-upload-summary"
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="textXLBold">
                Batch upload summary
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                We will send a file with uploaded users by email to <b>{username}</b>
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item>
                <Button variant="outlined" onClick={onClose}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};

export default BatchUploadSummary;
