import { changeOrganization as updateOrganization } from 'api/User/api';
import { UPDATE_USER_ORGANIZATION } from 'store/users/types';

import { useDispatch } from 'react-redux';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useChangeOrganization = (onSuccessfulSubmit: () => void) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  const changeOrganization = useCallback((userId: number, organizationId: number, organizationName: string) => {
    setSubmitting(true);
    setSubmitError('');

    updateOrganization(userId, organizationId)
      .then(() => {
        dispatch({ type: UPDATE_USER_ORGANIZATION, data: { userId, organizationId, organizationName } });
        onSuccessfulSubmit();
      })
      .catch(error => setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again')))
      .finally(() => setSubmitting(false));
  }, [ t, dispatch, onSuccessfulSubmit ]);

  return {
    changeOrganization,
    isSubmitting,
    submitError,
  };
};

export default useChangeOrganization;
