import { useNewsList } from 'common/hooks/useNewsList';
import { ADMIN_DASHBOARD_NEWS_ROUTE } from 'common/constants/urls';
import { getResourceUrl } from 'common/utils/getResourceUrl';
import { deleteNews } from 'api/News/api';

import React, { useEffect, MouseEvent } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const NewsListPage = () => {
  const navigate = useNavigate();
  
  const {
    requestNews,
    newsList,
    setNewsList,
    isLoading,
  } = useNewsList();

  useEffect(() => {
    requestNews();
  }, [ requestNews ]);

  const handleNewsClick = (newsId: string) => navigate(`${newsId}`);
  const handleDeleteNews = (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>, newsId: string) => {
    e.stopPropagation();
    deleteNews(newsId)
      .then(() => setNewsList(newsList.filter(({ id }) => newsId !== id)));
  };

  return (
    <Paper sx={{ maxWidth: '100%' }} data-testid="news-list-page">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="60px">
                <Typography>
                  ID
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  Preview
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  Title
                </Typography>
              </TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell component="th" colSpan={4}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {newsList.map(news => (
                  <TableRow hover key={news.id} sx={{ cursor: 'pointer' }} onClick={() => handleNewsClick(news.id)}>
                    <TableCell>
                      {news.id}
                    </TableCell>
                    <TableCell>
                      <Box height="64px">
                        <img src={getResourceUrl(news.title.imageUrl)} height="100%" alt="" />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {news.title.title}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit">
                        <IconButton color="primary">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={e => handleDeleteNews(e, news.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell component="th" colSpan={4}>
                    <Button variant="contained" onClick={() => navigate(`${ADMIN_DASHBOARD_NEWS_ROUTE}/add`)}>
                      Add news
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default NewsListPage;
