import { WithKit } from 'api/UserKits/types';
import { DELIVERY_TYPE_FROM_USER, DELIVERY_TYPE_TO_USER_AND_RETURN } from 'api/Shipment/constants';
import { recreateTransaction } from 'api/Shipment/api';
import { Transactions } from 'api/Shipment/types';

import { useCallback, useEffect, useState } from 'react';

export const useRenewShippingLabels = (onUpdated: (userId: number, transactions: Transactions) => void) => {
  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  useEffect(() => {
    return () => {
      setError('');
      setLoading(false);
    };
  }, []);

  const renewLabels = useCallback((userWithKit: WithKit) => {
    setError('');
    setLoading(true);

    const { user: { id }, transactions: { returnTransaction, directTransaction } } = userWithKit;
    const deliveryType = returnTransaction && directTransaction ? DELIVERY_TYPE_TO_USER_AND_RETURN : DELIVERY_TYPE_FROM_USER;

    recreateTransaction(id, deliveryType)
      .then(({ data }) => {
        onUpdated(id, data);
      })
      .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
      .finally(() => setLoading(false));
  }, [ onUpdated ]);

  return {
    renewLabels,
    isLoading,
    error,
  };
};
