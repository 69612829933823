import { Ancestry } from 'api/Reports/types';
import { AncestryFile } from 'api/User/types';
import FileInput from 'common/components/FileInput/FileInput';
import UploadReportModal from 'components/AdminDashboard/components/ReportsPage/components/UploadReportModal/UploadReportModal';

import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';

export interface Data {
  anchorEl: Element;
  userId: number;
  files: AncestryFile[];
}

interface Props {
  onClose: () => void;
  data: Data
  onUpdate: (userId: number, ancestries: Ancestry[]) => void
}

const UploadReportPopover: React.FC<Props> = ({ data, onClose, onUpdate }) => {
  const [ showUploadReportModal, setShowUploadReportModal ] = useState<boolean>(false);
  const openUploadReportModal = () => setShowUploadReportModal(true);
  const closeUploadReportModal = () => {
    setShowUploadReportModal(false);
    onClose();
  };

  return (
    <>
      <Menu
        open={Boolean(data)}
        anchorEl={data?.anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={openUploadReportModal}>
          Upload ancestry report
        </MenuItem>
        <FileInput
          loading={false}
          label="Upload PRS report"
          onChange={() => {}}
          data-testid="upload"
          accept=".csv, .tsv"
          ButtonComponent={MenuItem}
          ButtonProps={{
            disabled: true,
          }}
        />
      </Menu>
      {showUploadReportModal && (<UploadReportModal userId={data.userId} files={data.files} onCancel={closeUploadReportModal} onUpdate={onUpdate} />)}
    </>
  );
};

export default UploadReportPopover;
