import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const SampleMaskedInput = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  const mask = 'aa-**-********************************';

  return (
    <IMaskInput
      {...other}
      mask={mask}
      placeholder={mask}
      inputRef={ref as any}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
      data-testid={props.name}
    />
  );
});

export default SampleMaskedInput;
