import { forgotPasswordRequest } from 'api/User/api';

import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useResetPassword = (onSuccess: () => void) => {
  const { t } = useTranslation();

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();

  const resetPassword = useCallback((email: string) => {
    setIsLoading(true);
    setError('');

    forgotPasswordRequest(email)
      .then(() => onSuccess())
      .catch(error => setError(error?.response?.data?.message || t('errors.server_error_try_again')))
      .finally(() => setIsLoading(false));
  }, [ t, onSuccess ]);

  return {
    resetPassword,
    isLoading,
    error,
  };
};
