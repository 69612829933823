import styles from 'components/Dashboard/components/ProfilePage/ProfilePage.module.css';
import { COUNTRIES_NAMES, STATES, USA } from 'common/constants/countries';
import useAddressEdit from 'components/Dashboard/components/ShippingPage/components/TrackingPage/AddressEditForm/hooks';

import React from 'react';
import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import { FormikProvider } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
}

const AddressEditForm: React.FC<Props> = ({ onClose }) => {
  const { formik, isSubmitting, submitError } = useAddressEdit(onClose);
  const { t } = useTranslation();

  const onStateChangeHandler = React.useCallback((_, newValue: string | null) => {
    formik.setFieldValue('state', newValue ?? '');
  }, [ formik ]);
  
  const onCountryChangeHandler = React.useCallback((_, newValue: string | null) => {
    formik.setFieldValue('country', newValue ?? '');
  }, [ formik ]);

  return (
    <Grid xs={12} item marginTop={2} data-testid="address-edit-form">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <Grid item container rowSpacing={3} spacing={2} xs={12} sm={6} paddingRight={{ sm: 2 }}>
            <Grid item xs={12}>
              <Autocomplete
                onChange={onCountryChangeHandler}
                value={formik.values.country}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('dashboard.profile.country')}
                    name="country"
                    disabled={isSubmitting}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    
                  />
                )}
                options={COUNTRIES_NAMES}
              />
            </Grid>
            {formik.values.country === USA && (
              <Grid item sm={12}>
                <Autocomplete
                  onChange={onStateChangeHandler}
                  value={formik.values.state}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('dashboard.profile.address.state')}
                      name="state"
                      disabled={isSubmitting}
                      error={formik.touched.state && Boolean(formik.errors.state)}
                      helperText={formik.touched.state && formik.errors.state}
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  options={STATES}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                id="city"
                name="city"
                label={t('dashboard.profile.address.city')}
                variant="outlined"
                size="small"
                inputProps={{
                  autoComplete: 'none',
                }}
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                disabled={isSubmitting}
                fullWidth
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="street1"
                name="street1"
                label={t('dashboard.profile.address.street_address')}
                variant="outlined"
                size="small"
                rows={5}
                value={formik.values.street1}
                onChange={formik.handleChange}
                error={formik.touched.street1 && Boolean(formik.errors.street1)}
                helperText={formik.touched.street1 && formik.errors.street1}
                disabled={isSubmitting}
                fullWidth
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="street2"
                name="street2"
                label={t('dashboard.profile.address.street_address_line2')}
                variant="outlined"
                size="small"
                rows={5}
                value={formik.values.street2}
                onChange={formik.handleChange}
                error={formik.touched.street2 && Boolean(formik.errors.street2)}
                helperText={formik.touched.street2 && formik.errors.street2}
                disabled={isSubmitting}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="zip"
                name="zip"
                label={t('dashboard.profile.address.zip')}
                variant="outlined"
                size="small"
                value={formik.values.zip}
                onChange={formik.handleChange}
                error={formik.touched.zip && Boolean(formik.errors.zip)}
                helperText={formik.touched.zip && formik.errors.zip}
                disabled={isSubmitting}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item paddingTop={{ xs: 5, sm: 0 }} alignItems="center" alignSelf={{ sm: 'center' }} sm={3} xs={12} justifyContent="center" display="flex" flexDirection="column">
            <Grid item xs={12} width="100%" display="flex">
              <LoadingButton
                sx={{ width: { xs: '100%' }, marginRight: '20px', marginLeft: { sm: '20px' } }}
                loading={isSubmitting}
                size="medium"
                variant="contained"
                type="submit"
                data-testid="button-save-address"
              >
                {t('dashboard.shipping.address_edit_form.button_save')}
              </LoadingButton>
              <Button
                sx={{ width: { xs: '100%' } }}
                onClick={onClose}
                size="medium"
                variant="outlined"
                data-testid="button-cancel-address"
              >
                {t('dashboard.shipping.address_edit_form.button_cancel')}
              </Button>
            </Grid>
            {
              submitError && (
                <Grid marginTop={1.5}>
                  <Typography color="error" variant="subtitle2">
                    {submitError}
                  </Typography>
                </Grid>
              )
            }
          </Grid>
        </form>
      </FormikProvider>
    </Grid>
  );
};

export default AddressEditForm;
