import { SPECIFIED_RACES } from 'components/Dashboard/components/SurveyPage/components/V1/OriginQuestion/constants';
import { Survey, SurveyV1 } from 'api/User/types';
import { GRANDPARENTS_LIST } from 'components/Dashboard/components/SurveyPage/components/Common/GrandparentsBornPlaceQuestion/GrandparentsBornPlaceQuestion';
import { SurveyQuestion } from 'components/Dashboard/components/SurveyPage/types';

export const getSpecifiedRaces = (races: string[] | null) => {
  if (!races) {
    return [];
  }

  return SPECIFIED_RACES.filter(specifiedRace => races.includes(specifiedRace.label));
};

export const getNextUnansweredQuestion = (current: number, survey: Survey | null, questions: SurveyQuestion<any>[]): number => {
  const question = questions[current];

  if (!question || survey === null) {
    return current;
  }

  const questionAvailable = !question.condition || question.condition(survey);

  if (!questionAvailable) {
    return getNextUnansweredQuestion(current + 1, survey, questions);
  }

  if (!question.filled(survey)) {
    return current;
  }

  return getNextUnansweredQuestion(current + 1, survey, questions);
};

export const getPrevAvailableQuestion = (surveyProgress: number, current: number, survey: Survey, questions: SurveyQuestion<any>[]): number => {
  if (current === 0) {
    return 0;
  }

  const prevQuestion = questions[current - 1];

  if (!prevQuestion.condition || prevQuestion.condition(survey)) {
    return current - 1;
  }

  return getPrevAvailableQuestion(surveyProgress, current - 1, survey, questions);
};

export const getNextAvailableQuestion = (
  surveyProgress: number,
  current: number,
  survey: Survey | null,
  questions: SurveyQuestion<any>[],
  looped: boolean = true,
): number => {
  const nextQuestion = questions[current + 1];

  if (!nextQuestion && surveyProgress !== 100 && looped) {
    return getNextAvailableQuestion(surveyProgress, -1, survey, questions);
  }

  if (!nextQuestion?.condition || nextQuestion.condition(survey)) {
    return current + 1;
  }

  return getNextAvailableQuestion(surveyProgress, current + 1, survey, questions, looped);
};

export const getGrandparentsBornPlaceFilled = (survey: Survey) => {
  return GRANDPARENTS_LIST.reduce((result, name) => {
    if (survey.grandparentsBornPlace) {
      const filled = survey.grandparentsBornPlace[name] && survey.grandparentsBornPlace[name].city && survey.grandparentsBornPlace[name].country;
      const skipped = survey.grandparentsBornPlace[name] && survey.grandparentsBornPlace[name].dontKnow;

      return result && (Boolean(filled) || Boolean(skipped));
    }

    return false;
  }, true as boolean);
};

export const getOriginFilled = (survey: SurveyV1) => {
  if (!survey.race) {
    return true;
  }

  const specRaces = getSpecifiedRaces(survey?.race);
  if (!specRaces.length) {
    return true;
  }

  return specRaces.reduce((result, specRace): boolean => {
    if (survey.origin) {
      return result && Boolean(survey.origin[specRace.label]);
    }

    return false;
  }, true);
};
