import { DashboardLocationState } from 'components/Dashboard/components/CardsPage/types';
import ProgressBar from 'components/Dashboard/components/ProgressBar/ProgressBar';
import Instructions from 'components/Dashboard/components/ShippingPage/components/Instructions/Instructions';
import TrackingPage from 'components/Dashboard/components/ShippingPage/components/TrackingPage/TrackingPage';
import { getDeliveryType } from 'components/Dashboard/components/ShippingPage/helpers';
import { useGetTestKitReceived } from 'components/Dashboard/components/ShippingPage/hooks';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import { MONTSERRAT_ALTERNATES } from 'common/constants/fonts';
import { midnight } from 'common/constants/colors';
import { DASHBOARD_ROUTE } from 'common/constants/urls';
import { getShippingProgress } from 'common/helpers/shipping';
import { getUserProfileProgress } from 'common/helpers/user';
import { USA } from 'common/constants/countries';
import { RootState } from 'store';

import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ShippingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { myUser } = useSelector((state: RootState) => state.user);
  const { testKitReceived, isLoading } = useGetTestKitReceived();
  const profileProgress = getUserProfileProgress(myUser);
  const isProfileCompleted = profileProgress === 100;
  const isSurveyCompleted = myUser.surveyCompletedPercentage === 100;
  const isUserCountryUSA = myUser.mailingAddress?.country === USA;

  useEffect(() => {
    if (!isProfileCompleted || !isSurveyCompleted || testKitReceived || !isUserCountryUSA) {
      const state: DashboardLocationState = { showShippingLock: true };
      navigate(DASHBOARD_ROUTE, { state });
    }
  });

  const { transactions, returnShippingStatus, directShippingStatus } = useSelector((store: RootState) => store.shipping);

  const [ deliveryType, setDeliveryType ] = useState(() => getDeliveryType(transactions));

  const shippingProgress = getShippingProgress(transactions, returnShippingStatus, directShippingStatus);

  const onChosen = (flow: number) => {
    setDeliveryType(flow);
  };

  useEffect(() => {
    setDeliveryType(getDeliveryType(transactions));
  }, [ transactions ]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <Box
        padding={{ xs: '40px 16px', sm: 4, xl: 5 }}
        borderRadius={{ xs: '20px', sm: '30px' }}
        maxWidth="1100px"
        sx={{ backgroundColor: '#fff' }}
        height="100%"
        data-testid="shipping-page"
      >
        <Grid container paddingBottom={{ xs: 5, sm: 6 }} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography sx={{
              fontFamily: MONTSERRAT_ALTERNATES,
              fontWeight: 700,
              fontSize: { xs: '18px', sm: '28px' },
              lineHeight: '170%',
              letterSpacing: '-0.05em',
              color: midnight,
            }}
            >
              {t('dashboard.shipping.card_title')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <ProgressBar horizontal={false} progress={shippingProgress} />
          </Grid>
        </Grid>
        {deliveryType === undefined ?
          <Instructions onChosen={onChosen} /> :
          <TrackingPage deliveryType={deliveryType} onTypeChange={onChosen} />}
      </Box>
    </LoadingContainer>
  );
};

export default ShippingPage;
