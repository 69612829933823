import { postConfirm } from 'api/Auth/api';
import { LOGIN_SUCCESS } from 'store/auth/types';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

const validationSchema = yup.object({
  code1: yup
    .string()
    .required(''),
  code2: yup
    .string()
    .required(''),
  code3: yup
    .string()
    .required(''),
  code4: yup
    .string()
    .required(''),
  code5: yup
    .string()
    .required(''),
  code6: yup
    .string()
    .required(''),
});

export interface EnterCodeFormValues {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
}

const useEnterCodeForm = (mfaSessionId: string) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState<string>();

  const formikSubmit = (values: EnterCodeFormValues) => {
    setIsLoading(true);
    const code: string = Object.values(values).reduce((result: string, current: string) => result + current, '');

    postConfirm(code, mfaSessionId)
      .then(({ data }) => {
        const { token, refreshToken } = data;
        dispatch({ type: LOGIN_SUCCESS, token, refreshToken });
      })
      .catch((error) => {
        setError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik<EnterCodeFormValues>({
    initialValues: { code1: '', code2: '', code3: '', code4: '', code5: '', code6: '' },
    validationSchema,
    onSubmit: formikSubmit,
  });

  return { formik, isLoading, error };
};

export default useEnterCodeForm;
