import HaveKitFlow from 'components/Dashboard/components/ShippingPage/components/TrackingPage/HaveKitFlow/HaveKitFlow';
import DontHaveKitFlow from 'components/Dashboard/components/ShippingPage/components/TrackingPage/DontHaveKitFlow/DontHaveKitFlow';
import { RootState } from 'store';

import React from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Props {
  deliveryType: number;
  onTypeChange: (type: number) => void;
}

const TrackingPage: React.FC<Props> = ({ deliveryType, onTypeChange }) => {
  const { t } = useTranslation();
  const { directTransaction } = useSelector((store: RootState) => store.shipping.transactions);
  const { returnShippingStatus } = useSelector((store: RootState) => store.shipping);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onTypeChange(newValue);
  };

  const showTabs = !directTransaction && !returnShippingStatus;

  return (
    <Grid container spacing={4}>
      <Grid item container justifyContent="center">
        {showTabs && (
          <Tabs
            value={deliveryType}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="secondary"
          >
            <Tab color="orange" label={t('dashboard.shipping.button_have_kit')} value={2} />
            <Tab color="orange" label={t('dashboard.shipping.button_dont_have_kit')} value={3} />
          </Tabs>
        )}
      </Grid>
      <Grid item container>
        {deliveryType === 2 ?
          <HaveKitFlow /> :
          <DontHaveKitFlow />}
      </Grid>
    </Grid>
  );
};

export default TrackingPage;
