import React from 'react';

import styles from './Label.module.css';

interface Props {
  children: React.ReactNode;
}

const Label = (props: Props) => {
  return <span className={styles.label}>{props.children}</span>;
};

export default Label;
