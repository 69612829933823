import useAuth from 'common/hooks/useAuth';
import { getMyUser } from 'store/user/actions';
import { RootState } from 'store';
import Dashboard from 'components/Dashboard/Dashboard';
import AuthorizationPage from 'components/Dashboard/components/Authorization/AuthorizationPage';
import LogoIcon from 'common/icons/LogoIcon';
import { logout } from 'store/auth/actions';
import { DASHBOARD_ROUTE, SETTINGS_ROUTE } from 'common/constants/urls';
import { purple600, ivory } from 'common/constants/colors';
import { SATOSHI } from 'common/constants/fonts';
import ConsentPage from 'components/Dashboard/components/Authorization/SignUpPage/components/SignUp/components/ConsentPage';
import AssentPage from 'components/Dashboard/components/Authorization/SignUpPage/components/SignUp/components/AssentPage';
import { ageFromGivenDate } from 'common/helpers/dateTime';

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Grid, Popover, Stack, Typography, useMediaQuery, useTheme, Select, MenuItem, OutlinedInput, SelectChangeEvent }
  from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Language from '@mui/icons-material/Language';

import backgroundImage from './resources/background.svg';

const DashboardContainer = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { myUser } = useSelector((store: RootState) => store.user);
  const dispatch = useDispatch();

  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { t, i18n } = useTranslation();
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // if dob is not set then we can suppose that a user is over 18 years old
  const ageWhenSignedUp = myUser.dob ? ageFromGivenDate(myUser.dob) - ageFromGivenDate(myUser.createdAt) : 18;
  const isBetween13And18 = ageWhenSignedUp >= 13 && ageWhenSignedUp < 18;

  const [ language, setLanguage ] = useState(i18n.language);

  const handleLanguageChange = useCallback((event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  }, [ i18n ]);

  const onSettingsClick = React.useCallback(() => {
    navigate(SETTINGS_ROUTE);
    setAnchorEl(null);
  }, [ navigate ]);

  const onLogoutClick = React.useCallback(() => {
    dispatch(logout());
    setAnchorEl(null);
  }, [ dispatch ]);

  const renderMobileLanguageSwitcher = () => {
    const isEnglish = i18n.language === 'en';
    const toLanguage = isEnglish ? 'es' : 'en';

    return (
      <Button
        variant="text"
        onClick={() => i18n.changeLanguage(toLanguage)}
        endIcon={<Language />}
      >
        {isEnglish ? t('general.es_lang') : t('general.en_lang')}
      </Button>
    );
  };

  const renderLanguageSwitcher = (authorized?: boolean) => (
    <Select
      labelId="language-select-label"
      id="language-select"
      data-testid="language-select"
      value={language}
      label={t('general.language')}
      sx={{
        marginRight: authorized ? 2 : 0,
        maxWidth: { xs: '80px', sm: 'unset' },
        marginLeft: { xs: 1, sm: 0 },
        color: ivory,
        '& .MuiSelect-icon': { color: ivory },
        '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: ivory,
        },
      }}
      input={<OutlinedInput />}
      IconComponent={Language}
      onChange={handleLanguageChange}
      size="small"
    >
      <MenuItem value="en">
        {t('general.en_lang')}
      </MenuItem>
      <MenuItem value="es">
        {t('general.es_lang')}
      </MenuItem>
    </Select>
  );

  useEffect(() => {
    if (isAuthenticated && !myUser.id) {
      dispatch(getMyUser());
    }
  }, [ dispatch, isAuthenticated, myUser ]);

  return (
    <Grid
      display="flex"
      minWidth="100%"
      flexDirection="column"
      minHeight="100vh"
      color={purple600}
      fontFamily={SATOSHI}
      sx={!isAuthenticated ? { background: `url(${backgroundImage}) #3e355c 50% 100% no-repeat`, backgroundColor: purple600 } : { backgroundColor: purple600 }}
    >
      <Grid
        flex="0 1 auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={{ xs: '24px 16px', sm: '53px 32px', md: '32px 42px' }}
      >
        {pathname !== DASHBOARD_ROUTE && lgDown && isAuthenticated ? (
          <Button
            variant="text"
            color="ivory"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
            sx={{ textTransform: 'none' }}
          >
            {t('general.back')}
          </Button>
        ) : (
          <LogoIcon
            color="primaryContrast"
            sx={{ width: 262, height: 60, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />
        )}
        {!myUser.id && renderLanguageSwitcher()}
        {myUser?.id > 0 && (
          <Box display="flex" alignItems="center">
            {!mdDown && renderLanguageSwitcher(true)}
            <Button color="primaryContrast" aria-describedby={id} variant="text" onClick={handleClick} sx={{ padding: 0 }}>
              <Typography sx={{ p: 2 }} textTransform="capitalize" alignItems="center" display="flex" component="span">
                <AccountCircleOutlinedIcon fontSize="medium" sx={{ marginRight: { xs: 0, sm: 2 } }} />
                <Typography display={{ xs: 'none', sm: 'inherit' }}>{myUser.firstName} {myUser.lastName}</Typography>
              </Typography>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Stack padding={1} direction="column" spacing={1}>
                {mdDown && renderMobileLanguageSwitcher()}
                <Button
                  variant="text"
                  data-testid="settings-button"
                  onClick={onSettingsClick}
                >
                  {t('dashboard.settings.settings')}
                </Button>
                <Button
                  variant="text"
                  data-testid="logout-button"
                  onClick={onLogoutClick}
                >
                  {t('auth.logout')}
                </Button>
              </Stack>
            </Popover>
          </Box>
        )}
      </Grid>
      {isAuthenticated ? (
        <Routes>
          <Route path="view-consent" element={isBetween13And18 ? <AssentPage viewMode /> : <ConsentPage currentAge={ageWhenSignedUp} viewMode />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
      ) : (
        <div data-testid="authorization-container">
          <AuthorizationPage />
        </div>
      )}
    </Grid>
  );
};

export default DashboardContainer;
