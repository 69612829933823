import AuthForm from 'components/Dashboard/components/Authorization/SignInPage/components/AuthForm/AuthForm';
import EnterCodeForm from 'components/Dashboard/components/Authorization/SignInPage/components/EnterCodeForm/EnterCodeForm';
import { RootState } from 'store';

import React from 'react';
import { useSelector } from 'react-redux';

const SignInPage: React.FC = () => {
  const { mfaSessionId } = useSelector((store: RootState) => store.auth);

  return mfaSessionId ? <EnterCodeForm mfaSessionId={mfaSessionId} /> : <AuthForm />;
};

export default SignInPage;
