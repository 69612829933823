import {
  AncestryData,
} from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/types';

export const getChartOptions = (data: AncestryData[]) => {
  return {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      show: false,
    },
    minAngle: 1.5,
    series: [
      {
        type: 'pie',
        radius: '90%',
        minAngle: 4,
        color: data.map(({ color }) => color),
        data: data.map(({ title, value }) => ({
          value,
          name: title,
          tooltip: { valueFormatter: (value: number) => `${value}%` },
        })),
        avoidLabelOverlap: false,
        labelLine: {
          show: false,
        },
        legend: {
          show: false,
        },
        label: {
          show: false,
          position: 'center',
        },
        itemStyle: {
          borderWidth: 3,
          borderColor: 'white',
        },
        emphasis: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        },
      },
    ],
  };
};
