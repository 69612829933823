import { updateMyUserRequest } from 'api/User/api';
import { User } from 'api/User/types';
import { RootState } from 'store';
import { SET_MY_USER } from 'store/user/types';
import { getUserProfileProgress, getUSZipCodeRegExp } from 'common/helpers/user';
import { AmplitudeEvent } from 'common/constants/amplitude';
import { USA } from 'common/constants/countries';

import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';
import * as yup from 'yup';

export interface ProfileEditFormValues {
  dob: string | null;
  gender: string;
  race: string[];
  zip: string;
  state: string;
  city: string;
  street1: string;
  street2: string;
  country: string;
  previousZipCodes: string;
}

const useProfileEdit = (onSuccessCallback: () => void) => {
  const { t } = useTranslation();
  const { logEvent } = useAmplitude();

  const user: User = useSelector((state: RootState) => state.user.myUser);
  const dispatch = useDispatch();

  const validationSchema = useMemo(() => yup.object({
    dob: yup
      .date()
      .max(new Date(), t('registration.hint_dob'))
      .typeError(t('registration.hint_dob'))
      .required(t('registration.hint_dob')),
    race: yup
      .array()
      .min(1, t('dashboard.profile.errors.complete_race')),
    country: yup
      .string()
      .required(t('registration.hint_country')),
    state: yup
      .string()
      .when('country', {
        is: (country: string) => country === USA,
        then: yup.string().required(t('dashboard.profile.errors.complete_address')),
        otherwise: yup.string(),
      }),
    city: yup
      .string()
      .required(t('dashboard.profile.errors.complete_address')),
    street1: yup
      .string()
      .required(t('dashboard.profile.errors.complete_address')),
    zip: yup
      .string()
      .when('country', {
        is: (country: string) => country === USA,
        then: yup.string().required(t('registration.hint_zip')),
        otherwise: yup.string(),
      }),
    previousZipCodes: yup
      .string()
      .matches(getUSZipCodeRegExp(), 'Only the five-digit and nine-digit (ZIP+4) formats are allowed'),
  }), [ t ]);

  const formikSubmit = ({ dob, gender, race, zip, state, city, street1, street2, country, previousZipCodes }: ProfileEditFormValues) => {
    setSubmitting(true);
    const zipCodes = previousZipCodes.split(',');

    updateMyUserRequest({
      dob,
      gender,
      race: race.join(', '),
      mailingAddress: { zip, state: country === USA ? state : '', city, street1, street2, country, previousZipCodes: zipCodes },
    })
      .then(({ data }) => {
        dispatch({ type: SET_MY_USER, user: data });
        if (getUserProfileProgress(data) === 100) {
          logEvent(AmplitudeEvent.PROFILE_COMPLETED);
        }
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    dob: user.dob || null,
    gender: user.gender || '',
    race: user.race?.split(', ') || [],
    zip: user.mailingAddress.zip || '',
    state: user.mailingAddress.state || '',
    city: user.mailingAddress.city || '',
    street1: user.mailingAddress.street1 || '',
    street2: user.mailingAddress.street2 || '',
    country: user.mailingAddress.country || '',
    previousZipCodes: user.mailingAddress?.previousZipCodes.join(','),
  };

  const formik = useFormik<ProfileEditFormValues>({
    initialValues,
    onSubmit: formikSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  return { formik, isSubmitting, submitError };
};

export default useProfileEdit;
