import { exportUsersRequest } from 'api/User/api';
import { UsersFilter, FileFormat } from 'api/User/types';
import { downloadCSVFile } from 'common/helpers/downloadCSVFile';
import usePaginationSearchParams from 'common/hooks/usePaginationSearchParams';

import { useCallback } from 'react';

export const useExportUsers = (setIsExporting: (exporting: boolean) => void, onFinish: () => void) => {
  const {
    partnerParam,
    genderParam,
    testKitShippedParam,
    registrationParam,
    ehrStatusParam,
    surveyParam,
    usernameParam,
  } = usePaginationSearchParams();

  const exportUsers = useCallback((clinicalPatients: boolean, fileFormat: FileFormat) => {
    setIsExporting(true);

    const filters: UsersFilter = {
      userName: usernameParam,
      registration: registrationParam,
      testKitShipped: testKitShippedParam,
      gender: genderParam,
      partners: partnerParam !== '' ? partnerParam.split(',').map(p => Number(p)) : [],
      ehrStatus: ehrStatusParam,
      survey: surveyParam,
    };

    exportUsersRequest(filters, clinicalPatients, fileFormat)
      .then((response) => {
        downloadCSVFile('exported_users', response.data, fileFormat);
      })
      .finally(() => {
        setIsExporting(false);
        onFinish();
      });
  }, [ usernameParam, registrationParam, testKitShippedParam, genderParam, partnerParam, ehrStatusParam, surveyParam, setIsExporting, onFinish ]);

  return { exportUsers };
};
