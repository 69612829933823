import FileInput from 'common/components/FileInput/FileInput';
import { postAncestryFile } from 'api/User/api';
import { UPDATE_ANCESTRY_FILES } from 'store/user/types';
import { AmplitudeEvent } from 'common/constants/amplitude';
import { AncestryProvider } from 'api/Reports/types';
import {
  ErrorAlert,
  InstructionLink,
  InstructionList,
} from 'components/Dashboard/components/AncestryPage/components/AddAncestryView/components/UploadFileView/UploadFileViewStyles';

import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useAmplitude } from 'react-amplitude-hooks';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

interface Props {
  provider: AncestryProvider;
}

const SelectProviderView: React.FC<Props> = ({ provider }) => {
  const { t } = useTranslation();
  const { logEvent } = useAmplitude();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  const onSubmit = (file: File) => {
    if (!file) {
      return;
    }

    setLoading(true);

    const data = new FormData();
    data.append('uploadedFile', file);

    postAncestryFile(data, provider)
      .then(({ data }) => { // then print response status
        logEvent(AmplitudeEvent.ANCESTRY_FILE_UPLOADED);
        dispatch({ type: UPDATE_ANCESTRY_FILES, data: data || [] });
        navigate(-1);
      })
      .catch((error) => {
        let message = '';
        if (error?.response?.data?.message) {
          message = error?.response?.data?.message;

          if (error?.response?.data?.details) {
            message = `${message}. ${error?.response?.data?.details}`;
          }
        }

        setError(message || t('errors.server_error_try_again'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderInstruction = (provider: AncestryProvider) => {
    if (provider === AncestryProvider.TWENTY_THREE_AND_ME) {
      return (
        <Typography variant="textMMedium" color="black" data-testid="instruction-23andme">
          <InstructionList>
            <li>
              <Trans t={t} i18nKey="dashboard.ancestry.instructions.23andme.1">
                Login to your <a href="https://23andme.com" target="_blank" rel="noreferrer">23andMe.com</a> account
              </Trans>
            </li>
            <li>{t('dashboard.ancestry.instructions.23andme.2')}</li>
            <li>{t('dashboard.ancestry.instructions.23andme.3')}</li>
            <li>{t('dashboard.ancestry.instructions.23andme.4')}</li>
            <li>{t('dashboard.ancestry.instructions.23andme.5')}</li>
          </InstructionList>
        </Typography>
      );
    }
    if (provider === AncestryProvider.ANCESTRY) {
      return (
        <Typography variant="textMMedium" sx={{ whiteSpace: 'pre-line' }} color="black" data-testid="instruction-ancestry">
          <InstructionList>
            <li>
              <Trans t={t} i18nKey="dashboard.ancestry.instructions.ancestry.1">
                Login to your <a href="https://ancestry.com" target="_blank" rel="noreferrer">ancestry.com</a> account
              </Trans>
            </li>
            <li>{t('dashboard.ancestry.instructions.ancestry.2')}</li>
            <li>{t('dashboard.ancestry.instructions.ancestry.3')}</li>
            <li>{t('dashboard.ancestry.instructions.ancestry.4')}</li>
            <li>{t('dashboard.ancestry.instructions.ancestry.5')}</li>
            <li>{`${t('dashboard.ancestry.instructions.ancestry.6_1')}\n${t('dashboard.ancestry.instructions.ancestry.6_2')}`}</li>
            <li>{t('dashboard.ancestry.instructions.ancestry.7')}</li>
          </InstructionList>
        </Typography>
      );
    }
    if (provider === AncestryProvider.MYHERITAGE) {
      return (
        <Typography variant="textMMedium" color="black" data-testid="instruction-myheritage">
          <InstructionList>
            <li>
              <Trans t={t} i18nKey="dashboard.ancestry.instructions.myheritage.1">
                Login to your <a href="https://myheritage.com" target="_blank" rel="noreferrer">myheritage.com</a> account
              </Trans>
            </li>
            <li>{t('dashboard.ancestry.instructions.myheritage.2')}</li>
            <li>{t('dashboard.ancestry.instructions.myheritage.3')}</li>
            <li>{t('dashboard.ancestry.instructions.myheritage.4')}</li>
            <li>{t('dashboard.ancestry.instructions.myheritage.5')}</li>
            <li>{t('dashboard.ancestry.instructions.myheritage.6')}</li>
            <li>{t('dashboard.ancestry.instructions.myheritage.7')}</li>
          </InstructionList>
        </Typography>
      );
    }
    if (provider === AncestryProvider.OTHER) {
      return (
        <Box paddingTop={10} paddingBottom={5}>
          <Typography variant="textMMedium">
            {t('dashboard.ancestry.instructions.other')}
          </Typography>
        </Box>
      );
    }
  };

  const getInstructionLink = (provider: AncestryProvider) => {
    if (provider === AncestryProvider.TWENTY_THREE_AND_ME) {
      return 'https://www.yourdnaguide.com/download-23andme';
    }
    if (provider === AncestryProvider.ANCESTRY) {
      return 'https://www.yourdnaguide.com/download-ancestrydna';
    }
    if (provider === AncestryProvider.MYHERITAGE) {
      return 'https://www.yourdnaguide.com/download-myheritage';
    }
  };

  return (
    <Stack
      flexGrow={1}
      spacing={2}
      width="100%"
      height="100%"
      alignItems="flex-start"
      justifyContent={provider !== AncestryProvider.OTHER ? 'space-between' : 'flex-start'}
      data-testid="upload-file-view"
    >
      {renderInstruction(provider)}
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <FileInput
          loading={isLoading}
          label={t('dashboard.ancestry.upload.button_label')}
          onChange={onSubmit}
          data-testid="upload"
          accept=".zip, .txt"
          ButtonProps={{
            variant: 'contained',
            sx: { height: '100%' },
          }}
        />
        {error && <ErrorAlert severity="error">{error}</ErrorAlert>}
      </Stack>
      {provider !== AncestryProvider.OTHER && (
        <InstructionLink>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <HelpOutlineOutlinedIcon fontSize="small" />
            <Typography variant="textMMedium" color="black">
              <a href={getInstructionLink(provider)} target="_blank" rel="noreferrer">{t('dashboard.ancestry.instructions.details_link')}</a>
            </Typography>
          </Stack>
        </InstructionLink>
      )}
    </Stack>
  );
};

export default SelectProviderView;
