import { UserAccessLevel } from 'api/User/types';
import { AccessLevelLabels } from 'components/AdminDashboard/components/AdminRolesPage/types';
import useChangeRole from 'components/AdminDashboard/components/AdminRolesPage/components/ChangeRolePopover/hooks';

import React, { ChangeEvent } from 'react';
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Popover, Typography } from '@mui/material';

interface Props {
  userId: number
  accessLevel: UserAccessLevel,
  onClose: () => void;
  onChange: (value: UserAccessLevel) => void;
  anchorEl: HTMLElement;
}

const ChangeRolePopover: React.FC<Props> = ({ userId, accessLevel, anchorEl, onClose, onChange }) => {
  const { changeRole, isSubmitting, submitError } = useChangeRole(onClose);

  const handleRadioChanged = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value as unknown as UserAccessLevel);
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 1,
        sx: { padding: 4 },
      }}
      onClose={onClose}
      data-testid="popover-change-role"
      disableRestoreFocus
    >
      <RadioGroup
        value={accessLevel}
        onChange={handleRadioChanged}
      >
        <Grid container display="flex" flexDirection="column">
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography>
              Select a role:
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              value={UserAccessLevel.USER}
              control={<Radio />}
              label={AccessLevelLabels.USER}
              data-testid="user"
              sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px' } }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              value={UserAccessLevel.ADMIN}
              control={<Radio />}
              label={AccessLevelLabels.ADMIN}
              data-testid="admin"
              sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px' } }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              value={UserAccessLevel.BIO_BANK_ADMIN}
              control={<Radio />}
              label={AccessLevelLabels.BIO_BANK_ADMIN}
              data-testid="bio-bank"
              sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px' } }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              value={UserAccessLevel.DATA_SCIENCE_ADMIN}
              control={<Radio />}
              label={AccessLevelLabels.DATA_SCIENCE_ADMIN}
              data-testid="data-science"
              sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px' } }}
            />
          </Grid>
          {
            submitError && (
              <Grid item xs={12}>
                <Typography color="error" variant="subtitle2">
                  {submitError}
                </Typography>
              </Grid>
            )
          }
          <Grid item display="flex" justifyContent="flex-end">
            <Button
              disabled={isSubmitting}
              variant="contained"
              onClick={() => changeRole(userId, accessLevel)}
              data-testid="button-change-role"
              sx={{ marginTop: 2 }}
            >
              Change
            </Button>
          </Grid>
        </Grid>
      </RadioGroup>
    </Popover>
  );
};

export default ChangeRolePopover;
