import { midnight, purple900 } from 'common/constants/colors';
import useQuestions from 'components/Landing/components/MainContent/components/FaqPage/useQuestions';

import React from 'react';
import { Box, Collapse, Container, List, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';

const FaqPage: React.FC = () => {
  const theme = useTheme();
  const questions = useQuestions();
  const { t } = useTranslation();
  
  const [ openedQuestions, setOpenedQuestions ] = React.useState<boolean[]>(new Array(questions.length));
  
  const handleClick = (index: number) => {
    setOpenedQuestions([
      ...openedQuestions.slice(0, index),
      !openedQuestions[index],
      ...openedQuestions.slice(index + 1, openedQuestions.length),
    ]);
  };

  return (
    <Box
      position="relative"
      sx={{ background: `linear-gradient(0deg, ${purple900} 0%, ${midnight} 100%)` }}
      paddingBottom={{ xs: 0, sm: 6 }}
    >
      <Container maxWidth="xl">
        <Box
          position="relative"
          padding={{ xs: '54px 20px', sm: '62px 100px' }}
          margin={{ xs: -2, sm: 0 }}
          borderRadius={{ xs: '0', sm: '30px' }}
          sx={{ backgroundColor: theme.palette.purple.surface }}
        >
          <Typography sx={{
            fontFamily: 'Montserrat Alternates',
            fontSize: { xs: '1.5rem', md: '2.5rem' },
            fontWeight: 600,
            lineHeight: '125%',
            marginBottom: 3,
          }}
          >
            {t('landing.faq.frequently_asked_questions')}
          </Typography>
          <List sx={{ maxWidth: 650 }}>
            {questions.map(({ question, answer }, index) => {
              return (
                <div key={String(question)}>
                  <ListItemButton onClick={() => handleClick(index)} disableRipple sx={{ paddingLeft: 0, marginTop: { sx: 0.5, md: 1 } }}>
                    {openedQuestions[index] ? <RemoveIcon /> : <AddIcon />}
                    <ListItemText primary={(
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: { xs: '1.125rem', md: '1.5rem' },
                          fontWeight: 700,
                          marginLeft: 3,
                          lineHeight: '125%',
                          letterSpacing: '0.05em',
                        }}
                      >
                        {question}
                      </Typography>
                    )}
                    />
                  </ListItemButton>
                  <Collapse in={openedQuestions[index]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemText primary={(
                        <Typography
                          color="primary"
                          sx={{
                            fontSize: { xs: '1rem', md: '1.125rem' },
                            fontWeight: 500,
                            marginLeft: 6,
                            marginTop: 1,
                            letterSpacing: '-0.014em',
                          }}
                        >
                          {answer}
                        </Typography>
                      )}
                      />
                    </List>
                  </Collapse>
                </div>
              );
            })}
          </List>
        </Box>
      </Container>
    </Box>
  );
};

export default FaqPage;
