import KitMaskedInput from 'components/AdminDashboard/components/ShippingStatusPage/components/EditKitModal/components/KitMaskedInput/KitMaskedInput';
import { UserWithShipping } from 'api/UserKits/types';
import { useUpdateUserKit } from 'components/AdminDashboard/components/ShippingStatusPage/components/EditKitModal/hooks';
import { NEW_TEST_KIT_ID_LENGTH } from 'api/UserKits/constants';

import { Box, Button, FormHelperText, Grid, Modal, OutlinedInput, Paper, Typography } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';

interface Props {
  onCancel: () => void;
  onSave: (userId: number, kitId: string) => void;
  userWithShipping: UserWithShipping | null;
}

const EditKitModal: React.FC<Props> = ({ onCancel, onSave, userWithShipping }) => {
  const [ value, setValue ] = React.useState(userWithShipping?.kitId || '');

  const {
    requestUpdateUserKit,
    isLoading,
    error,
    setError,
  } = useUpdateUserKit(onSave);
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setValue(event.target.value);
  };

  const handleSave = () => {
    if (!userWithShipping) {
      return;
    }

    if (value.length < NEW_TEST_KIT_ID_LENGTH) {
      setError(`Kit id must contain ${NEW_TEST_KIT_ID_LENGTH} characters`);
      return;
    }

    requestUpdateUserKit(userWithShipping.id, value);
  };

  return (
    <Modal open>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '600px' },
        }}
        data-testid="modal-edit-kit"
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="textXLBold">
                Enter test kit id
              </Typography>
            </Grid>
            <Grid item>
              <OutlinedInput
                name="kit-id"
                value={value}
                onChange={handleChange}
                inputComponent={KitMaskedInput as any}
                error={Boolean(error)}
                size="small"
                disabled={isLoading}
                sx={{
                  minWidth: '460px',
                  fontFamily: 'Red Hat Mono',
                }}
                data-testid="input-kit-id"
              />
              {error && (
                <FormHelperText error data-testid="error-kit-length">
                  {error}
                </FormHelperText>
              )}
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item>
                <LoadingButton variant="contained" onClick={handleSave} loading={isLoading} data-testid="button-save-kit-id">
                  Save
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onCancel} disabled={isLoading}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};

export default EditKitModal;
