import { getMyUserRequest } from 'api/User/api';

import { useState, useEffect } from 'react';

export const useGetTestKitReceived = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ testKitReceived, setTestKitReceived ] = useState<boolean | null>(null);

  useEffect(() => {
    setIsLoading(true);

    getMyUserRequest()
      .then(({ data }) => {
        if (data) {
          setTestKitReceived(data.testKitReceived);
        }
      })
      .finally(() => setIsLoading(false));
  }, [ setIsLoading ]);

  return {
    isLoading,
    testKitReceived,
  };
};
