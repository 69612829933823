import { postAuth } from 'api/Auth/api';
import { CommonAuthResponse, MFAAuthResponse } from 'api/Auth/types';
import { LOGIN_SUCCESS, SET_MFA_SESSION_ID } from 'store/auth/types';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

const validationSchema = yup.object({
  email: yup
    .string()
    .email()
    .required('Enter email'),
  password: yup
    .string()
    .required('Enter password'),
});

interface AuthFormValues {
  email: string;
  password: string;
}

const useAuthForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState<string>();

  const formikSubmit = (values: AuthFormValues) => {
    setIsLoading(true);

    postAuth(values.email, values.password)
      .then(({ data }) => {
        if ('mfaSessionId' in data) {
          const { mfaSessionId } = data as MFAAuthResponse;
          dispatch({ type: SET_MFA_SESSION_ID, mfaSessionId });
        } else {
          const { token, refreshToken } = data as CommonAuthResponse;
          dispatch({ type: LOGIN_SUCCESS, token, refreshToken });
        }
      })
      .catch((error) => {
        setError(error?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setIsLoading(false));
  };

  const formik = useFormik<AuthFormValues>({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: formikSubmit,
  });

  return { formik, isLoading, error, setError };
};

export default useAuthForm;
