import TrackingStepIconRoot from 'components/Dashboard/components/ShippingPage/components/TrackingPage/TrackingStepIcon/TrackingStepIconStyles';

import { StepIconProps } from '@mui/material';
import { Check } from '@mui/icons-material';
import React from 'react';

const TrackingStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const renderIcon = () => {
    if (completed) {
      return <Check className="TrackingStepIcon-circle-completed" />;
    }
    return <div className="TrackingStepIcon-circle" />;
  };

  return (
    <TrackingStepIconRoot ownerState={{ active }} className={className}>
      {renderIcon()}
    </TrackingStepIconRoot>
  );
};

export default TrackingStepIcon;
