const openInNewTab = (url?: string) => {
  if (!url) {
    return;
  }

  const e = document.createElement('a');
  e.href = url;
  e.target = '_blank';
  e.download = url.substr(url.lastIndexOf('/') + 1);
  document.body.appendChild(e);
  e.click();
  document.body.removeChild(e);
};

export default openInNewTab;
