import { Organization } from 'api/Organizations/types';
import { Pagination } from 'common/types/Pagination';
import { OrganizationsState } from 'store/organizations/models';
import {
  SET_ORGANIZATIONS,
  SET_ORGANIZATIONS_LOADING,
  SET_ORGANIZATIONS_ERROR,
  UPDATE_ORGANIZATIONS,
} from 'store/organizations/types';

import { AnyAction } from 'redux';
import omit from 'lodash/omit';

export const initialState: OrganizationsState = {
  isLoading: false,
  organizations: [],
  pagination: {
    currentPage: 0,
    firstRowOnPage: 0,
    lastRowOnPage: 0,
    pageCount: 0,
    pageSize: 0,
    rowCount: 0,
  },
  error: '',
};

export const organizationsReducer = (
  state: OrganizationsState = { ...initialState },
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_ORGANIZATIONS_LOADING:
      return {
        ...state,
        isLoading: action.value as boolean,
      };

    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.data.results as Organization[],
        pagination: omit(action.data, 'results') as Pagination,
        error: '',
      };

    case SET_ORGANIZATIONS_ERROR:
      return {
        ...state,
        error: action.value as string,
      };

    case UPDATE_ORGANIZATIONS:
      return {
        ...state,
        organizations: (state.organizations.map((organization) => {
          if (organization.id !== action.data.id) {
            return organization;
          }
          const organizationData: Organization = action.data;

          return {
            ...organization,
            ...organizationData,
          };
        }) as Organization[]),
      };

    default:
      return { ...state };
  }
};
