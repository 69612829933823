import { postVerifyAccount } from 'api/Auth/api';
import { LOGIN_SUCCESS } from 'store/auth/types';
import { AmplitudeEvent } from 'common/constants/amplitude';
import { DASHBOARD_ROUTE } from 'common/constants/urls';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React from 'react';
import { useAmplitude } from 'react-amplitude-hooks';

const useVerify = (key?: string) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logEvent } = useAmplitude();

  const [ isLoading, setIsLoading ] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!key) {
      navigate(DASHBOARD_ROUTE);
      return;
    }

    postVerifyAccount(key)
      .then(({ data }) => {
        dispatch({ type: LOGIN_SUCCESS, token: data.token, refreshToken: data.refreshToken });
        logEvent(AmplitudeEvent.ACCOUNT_ACTIVATED);
        navigate(DASHBOARD_ROUTE);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return { isLoading };
};

export default useVerify;
