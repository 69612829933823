import { Props, System } from 'common/components/MeasurementSystem/types';

import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MeasurementSystem: React.FC<Props> = ({ system, onEnglishClick, onMetricClick }) => {
  const { t } = useTranslation();
  const isMetric = system === System.METRIC;

  const clickEnglish = () => {
    if (!isMetric) {
      return;
    }

    onEnglishClick();
  };

  const clickMetric = () => {
    if (isMetric) {
      return;
    }

    onMetricClick();
  };

  return (
    <Grid container item spacing={1}>
      <Grid item>(</Grid>
      <Grid item>
        <Typography
          variant="textMRegular"
          sx={{ textDecoration: isMetric ? 'underline' : 'none', cursor: isMetric ? 'pointer' : 'auto' }}
          onClick={clickEnglish}
        >
          {t('dashboard.survey.system_english')}
        </Typography>
      </Grid>
      <Grid item>|</Grid>
      <Grid item>
        <Typography
          variant="textMRegular"
          sx={{ textDecoration: !isMetric ? 'underline' : 'none', cursor: !isMetric ? 'pointer' : 'auto' }}
          onClick={clickMetric}
        >
          {t('dashboard.survey.system_metric')}
        </Typography>
      </Grid>
      <Grid item>)</Grid>
    </Grid>
  );
};

export default MeasurementSystem;
