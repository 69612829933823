import { AuthorizationContainer, Label } from 'common/components';
import useEnterCodeForm, { EnterCodeFormValues } from 'components/Dashboard/components/Authorization/hooks/useEnterCodeForm';
import useRequestNewCode from 'components/Dashboard/components/Authorization/hooks/useRequestNewCode';
import styles from 'components/Dashboard/components/Authorization/SignInPage/components/EnterCodeForm/EnterCodeForm.module.css';

import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, MouseEvent } from 'react';
import { FormikProvider } from 'formik';
import { Box, Button, Grid, Typography, Snackbar, Stack, TextField } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface Props {
  mfaSessionId: string;
}

const EnterCodeForm: React.FC<Props> = ({ mfaSessionId }) => {
  const { t } = useTranslation();
  const { formik, isLoading, error } = useEnterCodeForm(mfaSessionId);
  const { error: requestError, requestNewCode, timeLeft, isCodeRequested, setIsCodeRequested } = useRequestNewCode();

  const inputIds = [ 1, 2, 3, 4, 5, 6 ];

  const onInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const numericId = Number(event.target.id.replace(/[a-zA-Z]|_/g, ''));
      if (numericId < inputIds.length) {
        (document.querySelector(`#code${numericId + 1}`) as HTMLInputElement).select();
      }
    }
  };

  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const numericId = Number((event.target as HTMLInputElement).id.replace(/[a-zA-Z]|_/g, ''));
    if (event.code === 'ArrowRight' && numericId < inputIds.length) {
      (document.querySelector(`#code${numericId + 1}`) as HTMLInputElement).select();
    }

    if (event.code === 'ArrowLeft' && numericId > 1) {
      (document.querySelector(`#code${numericId - 1}`) as HTMLInputElement).select();
    }
  };

  const onClick = (event: MouseEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).select();
  };

  const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const stringFromClipboard = event.clipboardData.getData('Text').split('');
    const numericId = Number((event.target as HTMLInputElement).id.replace(/[a-zA-Z]|_/g, ''));
    for (let i = numericId, arrayIndex = 0; i <= inputIds.length; i++, arrayIndex++) {
      formik.setFieldValue(`code${i}`, stringFromClipboard[arrayIndex]);
    }
    event.preventDefault();
  };

  const onRequestNewCode = () => {
    requestNewCode(mfaSessionId);
  };

  return (
    <AuthorizationContainer data-testid="enter-code-form">

      <Label>
        {t('enter_code.please_enter')}
        <Typography>{t('enter_code.we_have_just_sent')}</Typography>
      </Label>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>

          <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
            <Grid container spacing={1}>

              {inputIds.map((id) => {
                const isTouched = formik.touched[`code${id}` as keyof EnterCodeFormValues];
                const hasError = Boolean((formik.errors[`code${id}` as keyof EnterCodeFormValues]) === '');
                const displayError = isTouched && hasError;
                return (
                  <Grid item xs={1.5} key={`code${id}`}>
                    <TextField
                      id={`code${id}`}
                      name={`code${id}`}
                      size="small"
                      inputProps={{ maxLength: 1, 'data-testid': `code${id}` }}
                      value={formik.values[`code${id}` as keyof EnterCodeFormValues]}
                      onChange={formik.handleChange}
                      error={displayError}
                      disabled={isLoading}
                      onClick={onClick}
                      onInput={onInput}
                      onKeyUp={onKeyUp}
                      onPaste={onPaste}
                    />
                  </Grid>
                );
              })}

            </Grid>
          </Stack>

          <Box marginTop={1}>
            <span className={classNames({ [styles.requestCodeWrapper]: timeLeft > 0 })}>
              <span
                className={classNames(styles.requestCode, { [styles.disabled]: timeLeft > 0 })}
                onClick={onRequestNewCode}
              >
                {t('enter_code.request_a_new_code')}
              </span>
            </span>
            <br />
            {timeLeft > 0 && (
              <Trans t={t} i18nKey="enter_code.you_can_request" values={{ timeLeft }}>
                You can request a new code after {{ timeLeft }} seconds
              </Trans>
            )}
          </Box>

          {(error || requestError) && (
            <Box>
              <Typography color="error" variant="subtitle2">
                {error || requestError}
              </Typography>
            </Box>
          )}

          <Box display="flex" justifyContent="flex-start" width="100%" marginTop={{ xs: 3, sm: 5 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              data-testid="continue-button"
              disabled={isLoading}
            >
              {t('general.continue')}
            </Button>
          </Box>

        </form>
      </FormikProvider>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isCodeRequested}
        autoHideDuration={5000}
        onClose={() => setIsCodeRequested(false)}
        message={t('enter_code.new_confirmation_code')}
      />

    </AuthorizationContainer>
  );
};

export default EnterCodeForm;
