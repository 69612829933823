import { Translations } from 'i18nextOptions';
import { User } from 'api/User/types';

const NAVIGATION_ITEMS = [
  {
    label: 'landing.navigation.dashboard' as keyof Translations['translations'],
    path: '/dashboard',
    condition: (user: User) => user.id,
  },
  {
    label: 'landing.navigation.partners' as keyof Translations['translations'],
    path: '/partners',
  },
];

export { NAVIGATION_ITEMS };
