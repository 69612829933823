import { white } from 'common/constants/colors';
import BackgroundImg from 'components/Landing/components/MainContent/components/QuotePage/resources/background.svg';

import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DistinctionsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      position="relative"
      overflow="hidden"
      sx={{
        background: `url(${BackgroundImg}) ${white} 50% 40% no-repeat`,
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ margin: { xs: '64px 8px', lg: '100px' } }}>
          <Typography
            color="midnight.main"
            sx={{
              maxWidth: 890,
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: { xs: '52px', lg: '80px' },
              lineHeight: '125%',
              letterSpacing: '-0.05em',
            }}
          >
            {t('landing.partners.distinctions.title')}
          </Typography>
          <Typography
            color="orange.main"
            marginTop={10}
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: { xs: '32px', lg: '40px' },
              lineHeight: '100%',
              letterSpacing: '-0.05em',
            }}
          >
            {t('landing.partners.distinctions.distinction_1')}
          </Typography>
          <Typography
            color="orange.main"
            marginTop={10}
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: { xs: '32px', lg: '40px' },
              lineHeight: '100%',
              letterSpacing: '-0.05em',
            }}
          >
            {t('landing.partners.distinctions.distinction_2')}
          </Typography>
          <Typography
            color="orange.main"
            marginTop={10}
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: { xs: '32px', lg: '40px' },
              lineHeight: '100%',
              letterSpacing: '-0.05em',
            }}
          >
            {t('landing.partners.distinctions.distinction_3')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default DistinctionsPage;
