import { APP_BAR_HEIGHT } from 'common/constants/sizes';
import GalateaSvg from 'components/Landing/components/MainContent/components/WelcomePage/resources/partners/galatea.svg';
import GenomelinkSvg from 'components/Landing/components/MainContent/components/WelcomePage/resources/partners/genomelink.svg';
import CornerSvg from 'components/Landing/components/MainContent/components/WelcomePage/resources/corner.svg';
import SaasSvg from 'components/Landing/components/MainContent/components/WelcomePage/resources/partners/saas.svg';
import StanfordSvg from 'components/Landing/components/MainContent/components/WelcomePage/resources/partners/stanford.svg';
import BiohubSvg from 'components/Landing/components/MainContent/components/WelcomePage/resources/partners/biohub.svg';
import WcgIrbSvg from 'components/Landing/components/MainContent/components/WelcomePage/resources/partners/wcg_irb.svg';
import BlueMedSvg from 'components/Landing/components/MainContent/components/WelcomePage/resources/partners/blue_med.svg';
import HemlifeSvg from 'components/Landing/components/MainContent/components/WelcomePage/resources/partners/hemlife.svg';

import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

const WelcomePage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [ pageEndRef, isPageInView ] = useInView();

  const navigateToSignUp = () => navigate('/dashboard/signup');

  const isEnglish = i18n.language === 'en';

  return (
    <Container
      maxWidth="xl"
      sx={{ height: '100vh', paddingTop: APP_BAR_HEIGHT, overflow: 'hidden', position: 'relative' }}
    >
      <Box display="flex" height="100%" flexDirection="column" justifyContent="center" marginTop={{ xs: -13, md: -8 }}>
        <Box maxWidth="850px">
          <Typography
            variant="h1"
            color="primary"
            sx={{
              fontFamily: 'Montserrat Alternates',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: { xs: '42px', lg: '72px', xl: isEnglish ? '100px' : '84px' },
              lineHeight: '125%',
              letterSpacing: '-0.05em',
            }}
          >
            {t('landing.welcome.help_us')}
          </Typography>
          <Box marginTop={{ xs: 4, xl: 8 }}>
            <Typography variant="subtitle1" maxWidth={408} color="primary">
              {t('landing.welcome.explore_studies')}
            </Typography>
          </Box>
          <Box marginTop={{ xs: 3, md: 4, xl: 6.75 }}>
            <Button variant="contained" color="primary" size="large" onClick={navigateToSignUp}>
              {t('landing.welcome.button_join_study')}
            </Button>
          </Box>
        </Box>
        <Grid
          container
          position="fixed"
          spacing={2}
          display={isPageInView ? 'flex' : 'none'}
          flexDirection="row"
          sx={{ bottom: { xs: '120px', sm: '96px' } }}
          height="46px"
          zIndex={0}
          maxWidth="850px"
          width="50%"
          flexWrap="wrap"
        >
          <Grid item xs={6} sm={3}>
            <Box sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.galatea.bio/')}>
              <img src={GalateaSvg} height="100%" alt="" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://genomelink.io/')}>
              <img src={GenomelinkSvg} height="100%" alt="" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://saas.ad/')}>
              <img src={SaasSvg} height="100%" alt="" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.stanford.edu/')}>
              <img src={StanfordSvg} height="100%" alt="" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.czbiohub.org/')}>
              <img src={BiohubSvg} height="100%" alt="" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.wcgirb.com/')}>
              <img src={WcgIrbSvg} height="100%" alt="" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://www.bluemedconsultants.com/')}>
              <img src={BlueMedSvg} height="100%" alt="" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box textAlign="center" sx={{ height: { xs: '20px', md: '30px', lg: '40px' }, cursor: 'pointer' }} onClick={() => window.open('https://hemolifeamerica.org/')}>
              <img src={HemlifeSvg} height="100%" alt="" />
            </Box>
          </Grid>
        </Grid>
        <Box
          position="fixed"
          display={isPageInView ? 'block' : 'none'}
          width="50%"
          height="100%"
          overflow="hidden"
          zIndex={-1000}
          sx={{
            bottom: { xs: '-40%', md: '-10%' },
            right: { xs: '-10%', md: '-10%' },
            width: { xs: '40%', md: '50%' },
          }}
        >
          <img src={CornerSvg} alt="" width="100%" height="100%" />
        </Box>
      </Box>
      <Box ref={pageEndRef} position="absolute" bottom={{ xs: '30%', md: '10%' }} />
    </Container>
  );
};

export default WelcomePage;
