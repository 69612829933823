export const ANCESTRY_LIST = [
  'White',
  'Black or African American',
  'American Indian or Alaska Native',
  'Chinese',
  'Vietnamese',
  'Native Hawaiian',
  'Filipino',
  'Korean',
  'Japanese',
  'Samoan',
  'Asian Indian',
  'Chamorro',
  'Other Asian',
  'Other Pacific Islander',
  'Some other race',
];
