import { createOrganizationRequest, changeOrganizationRequest } from 'api/Organizations/api';
import { Organization, Site } from 'api/Organizations/types';
import { getOrganizations } from 'store/organizations/actions';
import { UPDATE_ORGANIZATIONS } from 'store/organizations/types';

import { useDispatch } from 'react-redux';
import { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { get } from 'lodash';

const useOrganization = (onSuccessfulSubmit: () => void, initialValues: Organization | null) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ submitError, setSubmitError ] = useState<string>();

  yup.addMethod(yup.array, 'unique', function unique(message: string, path: string) {
    return this.test('unique', message, function testValuesForUnique(list: Site[] | undefined) {
      const mapper = (x: Site) => get(x, path);
      const uniqueValues: string[] = Array.from(new Set(list?.map(mapper)));
      if (list?.length === uniqueValues.length) {
        return true;
      }
      const idx = list?.findIndex((l, i) => mapper(l) !== uniqueValues[i]);
      // it happens for undefined values, but such values will be filtered in next steps, so we shouldn't throw an error
      if (idx === -1) {
        return true;
      }
      return this.createError({ path: `sites[${idx}]`, message });
    });
  });

  const validationSchema = useMemo(() => yup.object({
    primaryEmail: yup
      .string()
      .email('Enter a valid email'),
    name: yup
      .string()
      .required('Enter a name'),
    sites: yup
      .array()
      .of(yup.object().shape({
        id: yup.string(),
        name: yup.string(),
        isNew: yup.boolean().optional(),
      })
        .test('oldSitesAreRequired', 'You cannot delete already created site', (val) => {
          return !(!val?.isNew && !val.name);
        }))
      .unique('Duplicate site', 'name'),
  }), []);

  const onSubmit = (org: Organization) => {
    setSubmitting(true);
    setSubmitError('');
    const { id } = org;
    const callback = id === 0 ? createOrganizationRequest : changeOrganizationRequest;
    const dispatchCallback = (data: Organization) => {
      return id === 0 ?
        getOrganizations(0, 10) :
        { type: UPDATE_ORGANIZATIONS, data };
    };

    callback(org)
      .then(({ data }) => {
        dispatch(dispatchCallback(data));
        onSuccessfulSubmit();
      })
      .catch((error) => {
        setSubmitError(error?.response?.data?.message || t('errors.server_error_try_again'));
      })
      .finally(() => setSubmitting(false));
  };

  const initOrg = initialValues || {
    id: 0,
    primaryEmail: '',
    name: '',
    sites: [],
  };

  const formik = useFormik<Organization>({
    initialValues: {
      ...initOrg,
      sites: [
        ...initOrg.sites,
        { id: uuid(), name: '', isNew: true },
      ],
    },
    validationSchema,
    onSubmit,
  });

  return { formik, isSubmitting, submitError };
};

export default useOrganization;
