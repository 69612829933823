import { Overview } from 'api/Transactions/types';
import OverviewStatsBox
  from 'components/AdminDashboard/components/ShippingStatusPage/components/OverviewStats/components/OverviewStatsBox/OverviewStatsBox';

import React from 'react';
import { Grid } from '@mui/material';

interface IProps {
  overview: Overview
}

const OverviewStats: React.FC<IProps> = ({ overview }) => {
  const totalUsersTestKits = overview.totalTestKitRequestReturn - overview.totalTestKitRequestDirect;
  
  return (
    <Grid container rowSpacing={{ xs: 2 }} columnSpacing={{ xs: 2 }} marginBottom={2} data-testid="transactions-overview">
      <OverviewStatsBox title="Total Test Kit Requests" value={overview.totalTestKitRequestDirect} />
      <OverviewStatsBox title="Total Users’ Test Kits" value={totalUsersTestKits} />
      <OverviewStatsBox title="Total Test Kits Delivered" value={overview.totalTestKitDelivered} />
      <OverviewStatsBox title="In Transit" value={overview.kitInTransit} />
    </Grid>
  );
};

export default OverviewStats;
