import FileInput from 'common/components/FileInput/FileInput';
import { useBatchUpload } from 'components/AdminDashboard/components/UsersPage/components/UsersControlPanel/components/AddUserPopover/hooks';
import { UserPageProps } from 'components/AdminDashboard/components/UsersPage/types';

import React from 'react';
import { Menu, MenuItem } from '@mui/material';

interface Props extends UserPageProps{
  onAddUserClick: () => void;
  onClose: () => void;
  data: Element | null;
  onBatchUpload: () => void;
}

const AddUserPopover: React.FC<Props> = ({ data, onAddUserClick, onClose, onBatchUpload, isClinicalPatientPage }) => {
  const { batchUpload, isLoading } = useBatchUpload(onBatchUpload, !!isClinicalPatientPage);
  const uploadUsers = (file: File) => batchUpload(file);

  return (
    <Menu
      open={Boolean(data)}
      anchorEl={data}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MenuItem
        onClick={onAddUserClick}
        sx={{ fontSize: '14px' }}
      >
        Add Individual
      </MenuItem>
      <FileInput
        loading={isLoading}
        label="Batch Upload"
        onChange={uploadUsers}
        data-testid="batch-upload"
        accept=".csv, .tsv"
        ButtonComponent={MenuItem}
        ButtonProps={{
          sx: { fontSize: '14px' },
        }}
      />
    </Menu>
  );
};

export default AddUserPopover;
