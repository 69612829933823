import { MONTSERRAT_ALTERNATES } from 'common/constants/fonts';
import { midnight } from 'common/constants/colors';
import { theme } from 'theme';
import ProgressBar from 'components/Dashboard/components/ProgressBar/ProgressBar';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';

interface Props {
  label: string
  buttonLabel: string
  link: string
  progress: number
  disabled?: boolean
}

const DashboardCard = ({ label, progress, buttonLabel, link, disabled = false, ...props }: Props) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const isEnglish = i18n.language === 'en';

  const onMobileClickHandler = React.useCallback(() => {
    if (window.screen.width < theme.breakpoints!.values!.sm && !disabled && progress !== -1) {
      navigate(link);
    }
  }, [ disabled, link, navigate, progress ]);

  const onClickHandler = React.useCallback((event) => {
    if (!disabled && progress !== -1) {
      event.stopPropagation();
      navigate(link);
    }
  }, [ disabled, link, navigate, progress ]);

  return (
    <Box
      onClick={onMobileClickHandler}
      width="100%"
      sx={{ backgroundColor: '#fff' }}
      padding={{ xs: 2, sm: 3 }}
      borderRadius={{ xs: '20px', sm: '30px' }}
      {...props}
    >
      <Box display="flex" alignItems="center" marginBottom={{ xs: 2, sm: 3 }} justifyContent="space-between">
        <Typography sx={{
          fontFamily: MONTSERRAT_ALTERNATES,
          fontWeight: 700,
          fontSize: { xs: '18px', sm: isEnglish ? '28px' : '26px' },
          lineHeight: '170%',
          letterSpacing: '-0.05em',
          color: midnight,
        }}
        >{label}
        </Typography>
        {!disabled && (
          <Box sx={{ display: { sm: 'none' } }}>
            {progress === -1 ?
              <LockIcon color="orange" /> :
              <ArrowForwardIos />}
          </Box>
        )}
      </Box>
      <ProgressBar progress={progress} horizontal />
      <Button
        onClick={onClickHandler}
        disabled={disabled}
        fullWidth
        variant="outlined"
        color="primary"
        endIcon={(progress === -1) && <LockIcon color="orange" />}
        sx={{ fontSize: isEnglish ? '1rem' : '15px' }}
      >
        {buttonLabel}
      </Button>
    </Box>
  );
};

export default DashboardCard;
