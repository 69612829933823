import enTranslations from 'common/translations/en.json';
import esTranslations from 'common/translations/es.json';

import { Normalize2 } from 'react-i18next';

export type Translations = typeof enTranslations;
export type TranslationsKey = Normalize2<Translations['translations']>;

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'translations',
    resources: Translations;
  }
}

const i18nextOptions = {
  resources: {
    en: enTranslations,
    es: esTranslations,
  },
  defaultNS: 'translations',
  fallbackLng: 'en',
  supportedLngs: [ 'en', 'es' ],
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
};

export default i18nextOptions;
