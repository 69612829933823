import { UserAccessLevel, UserView } from 'api/User/types';
import { HeadCell } from 'common/types/MuiComponents';
import SearchUserInput from 'common/components/SearchUserInput/SearchUserInput';
import DataTable from 'common/components/DataTable/DataTable';
import LoadingContainer from 'common/components/LoadingContainer/LoadingContainer';
import AddAdminPopover from 'components/AdminDashboard/components/AdminRolesPage/components/AddAdminPopover/AddAdminPopover';
import ChangeRolePopover from 'components/AdminDashboard/components/AdminRolesPage/components/ChangeRolePopover/ChangeRolePopover';
import ChangeOrganizationPopover from 'components/AdminDashboard/components/AdminRolesPage/components/ChangeOrganizationPopover/ChangeOrganizationPopover';
import { getAccessLevelLabel } from 'components/AdminDashboard/components/AdminRolesPage/helpers';
import { isSuperAdmin } from 'common/helpers/user';
import { getUsers } from 'store/users/actions';
import { RootState } from 'store';

import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo, useState, MouseEvent } from 'react';
import { Box, Button, IconButton, Paper, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Edit, Add } from '@mui/icons-material';

interface Popover {
  el: HTMLElement | null;
  userId: number | null;
}

interface RolePopover extends Popover {
  accessLevel: UserAccessLevel | null;
}

interface OrgPopover extends Popover {
  orgId: number | null;
}

type PopoverEvent = MouseEvent<HTMLElement>;

const AdminRolesPage: React.FC = () => {
  const dispatch = useDispatch();
  const fetchUsers = useCallback((page: number, pageSize: number, userName: string) => {
    dispatch(getUsers(page, pageSize, { userName, onlyAdmins: true }));
  }, [ dispatch ]);
  const {
    users,
    pagination,
    isLoading,
  } = useSelector((store: RootState) => store.users);

  const [ addAdminPopoverEl, setAddAdminPopoverEl ] = useState<HTMLElement | null>(null);
  const onAddAdminButtonClick = (e: PopoverEvent) => setAddAdminPopoverEl(e.currentTarget);
  const closeAddAdminPopover = () => setAddAdminPopoverEl(null);

  const [ rolePopover, setRolePopover ] = useState<RolePopover>({ el: null, userId: null, accessLevel: null });
  const handleChangeRole = (accessLevel: UserAccessLevel) => setRolePopover({ el: rolePopover.el, userId: rolePopover.userId, accessLevel });
  const showRolePopover = (e: PopoverEvent, userId: number, accessLevel: UserAccessLevel) => setRolePopover({ el: e.currentTarget, userId, accessLevel });
  const closeRolePopover = () => setRolePopover({ el: null, userId: null, accessLevel: null });

  const [ orgPopover, setOrgPopover ] = useState<OrgPopover>({ el: null, userId: null, orgId: null });
  const handleChangeOrg = (orgId: number) => setOrgPopover({ el: orgPopover.el, userId: orgPopover.userId, orgId });
  const showOrgPopover = (e: PopoverEvent, userId: number, orgId: number | null) => setOrgPopover({ el: e.currentTarget, userId, orgId });
  const closeOrgPopover = () => setOrgPopover({ el: null, userId: null, orgId: null });

  const [ searchValue, setSearchValue ] = useState('');

  const headCells = useMemo((): HeadCell[] => [
    {
      id: 'userId',
      numeric: false,
      label: 'User id',
    },
    {
      id: 'user',
      numeric: false,
      label: 'User',
      button: <SearchUserInput onChange={e => setSearchValue(e.target.value)} />,
    },
    {
      id: 'role',
      numeric: false,
      label: 'Role',
    },
    {
      id: 'organization',
      numeric: false,
      label: 'Organization',
    },
  ], []);

  const renderRows = useCallback(() => users
    .map((row: UserView) => (
      <TableRow hover tabIndex={-1} key={row.id}>
        <TableCell>
          <Typography variant="textSRegular">
            {row.id}
          </Typography>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          padding="normal"
        >
          <Typography variant="textSRegular" component="div">
            {`${row.firstName} ${row.lastName}`}
          </Typography>
          <Typography variant="textSRegular" component="div">
            {row.username}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="textSRegular">
            {getAccessLevelLabel(row.accessRights)}
          </Typography>
          <Tooltip title="Change role">
            <Box component="span">
              <IconButton
                color="primary"
                size="small"
                disabled={isSuperAdmin(row)}
                onClick={e => showRolePopover(e, row.id, row.accessRights)}
                sx={{ marginLeft: 1 }}
              >
                <Edit fontSize="inherit" />
              </IconButton>
            </Box>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Typography variant="textSRegular">
            {row.organizationName}
          </Typography>
          <Tooltip title={row.organizationId ? 'Change organization' : 'Add organization'}>
            <IconButton
              color="primary"
              size="small"
              onClick={e => showOrgPopover(e, row.id, row.organizationId)}
              sx={{ marginLeft: 1 }}
            >
              {row.organizationId ? <Edit fontSize="inherit" /> : <Add fontSize="inherit" />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    )), [ users ]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <Box data-testid="admin-roles-page">
        <Box mb={2} display="flex" flexDirection="row-reverse">
          <Button
            sx={{
              backgroundColor: 'white',
              width: '102px',
              textTransform: 'none',
            }}
            variant="text"
            onClick={onAddAdminButtonClick}
          >
            Add Admin
          </Button>
          {addAdminPopoverEl && (
            <AddAdminPopover
              anchorEl={addAdminPopoverEl}
              onClose={closeAddAdminPopover}
            />
          )}
        </Box>
        <Paper sx={{ maxWidth: '100%' }}>
          <DataTable
            pagination={pagination}
            headCells={headCells}
            renderRows={renderRows}
            searchValue={searchValue}
            updateDataCallback={fetchUsers}
          />
        </Paper>
        {rolePopover.el && rolePopover.userId && rolePopover.accessLevel !== null && (
          <ChangeRolePopover
            anchorEl={rolePopover.el}
            userId={rolePopover.userId}
            accessLevel={rolePopover.accessLevel}
            onClose={closeRolePopover}
            onChange={handleChangeRole}
          />
        )}
        {orgPopover.el && orgPopover.userId && (
          <ChangeOrganizationPopover
            anchorEl={orgPopover.el}
            userId={orgPopover.userId}
            organizationId={orgPopover.orgId}
            onClose={closeOrgPopover}
            onChange={handleChangeOrg}
          />
        )}
      </Box>
    </LoadingContainer>
  );
};

export default AdminRolesPage;
