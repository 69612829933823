import { RootState } from 'store';
import SubmitButtons from 'components/Dashboard/components/SurveyPage/components/SubmitButtons/SubmitButtons';
import useQuestionForm from 'components/Dashboard/components/SurveyPage/hooks/useQuestionForm';
import { QuestionPageProps } from 'components/Dashboard/components/SurveyPage/types';
import { Survey, SurveyV1 } from 'api/User/types';

import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CovidVaccinatedQuestion: React.FC<QuestionPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  
  const { survey } = useSelector((store: RootState) => store.user.myUser);

  const [ error, setError ] = useState('');
  const [ value, setValue ] = useState('');

  const { submit, isSubmitting, submitError } = useQuestionForm();

  const onFormSubmit = useCallback((event) => {
    event.preventDefault();

    if (value) {
      submit({
        ...survey,
        covidVaccinated: value,
      })
        .then((data: Survey) => onNext(data));
    } else {
      setError(t('dashboard.survey.error_give_answer'));
    }
  }, [ t, value, submit, survey, onNext ]);

  const onRadioChanged = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  useEffect(() => {
    setValue((survey as SurveyV1)?.covidVaccinated || '');
  }, [ survey ]);

  return (
    <form onSubmit={onFormSubmit} data-testid="question-covid-vaccinated">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="textXLBold" color="primary">
            {t('dashboard.survey.question_covid_vaccinated.question')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              onChange={onRadioChanged}
              value={value || ''}
            >
              <FormControlLabel value="yes" control={<Radio />} label={t('dashboard.survey.question_covid_vaccinated.options.yes')} data-testid="option-yes" />
              <FormControlLabel value="no" control={<Radio />} label={t('dashboard.survey.question_covid_vaccinated.options.no')} data-testid="option-no" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {(error || submitError) && (
          <Grid item xs={12}>
            <Typography color="error" data-testid="label-error">
              {error || submitError}
            </Typography>
          </Grid>
        )}
        <SubmitButtons submitting={isSubmitting} />
      </Grid>
    </form>
  );
};

export default CovidVaccinatedQuestion;
