import Delete from '@mui/icons-material/Delete';
import React from 'react';

import styles from './ExtenalImg.module.css';

interface Props {
  logo: string;
  setLogo: (url: string) => void;
  onDeleteFile: () => void;
}

const ExternalImg: React.FC<Props> = ({ logo, setLogo, onDeleteFile }) => {
  const onDelete = () => {
    setLogo('');
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          style={{ maxWidth: '100%', margin: 'auto' }}
          src={logo}
          alt=""
        />
        <div className={styles.deletingWrap}>
          <Delete onClick={onDelete} />
        </div>
      </div>
    </div>
  );
};

export default ExternalImg;
