import { FormTextField } from 'common/components';
import useAddAdmin from 'components/AdminDashboard/components/AdminRolesPage/components/AddAdminPopover/hooks';
import { UserAccessLevel } from 'api/User/types';
import { AccessLevelLabels } from 'components/AdminDashboard/components/AdminRolesPage/types';

import React from 'react';
import { Button, FormControl, Grid, MenuItem, Popover, TextField, Typography } from '@mui/material';
import { FormikProvider } from 'formik';

interface Props {
  onClose: () => void;
  anchorEl: HTMLElement;
}

const AddAdminPopover: React.FC<Props> = ({ anchorEl, onClose }) => {
  const { formik, isSubmitting, submitError } = useAddAdmin(onClose);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 1,
        sx: { padding: 4 },
      }}
      onClose={onClose}
      data-testid="popover-add-admin"
      disableRestoreFocus
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display="flex" flexDirection="column" item container spacing={3}>
            <Grid item>
              <Typography>
                Invite an admin
              </Typography>
            </Grid>
            <Grid item>
              <FormTextField
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
                touched={formik.touched.email}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  id="role"
                  select
                  value={formik.values.role ?? ''}
                  label="Select a role"
                  name="role"
                  size="small"
                  disabled={isSubmitting}
                  onChange={formik.handleChange}
                  helperText={formik.touched.role && formik.errors.role}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  data-testid="input-role"
                >
                  <MenuItem value={UserAccessLevel.ADMIN}>{AccessLevelLabels.ADMIN}</MenuItem>
                  <MenuItem value={UserAccessLevel.BIO_BANK_ADMIN}>{AccessLevelLabels.BIO_BANK_ADMIN}</MenuItem>
                  <MenuItem value={UserAccessLevel.DATA_SCIENCE_ADMIN}>{AccessLevelLabels.DATA_SCIENCE_ADMIN}</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            {
              submitError && (
                <Grid item xs={12}>
                  <Typography color="error" variant="subtitle2">
                    {submitError}
                  </Typography>
                </Grid>
              )
            }
            <Grid item display="flex" justifyContent="flex-end">
              <Button
                disabled={isSubmitting}
                variant="contained"
                data-testid="button-invite-admin"
                type="submit"
              >
                Invite
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Popover>
  );
};

export default AddAdminPopover;
