import { TranslationsKey } from 'i18nextOptions';

import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

const useLocalizedProfile = () => {
  const { t } = useTranslation();

  const getLocalizedRace = useCallback((race: string) => {
    return race.split(', ')
      .map(simpleRace => t(`dashboard.profile.races.${simpleRace}` as TranslationsKey))
      .join(', ');
  }, [ t ]);

  const getLocalizedGender = useCallback((gender: string) => {
    return t(`dashboard.profile.genders.${gender}` as TranslationsKey);
  }, [ t ]);

  return {
    getLocalizedRace,
    getLocalizedGender,
  };
};

export default useLocalizedProfile;
