import { SurveyV2 } from 'api/User/types';
import HeightQuestion from 'components/Dashboard/components/SurveyPage/components/Common/HeightQuestion/HeightQuestion';
import WeightQuestion from 'components/Dashboard/components/SurveyPage/components/Common/WeightQuestion/WeightQuestion';
import { SurveyQuestionV2 } from 'components/Dashboard/components/SurveyPage/types';
import { getGrandparentsBornPlaceFilled } from 'components/Dashboard/components/SurveyPage/helpers';
import BornPlaceQuestion from 'components/Dashboard/components/SurveyPage/components/Common/BornPlaceQuestion/BornPlaceQuestion';
import GrandparentsBornPlaceQuestion
  from 'components/Dashboard/components/SurveyPage/components/Common/GrandparentsBornPlaceQuestion/GrandparentsBornPlaceQuestion';
import AncestralOriginQuestion from 'components/Dashboard/components/SurveyPage/components/V2/AncestralOriginQuestion/AncestralOriginQuestion';
import HeartDiseaseQuestion from 'components/Dashboard/components/SurveyPage/components/V2/HeartDiseaseQuestion/HeartDiseaseQuestion';
import PulmonaryDiseaseQuestion from 'components/Dashboard/components/SurveyPage/components/V2/PulmonaryDiseaseQuestion/PulmonaryDiseaseQuestion';
import SmokerQuestion from 'components/Dashboard/components/SurveyPage/components/V2/SmokerQuestion/SmokerQuestion';
import MetabolicDiseaseQuestion from 'components/Dashboard/components/SurveyPage/components/V2/MetabolicDiseaseQuestion/MetabolicDiseaseQuestion';
import LiverDisorderQuestion from 'components/Dashboard/components/SurveyPage/components/V2/LiverDisorderQuestion/LiverDisorderQuestion';
import DigestiveDisorderQuestion from 'components/Dashboard/components/SurveyPage/components/V2/DigestiveDisorderQuestion/DigestiveDisorderQuestion';
import KidneyDiseaseQuestion from 'components/Dashboard/components/SurveyPage/components/V2/KidneyDiseaseQuestion/KidneyDiseaseQuestion';
import NeurologicalDisorderQuestion from 'components/Dashboard/components/SurveyPage/components/V2/NeurologicalDisorderQuestion/NeurologicalDisorderQuestion';
import MentalIllnessQuestion from 'components/Dashboard/components/SurveyPage/components/V2/MentalIllnessQuestion/MentalIllnessQuestion';
import SkinDiseaseQuestion from 'components/Dashboard/components/SurveyPage/components/V2/SkinDiseaseQuestion/SkinDiseaseQuestion';
import RheumaticDiseaseQuestion from 'components/Dashboard/components/SurveyPage/components/V2/RheumaticDiseaseQuestion/RheumaticDiseaseQuestion';
import CancerQuestion from 'components/Dashboard/components/SurveyPage/components/V2/CancerQuestion/CancerQuestion';
import InfectiousDiseaseQuestion from 'components/Dashboard/components/SurveyPage/components/V2/InfectiousDiseaseQuestion/InfectiousDiseaseQuestion';

export const SURVEY_QUESTIONS_V2: SurveyQuestionV2[] = [
  {
    field: 'height',
    category: 'Demographics',
    component: HeightQuestion,
    filled: (survey: SurveyV2) => survey.height !== null && (survey.height.foots !== null || survey.height.centimeters !== null),
  },
  {
    field: 'weight',
    category: 'Demographics',
    component: WeightQuestion,
    filled: (survey: SurveyV2) => survey.weight !== null,
  },
  {
    field: 'bornPlace',
    category: 'Demographics',
    component: BornPlaceQuestion,
    filled: (survey: SurveyV2) => survey.bornPlace !== null && survey.bornPlace.country !== null && survey.bornPlace.city !== null,
  },
  {
    field: 'grandparentsBornPlace',
    category: 'Demographics',
    component: GrandparentsBornPlaceQuestion,
    filled: (survey: SurveyV2) => getGrandparentsBornPlaceFilled(survey),
  },
  {
    field: 'ancestralOrigin',
    category: 'Demographics',
    component: AncestralOriginQuestion,
    filled: (survey: SurveyV2) => survey.ancestralOrigin !== null,
  },
  {
    field: 'smoker',
    category: 'Health habits',
    component: SmokerQuestion,
    filled: (survey: SurveyV2) => survey.smoker !== null,
  },
  {
    field: 'heartDisease',
    category: 'Health Information',
    component: HeartDiseaseQuestion,
    filled: (survey: SurveyV2) => survey.heartDisease !== null && survey.heartDisease.answer !== null,
  },
  {
    field: 'pulmonaryDisease',
    category: 'Health Information',
    component: PulmonaryDiseaseQuestion,
    filled: (survey: SurveyV2) => survey.pulmonaryDisease !== null && survey.pulmonaryDisease.answer !== null,
  },
  {
    field: 'metabolicDisease',
    category: 'Health Information',
    component: MetabolicDiseaseQuestion,
    filled: (survey: SurveyV2) => survey.metabolicDisease !== null && survey.metabolicDisease.answer !== null,
  },
  {
    field: 'liverDisorder',
    category: 'Health Information',
    component: LiverDisorderQuestion,
    filled: (survey: SurveyV2) => survey.liverDisorder !== null && survey.liverDisorder.answer !== null,
  },
  {
    field: 'digestiveDisorder',
    category: 'Health Information',
    component: DigestiveDisorderQuestion,
    filled: (survey: SurveyV2) => survey.digestiveDisorder !== null && survey.digestiveDisorder.answer !== null,
  },
  {
    field: 'kidneyDisease',
    category: 'Health Information',
    component: KidneyDiseaseQuestion,
    filled: (survey: SurveyV2) => survey.kidneyDisease !== null && survey.kidneyDisease.answer !== null,
  },
  {
    field: 'neurologicalDisorder',
    category: 'Health Information',
    component: NeurologicalDisorderQuestion,
    filled: (survey: SurveyV2) => survey.neurologicalDisorder !== null && survey.neurologicalDisorder.answer !== null,
  },
  {
    field: 'mentalIllness',
    category: 'Health Information',
    component: MentalIllnessQuestion,
    filled: (survey: SurveyV2) => survey.mentalIllness !== null && survey.mentalIllness.answer !== null,
  },
  {
    field: 'skinDisease',
    category: 'Health Information',
    component: SkinDiseaseQuestion,
    filled: (survey: SurveyV2) => survey.skinDisease !== null && survey.skinDisease.answer !== null,
  },
  {
    field: 'rheumaticDisease',
    category: 'Health Information',
    component: RheumaticDiseaseQuestion,
    filled: (survey: SurveyV2) => survey.rheumaticDisease !== null && survey.rheumaticDisease.answer !== null,
  },
  {
    field: 'cancer',
    category: 'Health Information',
    component: CancerQuestion,
    filled: (survey: SurveyV2) => survey.cancer !== null && survey.cancer.answer !== null,
  },
  {
    field: 'infectiousDisease',
    category: 'Health Information',
    component: InfectiousDiseaseQuestion,
    filled: (survey: SurveyV2) => survey.infectiousDisease !== null && survey.infectiousDisease.answer !== null,
  },
];
