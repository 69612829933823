export const getGroupedGeographies = (geographies: any[]) => {
  const groupedGeographies: { [key: string]: any[] } = {
    afr: [],
    amr: [],
    eas: [],
    eur: [],
    sas: [],
    oce: [],
    lme: [],
    oth: [],
  };

  // todo use map regions from galatea
  geographies.forEach((geography) => {
    if (geography.properties.SUBREGION === 'Southern Asia' && geography.properties.NAME !== 'Afghanistan') {
      groupedGeographies.sas.push(geography);
    } else if (geography.properties.SUBREGION === 'Eastern Asia') {
      groupedGeographies.eas.push(geography);
    } else if (geography.properties.SUBREGION === 'Levantine and Middle East') {
      groupedGeographies.lme.push(geography);
    } else if (geography.properties.CONTINENT === 'Africa') {
      groupedGeographies.afr.push(geography);
    } else if (geography.properties.REGION_UN === 'Americas') {
      groupedGeographies.amr.push(geography);
    } else if (geography.properties.CONTINENT === 'Oceania') {
      groupedGeographies.oce.push(geography);
    } else if (geography.properties.REGION_UN === 'Europe') {
      groupedGeographies.eur.push(geography);
    } else {
      groupedGeographies.oth.push(geography);
    }
  });

  return groupedGeographies;
};
