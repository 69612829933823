import { RootState } from 'store';
import { CardWithPopoverProps } from 'components/Dashboard/components/CardsPage/types';
import DashboardCard from 'components/Dashboard/components/CardsPage/DashboardCard/DashboardCard';
import { ANCESTRY_ROUTE } from 'common/constants/urls';
import { getAncestryProgress } from 'common/helpers/ancestry';

import { useSelector } from 'react-redux';
import { Box, Button, Grid, Popover, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const AncestryDashboardCard: React.FC<CardWithPopoverProps> = ({ forceShowPopover, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { myUser } = useSelector((state: RootState) => state.user);

  const [ isPopoverOpened, setPopoverOpened ] = useState(false);
  const cardRef = React.useRef<HTMLElement | null>(null);

  const isSurveyCompleted = myUser.surveyCompletedPercentage === 100;
  const ancestryProgress = isSurveyCompleted ? getAncestryProgress(myUser.ancestries, myUser.ancestryFiles, myUser.testKitReceived) : -1;

  const hidePopover = () => {
    setPopoverOpened(false);
    navigate(location.pathname, { state: {} });
  };

  const showPopover = () => {
    if (!isSurveyCompleted) {
      setPopoverOpened(true);
    }
  };

  useEffect(() => {
    if (forceShowPopover) {
      setPopoverOpened(true);
    }
  }, [ setPopoverOpened, forceShowPopover ]);

  return (
    <>
      <Box
        ref={cardRef}
        onClick={showPopover}
        sx={{
          pointerEvents: isPopoverOpened ? 'none' : 'auto',
        }}
        {...props}
      >
        <DashboardCard
          label={t('dashboard.ancestry.ancestry')}
          buttonLabel={t('dashboard.ancestry.button_upload_data')}
          link={ANCESTRY_ROUTE}
          progress={ancestryProgress}
        />
      </Box>
      <Popover
        BackdropProps={{
          invisible: false,
          sx: {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
        }}
        open={isPopoverOpened}
        anchorEl={cardRef.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: { borderRadius: '30px' },
          elevation: 5,
        }}
        disableRestoreFocus
      >
        <Grid container padding={3} spacing={4} maxWidth={425}>
          <Grid item xs={12}>
            <Typography variant="textXLBold">
              {t('dashboard.ancestry.card_lock_dialog.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {t('dashboard.ancestry.card_lock_dialog.body')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" fullWidth onClick={hidePopover}>
              {t('dashboard.ancestry.card_lock_dialog.button_got_it')}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default AncestryDashboardCard;
