import { white } from 'common/constants/colors';
import { AncestryData } from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/types';
import { getGroupedGeographies }
  from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/components/AncestryMap/helpers';
import geoJson
  from 'components/Dashboard/components/AncestryPage/components/AncestryFilesView/components/AncestryReportView/components/AncestryMap/resources/world-110m.json';

import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { Tooltip } from '@mui/material';
import React from 'react';

interface Props {
  ancestryData: AncestryData[];
}

const AncestryMap: React.FC<Props> = ({ ancestryData }) => {
  return (
    <ComposableMap data-tip="" projectionConfig={{ scale: 180 }} data-testid="ancestry-map">
      <Geographies geography={geoJson}>
        {({ geographies }) => {
          const groupedGeographies = getGroupedGeographies(geographies);

          const mapProps = ancestryData.map(data => ({
            ...data,
            geographies: groupedGeographies[data.ancestry],
          }));
          
          return (
            <>
              <g>
                {groupedGeographies.oth.map((geo: any) => {
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      style={{
                        default: {
                          fill: white,
                          outline: 'none',
                        },
                        hover: {
                          fill: white,
                          outline: 'none',
                        },
                        pressed: {
                          fill: white,
                          outline: 'none',
                        },
                      }}
                    />
                  );
                })};
              </g>
              {mapProps.map(({ value, title, geographies, color }) => (
                <Tooltip title={`${title}: ${value}%`} followCursor enterDelay={200} key={title}>
                  <g>
                    {geographies && geographies.map((geo: any) => {
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          style={{
                            default: {
                              fill: value ? color : white,
                              outline: 'none',
                            },
                            hover: {
                              fill: value ? color : white,
                              outline: 'none',
                            },
                            pressed: {
                              fill: value ? color : white,
                              outline: 'none',
                            },
                          }}
                        />
                      );
                    })};
                  </g>
                </Tooltip>
              ))}
            </>
          );
        }}
      </Geographies>
    </ComposableMap>
  );
};

export default AncestryMap;
