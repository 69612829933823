import { AuthResponse, CommonAuthResponse, MFAAuthResponse, RegisterParams, RegisterResult } from 'api/Auth/types';
import { axiosInstance } from 'api/instance';

import { AxiosResponse } from 'axios';

export const postRegister = (params: RegisterParams): Promise<AxiosResponse<RegisterResult>> => {
  return axiosInstance.post<RegisterResult>('users/register', params);
};

export const postDiscardAgreement = (): Promise<AxiosResponse> => {
  return axiosInstance.post('users/discard-agreement?deleteProfile=true');
};

export const postAuth = (username: string, password: string): Promise<AxiosResponse<AuthResponse>> => {
  return axiosInstance.post<CommonAuthResponse>('users/authenticate', { username, password });
};

export const postConfirm = (code: string, mfaSessionId: string): Promise<AxiosResponse<CommonAuthResponse>> => {
  return axiosInstance.post<CommonAuthResponse>('users/confirm', { code, mfaSessionId });
};

export const postRequestNewCode = (mfaSessionId: string): Promise<AxiosResponse<MFAAuthResponse>> => {
  return axiosInstance.post<MFAAuthResponse>('users/request-new-code', { mfaSessionId });
};

export const postRefreshToken = (refreshToken: string): Promise<AxiosResponse<CommonAuthResponse>> => {
  return axiosInstance.post<CommonAuthResponse>('users/refresh-token', { refreshToken });
};

export const postRevokeTokens = (token: string | null, refreshToken: string | null): Promise<AxiosResponse> => {
  const data = {
    ...(token && { Token: token }),
    ...(refreshToken && { RefreshToken: refreshToken }),
  };

  return axiosInstance.post('token/revoke', data);
};

export const postVerifyAccount = (verificationKey: string): Promise<AxiosResponse<CommonAuthResponse>> => {
  return axiosInstance.post<CommonAuthResponse>(`users/verify/${verificationKey}`);
};
